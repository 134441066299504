import React from "react";
import "./ParticipantBoard.css";
import Tabs from "../../Common/Tabs/Tabs";
import Input from "../../Common/Input/Input";
import CustomersTab from "./Customer/Customers";
import ContractorTab from "./Contractor/Contractors";
import ProgramLayout from "../../Common/ProgramLayout";
import LoaderComponent from "../../Common/Loader/Loader";
import Modal from "../../Common/Modal/Modal";
import { connect, ReactReduxContext } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { participantActions } from "../../../redux/actions/participantActions";
import AddParticipant from "./AddParticipant/AddParticipant";
import ContractorEdit from "./Contractor/ContractorEdit/ContractorEdit";
import CustomerEdit from "./Customer/CustomerEdit/CustomerEdit";
import ParticipantApplications from "./Customer/ParticipantApplications/ParticipantApplications";
import ContractorReview from "./Contractor/ContractorReview/ContractorReview";
import CustomerReview from "./Customer/CustomerReview/CustomerReview";
import Button from "../../Common/Button/Button";
import { AuthContext } from "../../../firebaseAuthContext";
import Documents from "./Documents/Documents";
import { userService } from "../../../services/userService";
import { utils } from "../../../helpers/utils";
import ModalAppDataComponent from "../../Common/ModalAppDetails/ModalAppData";
import Table from "../../Common/Table/Table";
import ParticipantProjects from "./Customer/ParticipantProjects/ParticipantProjects";
class ParticipantBoard extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
      }
      if (this.props.history.location.pathname.includes("retrofit")) {
        currentProgram = "retrofit";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      currentProgram,
      createAccountModalOpen: false,
      showEditParticipantModal: false,
      showReviewParticipantModal: false,
      selectedUserData: {},
      showFlagModal: false,
      showSuspendModal: false,
      showReinstateModal: false,
      showActiveModal: false,
      showInactiveModal: false,
      showResendMailModal: false,
      showDocumentsModal: false,
      showSubmittedApplicationsModal: false,
      showRetrofitApplicantModal: false,
      showRetrofitApplicantProjectsModal: false,
      showRetrofitApplicantRepModal: false,
      showRetrofitApplicantRepProjectsModal: false,
      confirmingSuspension: false,
      suspensionMessage: "",
      confirmingReinstation: false,
      reinstationMessage: false,

      confirmingActive: false,
      activeMessage: "",
      confirmingInactive: false,
      inactiveMessage: false,

      confirmingFlagForQC: false,
      participantDetails: {},
      participantDetailsLoading: true,
      flagForQCMessage: "",
      contractorFilters: {
        SelectedFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
      },
      customerFilters: {
        SelectedFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
      },
    };
  }

  componentDidMount() {
    this.updateUsersDetails();
  }

  updateUsersDetails() {
    this.props.loadUserDetails();
    if (!this.props.history.location.pathname.includes("retrofit")) {
      this.props.getParticipants(this.state.currentProgram, "contractor");
      this.props.getParticipants(this.state.currentProgram, "customer");
    } else {
      this.props.getParticipantsRetrofit(this.state.currentProgram, "contractor");
      this.props.getParticipantsRetrofit(this.state.currentProgram, "customer");
    }
  }

  addParticipant = () => {
    this.setState({ createAccountModalOpen: true }, () => {
      this.props.showModal(false);
    });
  };

  closeAddParticipant = () => {
    this.setState({ createAccountModalOpen: false });
  };

  confirmSuspension = () => {
    this.setState({
      confirmingSuspension: !this.state.confirmingSuspension,
    });
  };

  confirmActive = () => {
    this.setState({
      confirmingActive: !this.state.confirmingActive,
    });
  };

  confirmReinstation = () => {
    this.setState({
      confirmingReinstation: !this.state.confirmingReinstation,
    });
  };

  confirmInactive = () => {
    this.setState({
      confirmingInactive: !this.state.confirmingInactive,
    });
  };

  confirmFlagForQC = () => {
    this.setState({
      confirmingFlagForQC: !this.state.confirmingFlagForQC,
    });
  };

  handleSuspensionMessageChange = (name, value) => {
    this.setState({
      suspensionMessage: value,
    });
  };

  handleActiveMessageChange = (name, value) => {
    this.setState({
      activeMessage: value,
    });
  };

  handleflagForQCMessageChange = (name, value) => {
    this.setState({
      flagForQCMessage: value,
    });
  };

  handleReinstationMessageChange = (name, value) => {
    this.setState({
      reinstationMessage: value,
    });
  };

  handleInactiveMessageChange = (name, value) => {
    this.setState({
      inactiveMessage: value,
    });
  };

  getparticipantinfo(uid) {
    this.setState({
      participantDetails: {},
    });
    if (this.state.currentProgram !== "retrofit") {
      userService.getParticipantDetails(this.state.currentProgram, uid).then((participantDetails) => {
        this.setState({
          participantDetails: participantDetails,
          participantDetailsLoading: false,
        });
      });
    } else {
      userService.getParticipantDetailsRetrofit(uid).then((participantDetails) => {
        this.setState({
          participantDetails: participantDetails,
          participantDetailsLoading: false,
        });
      });
    }
  }
  showActionModal = (selectedUserData) => {
    this.setState({
      selectedUserData,
      participantDetailsLoading: true,
    });
    if (selectedUserData.modalType === "edit") {
      const newToggleState = !this.state.showEditParticipantModal;
      this.setState({
        showEditParticipantModal: newToggleState,
      });
    } else if (selectedUserData.modalType === "view-applicant") {
      this.setState({
        showRetrofitApplicantModal: !this.state.showRetrofitApplicantModal,
      });
    }
    else if (selectedUserData.modalType === "view-applicant-projects") {
      this.setState({
        showRetrofitApplicantProjectsModal: !this.state.showRetrofitApplicantProjectsModal,
      });
    }
    else if (selectedUserData.modalType === "view-applicantRep") {
      this.setState({
        showRetrofitApplicantRepModal: !this.state.showRetrofitApplicantRepModal,
      });      
    } 
    else if (selectedUserData.modalType === "view-applicantRep-projects") {
      this.setState({
        showRetrofitApplicantRepProjectsModal: !this.state.showRetrofitApplicantRepProjectsModal,
      });
    }
    else if (selectedUserData.modalType === "review") {
      this.setState({
        showReviewParticipantModal: !this.state.showReviewParticipantModal,
      });
    } else if (selectedUserData.modalType === "flag-qa") {
      this.getparticipantinfo(selectedUserData.uid);
      this.setState({
        showFlagModal: !this.state.showFlagModal,
        flagForQCMessage: "",
        confirmingFlagForQC: false,
      });
    } else if (selectedUserData.modalType === "suspend") {
      this.getparticipantinfo(selectedUserData.uid);
      this.setState({
        showSuspendModal: !this.state.showSuspendModal,
        suspensionMessage: "",
        confirmingSuspension: false,
      });
    } else if (selectedUserData.modalType === "reinstate") {
      this.getparticipantinfo(selectedUserData.uid);
      this.setState({
        showReinstateModal: !this.state.showReinstateModal,
        reinstationMessage: "",
        confirmingReinstation: false,
      });
    } else if (selectedUserData.modalType === "active") {
      this.getparticipantinfo(selectedUserData.uid);
      this.setState({
        showActiveModal: !this.state.showActiveModal,
        activeMessage: "",
        confirmingActive: false,
      });
    } else if (selectedUserData.modalType === "inactive") {
      this.getparticipantinfo(selectedUserData.uid);
      this.setState({
        showInactiveModal: !this.state.showInactiveModal,
        inactiveMessage: "",
        confirmingInactive: false,
      });
    } else if (selectedUserData.modalType === "send-mail") {
      this.setState({
        showResendMailModal: !this.state.showResendMailModal,
      });
    } else if (selectedUserData.modalType === "view-docs") {
      this.setState({
        showDocumentsModal: !this.state.showDocumentsModal,
      });
    } else if (selectedUserData.modalType === "show-submitted-apps") {
      this.setState({
        showSubmittedApplicationsModal: !this.state.showSubmittedApplicationsModal,
      });
    } else {
      // Do Nothing
    }
  };

  closeActionModal = () => {
    this.props.resetEmailVerificationModal();
    this.setState({
      showEditParticipantModal: false,
      showReviewParticipantModal: false,
      showSubmittedApplicationsModal: false,
      showFlagModal: false,
      showSuspendModal: false,
      showReinstateModal: false,
      showActiveModal: false,
      showInactiveModal: false,
      showResendMailModal: false,
      showDocumentsModal: false,
      confirmingSuspension: false,
      confirmingFlagForQC: false,
      showRetrofitApplicantModal: false,
      showRetrofitApplicantProjectsModal: false,
      showRetrofitApplicantRepModal: false,
      showRetrofitApplicantRepProjectsModal: false,
      suspensionMessage: "",
      reinstationMessage: "",
      activeMessage: "",
      inactiveMessage: "",
      flagForQCMessage: "",
    });
  };

  flagParticipantForQaQc = async () => {
    const uid = this.state.selectedUserData && this.state.selectedUserData.uid;
    const participantInfo = this.state.selectedUserData.userInfo;
    const userData = {
      actionTaker: `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`,
      actionNote: this.state.flagForQCMessage,
      status: "Flagged for QA/QC",
    };
    await userService.updateUserDetails(this.state.currentProgram, uid, userData);
    this.searchData(participantInfo.accountCategory, true);
    this.setState({
      showFlagModal: false,
    });
  };

  setFilters = (accCategory, newState) => {
    let updateObj = {};
    updateObj = {
      [`${accCategory}Filters`]: {
        ...this.state[`${accCategory}Filters`],
        ...newState,
      },
    };
    this.setState(updateObj, () => {
      this.searchData(accCategory, true);
    });
  };

  searchData = (accCategory, refresh = false) => {
    const { sortConfig, SelectedFilters, searchInput } = this.state[`${accCategory}Filters`];
    let config = { statuses: SelectedFilters, sort: sortConfig };
    if (this.state.currentProgram !== "retrofit") {
      this.props.getParticipants(this.state.currentProgram, accCategory, searchInput, config, refresh ? 0 : this.props.participantOffset);
    } else {
      this.props.getParticipantsRetrofit(
        this.state.currentProgram,
        accCategory,
        searchInput,
        config,
        refresh ? 0 : this.props.participantOffset
      );
    }
  };

  suspendParticipant = async () => {
    const uid = this.state.selectedUserData && this.state.selectedUserData.uid;
    const participantInfo = this.state.selectedUserData.userInfo;
    const userData = {
      actionTaker: `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`,
      actionNote: this.state.suspensionMessage,
      status: "Suspended",
    };
    await userService.updateUserDetails(this.state.currentProgram, uid, userData);
    this.searchData(participantInfo.accountCategory, true);
    this.setState({
      showSuspendModal: false,
    });
  };

  reinstateContractor = async () => {
    const uid = this.state.selectedUserData && this.state.selectedUserData.uid;
    const participantInfo = this.state.selectedUserData.userInfo;
    const userData = {
      actionTaker: `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`,
      actionNote: this.state.reinstationMessage,
      status: "Participating",
    };
    await userService.updateUserDetails(this.state.currentProgram, uid, userData);
    this.searchData(participantInfo.accountCategory, true);
    this.setState({
      showReinstateModal: false,
    });
  };

  activeParticipant = async () => {
    const uid = this.state.selectedUserData && this.state.selectedUserData.uid;
    const participantInfo = await userService.getParticipantDetails(this.state.currentProgram, uid);
    let userData = {};
    userData.actionTaker = `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`;
    userData.actionNote = this.state.activeMessage;
    userData.status = participantInfo.previousStatus ? participantInfo.previousStatus : "Participating";
    await userService.updateUserDetails(this.state.currentProgram, uid, userData);
    this.searchData(participantInfo.accountCategory, true);
    this.setState({
      showActiveModal: false,
    });
  };

  inactiveParticipant = async () => {
    const uid = this.state.selectedUserData && this.state.selectedUserData.uid;
    const participantInfo = await userService.getParticipantDetails(this.state.currentProgram, uid);
    const userData = {
      actionTaker: `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`,
      actionNote: this.state.inactiveMessage,
      status: "Inactive",
      previousStatus: participantInfo.status === "Inactive" ? participantInfo.previousStatus : participantInfo.status,
    };
    await userService.updateUserDetails(this.state.currentProgram, uid, userData);
    this.searchData(participantInfo.accountCategory, true);
    this.setState({
      showInactiveModal: false,
    });
  };

  render() {
    const { displayName } = this.context.user;
    const { customerList, contractorList, contractorsLoading, customersLoading, resendingEmail, emailResent } = this.props;
    const {
      selectedUserData,
      createAccountModalOpen,
      showEditParticipantModal,
      showSubmittedApplicationsModal,
      showReviewParticipantModal,
      showRetrofitApplicantModal,
      showRetrofitApplicantProjectsModal,
      showRetrofitApplicantRepModal,
      showRetrofitApplicantRepProjectsModal,
      showFlagModal,
      showSuspendModal,
      showReinstateModal,
      showActiveModal,
      showInactiveModal,
      showResendMailModal,
      showDocumentsModal,
      confirmingSuspension,
      suspensionMessage,
      confirmingReinstation,
      reinstationMessage,
      activeMessage,
      confirmingActive,
      inactiveMessage,
      confirmingInactive,
      confirmingFlagForQC,
      flagForQCMessage,
      customerFilters,
      contractorFilters,
      participantDetailsLoading,
      participantDetails,
      addParticipantModalShown,
      currentProgram,
    } = this.state;
    const participantInfo = selectedUserData && selectedUserData.userInfo;
    let selectedCustomerData = [
      { label: "First Name", value: selectedUserData.firstName },
      { label: "Last Name", value: selectedUserData.lastName },
      { label: "Mobile Number", value: selectedUserData.userInfo ? selectedUserData.userInfo.phone : "" },
      { label: "Email", value: selectedUserData.EmailID },
      {
        label: "Company Address",
        value: selectedUserData.userInfo
          ? `${selectedUserData.userInfo.address1}, ${
              selectedUserData.userInfo.address2 ? ", " + selectedUserData.userInfo.address2 : ""
            } ${selectedUserData.userInfo.city}, ${selectedUserData.userInfo.province}, ${selectedUserData.userInfo.postal_code}`
          : "",
      },
    ];
    return (
      <ProgramLayout showFooter={false} props={this.props}>
        {participantInfo ? (
          <React.Fragment>
            {/* The below section is for Success modal for Add Participant */}
            <Modal
              HeaderText={"PARTICIPANT ADDED SUCCESSFULLY!"}
              className={"esb-modal"}
              IsOpen={createAccountModalOpen}
              handleCloseModal={() => this.closeAddParticipant()}
            >
              <div className="message-box-modal">
                <p>
                  <span>Anirudh Challa</span> has been added as a participant to the program.
                </p>
                <p>
                  Please ask the participant to visit the inbox of the registered email id and <span>click on the verification link</span>{" "}
                  to access the account page.
                </p>
                <p>
                  <b>Participant did not receive the verification email?</b>
                </p>
                <Button title={"Re-Send Email"} uppercase />
              </div>
            </Modal>
            {/* The below modal is for Add Participant */}
            <Modal
              IsOpen={this.props.addParticipantModalShown}
              className={"esb-modal"}
              handleCloseModal={() => this.props.showModal(false)}
              modalSize={"large"}
            >
              <AddParticipant addParticipant={this.addParticipant} />
            </Modal>

            {/* The below modal is for Edit Contractor/Customer  */}
            <Modal
              IsOpen={showEditParticipantModal}
              className={"esb-modal"}
              handleCloseModal={() => this.closeActionModal()}
              modalSize={"large"}
              // loading={activeParticipantLoading}
            >
              {participantInfo && participantInfo.accountCategory === "contractor" ? (
                <ContractorEdit updateUsersDetails={() => this.updateUsersDetails()} selectedContractor={selectedUserData} />
              ) : (
                <CustomerEdit updateUsersDetails={() => this.updateUsersDetails()} selectedCustomer={selectedUserData} />
              )}
            </Modal>
            <Modal
              IsOpen={showRetrofitApplicantModal}
              className={"esb-modal"}
              handleCloseModal={() => this.closeActionModal()}
              modalSize={"large"}
              // loading={activeParticipantLoading}
            >
              <div className="view-applicant">
                {selectedUserData ? (
                  <div className="section-header">
                    <div className="section-top-details">{selectedUserData.CustomerID}</div>
                    <div className="section-top-details name-section">{selectedUserData.firstName}</div>
                    <div className="section-top-details">{selectedUserData.EmailID}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="section-below-header">
                  <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Applicant Details" }}></h2>
                </div>
                <div className="applicant-details">
                  <Table
                    rows={selectedCustomerData}
                    headings={[
                      { title: "Field Name", value: "label" },
                      { title: "Field Value", value: "value" },
                    ]}
                  />
                </div>
              </div>
            </Modal> 
             <Modal
              IsOpen={showRetrofitApplicantProjectsModal}
              className={"esb-modal"}
              handleCloseModal={() => this.closeActionModal()}
              modalSize={"large"}
            >
             <ParticipantProjects selectedParticipant={selectedUserData} />
            </Modal>        
            <Modal
              IsOpen={showRetrofitApplicantRepModal}
              className={"esb-modal"}
              handleCloseModal={() => this.closeActionModal()}
              modalSize={"large"}
              // loading={activeParticipantLoading}
            >
              <div className="view-applicant">
                {selectedUserData ? (
                  <div className="section-header">
                    <div className="section-top-details">{selectedUserData.ContractorID}</div>
                    <div className="section-top-details name-section">{selectedUserData.firstName}</div>
                    <div className="section-top-details">{selectedUserData.EmailID}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="section-below-header">
                  <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Applicant Rep Details" }}></h2>
                </div>
                <div className="applicant-details">
                  <Table
                    rows={selectedCustomerData}
                    headings={[
                      { title: "Field Name", value: "label" },
                      { title: "Field Value", value: "value" },
                    ]}
                  />
                </div>
              </div>
            </Modal>
             <Modal
              IsOpen={showRetrofitApplicantRepProjectsModal}
              className={"esb-modal"}
              handleCloseModal={() => this.closeActionModal()}
              modalSize={"large"}
            >
             <ParticipantProjects selectedParticipant={selectedUserData} />
            </Modal>   
            <Modal
              IsOpen={showSubmittedApplicationsModal}
              className={"esb-modal"}
              handleCloseModal={() => this.closeActionModal()}
              modalSize={"large"}
              // loading={activeParticipantLoading}
            >
              <ParticipantApplications selectedParticipant={selectedUserData} />
            </Modal>

            {/* The below modal is for Review Contractor/Customer  */}
            <Modal
              IsOpen={showReviewParticipantModal}
              className={"esb-modal"}
              handleCloseModal={() => this.closeActionModal()}
              modalSize={"large"}
            >
              {participantInfo && participantInfo.accountCategory === "contractor" ? (
                <ContractorReview selectedContractor={selectedUserData} />
              ) : (
                <CustomerReview />
              )}
            </Modal>

            {/* The below modal is for Flag Contractor/Customer for QC  */}
            <Modal
              //  HeaderText={`FLAG ${participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()} FOR QC`}
              className={"esb-modal"}
              IsOpen={showFlagModal}
              modalSize={"large"}
              handleCloseModal={() => this.closeActionModal()}
            >
              {participantInfo ? (
                <React.Fragment>
                  {participantDetailsLoading ? <LoaderComponent /> : ""}
                  {participantDetails.userNo ? (
                    <div className="appinfoblock">
                      <ModalAppDataComponent
                        participantInfo={participantDetails}
                        modalTitle={`FLAG ${
                          participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()
                        } FOR QC`}
                      ></ModalAppDataComponent>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                ""
              )}
              {/* <div className="section-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html:`FLAG ${participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()} FOR QC`}}></h2>
                    </div> */}
              <p>Are you sure you want to Flag the {participantInfo && participantInfo.accountCategory} for QC?</p>
              <Input
                disabled={confirmingFlagForQC}
                type={"richtext"}
                value={flagForQCMessage}
                onChange={this.handleflagForQCMessageChange}
                name="flagForQCMessage"
              />
              <div className="button-set">
                {confirmingFlagForQC ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmFlagForQC} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                )}
                {confirmingFlagForQC ? (
                  <Button
                    className="signup-btn"
                    title={"Flag for QA/QC"}
                    uppercase
                    onClick={this.flagParticipantForQaQc}
                    disabled={!utils.cleanHTMLText(flagForQCMessage)}
                  />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Confirm Flag for QA/QC"}
                    uppercase
                    onClick={this.confirmFlagForQC}
                    disabled={!utils.cleanHTMLText(flagForQCMessage)}
                  />
                )}
              </div>
            </Modal>

            {/* The below modal is for Suspend Contractor  */}
            <Modal
              //HeaderText={`SUSPEND ${participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()}`}
              IsOpen={showSuspendModal}
              className={"esb-modal"}
              modalSize={"large"}
              handleCloseModal={() => this.closeActionModal()}
            >
              {participantDetailsLoading ? <LoaderComponent /> : ""}
              {participantDetails.userNo ? (
                <div className="appinfoblock">
                  <ModalAppDataComponent
                    participantInfo={participantDetails}
                    modalTitle={`SUSPEND ${
                      participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()
                    }`}
                  ></ModalAppDataComponent>
                </div>
              ) : (
                ""
              )}
              <p>Are you sure you want to suspend the {participantInfo && participantInfo.accountCategory}?</p>
              <Input
                disabled={confirmingSuspension}
                type={"richtext"}
                value={suspensionMessage}
                onChange={this.handleSuspensionMessageChange}
                name="supsensionMessage"
              />
              <div className="button-set">
                {confirmingSuspension ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmSuspension} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                )}
                {confirmingSuspension ? (
                  <Button
                    className="signup-btn"
                    title={"Suspend"}
                    uppercase
                    onClick={this.suspendParticipant}
                    disabled={!utils.cleanHTMLText(suspensionMessage)}
                  />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Confirm Suspension"}
                    uppercase
                    onClick={this.confirmSuspension}
                    disabled={!utils.cleanHTMLText(suspensionMessage)}
                  />
                )}
              </div>
            </Modal>
            {/* The below modal is for Reinstating a Contractor/Customer  */}
            <Modal
              // HeaderText={`REINSTATE ${participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()}`}
              IsOpen={showReinstateModal}
              className={"esb-modal"}
              modalSize={"large"}
              handleCloseModal={() => {
                this.closeActionModal();
              }}
            >
              {participantDetailsLoading ? <LoaderComponent /> : ""}
              {participantDetails.userNo ? (
                <div className="appinfoblock">
                  <ModalAppDataComponent
                    participantInfo={participantDetails}
                    modalTitle={`REINSTATE ${
                      participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()
                    }`}
                  ></ModalAppDataComponent>
                </div>
              ) : (
                ""
              )}
              <p>Are you sure you want to reinstate the {participantInfo && participantInfo.accountCategory}?</p>
              <Input
                disabled={confirmingReinstation}
                type={"richtext"}
                value={reinstationMessage}
                onChange={this.handleReinstationMessageChange}
                name="reinstationMessage"
              />
              <div className="button-set">
                {confirmingReinstation ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmReinstation} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                )}
                {confirmingReinstation ? (
                  <Button
                    className="signup-btn"
                    title={"Re-Instate"}
                    uppercase
                    onClick={this.reinstateContractor}
                    disabled={!utils.cleanHTMLText(reinstationMessage)}
                  />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Confirm Re-instation"}
                    uppercase
                    onClick={this.confirmReinstation}
                    disabled={!utils.cleanHTMLText(reinstationMessage)}
                  />
                )}
              </div>
            </Modal>

            {/* The below modal is for Active Customer  */}
            <Modal IsOpen={showActiveModal} className={"esb-modal"} modalSize={"large"} handleCloseModal={() => this.closeActionModal()}>
              {participantDetailsLoading ? <LoaderComponent /> : ""}
              {participantDetails.userNo ? (
                <div className="appinfoblock">
                  <ModalAppDataComponent participantInfo={participantDetails} modalTitle={`MARK AS ACTIVE`}></ModalAppDataComponent>
                </div>
              ) : (
                ""
              )}
              <p>Are you sure you want to mark this user as Active?</p>
              <Input
                disabled={confirmingActive}
                type={"richtext"}
                value={activeMessage}
                onChange={this.handleActiveMessageChange}
                name="activeMessage"
              />
              <div className="button-set">
                {confirmingActive ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmActive} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                )}
                {confirmingActive ? (
                  <Button
                    className="signup-btn"
                    title={"Activate Account"}
                    uppercase
                    onClick={this.activeParticipant}
                    disabled={!utils.cleanHTMLText(activeMessage)}
                  />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Confirm Active"}
                    uppercase
                    onClick={this.confirmActive}
                    disabled={!utils.cleanHTMLText(activeMessage)}
                  />
                )}
              </div>
            </Modal>
            {/* The below modal is for Reinstating a Contractor/Customer  */}
            <Modal
              // HeaderText={`REINSTATE ${participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()}`}
              IsOpen={showInactiveModal}
              className={"esb-modal"}
              modalSize={"large"}
              handleCloseModal={() => {
                this.closeActionModal();
              }}
            >
              {participantDetailsLoading ? <LoaderComponent /> : ""}
              {participantDetails.userNo ? (
                <div className="appinfoblock">
                  <ModalAppDataComponent participantInfo={participantDetails} modalTitle={`MARK AS INACTIVE`}></ModalAppDataComponent>
                </div>
              ) : (
                ""
              )}
              <p>Are you sure you want to mark this user as Inactive?</p>
              <Input
                disabled={confirmingInactive}
                type={"richtext"}
                value={inactiveMessage}
                onChange={this.handleInactiveMessageChange}
                name="inactiveMessage"
              />
              <div className="button-set">
                {confirmingInactive ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmInactive} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeActionModal()} />
                )}
                {confirmingInactive ? (
                  <Button
                    className="signup-btn"
                    title={"Deactivate Account"}
                    uppercase
                    onClick={this.inactiveParticipant}
                    disabled={!utils.cleanHTMLText(inactiveMessage)}
                  />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Confirm Inactive"}
                    uppercase
                    onClick={this.confirmInactive}
                    disabled={!utils.cleanHTMLText(inactiveMessage)}
                  />
                )}
              </div>
            </Modal>

            {/* The below modal is for Resend Mail  */}

            <Modal
              HeaderText={"RESEND VERIFICATION EMAIL"}
              IsOpen={showResendMailModal}
              className={"esb-modal"}
              handleCloseModal={() => {
                this.closeActionModal();
              }}
              loading={resendingEmail}
            >
              {emailResent ? (
                <p>A verification email has been sent out </p>
              ) : (
                <p>Are you sure you want to resend the verification email?</p>
              )}
              {!emailResent ? (
                <div className="button-set">
                  <Button
                    className="inversed-btn"
                    title={"YES"}
                    onClick={() => {
                      this.props.verifyEmail(currentProgram, participantInfo.email);
                      window.setTimeout(this.closeActionModal, 5000);
                    }}
                    uppercase
                  />
                  <Button title={"NO"} onClick={() => this.closeActionModal()} uppercase />
                </div>
              ) : (
                ""
              )}
            </Modal>

            {/* The below modal is for View Documents  */}
            <Modal IsOpen={showDocumentsModal} className={"esb-modal"} handleCloseModal={() => this.closeActionModal()} modalSize={"large"}>
              <Documents />
            </Modal>
          </React.Fragment>
        ) : null}
        {contractorsLoading || customersLoading ? (
          <LoaderComponent>
            <h3>
              Welcome, <span className="highlight-text">{displayName}</span>
            </h3>
            <h3>Please wait while the page loads..</h3>
          </LoaderComponent>
        ) : null}
        <React.Fragment>
          <Tabs>
            <div label={currentProgram !== "retrofit" ? "Customers" : "Applicants"}>
              {customerList ? (
                <CustomersTab
                  SelectedFilters={customerFilters.SelectedFilters}
                  searchInput={customerFilters.searchInput}
                  sortConfig={customerFilters.sortConfig}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                  showModal={this.props.showModal}
                />
              ) : (
                ""
              )}
            </div>
            <div label={currentProgram !== "retrofit" ? "Contractors" : "Applicant Reps"}>
              {contractorList ? (
                <ContractorTab
                  SelectedFilters={contractorFilters.SelectedFilters}
                  searchInput={contractorFilters.searchInput}
                  sortConfig={contractorFilters.sortConfig}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                  showModal={this.props.showModal}
                />
              ) : (
                ""
              )}
            </div>
          </Tabs>
        </React.Fragment>
      </ProgramLayout>
    );
  }
}

function mapState(state) {
  const { addParticipantModalShown } = state.registration;
  const { adminUserInfo, contractorsLoading, customersLoading, emailResent, resendingEmail, currentProgram } = state.authentication;
  const { customerList, contractorList, activeParticipantLoading, activeParticipant } = state.participant;
  return {
    currentProgram,
    addParticipantModalShown,
    adminUserInfo,
    customerList,
    contractorList,
    contractorsLoading,
    customersLoading,
    activeParticipantLoading,
    activeParticipant,
    emailResent,
    resendingEmail,
  };
}

const actionCreators = {
  setActiveProgram: userActions.setActiveProgram,
  showModal: userActions.showAddParticipantModal,
  getParticipants: participantActions.getParticipants,
  getParticipantsRetrofit: participantActions.getParticipantsRetrofit,
  verifyEmail: userActions.verifyEmail,
  loadUserDetails: userActions.getUserDetails,
  resetEmailVerificationModal: participantActions.resetEmailVerifyModal,
  getParticipant: participantActions.getParticipantDetails,
};

export default connect(mapState, actionCreators)(ParticipantBoard);
