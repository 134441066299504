import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../../redux/actions/userActions";
import "./ApplicationReview.css";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import { AuthContext } from "../../../../firebaseAuthContext";
import { utils } from "../../../../helpers/utils";
import { applicationService } from "../../../../services/applicationService";

class ApplicationReview extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      reviewLoading: false,
      measureList: props.measureList || [],
      readyForApprove: false,
      approvedSuccessfully: false,
      confirmingApproval: false,
      approvalMessage: "",
      totalOfPostlnvoices: 0,
      differenceAmountPost: 0,
      explanationForDifference: "",
      qaQcTypes: [
        { label: "N/A", value: "N/A" },
        { label: "Site Visit", value: "Site Visit" },
        { label: "Sampling", value: "Sampling" },
        ],
      qaQcType: {},
      // approvedByBERA: false,
      // fundingApprovedByBERA: ''
    };
  }

  handleApprovalMessageChange = (name, value) => {
    this.setState({
      approvalMessage: value,
    });
  };

  handleQaQcType = (val) => {
    this.setState({
      qaQcType: val,
    });
  };

  handleAdditionalReview = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    this.setState({
      measureList: this.props.measureList || [],
    });
    applicationService.getApplication(this.props.selectedAppId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
      });
    });
    if (this.props.appData.stage === "Post-Project") {
      applicationService.getApplicationReview(this.props.selectedAppId).then((data) => {
        this.setState({
          appReviewData: data,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.measureList !== this.props.measureList) {
      this.setState({
        measureList: this.props.measureList || [],
      });
    }
  }

  handleChangeReviewerIncentive = (name, value) => {
    let { measureList } = this.state;
    let fIndex = measureList.findIndex((f) => f.id === name);
    if (fIndex > -1) measureList[fIndex]["ReviewerIncentive"] = value;
    this.setState({ measureList });
  };
  handleChangePostProjectReviewerIncentive = (name, value) => {
    let { measureList } = this.state;
    let fIndex = measureList.findIndex((f) => f.id === name);
    if (fIndex > -1) measureList[fIndex]["PostProjectReviewerApprovedIncentive"] = value;
    this.setState({ measureList });
  };
  handleChangeApprovedByBERA = (name, approvedByBERA) => {
    let updateObj = {
      approvedByBERA,
    };
    if (!approvedByBERA) {
      updateObj["fundingApprovedByBERA"] = "";
    }
    this.setState(updateObj);
  };

  handleChangeFundingApprovedByBERA = (name, fundingApprovedByBERA) => {
    this.setState({ fundingApprovedByBERA });
  };

  confirmApproval = () => {
    this.setState({
      confirmingApproval: !this.state.confirmingApproval,
    });
  };

  validateReviewerIncentive = (measures) => {
    let readyForApprove = true;
    for (let item of measures) {
      if (!item.ReviewerIncentive) readyForApprove = false;
    }
    return readyForApprove;
  };

  validatePostProjectReviewerIncentive = (measures) => {
    let readyForApprove = true;
    for (let item of measures) {
      if (!item.PostProjectReviewerApprovedIncentive) readyForApprove = false;
    }
    return readyForApprove;
  };
  validateFundingApprovedByBERA = (measures) => {
    let readyForApprove = false;
    let { approvedByBERA, fundingApprovedByBERA } = this.state;
    if (approvedByBERA) {
      if (!fundingApprovedByBERA) readyForApprove = true;
    }
    return readyForApprove;
  };

  sumOfReviewerIncentive = (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      if (item.ReviewerIncentive) ans += parseFloat(item.ReviewerIncentive);
    }
    return ans.toFixed(2);
  };
  sumOfPostprojectReviewerIncentive = (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      if (item.PostProjectReviewerApprovedIncentive) ans += parseFloat(item.PostProjectReviewerApprovedIncentive);
    }
    return ans.toFixed(2);
  };

  approveApplication = () => {
    let { measureList } = this.state;
    let { adminUserInfo } = this.props;
    let measuresReviewerEstimatedIncentive = {},
      applicationId = "";
    measureList.map((measure) => {
      applicationId = measure.applicationId;
      measuresReviewerEstimatedIncentive[measure.id] = measure["ReviewerIncentive"] ? parseFloat(measure["ReviewerIncentive"]) : 0.0;
    });
    applicationService
      .createApplicationReview(applicationId, {
        totalReviewerEstimatedIncentive: parseFloat(this.sumOfReviewerIncentive(measureList)),
        reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
        reviewStatus: "Application Draft Approved",
        informationRequest: this.state.approvalMessage,
        measuresReviewerEstimatedIncentive,
      })
      .then((res) => {
        this.setState(
          {
            approvedSuccessfully: true,
          },
          () => {
            setTimeout(() => {
              this.props.closeActionModal(true);
              this.setState({
                measureList: [],
              });
            }, 5000);
          }
        );
      });
  };
  validateAdditionalReviewer = () => {
    const { totalOfPostlnvoices, explanationForDifference } = this.state;
    const { appData } = this.props;
    if (totalOfPostlnvoices) {
      let difference = appData["Actual Project Cost"] - totalOfPostlnvoices;
      if (difference) {
        if (utils.cleanHTMLText(explanationForDifference)) {
          return false;
        } else return true;
      }
    } else return true;
  };
  approvePostProjectApplication = () => {
    let { measureList, totalOfPostlnvoices, explanationForDifference, qaQcType } = this.state;
    let { adminUserInfo, appData } = this.props;
    let measuresPostProjectReviewerApprovedIncentive = {},
      applicationId = "";
    measureList.map((measure) => {
      applicationId = measure.applicationId;
      measuresPostProjectReviewerApprovedIncentive[measure.id] = measure["PostProjectReviewerApprovedIncentive"]
        ? parseFloat(measure["PostProjectReviewerApprovedIncentive"])
        : 0.0;
    });

    applicationService
      .createPostProjectApplicationReview(applicationId, {
        totalReviewerEstimatedIncentive: parseFloat(this.sumOfPostprojectReviewerIncentive(measureList)),
        totalOfPostlnvoices: totalOfPostlnvoices,
        explanationForDifference: explanationForDifference,
        differenceAmountPost: (parseFloat(appData["Actual Project Cost"]) - parseFloat(totalOfPostlnvoices)),
        qaQcType: qaQcType,
        // approvedByBERA: approvedByBERA,
        // fundingApprovedByBERA: fundingApprovedByBERA,
        reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
        reviewStatus: "Incentive Recommended",
        informationRequest: this.state.approvalMessage,
        measuresPostProjectReviewerApprovedIncentive,
      })
      .then((res) => {
        this.setState(
          {
            approvedSuccessfully: true,
          },
          () => {
            setTimeout(() => {
              this.props.closeActionModal(true);
              this.setState({
                measureList: [],
              });
            }, 5000);
          }
        );
      });
  };
  renderMeasureDetails = () => {
    const { measureList, confirmingApproval, appReviewData, applicationObj } = this.state;
    const { appData } = this.props;
    var additional_class = "";
    if (appData.stage === "Post-Project") {
      additional_class = "post-project";
    }
    return measureList.length > 0 ? (
      <React.Fragment>
        <div className="measures-application-review-container">
          <div className={`contractor-review-header ${additional_class}`}>
            <div className="review-detail">Measure</div>
            <div className="review-detail">Quantity</div>
            <div className="review-detail">Estimated Emissions Reduction (Lifetime)</div>
            {appData.stage === "Post-Project" ? (
              <React.Fragment>
                <div className="review-detail">Pre-Approved Incentive</div>
                <div className="review-detail">Applicable Incentive</div>
                <div className="review-detail">Approved Incentive</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="review-detail">Estimated Incentive</div>
                <div className="review-detail">Reviewer Estimated Incentive</div>
              </React.Fragment>
            )}
          </div>
          <div className="contractor-review-table-left">
            <div className="contractor-review-inner-container">
              {measureList.map((measure) => (
                <div className={`contractor-review-details ${additional_class}`}>
                  <div className="review-detail">
                    <label>Measure</label>
                    <span>{measure.measure.label}</span>
                  </div>
                  <div className="review-detail">
                    <label>Quantity</label>
                    <span>{utils.formatNumberWithCommas(measure.Quantity)}</span>
                  </div>
                  <div className="review-detail">
                    <label>Estimated Emissions Reduction (Lifetime)</label>
                    <span>{utils.formatNumberWithCommas(utils.getTEM(measure))} tonnes</span>
                  </div>
                  <div className="review-detail">
                    <label>{appData.stage !== "Post-Project" ? "Estimated Incentive" : "Pre-Approved Incentive"}</label>
                    {appData.stage === "Post-Project" ? (
                      <span>
                        {utils.formatAsCAD(
                          (appReviewData && appReviewData.measuresReviewerEstimatedIncentive
                            ? appReviewData.measuresReviewerEstimatedIncentive[measure.id]
                            : 0.0) || 0.0
                        )}
                      </span>
                    ) : (
                      <span>{utils.formatAsCAD(utils.getTEMI(measure))}</span>
                    )}
                  </div>
                  {appData.stage === "Post-Project" ? (
                    <div className="review-detail">
                      <label>Applicable Incentive</label>
                      <span>{utils.formatAsCAD(utils.getApplicableTEMI(measure))}</span>
                    </div>
                  ) : (
                    <div className="review-detail">
                      <label>Reviewer Estimated Incentive</label>
                      {confirmingApproval ? (
                        <span>{utils.formatAsCAD(measure.ReviewerIncentive ? measure.ReviewerIncentive : 0.0)}</span>
                      ) : (
                        <Input
                          type="dollar"
                          value={measure.ReviewerIncentive ? measure.ReviewerIncentive : 0.0}
                          name={`${measure.id}`}
                          disabled={confirmingApproval}
                          isAllowed={(obj) => {
                            let { value } = obj;
                            let estimatedValue = utils.getTEMI(measure);
                            if (utils.isNullOrEmpty(value)) {
                              return true;
                            }
                            let valid = true;
                            valid = parseFloat(value) <= parseFloat(estimatedValue);
                            if (valid) {
                              let tempMeasureList = JSON.parse(JSON.stringify(measureList));
                              let fIndex = tempMeasureList.findIndex((f) => f.id === measure.id);
                              if (fIndex > -1) tempMeasureList[fIndex]["ReviewerIncentive"] = value;
                              let newEstimatedSum = parseFloat(this.sumOfReviewerIncentive(tempMeasureList));
                              if (applicationObj && applicationObj.program === 'esb' && newEstimatedSum > 250000) {
                                valid = false;
                              } else if (applicationObj && applicationObj.program === 'speed' && newEstimatedSum > 1000000) {
                                valid = false;
                              }
                            }
                            return valid;
                          }}
                          onChange={(name, value) => this.handleChangeReviewerIncentive(measure.id, value)}
                        />
                      )}
                    </div>
                  )}
                  {appData.stage !== "Post-Project" ? (
                    ""
                  ) : (
                    <div className="review-detail">
                      <label>Approved Incentive</label>
                      {confirmingApproval ? (
                        <span>
                          {utils.formatAsCAD(
                            measure.PostProjectReviewerApprovedIncentive ? measure.PostProjectReviewerApprovedIncentive : 0.0
                          )}
                        </span>
                      ) : (
                        <Input
                          type="dollar"
                          value={measure.PostProjectReviewerApprovedIncentive ? measure.PostProjectReviewerApprovedIncentive : 0.0}
                          name={`${measure.id}`}
                          disabled={confirmingApproval}
                          isAllowed={(obj) => {
                            let { value } = obj;
                            let estimatedValue = utils.getApplicableTEMI(measure);
                            if (utils.isNullOrEmpty(value)) {
                              return true;
                            }
                            let valid = true;
                            valid = parseFloat(value) <= parseFloat(estimatedValue);
                            if (valid) {
                              let tempMeasureList = JSON.parse(JSON.stringify(measureList));
                              let fIndex = tempMeasureList.findIndex((f) => f.id === measure.id);
                              if (fIndex > -1) tempMeasureList[fIndex]["PostProjectReviewerApprovedIncentive"] = value;
                              let newEstimatedSum = parseFloat(this.sumOfPostprojectReviewerIncentive(tempMeasureList));
                              if (applicationObj && applicationObj.program === 'esb' && newEstimatedSum > 250000) {
                                valid = false;
                              } else if (applicationObj && applicationObj.program === 'speed' && newEstimatedSum > 1000000) {
                                valid = false;
                              }
                            }
                            return valid;
                          }}
                          onChange={(name, value) => this.handleChangePostProjectReviewerIncentive(measure.id, value)}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="app-review-totals-inner-container">
            <div className={`app-review-totals ${additional_class}`}>
              <div className="review-detail mobile-total">Total</div>
              <div className="review-detail"></div>
              <div className="review-detail">
                <label>Estimated Emissions Reduction (Lifetime)</label>
                <span>{utils.formatNumberWithCommas(utils.sumOfTEMs(measureList))} tonnes</span>
              </div>
              <div className="review-detail">
                <label>{appData.stage !== "Post-Project" ? "Estimated Incentive" : "Pre-Approved Incentive"}</label>
                {appData.stage === "Post-Project" ? (
                  <span>{utils.formatAsCAD(appData["Reviewer Estimated Incentive"] || "0")}</span>
                ) : (
                  <span>{utils.formatAsCAD(utils.sumOfTEMIs(measureList, applicationObj ? applicationObj.program : ''))}</span>
                )}
              </div>
              {appData.stage === "Post-Project" ? (
                <div className="review-detail">
                  <label>Applicable Incentive</label>
                  <span>{utils.formatAsCAD(appData["Post-Project Applicable Incentive"] || "0")}</span>
                </div>
              ) : (
                ""
              )}
              <div className="review-detail">
                <label> {appData.stage === "Post-Project" ? "Approved Incentive" : "Reviewer Estimated Incentive"}</label>
                <span>
                  {appData.stage === "Post-Project"
                    ? utils.formatAsCAD(this.sumOfPostprojectReviewerIncentive(measureList))
                    : utils.formatAsCAD(this.sumOfReviewerIncentive(measureList))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      ""
    );
  };

  renderBERADetails = () => {
    const { approvedByBERA, fundingApprovedByBERA, confirmingApproval } = this.state;
    const { appData } = this.props;
    return (
      <div className="measures-application-review-container">
        <div className="contractor-review-header post-project">
          <div className="review-detail">BERA Funding Review</div>
          <div className="review-detail">Review</div>
          <div className="review-detail">Disclosed Sources</div>
          <div className="review-detail">Disclosed Funding</div>
        </div>
        <div className="contractor-review-table-left">
          <div className="contractor-review-inner-container">
            <div className="contractor-review-details post-project">
              <div className="review-detail">
                <label>BERA Funding Review</label>
                <span>Has this project been approved by BERA for funding?</span>
              </div>
              <div className="review-detail">
                <label>Review</label>
                {confirmingApproval ? (
                  <span>{approvedByBERA ? "Yes" : "No"}</span>
                ) : (
                  <Input
                    type="switch"
                    checked={approvedByBERA}
                    name={`approvedByBERA`}
                    disabled={confirmingApproval}
                    onChange={this.handleChangeApprovedByBERA}
                  />
                )}
              </div>
              <div className="review-detail">
                <label>Disclosed Sources</label>
                <span>{appData.sourcesOfAllOtherFunding ? appData.sourcesOfAllOtherFunding : "N/A"}</span>
              </div>
              <div className="review-detail">
                <label>Disclosed Funding</label>
                <span>{utils.formatAsCAD(appData.totalAmountOfAllOtherFunding)}</span>
              </div>
            </div>
          </div>
          <div className="contractor-review-inner-container bera-funding">
            {approvedByBERA && (
              <div className="contractor-review-details post-project">
                <div className="review-detail">What is the amount of funding approved by BERA?</div>
                <div className="review-detail">
                  {confirmingApproval ? (
                    <span>{utils.formatAsCAD(fundingApprovedByBERA)}</span>
                  ) : (
                    <Input
                      type="dollar"
                      value={fundingApprovedByBERA}
                      name={`fundingApprovedByBERA`}
                      disabled={confirmingApproval}
                      onChange={(name, value) => this.handleChangeFundingApprovedByBERA(name, value)}
                    />
                  )}
                </div>
                <div className="review-detail"></div>
                <div className="review-detail"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderAdditionalReview = () => {
    const { totalOfPostlnvoices, explanationForDifference, confirmingApproval } = this.state;
    const { appData } = this.props;
    return (
      <div className="measures-application-review-container">
        <div className="contractor-review-header post-project additional-review">
          <div className="review-detail">Additional Review</div>
          <div className="review-detail">Response</div>
          <div className="review-detail">Actual Project Cost</div>
          <div className="review-detail">Difference</div>
        </div>
        <div className="contractor-review-table-left">
          <div className="contractor-review-inner-container">
            <div className="contractor-review-details post-project additional-review">
              <div className="review-detail">
                <label>Additional Review</label>
                <span>What is the total of all the invoice amounts?</span>
              </div>
              <div className="review-detail">
                <label>Response</label>
                {confirmingApproval ? (
                  <span>{totalOfPostlnvoices}</span>
                ) : (
                  <Input
                    type="dollar"
                    name={`totalOfPostlnvoices`}
                    value={totalOfPostlnvoices}
                    onChange={this.handleAdditionalReview}
                  />
                )}
              </div>
              <div className="review-detail">
                <label>Actual Project Cost</label>
                <span>{utils.formatAsCAD(appData["Actual Project Cost"])}</span>
              </div>
              <div className="review-detail">
                <label>Difference</label>
                <span>
                  { totalOfPostlnvoices ? `${(parseFloat(appData["Actual Project Cost"]) < parseFloat(totalOfPostlnvoices)) ? "-" : ''}${utils.formatAsCAD(Math.abs(parseFloat(appData["Actual Project Cost"]) - parseFloat(totalOfPostlnvoices)).toFixed(2))}` : "Enter Invoice Value" }
                </span>
              </div>
            </div>
          </div>
          <div className="contractor-review-inner-container bera-funding">
            {totalOfPostlnvoices !== 0 && appData["Actual Project Cost"] - totalOfPostlnvoices !== 0 && (
              <div className="contractor-review-details post-project">
                <div className="review-detail">Explanation for Difference</div>
                <div className="review-detail explanation-text">
                  {!confirmingApproval ? (
                    <Input
                      type="richtext"
                      value={explanationForDifference}
                      name={`explanationForDifference`}
                      disabled={confirmingApproval}
                      onChange={(name, value) => this.handleAdditionalReview(name, value)}
                    />
                  ) : (
                    <div className="review-notes-text" dangerouslySetInnerHTML={{ __html: explanationForDifference }} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { appData, currentProgram } = this.props;
    const { applicationObj } = this.state;
    const { confirmingApproval, qaQcTypes, qaQcType, review, approvalMessage, reviewLoading, reviewCompleted, userInfo, measureList, approvedSuccessfully } =
      this.state;
    if (reviewLoading) {
      return <p>Loading</p>;
    }
    return (
      <div>
        <div className="app-review-container">
          {appData ? (
            <React.Fragment>
              <div className="section-header">
                <div className="section-top-details">{applicationObj ? applicationObj.program === 'esb' ? 'ESB-' : 'SPEED-' : ''}{applicationObj && applicationObj.appNo}</div>
                <div className="section-top-details name-section">{applicationObj && applicationObj.companyName}</div>
                <div className="section-top-details">
                  {applicationObj && applicationObj.contractor ? applicationObj.contractor.label : ""}
                </div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Application Approval" }}></h2>
                {appData.stage === "Post-Project" ? <div className="qaQcType">
                  <Input
                    disabled={confirmingApproval}
                    placeholder="Select QA/QC Type"
                    type="dropdown"
                    name="qaQcType"
                    onChange={this.handleQaQcType}
                    value={utils.isNullOrEmpty(qaQcType) ? undefined : qaQcType}
                    options={qaQcTypes.map((qaQcType) => {
                      return { label: qaQcType.label, value: qaQcType.value };
                    })}
                    isSearchable={false}
                  />
                </div> : ''}
              </div>

              <div className="contractor-review-container">
                <div className={`contractor-review-table ${!confirmingApproval ? "review-mode" : ""}`}>
                  {this.renderMeasureDetails()}
                  <div className="vertical-separator-box">
                    <span className="separator"></span>
                  </div>
                  <div className="contractor-review-table-right">
                    {approvedSuccessfully ? (
                      <p>Application approved successfully!</p>
                    ) : (
                      <p>
                        You are about to approve the application.
                        <br />
                        <br />
                        Please verify the details and the notes and confirm their accuracy
                      </p>
                    )}
                  </div>
                </div>
                {appData.stage === "Post-Project" ? (
                  <>
                    <div className="horizontal-bar"></div>
                    {/* <div className="contractor-review-table review-mode bera">{this.renderBERADetails()}</div> */}
                    <div className="contractor-review-table review-mode bera">{this.renderAdditionalReview()}</div>
                    <div className="horizontal-bar"></div>
                  </>
                ) : (
                  ""
                )}
                <div className="review-notes-container">
                  <label>Notes:</label>
                  {!confirmingApproval ? (
                    <Input
                      disabled={confirmingApproval}
                      type={"richtext"}
                      value={approvalMessage}
                      onChange={this.handleApprovalMessageChange}
                      name="approvalMessage"
                    />
                  ) : (
                    <div className="review-notes-text" dangerouslySetInnerHTML={{ __html: approvalMessage }} />
                  )}
                  <p className="notice-message">
                    <span>*</span> The maximum allowed Reviewer Estimated Incentive per application is {applicationObj && applicationObj.program === 'esb' ? "$250,000" : "$1,000,000"}
                  </p>
                </div>
              </div>
              {approvedSuccessfully ? (
                ""
              ) : (
                <React.Fragment>
                  {!confirmingApproval ? (
                    <React.Fragment>
                      {appData.stage === "Post-Project" ? (
                        <div className="review-btn-container">
                          <Button
                            title={"Complete Approval"}
                            className="signup-btn"
                            onClick={this.confirmApproval}
                            disabled={
                              !utils.cleanHTMLText(approvalMessage) ||
                              !this.validatePostProjectReviewerIncentive(measureList) ||
                              this.validateAdditionalReviewer() ||
                              utils.isNullOrEmpty(qaQcType)
                            }
                            uppercase
                          />
                        </div>
                      ) : (
                        <div className="review-btn-container">
                          <Button
                            title={"Complete Approval"}
                            className="signup-btn"
                            onClick={this.confirmApproval}
                            disabled={!utils.cleanHTMLText(approvalMessage) || !this.validateReviewerIncentive(measureList)}
                            uppercase
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {appData.stage === "Post-Project" ? (
                        <div className="review-btn-container">
                          <Button className="inversed-btn" title={"EDIT"} onClick={this.confirmApproval} />
                          <Button
                            title={"Confirm Approval"}
                            className="signup-btn"
                            uppercase
                            onClick={this.approvePostProjectApplication}
                          />
                        </div>
                      ) : (
                        <div className="review-btn-container">
                          <Button className="inversed-btn" title={"EDIT"} onClick={this.confirmApproval} />
                          <Button title={"Confirm Approval"} className="signup-btn" uppercase onClick={this.approveApplication} />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { measureList, applicationMeasuresLoading } = state.application;
  const { adminUserInfo, currentProgram } = state.authentication;
  return { measureList, adminUserInfo, applicationMeasuresLoading };
}

const actionCreators = {
  showModal: userActions.showEditParticipantModal,
};

export default connect(mapState, actionCreators)(ApplicationReview);
