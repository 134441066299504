import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../../redux/actions/userActions";
import { userService } from "../../../../services/userService";
import { participantActions } from "../../../../redux/actions/participantActions";
import { appActions } from "../../../../redux/actions/appActions";
import { utils } from "../../../../helpers/utils";
import Table from "../../../Common/Table/Table";
import LoaderComponent from "../../../Common/Loader/Loader";
import "./CustomerApplications.css";

class CustomerApplications extends React.Component {
  async componentDidMount() {
    const { currentProgram, customerId, customerCategory } = this.props;
    if (currentProgram === "retrofit") {
      this.props.getRetrofitApps(`${customerId}`);
    } else {
      this.props.getParticipant(currentProgram, customerId);
      this.props.getApps(customerCategory, customerId);
    }
  }

  render() {
    let { activeParticipant, participantApplicationList, loadingApplications, currentProgram, application } = this.props;
    let totalRequestedIncentive = "0.00";
    let totalApprovedIncentive = "0.00";
    let preApprovedSumIncentive = "0.00";
    let postApprovedSumIncentive = "0.00";
    if (participantApplicationList && (participantApplicationList.data || participantApplicationList.length > 0)) {
      if (currentProgram === "retrofit") {
        totalRequestedIncentive = participantApplicationList.data.reduce(function (sum, current) {
          return Number(sum) + (current.total_est_app_incentive ? Number(current.total_est_app_incentive) : 0);
        }, 0);
        totalApprovedIncentive = participantApplicationList.data.reduce(function (sum, current) {
          return Number(sum) + (current.total_actual_app_incentive ? Number(current.total_actual_app_incentive) : 0);
        }, 0);
      } else {
        preApprovedSumIncentive = participantApplicationList.reduce(function (sum, current) {
          return Number(sum) + (current["Reviewer Estimated Incentive"] ? Number(current["Reviewer Estimated Incentive"]) : 0);
        }, 0);
        postApprovedSumIncentive = participantApplicationList.reduce(function (sum, current) {
          return (
            Number(sum) + (current["PostProjectReviewerApprovedIncentive"] ? Number(current["PostProjectReviewerApprovedIncentive"]) : 0)
          );
        }, 0);
      }
    }
    return loadingApplications ? (
      <LoaderComponent />
    ) : (
      <div>
        <div className="customer-apps-container">
          {currentProgram !== "retrofit" ? (
            activeParticipant ? (
              <div className="section-header">
                <div className="section-top-details">
                  {activeParticipant.accountCategory === "contractor" ? "CON" : "CUS"}-{activeParticipant.userNo}
                </div>
                <div className="section-top-details name-section">{activeParticipant.companyDetails.name}</div>
                <div className="section-top-details">{activeParticipant.email}</div>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="section-header">
              <div className="section-top-details">{application.customerId}</div>
              <div className="section-top-details name-section">{application.applicantName}</div>
              <div className="section-top-details">{application.applicantEmail}</div>
            </div>
          )}
          <div className="section-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: `${currentProgram === 'retrofit' ? "Applicant Projects" : "Customer Applications"}` }}></h2>
          </div>
          {participantApplicationList && (participantApplicationList.data || participantApplicationList.length > 0) ? (
            <div className="container-box">
              {currentProgram === "retrofit" ? (
                <Table
                  rows={participantApplicationList.data.map((item) => {
                    return {
                      estimatedIncentive: utils.formatAsCAD(Number(item.total_est_app_incentive)),
                      actualIncentive: utils.formatAsCAD(Number(item.total_actual_app_incentive)),
                      ...item,
                    };
                  })}
                  headings={[
                    { title: "Application ID", value: "application_id" },
                    { title: "Application Status", value: "application_status_name" },
                    // { title: "Internal Status", value: "application_status_short_name" },
                    { title: "Total Estimated Application Incentive", value: "estimatedIncentive" },
                    { title: "Total Actual Application Incentive", value: "actualIncentive" },
                  ]}
                />
              ) : (
                <Table
                  rows={participantApplicationList.map((item) => {
                    return {
                      appId: `${this.props.currentProgram === "esb" ? "ESB" : "SPEED"}-${item.appNo}`,
                      PostProjectReviewerApprovedIncentive: utils.formatAsCAD(item["PostProjectReviewerApprovedIncentive"]),
                      estimatedIncentive: utils.formatAsCAD(item["Reviewer Estimated Incentive"]),
                      ...item,
                    };
                  })}
                  headings={[
                    { title: "Application #ID", value: "appId" },

                    // {title:activeParticipant.accountCategor === 'contractor' ? 'Customer'  : 'Assigned Contractor', value:'participantName'},

                    { title: "Application Status", value: "status" },
                    { title: "Application Stage", value: "stage" },
                    // {title:'Created Date', value:'createdDate'},
                    { title: "Pre-Approved Incentive", value: "estimatedIncentive" },
                    { title: "Recommended Incentive", value: "PostProjectReviewerApprovedIncentive" },
                    // {title:'Details', value: 'actionURL'},
                  ]}
                />
              )}
            </div>
          ) : (
            ""
          )}
          {participantApplicationList.length !== 0 ? (
            currentProgram !== "retrofit" ? (
              <div className="section-summary">
                <div className="section-block">
                  <div className="label right">Total Pre-Approved Incentive</div>
                  <div className="totals">${preApprovedSumIncentive.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                </div>
                <div className="section-block">
                  <div className="label right">Total Recommended Incentive</div>
                  <div className="totals">${postApprovedSumIncentive.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                </div>
              </div>
            ) : (
              <div className="section-summary">
                <div className="section-block">
                  <div className="label right">Total Requested Incentive</div>
                  <div className="totals">${totalRequestedIncentive.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                </div>
                <div className="section-block">
                  <div className="label right">Total Approved Incentive</div>
                  <div className="totals">${totalApprovedIncentive.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { activeParticipant, activeStatusLogs } = state.participant;
  const { participantApplicationList, loadingApplications } = state.application;
  const { currentProgram } = state.authentication;
  return { activeParticipant, activeStatusLogs, participantApplicationList, loadingApplications, currentProgram };
}

const actionCreators = {
  showModal: userActions.showEditParticipantModal,
  getParticipant: participantActions.getParticipantDetails,
  getApps: appActions.getApplicationsForParticipant,
  getRetrofitApps: appActions.getRetrofitApplicationsForParticipant,
  setActiveProgram: userActions.setActiveProgram,
};

export default connect(mapState, actionCreators)(CustomerApplications);
