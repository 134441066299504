import React from 'react';
import './LicenseDetails.css';
import { connect } from 'react-redux'
import { participantActions } from '../../../../redux/actions/participantActions';

function Row(props) {
    return <div className="row-section">
    <div className="section-left"><span className='section-subtitle'>{props.label}</span></div>
    <div className="section-right">
      {props.data ?  <p>Yes</p>: <p>No</p>}
    </div>
  </div>;
}

// const licenseReqs = user.licenseReqs;
// const techFocus = (user.technologyFocusForCompany || []).map((item)=>item.value);
// const includesCHP = techFocus.includes('CHP');
// const includesSolarPV = techFocus.includes('Solar PV');
// const includesGeothermal = techFocus.includes('Geothermal (GSHP)');
class LicenseDetails extends React.Component {
  render() {
      const {activeParticipant} = this.props;
      const companyDetails = activeParticipant.companyDetails || {};
      const techFocus = (companyDetails.technologyFocusForCompany || []).map((item)=>item.value);
      const includesCHP = techFocus.includes('CHP');
      const includesSolarPV = techFocus.includes('Solar PV');
      const includesGeothermal = techFocus.includes('Geothermal (GSHP)');
      return (
        <div className="license-details-container">
          <div className="container-box">
            <div className="form-sections">
            {activeParticipant.licenseReqs ? <React.Fragment>
            <div className="row-section first-row">
              <div className="section-left"><span className='section-title'>Requirements</span></div>
              <div className="section-right"><span className='section-title'>Answers</span></div>
            </div>
            <Row label={`Do you meet the Insurance Requirements specified in the Code of Conduct?`} data={activeParticipant.licenseReqs.meetInsuranceRequirements}/>
            <Row label={`Do you meet the WCB Requirements specified in the Code of Conduct?`} data={activeParticipant.licenseReqs.meetWCBRequirements}/>
            <Row label={`Are you registered for GST?`} data={activeParticipant.licenseReqs.registeredForGST}/>
            <Row label={`Do you have the required Licences and Certifications specified in the Code of Conduct?`} data={activeParticipant.licenseReqs.codeOfConductLicensesAndCertifications}/>
            {includesCHP ? <React.Fragment>
            <Row label={`Do you have a P.Eng on Staff?`} data={activeParticipant.licenseReqs.havePEngOnStaff}/>
            <Row label={`Do you have a CEM on Staff?`} data={activeParticipant.licenseReqs.haveCEMOnStaff}/>
            </React.Fragment> : ''}
            {includesSolarPV ? <React.Fragment>
            <Row label={`Are you a Member of SESA?`} data={activeParticipant.licenseReqs.memberOfSESA}/>
            <Row label={`Are you a Member of CanREA?`} data={activeParticipant.licenseReqs.memberOfCanREA}/>
            </React.Fragment> : ''}
            {includesGeothermal ? <Row label={`Have your Ground Source Heat Pump installers completed the Accredited Installer course from the International Ground Source Heat Pump Association or equivalent?`} data={activeParticipant.licenseReqs.accreditedInstallerofIGSHPA}/> : ''}
            </React.Fragment> : <p>No Data</p>}
            </div>
          </div>
        </div>
      );
    }
}


function mapState(state) {
  const { activeParticipant } = state.participant;
  return { activeParticipant };
}

const actionCreators = {
}

export default connect(mapState, actionCreators)(LicenseDetails);
