import React from 'react';
import Button from '../../../Common/Button/Button'
import Input from '../../../Common/Input/Input'
import './AddParticipant.css';
import { AddParticipantTitle } from '../../../../contentFile'
import { connect } from 'react-redux'
import { userActions } from '../../../../redux/actions/userActions';
import { userService } from '../../../../services/userService';
import { utils } from '../../../../helpers/utils';
import { Redirect } from 'react-router-dom';
import { debounce } from "debounce";

class AddParticipant extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cancelModalOpen: false,
      passwordValid: false,
      confirmAccountDetails: false,
      term1: false,
      term2: false,
      term3: false,
      user: {
        firstName: '',
        lastName: '',
        phoneNum: '',
        email: '',
        confirmEmail: '',
        accountCategory: '',
        companyWebsite : '',
        companyName: '',
        locationInfo: null,
        address2: ''
      },
      errors: {}
    }
    this.checkEmail = debounce(this.checkEmailInSystem, 300);
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    const { user, errors } = this.state;
    delete errors[name];
    this.setState({
      user: {
        ...user,
        [name]: value
      },
      errors
    });
  }

  checkEmailInSystem = () => {
    const { errors, user } = this.state;
    userService.checkIfEmailExists(user.email, (userExists) => {
      if (userExists) {
        errors.email = 'Email already exists in our system.';
      }
      this.setState({
        errors
      });
    });
  }

  onEmailChange = (e) => {
    const { name, value } = e.target;
    const { user, errors } = this.state;
    delete errors[name];

    if (!utils.isNullOrEmpty(value)) {
      if (name === 'email') {
        if (value === user.confirmEmail) {
          delete errors.confirmEmail;
        } else {
          errors.confirmEmail = 'The email ids do not match';
        }
        if (utils.validateEmail(value)) {
          this.checkEmail();
        } else {
          errors.email = 'The email id is invalid';
        }
      } else {
        if (value === user.email) {
          delete errors.confirmEmail;
        } else {
          errors.confirmEmail = 'The email ids do not match';
        }
      }
    }

    this.setState({
      user: {
        ...user,
        [name]: value
      },
      errors
    });
  }

  onCatChange = (cat) => {
    const { user, errors } = this.state;
    delete errors['accountCategory'];
    this.setState({
      user: {
        ...user,
        accountCategory: cat.value
      },
      errors
    });
  }


  onLocationChange = (locationInfo) => {
    const { user, errors } = this.state;
    delete errors['companyAddress'];
    this.setState({
      user: {
        ...user,
        locationInfo
      },
      errors
    });
  }

  verifyForm = (user) => {
    let errors = {};
    let errorCount = 0;
    if (utils.isNullOrEmpty(user.firstName)) {
      errors.firstName = 'First name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.lastName)) {
      errors.lastName = 'Last name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.companyName)) {
      errors.companyName = 'Company name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.companyWebsite)) {
      errors.companyWebsite = 'Company website is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.locationInfo)) {
      errors.companyAddress = 'Company address is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.accountCategory)) {
      errors.accountCategory = 'Please select an account category.'
      errorCount++;
    }
    if (!utils.isNullOrEmpty(user.phoneNum) && (user.phoneNum.length > 1 && user.phoneNum.length < 11)) {
      errors.phoneNum = 'Phone number is invalid.'
      errorCount++;
    } else if (utils.isNullOrEmpty(user.phoneNum) || (user.phoneNum.length <= 1)) {
      errors.phoneNum = 'Phone number is required.'
      errorCount++;
    }

    if (!utils.isNullOrEmpty(user.email)) {
      if (!utils.validateEmail(user.email)) {
        errors.email = 'Email id is invalid.'
        errorCount++;
      }
    } else {
      errors.email = 'Email id is required.'
      errorCount++;
    }
    // if (!utils.isNullOrEmpty(user.passwordOne)) {
    //   if (!utils.validatePassword(user.passwordOne)) {
    //     errors.passwordOne = 'Password does not meet requirements.';
    //     errorCount++;
    //   }
    // } else {
    //   errors.passwordOne = 'Password is required.'
    //   errorCount++;
    // }
    // if (!utils.isNullOrEmpty(user.passwordTwo)) {
    //   if (user.passwordOne !== user.passwordTwo) {
    //     errors.passwordTwo = 'Passwords do not match.'
    //     errorCount++;
    //   }
    // } else {
    //   errors.passwordTwo = 'Please confirm password.'
    //   errorCount++;
    // }
    if (errorCount > 0) {
      this.setState({ errors });
    }
    return errorCount === 0;
  }

  nextStep = () => {
    const { user } = this.state;
    if (this.verifyForm(user)) {
      userService.checkIfEmailExists(user.email, (userExists) => {
        if (userExists) {
          let errors = { email: 'Email already exists in our system.' };
          this.setState({ errors });
        } else {
          this.setState({ confirmAccountDetails: !this.state.confirmAccountDetails })
        }
      });
    }
  }

  render() {
    const { registering, registeredSuccessfully } = this.props;
    if (registeredSuccessfully) {
      return <Redirect to="/account" />;
    } else {
      const { user, errors, confirmAccountDetails } = this.state;
      const thereAreErrors = !utils.isNullOrEmpty(errors);
      return (
        <div className="add-participant-container">
          <div className="section-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: AddParticipantTitle.title }}></h2>
          </div>
          <div className="container-box">
            {confirmAccountDetails ?
              <div className="section-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'Confirm Details' }}></h2>
              </div>
              : null}
            <div className="form-sections">
              <div className="form-section">
                <Input
                  label={'First Name'}
                  name='firstName'
                  value={user.firstName}
                  error={errors['firstName']}
                  onChange={this.onChangeHandler}
                  placeholder={'Type your first name'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Last Name'}
                  name='lastName'
                  value={user.lastName}
                  error={errors['lastName']}
                  onChange={this.onChangeHandler}
                  placeholder={'Type your last name'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  type='phone'
                  label={'Mobile Number'}
                  name='phoneNum'
                  value={user.phoneNum}
                  error={errors['phoneNum']}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Email'}
                  type='email'
                  name='email'
                  value={user.email}
                  onChange={this.onEmailChange}
                  error={errors['email']}
                  placeholder={'Type your email id'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Confirm Email'}
                  type='email'
                  name='confirmEmail'
                  value={user.confirmEmail}
                  onChange={this.onEmailChange}
                  error={errors['confirmEmail']}
                  placeholder={'Type your email id again'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Account Category'}
                  type='dropdown'
                  name='accountCategory'
                  error={errors['accountCategory']}
                  onChange={this.onCatChange}
                  value={user.accountCategory}
                  options={[{ value: 'contractor', label: 'Contractor' }, { value: 'customer', label: 'Customer' }]}
                  disabled={confirmAccountDetails}
                  isSearchable={false}
                />
              </div>
              <div className='vertical-separator-box'>
                <span className='separator'></span>
              </div>
              <div className="form-section">
                <Input
                  label={'Company Name'}
                  name='companyName'
                  value={user.companyName}
                  error={errors['companyName']}
                  onChange={this.onChangeHandler}
                  placeholder={"Type your company's name"}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Company Website'}
                  name='companyWebsite'
                  value={user.companyWebsite}
                  error={errors['companyWebsite']}
                  onChange={this.onChangeHandler}
                  placeholder={"Type your company's website"}
                  disabled={confirmAccountDetails}
                />
                <Input
                  type='location'
                  name='companyAddress'
                  label={'Company Address'}
                  error={errors['companyAddress']}
                  onChange={this.onLocationChange}
                  placeholder={'Company HQ Address Line 1'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Address Line 2'}
                  name='address2'
                  value={user.address2}
                  onChange={this.onChangeHandler}
                  placeholder={'Company HQ Address Line 2'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Postal Code'}
                  value={user.locationInfo ? user.locationInfo.postalCode : ''}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled
                />
                <Input
                  label={'City'}
                  value={user.locationInfo ? user.locationInfo.city : ''}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled
                />
                <Input
                  label={'Province'}
                  value={user.locationInfo ? user.locationInfo.province : ''}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled
                />
              </div>
            </div>
          </div>
          {confirmAccountDetails ?
            <div className="confirm-btn-container">
              <Button
                title={'Yes, Add Participant'}
                className='signup-btn'
                onClick={this.props.addParticipant}
                uppercase
                disabled={thereAreErrors}
              />
              <Button
                title={'No, Edit Details'}
                className='signup-btn'
                onClick={this.nextStep}
                uppercase
                disabled={thereAreErrors}
              />
            </div>
            :
            <Button
              title={'Add Participant'}
              className='signup-btn'
              onClick={this.nextStep}
              uppercase
              disabled={thereAreErrors}
            />
          }
        </div>
      );
    }
  }
}

function mapState(state) {
  const { registering, registeredSuccessfully, registrationError } = state.registration;
  return { registering, registeredSuccessfully, registrationError };
}

const actionCreators = {
  register: userActions.register
}

export default connect(mapState, actionCreators)(AddParticipant);
