import { combineReducers } from 'redux';

import { authentication } from './authenticationReducer';
import { registration } from './registrationReducer';
import { alert } from './alertReducer';
import { participant } from './participantReducer';
import { settings } from './settingsReducer';
import { application } from './applicationReducer';
const rootReducer = combineReducers({
    authentication,
    application,
    participant,
    settings,
    registration,
    alert
});

export default rootReducer;