import React from "react";
import "./AppState.css";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/Loader/Loader";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import Table from "../../../Common/Table/Table";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import { appActions } from "../../../../redux/actions/appActions";
class AppState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appLoading: true,
      applicationObj: {},
      appStateNotes: "",
      confirmedState: false,
      changeAppState: false,
      appStateLogs: this.props.activeStateLogs || [],
      confirmMessage: false,
    };
  }
  async componentDidMount() {
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      let currentAppState = appObj.hasOwnProperty("isActive") ? (appObj.isActive === true ? "Active" : "Inactive") : "Active";
      this.props.getApplicationsStateLogs(this.props.applicationId);
      this.setState({
        applicationObj: appObj,
        appLoading: false,
        currentAppState: currentAppState,
      });
    });
  }

  handleappStateNotesChange = (name, value) => {
    this.setState({
      appStateNotes: value,
    });
  };

  handleChangeState = () => {
    this.setState({ confirmedState: true });
  };
  handleCancel = () => {
    this.setState({
      changeAppState: false,
    });
  };
  handleConfirmStateChange = () => {
    const { appStateNotes, currentAppState } = this.state;
    const { adminUserInfo } = this.props;
    let updateObj = {};
    updateObj.appStateActionNotes = appStateNotes;
    updateObj.isActive = currentAppState === "Active" ? false : true;
    updateObj.actionTaker = `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`;
    applicationService.updateApplication(this.props.applicationId, updateObj).then((data) => {
      this.setState(
        {
          confirmMessage: true,
        },
        () => {
          setTimeout(() => {
            this.props.closeActionModal(true);
            this.props.reload();
          }, 3000);
        }
      );
    });
  };
  handleGoBack = () => {
    this.setState({ confirmedState: false });
  };
  handleChangeAppState = () => {
    this.setState({
      changeAppState: true,
    });
  };

  render() {
    const { applicationObj, appLoading, currentAppState, appStateNotes, confirmedState, changeAppState, confirmMessage } = this.state;
    let { adminUserInfo, appStateLogsLoading, activeStateLogs, currentProgram } = this.props;
    let actions = {};
    let canEdit = true;
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions[currentProgram].actions;
      if (utils.isNullOrEmpty(actions["Application Board-Change Application State"])) {
        canEdit = false;
      }
    }
    return (
      <div className="appstate-container">
        <React.Fragment>
          {appLoading ? (
            <LoaderComponent />
          ) : appStateLogsLoading ? (
            <LoaderComponent />
          ) : (
            <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
          )}
          <div className="header-container">
            <div className="section-below-header">
              <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Application State" }}></h2>
              <div className="current-app-state">
                <div className={currentAppState === "Active" ? "active-flag" : "inactive-flag"}></div>
                <span className="currstate">{currentAppState}</span>
              </div>
            </div>
            <div className="state-change-btn">
                {canEdit ? (
                  changeAppState ? (
                    ""
                  ) : (
                    <Button
                      className="submit-details change-btn"
                      title={"change state"}
                      onClick={() => this.handleChangeAppState()}
                      uppercase
                    />
                  )
                ) : (
                  ""
                )}
            </div>
          </div>
          {changeAppState ? (
            <React.Fragment>
              <div className="container-box">
                {!confirmMessage ? (
                  <div className="details">
                    <p>
                      This will change the state of the application from <span>{currentAppState}</span> to{" "}
                      <span>{currentAppState === "Active" ? "Inactive" : "Active"}</span>.
                    </p>
                    {confirmedState ? <p>Are you sure?</p> : ""}
                  </div>
                ) : (
                  <div className="details">
                    <p>
                      State of the application changed from <span>{currentAppState}</span> to{" "}
                      <span>{currentAppState === "Active" ? "Inactive" : "Active"}</span>.
                    </p>
                  </div>
                )}
                {confirmMessage ? (
                  ""
                ) : (
                  <React.Fragment>
                    <div className="note-section">
                      <div className="note-label">Notes:</div>
                    </div>
                    <div className="note-section">
                      <div className="note-text">
                        <Input
                          type="richtext"
                          disabled={confirmedState}
                          value={appStateNotes}
                          onChange={this.handleappStateNotesChange}
                          name="note"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
              {confirmedState ? confirmMessage ? "" : (
                <div className="button-set ">
                  <Button className="inversed-btn" title={"Go Back"} onClick={() => this.handleGoBack()} uppercase />
                  <Button
                    className="submit-details"
                    title={"Confirm State Change"}
                    uppercase
                    onClick={() => this.handleConfirmStateChange()}
                  />
                </div>
              ) : (
                <div className="button-set ">
                  <Button className="inversed-btn" title={"cancel"} onClick={() => this.handleCancel()} uppercase />
                  <Button
                    className="submit-details"
                    disabled={!utils.cleanHTMLText(appStateNotes)}
                    title={"Change State"}
                    uppercase
                    onClick={() => this.handleChangeState()}
                  />
                </div>
              )}{" "}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="logs-container-box">
                {activeStateLogs && activeStateLogs.length ? (
                  <React.Fragment>
                    <Table
                      rows={activeStateLogs}
                      headings={[
                        { title: "Date", value: "createdAt" },
                        { title: "Change Log", value: "action" },
                        { title: "Change Note", value: "actionNotes" },
                        { title: "Changed By", value: "actionTaker" },
                      ]}
                    />
                  </React.Fragment>
                ) : (
                  <EmptyContainer>There are currently no logs to show.</EmptyContainer>
                )}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }
}

function mapState(state) {
  const { adminUserInfo, currentProgram } = state.authentication;
  const { appStateLogsLoading, activeStateLogs } = state.application;
  return { currentProgram, adminUserInfo, appStateLogsLoading, activeStateLogs };
}
const actionCreators = {
  getApplicationsStateLogs: appActions.getApplicationsStateLogs,
};

export default connect(mapState, actionCreators)(AppState);
