import React from "react";
import { connect } from "react-redux";
import { appActions } from "../../../../../redux/actions/appActions";
import { utils } from "../../../../../helpers/utils";
import ResponsiveTable from "../../../../Common/ResponsiveTable/ResponsiveTable";
import "./ParticipantProjects.css";
import EmptyContainer from "../../../../Common/EmptyContainer/EmptyContainer";
import LoaderComponent from "../../../../Common/Loader/Loader";

class ParticipantProjects extends React.Component {
  componentDidMount() {
    this.props.getApps(this.props.selectedParticipant.uid);
  }

  render() {
    const { selectedParticipant } = this.props;
    let { participantApplicationList, loadingApplications } = this.props;
    return loadingApplications ? (
      <LoaderComponent />
    ) : (
      <div>
        <div className="applicant-projects-container">
          <div className="section-header">
            <div className="section-top-details">{selectedParticipant.uid}</div>
            <div className="section-top-details name-section">{selectedParticipant.firstName + " " + selectedParticipant.lastName}</div>
            <div className="section-top-details">{selectedParticipant.EmailID}</div>
          </div>
          <div className="section-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Projects" }}></h2>
          </div>
          {participantApplicationList && participantApplicationList.data && participantApplicationList.data.length > 0 ? (
            <div className="container-box">
              <ResponsiveTable
                rows={participantApplicationList.data.map((item) => {
                  return {
                    actionURL: (
                      <a rel="noreferrer" target="_blank" href={`/retrofit/viewapplication/${item.id}`}>
                        View
                      </a>
                    ),
                    appId: `${item.id}`,
                    assignedApplicantRep: `${item.app_rep_first_name === null && item.app_rep_last_name === null ? "N/A" : ""}
                            ${item.app_rep_first_name === null ? "" : item.app_rep_first_name} ${
                      item.app_rep_last_name === null ? "" : item.app_rep_last_name
                    }`,
                    applicantName: `${item.applicant_first_name === null && item.applicant_last_name === null ? "N/A" : ""}
                            ${item.applicant_first_name === null ? "" : item.applicant_first_name} ${
                      item.applicant_last_name === null ? "" : item.applicant_last_name
                    }`,
                    applicationStage: `${item.application_status_name.includes("Pre-Project") ? "Pre-Project" : item.application_status_name.includes("Post-Project") ? "Post-Project" : item.application_status_name === "Cancelled" ? "Cancelled" : "Payment"}`,
                    createdDate: `${utils.formatDate(item.creation_dt)}`,
                    estimatedIncentive: utils.formatAsCAD(Number(item.total_est_app_incentive)),
                    ...item,
                  };
                })}
                headings={
                  selectedParticipant.accCategory === "applicant_rep"
                    ? [
                        { title: "Application #ID", value: "appId" },
                        { title: "Applicant", value: "applicantName" },
                        { title: "Stage", value: "applicationStage" },
                        { title: "Application Status", value: "application_status_name" },
                        { title: "Created Date", value: "createdDate" },
                        { title: "Total Estimated Application Incentive", value: "estimatedIncentive" },
                        { title: "Details", value: "actionURL" },
                      ]
                    : [
                        { title: "Application #ID", value: "appId" },
                        { title: "Assigned Applicant Rep", value: "assignedApplicantRep" },
                        { title: "Stage", value: "applicationStage" },
                        { title: "Application Status", value: "application_status_name" },
                        { title: "Total Estimated Application Incentive", value: "estimatedIncentive" },
                        { title: "Details", value: "actionURL" },
                      ]
                }
              />
            </div>
          ) : (
            <EmptyContainer>No Projects to Show</EmptyContainer>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { participantApplicationList, loadingApplications } = state.application;
  return { participantApplicationList, loadingApplications };
}

const actionCreators = {
  getApps: appActions.getRetrofitApplicationsForParticipant,
};

export default connect(mapState, actionCreators)(ParticipantProjects);
