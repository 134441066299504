import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { utils } from "../../../helpers/utils";
import FlagIcon from "../FlagIcon/FlagIcon";
import { applicationService } from "../../../services/applicationService";
const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function CustomTable(props) {
  const classes = useStyles();
  const { rows } = props;
  return (
    <TableContainer component={Paper} className={classes.container}>
      {rows ? (
        <Table className={classes.table} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {props.headings.map((heading, index) => {
                return <TableCell key={`${heading.title}${index}`}>{heading.title}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={row.name}>
                {props.headings.map((heading) => {
                  let val = "";
                  let tablecell = "";
                  if (heading.value === "createdAt") {
                    val = utils.formatLogTime(row[heading.value]);
                    tablecell = <TableCell dangerouslySetInnerHTML={{ __html: val }}></TableCell>;
                  } else if (heading.value === 'action_date') {
                    val = utils.formatLogTimeUTC(row[heading.value]);
                    tablecell = <TableCell dangerouslySetInnerHTML={{ __html: val }}></TableCell>;
                  } else if (heading.value === "incentive" || heading.value === "projectCost") {
                    val = utils.formatAsCAD(row[heading.value]);
                    tablecell = <TableCell dangerouslySetInnerHTML={{ __html: val }}></TableCell>;
                  } else if (heading.value === "documentName") {
                    let urlVal = row["url"];
                    let docNameVal = row[heading.value];
                    tablecell = <TableCell><a href={urlVal} target="_blank" style={{ textDecoration: "none" }}>{docNameVal}</a></TableCell>
                  } else if (heading.value === "documentDownloadAPI") {
                    tablecell = <TableCell><a style={{ textDecoration: "none" }} href={row['finalChecklistZipUrl']} >{`${row['finalChecklistZipName']}`}</a></TableCell>
                  } else if (heading.value === "verficationDocument") {
                    let urlVal = row["verficationDocument"];
                    let docNameVal = 'View Document';
                    tablecell = <TableCell><a href={urlVal} target="_blank" style={{ textDecoration: "none" }}>{docNameVal}</a></TableCell>
                  }
                  else if ((heading.value === "changedFrom" || heading.value === "changedTo") && heading.custom === 'flag') {
                    let obj = row[heading.value];
                    tablecell = <TableCell>
                      {obj.status === "No Internal Status" ? '' : <FlagIcon color={obj.colorCode} />}
                      {obj.status}
                    </TableCell>
                  } else if (heading.value === "changes" && heading.custom === 'measureLogs') {
                    let changeRows = row[heading.value];
                    tablecell = <TableCell>
                      {changeRows.map((changeRow, crIndex) => {
                        if (changeRow.isURL) {
                          return <p><b>{changeRow.name}</b>{` : `} {changeRow.oldValue || "Nil"}{` => `}{changeRow.newValue}</p>;
                        } else if (changeRow.name.includes('Incentive') || changeRow.name.includes('Cost')) {
                          return <p><b>{changeRow.name}</b>{` : ${changeRow.oldValue || "Nil"} => ${utils.formatAsCAD(changeRow.newValue)}`}</p>;
                        } else if (changeRow.name.includes('Savings')) {
                          return <p><b>{changeRow.name}</b>{` : ${changeRow.oldValue || "Nil"} => ${utils.formatNumberWithCommas(changeRow.newValue)}`}</p>;
                        } else {
                          return <p><b>{changeRow.name}</b>{` : ${changeRow.oldValue || "Nil"} => ${changeRow.newValue}`}</p>;
                        }
                      })}
                    </TableCell>
                  } else if ((heading.value === "SNO")) {
                    tablecell = <TableCell >{index + 1}</TableCell>;
                  }

                  else {
                    tablecell = <TableCell dangerouslySetInnerHTML={{ __html: row[heading.value] }}></TableCell>;
                  }

                  return tablecell;
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        ""
      )}
    </TableContainer>
  );
}
