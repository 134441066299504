import React from 'react';
import { connect } from 'react-redux'
import { userActions } from '../../../../redux/actions/userActions';
import './ViewRequest.css';
import Input from '../../../Common/Input/Input';
import Button from '../../../Common/Button/Button';
import { AuthContext } from '../../../../firebaseAuthContext';
import { utils } from '../../../../helpers/utils';
import { applicationService } from '../../../../services/applicationService';
import LoaderComponent from '../../../Common/Loader/Loader';
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";

class ViewRequest extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            applicationObj : {},
            changeReqObj : {},
            newProjectDeadline : null,
            errors:{},
            reviewLoading: true,
            readyForApprove: false,
            approvedSuccessfully: false,
            confirmingApproval:true,
            changeNote:'',
            reviewStatus : { value : '', label : 'Select status from list'}
        }
    }

    async componentDidMount() {
        applicationService.getApplicationChangeRequest(this.props.changeReqId).then((changeReq)=>{
            applicationService.getApplication(changeReq.applicationId).then((appObj)=>{
                this.setState({
                    applicationObj: appObj,
                    changeReqObj : changeReq,
                    newProjectDeadline : appObj['Project Completion Deadline'] ? utils.getDateFromAmericanDateString(appObj['Project Completion Deadline']): '',
                    reviewLoading : false
                })
            });
        });
    }


    renderAppDetails = ()=>{
        const { applicationObj, changeReqObj,errors } = this.state;
        const  { program } = applicationObj;
        return !utils.isNullOrEmpty(applicationObj) ? <React.Fragment>
    <div className="change-request-review-inner-container">
            <div className="contractor-review-details">
                <div className="review-detail">Application ID</div>
                <div className="review-detail"><a href={`viewapplication/${changeReqObj.applicationId}`} target="_blank" rel="noreferrer">{program === "speed" ? "SPEED" : "ESB"}-{applicationObj.appNo}</a></div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Project Name</div>
                <div className="review-detail">{applicationObj.projectName}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Contractor Name</div>
                <div className="review-detail">{applicationObj.contractor.label}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Project Completion Deadline</div>
                <div className="review-detail">{applicationObj['Project Completion Deadline']}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Pre-Project Reviewer Estimated Incentive</div>
                <div className="review-detail">{utils.formatAsCAD(applicationObj['Reviewer Estimated Incentive'])}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Change Request Type</div>
                <div className="review-detail">{changeReqObj.requestType.label}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Change Request Description</div>
                <div className="review-detail">{changeReqObj.description}</div>
            </div>
    </div>
    </React.Fragment> : '';
    }

    render() {
        const { confirmingApproval, reviewLoading, reviewCompleted, userInfo, measureList,approvedSuccessfully, applicationObj, changeReqObj,reviewStatus,errors } = this.state;
        return (
            <div>
                <div className="view-request-container">
                    {reviewLoading ? <LoaderComponent/> : <React.Fragment>
                        <ModalAppDataComponent  applicationObj={{...applicationObj,...{changeReqNo:changeReqObj.changeReqNo}}}></ModalAppDataComponent>
                   {/* <div className="section-header">
                        <div className="section-top-details">CR-{changeReqObj.changeReqNo}</div>
                        <div className="section-top-details name-section">{applicationObj.companyName}</div>
                        <div className="section-top-details">{applicationObj.contractor.label}</div>
                    </div> */}
                    <div className="section-below-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'VIEW REQUEST' }}></h2>
                        <div className="review-status-selection">
                            <Input
                                disabled={true}
                                type='dropdown'
                                label={'CURRENT STATUS'}
                                name='changeReqStatus'
                                value={{ value : '', label : changeReqObj.status}}
                                options={[{ value : '', label : 'Select status from list'},{ value: 'Approved', label: 'Approved' }, { value: 'Rejected', label: 'Rejected' }]}
                                placeholder={'Select status from list'}
                            />
                        </div>
                    </div>
                    <div className='content-container'>
                    <div className="change-request-review-container">
                        <div className={`contractor-review-table`}>
                            <div className="contractor-review-table-left">
                                {this.renderAppDetails()}
                            </div>
                        </div>
                    </div>
                   <div className="review-notes-container">
                            {(changeReqObj.requestType.value === 'Extension-Deadline' && changeReqObj.status === 'Approved') ? 
                            <div className="project-deadline-container">
                            <Input
                            label={'Revised Project Completion Deadline'}
                            type={'date'}
                            disabled={confirmingApproval}
                            name={'newProjectDeadline'}
                            minDate={new Date(applicationObj.projectStartDate)}
                            value={this.state.newProjectDeadline}
                            error={errors['newProjectDeadline']}
                            onChange={this.onChangeHandler}
                            />
                            </div> : ''}
                            <label>Notes:</label>
                            <div className="review-notes-text" dangerouslySetInnerHTML={{__html:changeReqObj.changeNote }}/>
                        </div>
                        </div>
                    </React.Fragment>}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { measureList,applicationMeasuresLoading } = state.application;
    const { adminUserInfo } = state.authentication;
    return { measureList, adminUserInfo,applicationMeasuresLoading };
}

const actionCreators = {
    showModal: userActions.showEditParticipantModal
}

export default connect(mapState, actionCreators)(ViewRequest);