import React from "react";
import "./IncentivesSelectedForAction.css";
import Button from "../../../Common/Button/Button";
import { applicationService } from "../../../../services/applicationService";
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import { userActions } from "../../../../redux/actions/userActions";
import Input from "../../../Common/Input/Input";
class IncentivesSelectedForAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedAppsForAction: false,
      assignedSuccessfully: false,
      confirmingAction: false,
      appFlagNotes: "",
      activeAppId: "",
      actionNote: "",
      disableMultiClick: false
    };
  }
  async componentDidMount() {
    this.props.loadAdminConfig();
  }
  
  handleNotesChange = async (name, value) => {
    this.setState({
      actionNote:value,
    });
  };

  confirmingAppsforAction = () => {
    this.setState({
      confirmingAction: !this.state.confirmingAction,
    });
  };
  assignApplicationsForPaymentProcess = () => {
    const {selectedApplications,selectedApplicationsIncentiveTotal,selectedapplicationData,adminUserInfo,currentProgram}=this.props;
    this.setState({
      disableMultiClick: true,
    });
    const { actionNote } = this.state;
      applicationService.assignApplicationsForPaymentProcess({
        actionType:selectedapplicationData.actionType,
        applications:selectedApplications,
        actionNote:actionNote,actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
        incentiveTotal:selectedApplicationsIncentiveTotal,
        program : currentProgram
      }).then((res) => {
       this.setState(
     {
     assignedSuccessfully: true,
     disableMultiClick: false,
     },
     () => {
      setTimeout(() => {
        this.props.closeActionModal(true);
        this.props.searchData();
    //  this.props.handleAllAppsSelection();
     }, 5000);
    }
    );
    });
  };
  render() {
    const { configData,selectedapplicationData} = this.props;
    const {actionNote,assignedSuccessfully, disableMultiClick} = this.state;
    // let flagsList = [];
    // if (configData) {
    //   flagsList = configData.appFlags.flags.filter((item)=>item.trigger !== 'Automated');
    // }
    const { applicationObj, assignedAppsForAction, confirmingAction} = this.state;
    let appslist={};
    let appIds=[];
   // console.log("full data ======",selectedapplicationData);
    if(this.props.selectedApplications){
      appslist=this.props.selectedApplications;
      appIds=Object.keys(this.props.selectedApplications);
    }
    //console.log("apps list==",appslist);
    let modalTitle="APPROVE PAYOUT OF Incentives";
    let noteTitle="Incentive Payment Approval Notes"
    let actionTitle="Assign for Approval";
    let actionConfirmationTitle="RECOMMEND PAYOUT";
    let confirmationTitle="Confirm the details before sharing approval for incentive payout.";
    let actionSuccessfullMessage="Application(s) approved for incentive payout.";
    if(selectedapplicationData && selectedapplicationData.actionType==='reject'){
      modalTitle="Recommend Rejection";
      noteTitle="Recommend Rejection Note";
      actionTitle="Recommend Rejection";
      actionConfirmationTitle="CONFIRM RECOMMENDATION";
      confirmationTitle="Verify details before recommending rejection of applications";
      actionSuccessfullMessage="Recommended rejection of applications.";
    }
    if(selectedapplicationData && selectedapplicationData.actionType==='qaqc'){
      modalTitle="Recommend QA/QC";
      noteTitle="Recommend QA/QC Note";
      actionTitle="Recommend QA/QC";
      actionConfirmationTitle="CONFIRM RECOMMENDATION";
      confirmationTitle="Verify details before recommending further QA/QC of the applications";
      actionSuccessfullMessage="Recommended further QA/QC for the applications.";
    }
    return (
      <div className="incentives-selected-for-action">
        <React.Fragment>
        <div className="section-summary">
          <div className="section-block"><div className="label">No of Applications:</div><div className="info">{appIds.length}</div> </div>
          <div className="section-block"><div className="label">Total Incentive</div> <div className="totals">{`$${this.props.selectedApplicationsIncentiveTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</div></div>
        </div>
          <div className="section-below-header"> 
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: modalTitle }}></h2>
          </div>
          <div className="incentives-selected-for-action-container">
            <div className={`incentives-selected-for-action-table ${!confirmingAction ? "action-mode" : ""}`}>
              <div className="incentives-selected-for-action-table-left">
                <div className="incentives-selected-for-action-header">
                  <div className="action-detail">Application ID</div>
                  <div className="action-detail">Payee Name</div>
                  <div className="action-detail">Incentive Amount Payable</div>
                </div>
                <div className="incentives-selected-for-action-inner-container">
                  <React.Fragment>
                  {appIds && appIds.length>0? ( 
                     appIds.map((appId,i) => (
                        <div className="incentives-selected-for-action-details">
                        <div className="action-detail">#{appslist[appId].appNo}</div>
                        <div className="action-detail">{appslist[appId].payeeName}</div>
                        <div className="action-detail">{utils.formatAsCAD(appslist[appId].incentive)}
                        </div>
                      </div>
                      ))):('')}
                 
                    </React.Fragment>
                </div>
              </div>
              {confirmingAction ? (
                <React.Fragment>
                  <div className="vertical-separator-box">
                    <span className="separator"></span>
                  </div>
                  {assignedSuccessfully ? (
                      <p>{actionSuccessfullMessage}</p>
                    ) : (
                  <div className="incentives-selected-for-action-table-right">
                    <span>{confirmationTitle}</span>
                  </div>
                    )}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            <div className="form-inner-section">
                <div className="form-label bold full">{noteTitle}:</div>
              </div>
            <div className="form-inner-section">
                <div className="form-element full">
                  <Input
                    type="richtext"
                    disabled={confirmingAction}
                    value={actionNote}
                    onChange={this.handleNotesChange}
                    name="notes"
                  />
                </div>
              </div>
          </div>
          {assignedSuccessfully ? (
            ""
          ) : (
           
              <div className="button-set">
                {confirmingAction ? (
                  <Button className="inversed-btn" title={"EDIT Details"} uppercase onClick={this.confirmingAppsforAction} />
                ) : (""
                 // <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.props.closeActionModal()} />
                )}
                {confirmingAction ? (
                  <Button className="signup-btn" title={actionConfirmationTitle} disabled={disableMultiClick} uppercase onClick={this.assignApplicationsForPaymentProcess} />
                ) : (
                  <Button  
                    className="signup-btn"
                    title={actionTitle}
                    uppercase
                    onClick={this.confirmingAppsforAction}
                    disabled={
                      utils.isNullOrEmpty(actionNote) ||
                      utils.isNullOrEmpty(utils.cleanHTMLText(actionNote)) 
                    }
                  />
                )}
              </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}
function mapState(state) {
  const { adminUserInfo , currentProgram } = state.authentication;
  const { configData } = state.settings;
  return {
    adminUserInfo,
    currentProgram,
    configData,
  };
}
const actionCreators = {
  getUserDetails: userActions.getUserDetails,
  loadAdminConfig: adminSettingsActions.getAdminConfig,
};
export default connect(mapState, actionCreators)(IncentivesSelectedForAction);
