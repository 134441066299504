import React from "react";
import "./AppSummaryRetrofit.css";
import { applicationService } from "../../../services/applicationService";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import { utils } from "../../../helpers/utils";
import { AuthContext } from "../../../firebaseAuthContext";
import ProgramLayout from "../../Common/ProgramLayout";
import Loader from "../../Common/Loader/Loader";
import Table from "../../Common/Table/Table";
import EmptyContainer from "../../Common/EmptyContainer/EmptyContainer";
import Button from "../../Common/Button/Button";
import SyncIcon from "../../../images/SyncIcon.svg";
import Modal from "../../Common/Modal/Modal";
import FieldCardList from "../../Common/FieldCardList/FieldCardList";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";

export class AppSummaryRet extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
      }
      if (this.props.history.location.pathname.includes("retrofit")) {
        currentProgram = "retrofit";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      currentProgram,
      dataLoaded: false,
      applicationObj: {
        stage: "Pre-Project",
      },
      facilities: [],
      facilityDetails: {},
      comments: {},
      updatedTime: true,
      currentFacilityDetailsLoading: true,
      currentFacilityDetails: [],
      facilityDetailModalOpen: false,
      measureDetailModalOpen: false,
      currentMeasureDetailsLoading: true,
      currentMeasureDetails: [],
      finalChecklistData: [],
      openPreProjectChecklistModal: false,
      openPostProjectChecklistModal: false,
      lastChecklistData: [],
      modalLoading: false,
      openTextModal: false,
      summaryText: '',
    };
  }

  componentDidMount() {
    this.syncApplicationById();
  }
  syncApplicationById = async () => {
    let syncAppId = this.props.match.params.id;
    await applicationService.normalSyncApplicationSummaryRetrofit(syncAppId);
    this.updateDetails();
  };
  updateDetails() {
    const appIdToLoad = this.props.match.params.id;
    if (!utils.isNullOrEmpty(appIdToLoad)) {
      applicationService
        .getApplicationRetrofit(appIdToLoad)
        .then(async (data) => {
          let applicationObj = { ...data };
          let facilities = "";
          let commentsObj = "";
          try {
            facilities = await applicationService.getFacilitiesByApplicationIdRetrofit(appIdToLoad);
          } catch (err) {
            console.log("Facilities", err);
          }
          try {
            commentsObj = await applicationService.getApplicationsCommentsByIdRetrofit(appIdToLoad);
          } catch (err) {
            console.log("Comments", err);
          }
          try {
            await this.props.getRetrofitApplicationsReviewProgress(appIdToLoad);
          } catch (err) {
            console.log("Review Progress", err);
          }
          applicationService.getRetrofitApplicationChecklist(appIdToLoad).then(async (checklistData) => {
            if (utils.isNullOrEmpty(checklistData)) {
              this.setState({
                dataLoaded: true,
                applicationObj,
                facilities,
                comments: commentsObj.comments,
                updatedTime: true,
                finalChecklistData: {},
              });
            } else {
              // let stage = applicationObj.stage;
              // let appId = `${appIdToLoad}`;
              // let facilityZipUrlByStage = checklistData[applicationObj.stage];
              // if (facilityZipUrlByStage) {
              //   for (var key in facilityZipUrlByStage) {
              //     let facilityId = Object.keys(facilityZipUrlByStage[key])[0];
              //     let finalChecklistZipObj = await applicationService.getRetrofitChecklistDocumentByFacilityId(stage, appId, facilityId);
              //     facilityZipUrlByStage[key][facilityId].finalZipURL = finalChecklistZipObj.resultZipUrl;
              //     facilityZipUrlByStage[key][facilityId].finalZipURLName = finalChecklistZipObj.name;
              //   }
              //   checklistData[applicationObj.stage] = facilityZipUrlByStage;
              this.setState({
                dataLoaded: true,
                applicationObj,
                facilities,
                comments: commentsObj.comments,
                updatedTime: true,
                finalChecklistData: checklistData,
              });
              // }
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  exportFacility = async (appId, facilityId) => {
    await applicationService.getExcelByApplicationAndFacilityIdRetrofit(appId, facilityId);
  };
  showFacilityDetailModal = (shown) => {
    this.setState({
      facilityDetailModalOpen: shown,
    });
  };
  viewFacilityDetails = (facilitiesDetails) => {
    let details = [
      { label: "Facility Name", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_name) || "N/A" },
      { label: "Facility ID", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_id) || "N/A" },
      { label: "Facility Ownership", value: (facilitiesDetails.facility && facilitiesDetails.facility.ownership_type) || "N/A" },
      { label: "Facility Type", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_type_name) || "N/A" },
      { label: "Project Type", value: (facilitiesDetails.facility && facilitiesDetails.facility.project_type) || "N/A" },
      { label: "Project Size", value: (facilitiesDetails.facility && facilitiesDetails.facility.project_size_class) || "N/A" },
      {
        label: "Facility Address",
        value:
          (facilitiesDetails.facility &&
            `${facilitiesDetails.facility.address1},${
              facilitiesDetails.facility.address2 ? " " + facilitiesDetails.facility.address2 + "," : ""
            }${facilitiesDetails.facility.address3 ? " " + facilitiesDetails.facility.address3 + "," : ""} ${
              facilitiesDetails.facility.city
            }, ${facilitiesDetails.facility.province}, ${facilitiesDetails.facility.postal_code}`) ||
          "N/A",
      },
      {
        label: "Estimated SHP Adder Amount",
        value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.total_estimated_sha_amount),
      },
      {
        label: "Actual SHP Adder Amount",
        value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.total_actual_shp_amount),
      },
      {
        label: "SHP Advance Incentive",
        value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.total_advance_incentive_amount),
      },
      {
        label: "Capped total estimated facility incentive",
        value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.cap_tot_est_facility_incentive),
      },
      {
        label: "Total estimated facility incentive",
        value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.tot_est_facility_incentive),
      },
      {
        label: "Capped total actual facility incentive",
        value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.cap_tot_act_facility_incentive),
      },
      {
        label: "Total actual facility incentive",
        value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.tot_actual_facility_incentive),
      },
      { label: "SHP Incentive %", value: facilitiesDetails.facility && utils.formatAsCAD(facilitiesDetails.facility.sha_advance_percent) },
      { label: "Utility Number", value: (facilitiesDetails.facility && facilitiesDetails.facility.electric_utility_number) || "N/A" },
    ];
    this.setState({
      facilityDetailModalOpen: true,
      currentFacilityDetailsLoading: false,
      currentFacilityDetails: details,
    });
  };
  showMeasureDetailModal = (shown) => {
    this.setState({
      measureDetailModalOpen: shown,
    });
  };
  viewMeasureDetails = (measure) => {
    let details = [
      { label: "Prescriptive Measure ID", value: measure.prescriptive_measure_id || "N/A" },
      { label: "Conservation Measure Name", value: measure.conservation_measure_name || "N/A" },
      { label: "Measure Category", value: measure.measure_category || "N/A" },
      { label: "Measure Description", value: measure.measure_description || "N/A" },
      { label: "End-use", value: measure.end_use || "N/A" },
      { label: "Eligibility criteria", value: measure.eligibility_criteria || "N/A" },
      { label: "Manufacturer", value: measure.manufacturer || "N/A" },
      {
        label: "Model Number",
        value: measure.model_number || "N/A",
      },
      { label: "ES/DLC Code", value: measure.es_dlc_code || "N/A" },
      { label: "Assumed Base Case", value: measure.assumed_base_case || "N/A" },
      { label: "Estimated Quantity", value: measure.estimated_quantity || "N/A" },
      { label: "Actual Quantity", value: measure.actual_quantity || "N/A" },
      { label: "Measure Unit", value: measure.measure_unit || "N/A" },
      { label: "Incentive per Unit", value: measure.incentive_per_unit || "N/A" },
      { label: "Estimated Calculated Incentive", value: utils.formatAsCAD(measure.estimated_calculated_incentive) },
      { label: "Actual Calculated Incentive", value: utils.formatAsCAD(measure.actual_calculated_incentive) },
      { label: "Estimated kWh Savings", value: measure.estimated_kwh_saving || "N/A" },
      { label: "Actual kWh Savings", value: measure.actual_kwh_saving || "N/A" },
      { label: "Estimated Peak Demand Savings", value: measure.est_peak_demand_savings || "N/A" },
      { label: "Actual Peak Demand Savings", value: measure.act_peak_demand_savings || "N/A" },
      { label: "Energy Saving per Unit", value: measure.energy_saving_per_unit || "N/A" },
      { label: "Demand Saving per Unit", value: measure.demand_saving_per_unit || "N/A" },
      { label: "Peak Demand Savings", value: measure.peak_demand_savings || "N/A" },
      { label: "Estimated kW Reduction", value: measure.estimated_kw_reduction || "N/A" },
      { label: "Actual kW Reduction", value: measure.acutal_kw_reduction || "N/A" },
      { label: "Measure Creation Date", value: measure.creation_dt || "N/A" },
      // { label: "Is complete?", value: measure.is_complete || "N/A" },
    ];
    this.setState({
      measureDetailModalOpen: true,
      currentMeasureDetailsLoading: false,
      currentMeasureDetails: details,
    });
  };
  viewCustomMeasureDetails = (measure) => {
    let details = [
      { label: "Custom Measure ID", value: measure.custom_measure_id || "N/A" },
      { label: "Measure Name", value: measure.custom_measure_name || "N/A" },
      { label: "Category", value: measure.custom_measure_category || "N/A" },
      { label: "End Use", value: measure.end_use || "N/A" },
      { label: "Sub-Category", value: measure.sub_category || "N/A" },
      { label: "Other Sub-Category", value: measure.sub_category_other || "N/A" },
      { label: "Base Case Description", value: measure.base_case_description || "N/A" },
      { label: "Energy Efficient Case Description", value: measure.energy_efficient_case_desc || "N/A" },
      { label: "Manufacturer", value: measure.manufacturer || "N/A" },
      { label: "Model Number", value: measure.model_number || "N/A" },
      { label: "Estimated Base Case Consumption", value: measure.est_base_case_consumption || "N/A" },
      { label: "Estimated Energy Efficient Case Consumed", value: measure.est_energy_eff_case_consume || "N/A" },
      { label: "Estimated Base Case Demand", value: measure.est_base_case_demand || "N/A" },
      { label: "Estimated Energy Efficient Case Demand", value: measure.est_energy_eff_case_demand || "N/A" },
      { label: "Actual Base Case Consumption", value: measure.actual_base_case_consumption || "N/A" },
      { label: "Actual Energy Efficient Case Consumed", value: measure.actual_nrg_eff_case_consume || "N/A" },
      { label: "Actual Base Case Demand", value: measure.actual_base_case_demand || "N/A" },
      { label: "Actual Energy Efficient Case Demand", value: measure.actual_nrg_eff_case_demand || "N/A" },
      { label: "Estimated kWh Savings", value: measure.estimated_kwh_savings || "N/A" },
      { label: "Actual kWh Savings", value: measure.actual_kwh_savings || "N/A" },
      { label: "Estimated kW Reduction", value: measure.estimated_kw_reduction || "N/A" },
      { label: "Actual kW Reduction", value: measure.actual_kw_reduction || "N/A" },
      { label: "Incentive Metric Pre-Project", value: measure.incentive_metric_pre_project || "N/A" },
      { label: "Calculated Incentive Pre-Project", value: measure.calculated_incentive_pre_proj || "N/A" },
      { label: "Incentive Metric Post-Project", value: measure.incentive_metric_post_project || "N/A" },
      { label: "Calculated Incentive Post-Project", value: measure.calculated_incentive_post_proj || "N/A" },
      { label: "Estimated Equipment Cost", value: measure.estimated_equipment_cost || "N/A" },
      { label: "Actual Equipment Cost", value: measure.actual_equipment_cost || "N/A" },
      { label: "Estimated Cost Summary", value: measure.estimated_cost_summary || "N/A" },
      { label: "Actual Cost Summary", value: measure.actual_cost_summary || "N/A" },
      { label: "One Month Meter Load", value: measure.one_month_meter_load || "N/A" },
      { label: "M&T Advance Incentive Amount", value: measure.mt_advance_incentive || "N/A" },
      { label: "TSM Advance Incentive", value: measure.tsm_advance_incentive || "N/A" },
      { label: "Estimated Installation Cost", value: measure.estimated_installation_cost || "N/A" },
      { label: "Actual Installation Cost", value: measure.actual_installation_cost || "N/A" },
      { label: "Is Complete?", value: measure.is_complete || "N/A" },
      { label: "Engineered", value: measure.engineered || "N/A" },
      { label: "Creation Date", value: measure.creation_dt || "N/A" },
    ];
    this.setState({
      measureDetailModalOpen: true,
      currentMeasureDetailsLoading: false,
      currentMeasureDetails: details,
    });
  };
  viewDocument = (url) => {
    window.open(url);
  };
  setChecklist = async (currentStage, finalChecklistData) => {
    const { applicationObj } = this.state;
    let openPre = currentStage === "Pre-Project";
    let openPost = currentStage === "Post-Project";
    this.setState({
      openPostProjectChecklistModal: openPost,
      openPreProjectChecklistModal: openPre,
      modalLoading: true,
    });
    let stage = currentStage;
    let appId = `${applicationObj.application_id}`;
    let facilityZipUrlByStage = finalChecklistData[stage];
    if (facilityZipUrlByStage) {
      for (var key in facilityZipUrlByStage) {
        let facilityId = Object.keys(facilityZipUrlByStage[key])[0];
        let finalChecklistZipObj = await applicationService.getRetrofitChecklistDocumentByFacilityId(stage, appId, facilityId);
        facilityZipUrlByStage[key][facilityId].finalZipURL = finalChecklistZipObj.resultZipUrl;
        facilityZipUrlByStage[key][facilityId].finalZipURLName = finalChecklistZipObj.name;
      }
      finalChecklistData[stage] = facilityZipUrlByStage;
      this.setState({
        lastChecklistData: finalChecklistData,
        openPostProjectChecklistModal: openPost,
        openPreProjectChecklistModal: openPre,
        modalLoading: false,
      });
    }
  };
  applicationSummary = () => {
    const { applicationObj, facilities, comments, finalChecklistData } = this.state;
    const { adminUserInfo, currentProgram, reviewProgress } = this.props;

    let actions = [];
    if (currentProgram && adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions[currentProgram].actions;
    }
    let activeFacilities = facilities && facilities.filter((f) => f.isActive === true);
    let invoicesList = applicationObj && applicationObj.applicationDetails ? applicationObj.applicationDetails.invoice : [];
    let appDetails = applicationObj && applicationObj.applicationDetails ? applicationObj.applicationDetails.application : "";
    let invoiceHeading = [
      { title: "Document Name", value: "document_name" },
      { title: "Date of Upload", value: "creation_dt" },
      { title: "Type", value: "type" },
      { title: "View", value: "view" },
    ];
    let invoiceRows = invoicesList.length
      ? invoicesList.filter((invoice)=> !utils.isNullOrEmpty(invoice.url)).map((invoice) => {
          return {
            document_name: `${invoice.document_name}` || "N/A",
            creation_dt: utils.formatDate(invoice.creation_dt) || "N/A",
            type: `${invoice.type}` || "N/A",
            view: (
              <div className="view-icon-container">
                <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(invoice.url)} />
              </div>
            ),
          };
        })
      : "";
    let facilityAndMeasure =
      activeFacilities &&
      activeFacilities.map((json) => {
        let prescriptiveMeasureRows = json.facilitiesDetails
          ? json.facilitiesDetails.prescriptiveMeasures
              .filter((ele) => ele.isActive)
              .map((measure, index) => {
                return {
                  endUse: (
                    <div className="facility-name">
                      {measure.end_use}
                      <div
                        className="view-facility"
                        onClick={() => this.viewMeasureDetails(json.facilitiesDetails.prescriptiveMeasures[index])}
                      >
                        {`(#${measure.prescriptive_measure_id})`}
                      </div>
                    </div>
                  ),
                  Measures: (
                    <div className="facility-name">
                      {measure.conservation_measure_name}
                      <div className="criteria-text">
                        <span className="turncate-text"> {measure.measure_description}</span>
                        <span
                          className="expand"
                          onClick={() =>
                            this.setState({
                              summaryText: measure.measure_description,
                              openTextModal: true,
                            })
                          }
                        >
                          Expand
                        </span>
                      </div>
                    </div>
                  ),
                  "Measure Type": "Prescriptive",
                  estimatePeakDemand: utils.formatKw(measure.est_peak_demand_savings) || "N/A",
                  estimatedSaving: utils.formatKwh(measure.estimated_kwh_saving) || "N/A",
                  estimatedCalculatedIncentive: utils.formatAsCAD(measure.estimated_calculated_incentive) || "N/A",
                  actualPeakDemand: utils.formatKw(measure.act_peak_demand_savings) || "N/A",
                  actualSaving: utils.formatKwh(measure.actual_kwh_saving) || "N/A",
                  calculatedIncentive: utils.formatAsCAD(measure.actual_calculated_incentive) || "N/A",
                };
              })
          : [];
        let customMeasureRows = json.facilitiesDetails
          ? json.facilitiesDetails.customMeasures
              .filter((ele) => ele.isActive)
              .map((measure, index) => {
                return {
                  endUse: (
                    <div className="facility-name">
                      {measure.end_use}
                      <div
                        className="view-facility"
                        onClick={() => this.viewCustomMeasureDetails(json.facilitiesDetails.customMeasures[index])}
                      >
                        {`(#${measure.custom_measure_id})`}
                      </div>
                    </div>
                  ),
                  Measures: (
                    <div className="facility-name">
                      {measure.custom_measure_name}
                      <div className="criteria-text">
                        <span className="turncate-text"> {measure.energy_efficient_case_desc}</span>
                        <span
                          className="expand"
                          onClick={() =>
                            this.setState({
                              summaryText: measure.energy_efficient_case_desc,
                              openTextModal: true,
                            })
                          }
                        >
                          Expand
                        </span>
                      </div>
                    </div>
                  ),
                  "Measure Type": "Custom",
                  estimatePeakDemand: utils.formatKw(measure.estimated_kw_reduction) || "N/A",
                  estimatedSaving: utils.formatKwh(measure.estimated_kwh_savings) || "N/A",
                  estimatedCalculatedIncentive: utils.formatAsCAD(measure.calculated_incentive_pre_proj) || "N/A",
                  actualPeakDemand: utils.formatKw(measure.actual_kw_reduction) || "N/A",
                  actualSaving: utils.formatKwh(measure.actual_kwh_savings) || "N/A",
                  calculatedIncentive: utils.formatAsCAD(measure.calculated_incentive_post_proj) || "N/A",
                };
              })
          : [];
        let facilityObj = {
          facilityID: json.facility_id,
          facilityHeadings:
            applicationObj.stage === "Pre-Project"
              ? [
                  { title: "Facility Name", value: "Facility Name" },
                  { title: "Project Type", value: "Project Type" },
                  { title: "Project Size", value: "Project Size" },
                  { title: "Facility Address", value: "Facility Address" },
                  { title: "Facility Ownership", value: "Facility Ownership" },
                  { title: "Facility Type", value: "Facility Type" },
                  { title: "Facility Incentive", value: "Facility Incentive" },
                  { title: "View Details", value: "View Details" },
                ]
              : [
                  { title: "Facility Name", value: "Facility Name" },
                  { title: "Project Type", value: "Project Type" },
                  { title: "Project Size", value: "Project Size" },
                  { title: "Facility Address", value: "Facility Address" },
                  { title: "Facility Ownership", value: "Facility Ownership" },
                  { title: "Facility Type", value: "Facility Type" },
                  { title: "Actual Facility Incentive", value: "Actual Facility Incentive" },
                  { title: "View Details", value: "View Details" },
                ],
          facilityRows: [
            {
              "Facility Name": json.facility_name ? json.facility_name : "N/A",
              "Project Type": json.project_type ? json.project_type : "N/A",
              "Project Size": json.project_size_class ? json.project_size_class : "N/A",
              "Facility Address":
                json.facilitiesDetails && json.facilitiesDetails.facility
                  ? `${json.facilitiesDetails.facility.address1},${
                      json.facilitiesDetails.facility.address2 ? " " + json.facilitiesDetails.facility.address2 + "," : ""
                    }${json.facilitiesDetails.facility.address3 ? " " + json.facilitiesDetails.facility.address3 + "," : ""} ${
                      json.facilitiesDetails.facility.city
                    }, ${json.facilitiesDetails.facility.province}, ${json.facilitiesDetails.facility.postal_code}`
                  : "N/A",
              "Facility Ownership":
                json.facilitiesDetails.facility && json.facilitiesDetails.facility.ownership_type
                  ? json.facilitiesDetails.facility.ownership_type
                  : "N/A",
              "Facility Type":
                json.facilitiesDetails.facility && json.facilitiesDetails.facility.facility_type_name
                  ? json.facilitiesDetails.facility.facility_type_name
                  : "N/A",
              "Facility Incentive":
                json.facilitiesDetails.facility && json.facilitiesDetails.facility.tot_est_facility_incentive
                  ? utils.formatAsCAD(json.facilitiesDetails.facility.tot_est_facility_incentive)
                  : "$0.0",
              "Actual Facility Incentive":
                json.facilitiesDetails.facility && json.facilitiesDetails.facility.tot_est_facility_incentive
                  ? utils.formatAsCAD(json.facilitiesDetails.facility.tot_actual_facility_incentive)
                  : "$0.0",
              "View Details": (
                <div className="view-icon-container">
                  <ViewIcon
                    className="view-eye-icon"
                    width="24"
                    height="15"
                    onClick={() => this.viewFacilityDetails(json.facilitiesDetails)}
                  />
                </div>
              ),
            },
          ],
          measureHeading:
            applicationObj.stage === "Pre-Project"
              ? [
                  { title: "End-Use", value: "endUse" },
                  { title: "Measures", value: "Measures" },
                  { title: "Measure Type", value: "Measure Type" },
                  { title: "Estimated Peak Demand Savings", value: "estimatePeakDemand" },
                  { title: "Estimated kWh Savings", value: "estimatedSaving" },
                  { title: "Estimated Calculated Incentive", value: "estimatedCalculatedIncentive" },
                ]
              : [
                  { title: "End-Use", value: "endUse" },
                  { title: "Measures", value: "Measures" },
                  { title: "Measure Type", value: "Measure Type" },
                  { title: "Actual Peak Demand Savings", value: "actualPeakDemand" },
                  { title: "Actual kWh Savings", value: "actualSaving" },
                  { title: "Estimated Calculated Incentive", value: "estimatedCalculatedIncentive" },
                  { title: "Actual Calculated Incentive", value: "calculatedIncentive" },
                ],
          measureRows: [...prescriptiveMeasureRows, ...customMeasureRows],
          measureFooter: (
            <div className="responsive-table-row">
              <div className="responsive-table-details">Total</div>
              <div className="responsive-table-details"></div>
              <div className="responsive-table-details"></div>
              {applicationObj.stage === "Pre-Project" ? <div className="responsive-table-details">
                <label>Total Estimated Peak Demand Savings</label>
                <span>{utils.formatKw(json.facilitiesDetails && json.facilitiesDetails.facility.tot_est_peak_demand_savings) || "0"}</span>
              </div> : null}
              {applicationObj.stage === "Pre-Project" ? null : (
                <div className="responsive-table-details">
                  <label>Total Actual Peak Demand Savings</label>
                  <span>{utils.formatKw(json.facilitiesDetails && json.facilitiesDetails.facility.tot_act_peak_demand_savings) || "0"}</span>
                </div>
              )}
              {applicationObj.stage === "Pre-Project" ? <div className="responsive-table-details">
                <label>Total Estimated kWh Savings</label>
                <span>{utils.formatKwh(json.facilitiesDetails && json.facilitiesDetails.facility.tot_estimated_kwh_saving) || "0"}</span>
              </div> : null}
              {applicationObj.stage === "Pre-Project" ? null : (
                <div className="responsive-table-details">
                  <label>Total Actual kWh Savings</label>
                  <span>{utils.formatKwh(json.facilitiesDetails && json.facilitiesDetails.facility.tot_actual_kwh_saving) || "0"}</span>
                </div>
              )}
              <div className="responsive-table-details">
                <label>Total Estimated Facility Incentive ($)</label>
                <span>{json.facilitiesDetails && utils.formatAsCAD(json.facilitiesDetails.facility.tot_est_facility_incentive)}</span>
              </div>
              {applicationObj.stage === "Pre-Project" ? null : (
                <div className="responsive-table-details">
                  <label>Total Actual Facility Incentive</label>
                  <span>{json.facilitiesDetails && utils.formatAsCAD(json.facilitiesDetails.facility.tot_actual_facility_incentive)}</span>
                </div>
              )}
            </div>
          ),
          docHeading: [
            { title: "Document Name", value: "upload_file_doc_name" },
            { title: "Date of Upload", value: "creation_dt" },
            { title: "View", value: "view" },
          ],
          docRows: json.facilitiesDetails
            ? json.facilitiesDetails.fileUploads.map((doc) => {
                return {
                  upload_file_doc_name: `${doc.upload_file_doc_name}` || "N/A",
                  creation_dt: utils.formatDate(doc.creation_dt) || "N/A",
                  view: (
                    <div className="view-icon-container">
                      <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(doc.url)} />
                    </div>
                  ),
                };
              })
            : [],
        };
        return facilityObj;
      });
    return (
      <div className="form-sections application-summary">
        <div className="step-header-container">
          <div className="step-header-container-left">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Application Summary" }}></h2>
          </div>
        </div>
        {actions["Application Board-Pre-Project Review Reponses"] && !utils.isNullOrEmpty(reviewProgress) ? (
          <div className="form-inner-section">
            <h2 className="step-header-title">Application Review</h2>
            <div className="btn-container">
              {actions["Application Board-Pre-Project Review Reponses"] && reviewProgress["Pre-Project"] ? (
                <div
                  className="enerva-btn"
                  onClick={() =>
                    window.open(`reviewresponses/Pre-Project/${applicationObj.application_id}`, "", "resizable=no,width=1000,height=900")
                  }
                >
                  Pre-Project Review Reponses
                </div>
              ) : (
                ""
              )}
              {applicationObj.stage !== "Pre-Project" &&
              reviewProgress["Post-Project"] &&
              actions["Application Board-Post-Project Review Reponses"] ? (
                <>
                  {reviewProgress["Pre-Project"] ? <span style={{ borderRight: "3px solid #048041", height: "40px" }}></span> : ""}
                  <div
                    className="enerva-btn"
                    onClick={() =>
                      window.open(`reviewresponses/Post-Project/${applicationObj.application_id}`, "", "resizable=no,width=1000,height=900")
                    }
                  >
                    Post-Project Review Reponses
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {actions["Application Board-Pre-Project Checklist"] && !utils.isNullOrEmpty(finalChecklistData) && (
          <div className="form-inner-section">
            <h2 className="step-header-title">Checklist</h2>
            <div className="btn-container">
              {finalChecklistData["Pre-Project"] ? (
                <div className="enerva-btn" onClick={() => this.setChecklist("Pre-Project", finalChecklistData)}>
                  Pre-Project Checklist
                </div>
              ) : (
                ""
              )}
              {finalChecklistData["Pre-Project"] && finalChecklistData["Post-Project"] ? (
                <span style={{ borderRight: "3px solid #048041", height: "40px" }}></span>
              ) : (
                ""
              )}
              {finalChecklistData["Post-Project"] ? (
                <div className="enerva-btn" onClick={() => this.setChecklist("Post-Project", finalChecklistData)}>
                  Post-Project Checklist
                </div>
              ) : (
                ""
              )}
              {/* {preProjectDownloadingFileRows.length > 0 ? (
                <div className="pre-project-checklist">
                  <Table
                    rows={preProjectDownloadingFileRows}
                    headings={[
                      { title: "Facility ID", value: "facilityId" },
                      { title: "Download Facility Files", value: "documentDownloadAPI" },
                    ]}
                  />
                </div>
              ) : (
                ""
              )} */}
              {/* {applicationObj.stage !== "Pre-Project" && postProjectDownloadingFileRows.length > 0 ? (
                <>
                  {preProjectDownloadingFileRows.length ? <span style={{ borderRight: "3px solid #048041", height: "40px" }}></span> : ""}
                  <div className="post-project-checklist">
                    <Table
                      rows={postProjectDownloadingFileRows}
                      headings={[
                        { title: "Facility ID", value: "facilityId" },
                        { title: "Download Facility Files", value: "documentDownloadAPI" },
                      ]}
                    />
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>
          </div>
        )}
        {applicationObj ? (
          <div className="form-inner-section">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Project Summary" }}></h2>
            <div className="form-inner-elements">
              <div className="application-form-label">Application ID</div>
              <div className="application-form-element">{applicationObj.application_id ? applicationObj.application_id : "N/A"}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Application Status </div>
              <div className="application-form-element">
                {applicationObj.application_status_name ? applicationObj.application_status_name : "N/A"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Applicant Name</div>
              <div className="application-form-element">{`${
                applicationObj.applicant_first_name ? applicationObj.applicant_first_name : "N/A"
              } ${applicationObj.applicant_last_name ? applicationObj.applicant_last_name : "N/A"}`}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Application Project Type </div>
              <div className="application-form-element">
                {applicationObj.application_project_type ? applicationObj.application_project_type : "N/A"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Company Name</div>
              <div className="application-form-element">{`${appDetails.company ? appDetails.company : "N/A"}`}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Total Estimated Application Incentive</div>
              <div className="application-form-element">
                {applicationObj.total_est_app_incentive ? utils.formatAsCAD(applicationObj.total_est_app_incentive) : "$0.0"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Total Actual Application Incentive</div>
              <div className="application-form-element">
                {applicationObj.total_actual_app_incentive ? utils.formatAsCAD(applicationObj.total_actual_app_incentive) : "$0.0"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Application Created Date</div>
              <div className="application-form-element">
                {applicationObj &&
                applicationObj.applicationDetails.application &&
                applicationObj.applicationDetails.application.creation_dt
                  ? utils.formatDate(applicationObj.applicationDetails.application.creation_dt)
                  : "N/A"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Pre-Project Submission Date</div>
              <div className="application-form-element">
                {applicationObj.pre_project_submission_date ? utils.formatDate(applicationObj.pre_project_submission_date) : "N/A"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Project Start Date</div>
              <div className="application-form-element">
                {applicationObj.estimated_project_start_date
                  ? utils.formatAmericanDate(applicationObj.estimated_project_start_date)
                  : "N/A"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Project End Date</div>
              <div className="application-form-element">
                {applicationObj.estimated_completion_date ? utils.formatAmericanDate(applicationObj.estimated_completion_date) : "N/A"}
              </div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Project Completion Deadline</div>
              <div className="application-form-element">December 31, 2025</div>
            </div>
          </div>
        ) : (
          ""
        )}
        {!utils.isNullOrEmpty(facilityAndMeasure) ? (
          facilityAndMeasure.map((data) => {
            return (
              <React.Fragment>
                <div className="horizontal-bar"></div>
                <div className="form-inner-section">
                  <div className="facility-block">
                    <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: `Facility Details - #${data.facilityID}` }}></h2>
                    {actions["Application Board-Export For Review"] && (
                      <Button
                        className="export-facility"
                        title={"Export For Review"}
                        onClick={() => this.exportFacility(applicationObj.application_id, data.facilityID)}
                      />
                    )}
                  </div>
                  <ResponsiveTable headings={data.facilityHeadings} rows={data.facilityRows} />
                </div>
                <div className="horizontal-bar"></div>
                <div className="form-inner-section">
                  <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: `Measure Details - #${data.facilityID}` }}></h2>
                  {utils.isNullOrEmpty(data.measureRows) ? (
                    <EmptyContainer>No Measures to Show</EmptyContainer>
                  ) : (
                    <ResponsiveTable headings={data.measureHeading} rows={data.measureRows} customFooter={data.measureFooter} />
                  )}
                </div>
                <div className="horizontal-bar"></div>
                <div className="form-inner-section">
                  <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: `Documents Summary - #${data.facilityID}` }}></h2>
                  {utils.isNullOrEmpty(data.docRows) ? (
                    <EmptyContainer>No Documents to Show</EmptyContainer>
                  ) : (
                    <ResponsiveTable headings={data.docHeading} rows={data.docRows} />
                  )}
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <React.Fragment>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <div className="facility-block">
                <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: `Facility Details` }}></h2>
              </div>
              <EmptyContainer>No Facilities to Show</EmptyContainer>
            </div>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Measure Details" }}></h2>
              <EmptyContainer>No Measures to Show</EmptyContainer>
            </div>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Documents Summary" }}></h2>
              <EmptyContainer>No Documents to Show</EmptyContainer>
            </div>
          </React.Fragment>
        )}
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: `Invoices` }}></h2>
          {utils.isNullOrEmpty(invoiceRows) ? (
            <EmptyContainer>No Invoices to Show</EmptyContainer>
          ) : (
            <ResponsiveTable headings={invoiceHeading} rows={invoiceRows} />
          )}
        </div>
        <div className="horizontal-bar"></div>
        {comments && (
          <div className="form-inner-section">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "History and Comments" }}></h2>
            {comments.length ? (
              <Table
                rows={comments}
                headings={[
                  { title: "User", value: "account_id" },
                  { title: "User Role", value: "user_role" },
                  { title: "Action Type", value: "action_type_name" },
                  { title: "Submitted To", value: "submitted_to" },
                  { title: "Date and Time", value: "action_date" },
                  { title: "Comments", value: "comments" },
                ]}
              />
            ) : (
              <EmptyContainer>No Comments to Show</EmptyContainer>
            )}
          </div>
        )}
      </div>
    );
  };
  updateSyncTime = async (appId) => {
    this.setState({ updatedTime: false });
    try {
      await applicationService.syncApplicationSummaryRetrofit(appId);
      this.updateDetails();
    } catch (err) {
      console.log("Updating App", err);
    }
  };
  render() {
    const {
      currentFacilityDetailsLoading,
      currentFacilityDetails,
      currentMeasureDetailsLoading,
      currentMeasureDetails,
      dataLoaded,
      applicationObj,
      updatedTime,
      facilityDetailModalOpen,
      measureDetailModalOpen,
      openPreProjectChecklistModal,
      openPostProjectChecklistModal,
      lastChecklistData,
      modalLoading,
      summaryText,
      openTextModal
    } = this.state;
    const appIdToLoad = this.props.match.params.id;
    let preProjectDownloadingFileRows = [];
    let postProjectDownloadingFileRows = [];
    if (!utils.isNullOrEmpty(lastChecklistData) && lastChecklistData["Pre-Project"]) {
      preProjectDownloadingFileRows = Object.values(lastChecklistData && lastChecklistData["Pre-Project"]).map((data, i) => {
        let newData = Object.values(data);
        return {
          facilityId: newData[0].facilityId,
          url: newData[0].checklistZipUrl,
          documentDownloadAPI: newData[0].name,
          finalChecklistZipUrl: newData[0].finalZipURL,
          finalChecklistZipName: newData[0].finalZipURLName,
          projectType: newData[0].projectType
        };
      });
    }
    if (
      !utils.isNullOrEmpty(lastChecklistData) &&
      lastChecklistData["Post-Project"] &&
      applicationObj &&
      applicationObj.stage !== "Pre-Project"
    ) {
      postProjectDownloadingFileRows = Object.values(lastChecklistData && lastChecklistData["Post-Project"]).map((data, i) => {
        let newData = Object.values(data);
        return {
          facilityId: newData[0].facilityId,
          url: newData[0].checklistZipUrl,
          documentDownloadAPI: newData[0].name,
          finalChecklistZipUrl: newData[0].finalZipURL,
          finalChecklistZipName: newData[0].finalZipURLName,
          projectType: newData[0].projectType
        };
      });
    }
    return (
      <ProgramLayout showFooter={false} showSideBarMenu={true} props={this.props}>
        <div className="view-application-container">
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            loading={modalLoading}
            IsOpen={openPreProjectChecklistModal}
            handleCloseModal={() => this.setState({ openPreProjectChecklistModal: false, modalLoading: false })}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{applicationObj.application_id}</div>
                <div className="section-top-details name-section">{`${applicationObj.applicant_first_name} ${applicationObj.applicant_last_name}`}</div>
                <div className="section-top-details">{applicationObj.applicant_account_id}</div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Pre-Project Checklist" }}></h2>
              </div>
              <div className="table-container">
                <div className="pre-project-checklist">
                  <Table
                    rows={preProjectDownloadingFileRows}
                    headings={[
                      { title: "Facility ID", value: "facilityId" },
                      { title: "Checklist", value: "documentDownloadAPI" },
                      { title: "Project type", value: "projectType" },
                    ]}
                  />
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            modalSize={"large"}
            className={"esb-modal"}
            IsOpen={openPostProjectChecklistModal}
            loading={modalLoading}
            handleCloseModal={() => this.setState({ openPostProjectChecklistModal: false })}
          >
            <div className="appinfoblock">
              <div className="section-header">
                <div className="section-top-details">{applicationObj.application_id}</div>
                <div className="section-top-details name-section">{`${applicationObj.applicant_first_name} ${applicationObj.applicant_last_name}`}</div>
                <div className="section-top-details">{applicationObj.applicant_account_id}</div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Post-Project Checklist" }}></h2>
              </div>
              <div className="table-container">
                <div className="pre-project-checklist">
                  <Table
                    rows={postProjectDownloadingFileRows}
                    headings={[
                      { title: "Facility ID", value: "facilityId" },
                      { title: "Checklist", value: "documentDownloadAPI" },
                      { title: "Project type", value: "projectType" },
                    ]}
                  />
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            IsOpen={facilityDetailModalOpen}
            className={"esb-modal measure-details-modal"}
            handleCloseModal={() => this.showFacilityDetailModal(false)}
            modalSize={"large"}
            loading={currentFacilityDetailsLoading}
          >
            <h3>Facility Details</h3>
            <div className="measure-details-container">
              {currentFacilityDetails ? (
                <Table
                  rows={currentFacilityDetails}
                  headings={[
                    { title: "Field Name", value: "label" },
                    { title: "Field Value", value: "value" },
                  ]}
                />
              ) : (
                ""
              )}
            </div>
          </Modal>
          <Modal
            IsOpen={measureDetailModalOpen}
            className={"esb-modal measure-details-modal"}
            handleCloseModal={() => this.showMeasureDetailModal(false)}
            modalSize={"large"}
            loading={currentMeasureDetailsLoading}
          >
            <h3>Measure Specific Fields</h3>
            <div className="measure-details-container">
              {currentMeasureDetails ? (
                <Table
                  rows={currentMeasureDetails}
                  headings={[
                    { title: "Field Name", value: "label" },
                    { title: "Field Value", value: "value" },
                  ]}
                />
              ) : (
                ""
              )}
            </div>
          </Modal>
          <Modal
            IsOpen={openTextModal}
            className={"esb-modal measure-details-modal"}
            handleCloseModal={() => this.setState({ openTextModal: false, summaryText: "" })}
            modalSize={"small"}
          >
            <div className="text-container">
              <p>{summaryText}</p>
            </div>
          </Modal>
          {dataLoaded ? (
            <>
              <div className="section-header-ret">
                <h2 className="section-title">{`Application ID: ${appIdToLoad}`}</h2>
              </div>
              <div className="app-update">
                <p className="sync-time">{`Last Sync: ${
                  applicationObj && applicationObj.applicationDetails.applicationDetailsSyncTime
                    ? utils.formatLogTime(applicationObj.applicationDetails.applicationDetailsSyncTime.fetch_time)
                    : ""
                }`}</p>
                <div className="s-icon" title="Sync Now" onClick={() => this.updateSyncTime(appIdToLoad)}>
                  <img className={!updatedTime ? "icon-rotate" : "icon"} src={SyncIcon} alt="Sync Icon" />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {dataLoaded ? <div className="container-box">{this.applicationSummary()}</div> : <Loader />}
        </div>
      </ProgramLayout>
    );
  }
}

function mapState(state) {
  const { applicationId, reviewProgress } = state.application;
  const { currentProgram, adminUserInfo } = state.authentication;
  return {
    applicationId,
    currentProgram,
    adminUserInfo,
    reviewProgress,
  };
}
const actionCreators = {
  loadUserDetails: userActions.getUserDetails,
  loadFacilities: appActions.getFacilitiesForCustomerRetrofit,
  setActiveProgram: userActions.setActiveProgram,
  getRetrofitApplicationsReviewProgress: appActions.getRetrofitApplicationsReviewProgress,
};
export default connect(mapState, actionCreators)(AppSummaryRet);
