import React from 'react';
import './ChangeReqLogs.css';
import Table from '../Table/Table';
import EmptyContainer from '../EmptyContainer/EmptyContainer';
class ApplicationChangeReqLogs extends React.Component {

  render() {
      const {activeChangeRequests } = this.props;
      let logs = activeChangeRequests.map((item)=>{
        return {
          changeReqNo : `CR-${item.changeReqNo}`,
          createdAt : item.createdAt,
          reqType : item.requestType.label,
          status : item.status,
          description : item.description,
          note : item.changeNote
        };
      })
      return (
        <div className="change-req-logs-container">
          { activeChangeRequests.length ?
            // <div className="form-sections">
              <Table rows={logs} headings={[
                {title:'Request No.', value:'changeReqNo'}, 
                {title :'Request Date', value : 'createdAt'},
                {title :'Request Type', value : 'reqType'},
                {title :'Request Description', value : 'description'},
                {title :'Request Status', value : 'status'},
                {title :'Note', value : 'note'}
                ]}/> 
            // </div>
          : <EmptyContainer>No Logs to Show</EmptyContainer>}
        </div>
      );
    }
}

export default ApplicationChangeReqLogs;
