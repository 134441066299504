import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "../../Common/Tabs/Tab";
import "../../Common/Tabs/Tabs.css";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "",
    };
  }
  setCurrentTab = () => {
    const { currentReviewTab } = this.props;
    let tab = currentReviewTab ? currentReviewTab : this.props.children[0].props.label;
    this.setState({
      activeTab: tab,
    });
  };
  componentDidMount = () => {
    this.setCurrentTab();
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setCurrentTab();
    }
  };
  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
    this.props.onTabChange && this.props.onTabChange(tab);
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;
    return (
      <div className="tabs">
        <ol className="tab-list">
          {children.map((child) => {
            const { label } = child.props;

            return <Tab activeTab={activeTab} key={label} label={label} onClick={onClickTabItem} />;
          })}
        </ol>
        <div className={`flag-${this.props.flagStatus}`}>{this.props.flagStatus}</div>
        <div className={`tab-content ${this.props.flagStatus}`}>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
