import React from "react";
import Input from "../../Common/Input/Input";
import CustomTooltip from "./CustomTooltip";
import Button from "../../Common/Button/Button";
import ErrorIcon from '../../../images/ErrorIcon.svg';

const DynamicReviewQuestions = (props) => {
  const { element, formData, errors, uploadedFileList, preProjectReview, handleDocumentUpload, stage } = props;
  let highlightQuestion = "";
  if(element.customPostProjectChecklist || element.customPreProjectChecklist || element.prescriptiveAndCustomPostProjectChecklist || element.prescriptiveAndCustomPreProjectChecklist || element.prescriptivePostProjectChecklist || element.prescriptivePreProjectChecklist) {
    highlightQuestion = true;
  }
  let hasFilesUploaded = false;
  if(element.reviewSubSection === "Measures") {
    if(uploadedFileList && uploadedFileList.length > 0) {
      if(uploadedFileList.find((uploadedFile)=> uploadedFile.fieldName === element.fieldName)) {
        hasFilesUploaded = true
      } else {
        hasFilesUploaded = false
      }
    } else {
      hasFilesUploaded = false
    }
  } else {
    if(uploadedFileList && uploadedFileList.length > 0) {
      hasFilesUploaded = true
    } else {
      hasFilesUploaded = false
    }
  }
  return (
    <React.Fragment>
      {element.inputType === "file" ? (
        <div className="input-parent">
          <div className="input-container">
            <label className="input-label">{element.question}</label>
            <div className="upload-section">
              <Button
                className="upload-files-btn"
                title={hasFilesUploaded ? "View Uploaded Files" : "Upload Files"}
                disabled={false}
                onClick={() => {
                  handleDocumentUpload(element.reviewSubSection, element.fieldName);
                  props.onChange(element.fieldName, "uploadError")
                }}
              />
              {errors[element.fieldName] ? <div className="error-message">
                <div className="error-icon">
                  <img alt="error icon" src={ErrorIcon} />
                </div>
                This field is required.
              </div> : null}
            </div>
          </div>
        </div>
      ) : (
        <Input
          label={`${highlightQuestion && element.question ? "*" : ""}${
            element.question ? element.question : " "
          }`}
          labelclassname={highlightQuestion && element.question ? "highlight" : ""}
          type={element.inputType === "toggle" ? "switch" : element.inputType}
          placeholder={element.placeHolder}
          name={element.fieldName}
          value={
            element.inputType === "textarea" && element.question
              ? formData[element.fieldName] && formData[element.fieldName]["value"]
              : element.inputType === "dropdown" && element.question
              ? formData[element.fieldName] && formData[element.fieldName]["value"]
                ? formData[element.fieldName]
                : null
              : formData[element.fieldName]
              ? formData[element.fieldName]
              : null
          }
          error={errors[element.fieldName]}
          onChange={props.onChange}
          options={element.options}
          checked={formData[element.fieldName] && formData[element.fieldName].value === "Yes" ? true : false}
          maxFiles={element.maxFiles}
          isSearchable={element.isSearchable}
          disabled={stage === "Post-Project" && element.stage === "Pre-Project" ? (preProjectReview ? true : false) : false}
        />
      )}
      <span className="info-icon">{element.helpText ? <CustomTooltip helpText={element.helpText} /> : ""}</span>
    </React.Fragment>
  );
};

export default DynamicReviewQuestions;
