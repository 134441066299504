import React from 'react';
import CheckBox from '../../../../Common/CheckBox/CheckBox';

class CustomerReviewDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { ReviewDetail, Index, EditDetailsForReview } = this.props
        return (
            <div className="contractor-review-details">
                {EditDetailsForReview ? <div className="review-detail">{Index}</div>
                    : ''}
                <div className="review-detail">{ReviewDetail.ReviewParameter}</div>
                <div className="review-detail">{ReviewDetail.SharedDetail}</div>
                <div className="review-detail review-status">
                    {EditDetailsForReview ? <React.Fragment><CheckBox checked={ReviewDetail.ReviewStatus} /> <span>Yes</span> <CheckBox checked={!ReviewDetail.ReviewStatus} /> <span>No</span></React.Fragment>
                        : ReviewDetail.ReviewStatus ? 'Yes' : 'No'}
                </div>
            </div>
        );
    }
}

export default CustomerReviewDetail;
