import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {utils} from '../../../helpers/utils';
import Table from '../Table/Table';
import './FieldCardList.css';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent:'center',
    '& > *': {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      margin: theme.spacing(2),
      padding: theme.spacing(1),
      width: theme.spacing(30)
    },
  },
}));

export default function FieldCardList(props) {
  const classes = useStyles();

  let renderElement = (itemConfig, itemValue) =>{
    let ans = null;
    switch (itemConfig.type.toLowerCase()) {
        case 'integer':
          ans = parseInt(itemValue);
          break;
        case 'number':
          ans = itemValue ? itemConfig.label === 'Quantity' ? itemValue : utils.formatNumberWithCommas(itemValue) : '0.00';
          break;
        case 'text': 
          ans = itemValue ? itemValue : 'No Data';
          break;
        case 'buttonselect':
          ans = itemValue ? utils.formatTitle(itemValue) : 'No Data';
          break;
        case 'mcode':
          ans = itemValue ? itemValue : 'No Data';
          break;
        case 'dollar':
            ans = itemValue ? utils.formatAsCAD(itemValue) : 'No Data';
        break;
        case 'dropdown':
            ans = itemValue ? itemValue.label : 'No Data';
        break;
        case 'file':
            ans = itemValue ? `<a download="${itemValue.name}" rel="noreferrer" target="_blank" href="${itemValue.url}"><button className="enerva-btn">View</button></a>` : 'No Data';
            break;
        case 'multiselect':
            ans = itemValue ? itemValue.map((val)=>` ${val.label}`).join(',') : 'No Data';
            break;
        // case 2:
        //    day = "Tuesday";
        //   break;
        // case 3:
        //   day = "Wednesday";
        //   break;
        // case 4:
        //   day = "Thursday";
        //   break;
        // case 5:
        //   day = "Friday";
        //   break;
        default:
          ans = <p><span>{itemConfig.type} - {itemConfig.label}</span>{JSON.stringify(itemValue)}</p>
      }
      return ans;
  }

  let renderData = ()=>{
      const { config, obj } = props;
      let tableData = config.map((item,index)=>{
        return { label : item.label , value  : renderElement(item, obj[item.name]) }
      });
       return <Table rows={tableData} headings={[
        {title:'Field Name', value:'label'}, 
        {title : 'Field Value', value : 'value'}
        ]}/>;
  }

  return (
    <div>
      {renderData()}
    </div>
  );
}