import React from "react";
import AdminLoader from '../../../images/AdminLoader.svg';
import Loader from '../../../images/Loader.svg';
import './Loader.css'

const LoaderComponent = (props) => {
    return (
        <div className='page-loader'>
            {props.dashboard === 'admin' ? <img src={AdminLoader} alt='loader..' /> : <img src={Loader} alt='loader..' />}
            {props.children}
        </div>
    )
}

export default LoaderComponent;