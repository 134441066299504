import React from "react";
import "./IPNSummary.css";
import { utils } from "../../../helpers/utils";
import { AuthContext } from "../../../firebaseAuthContext";
import { applicationService } from "../../../services/applicationService";
import LoaderComponent from "../../Common/Loader/Loader";
import ESBLogo from "../../../images/ESBVectorLogoWithSlogan.svg";
import EnervaLogo from "../../../images/EnervaVectorLogo.svg";

class IPNSummary extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      loading : true,
      ipnData : {},
      ipnId: props.match.params.id
    };
  }

  componentDidMount() {
    const ipnIdToLoad = this.props.match.params.id;
    applicationService.getIPN(ipnIdToLoad).then(async (data)=>{
        data.apps = Object.keys(data.applications).map((key)=>{
          let app = data.applications[key];
          return app
        })
        // TODO: Testing More Data Code
        // for(let i=0;i<4;i++){
        //   data.apps.push(data.apps[0]);
        // }
        data.approvedDate = utils.formatAmericanDate(data.createdAt);
        this.setState({
          loading: false,
          ipnData : data
        })
    });
  }

  render() {
    const {
      ipnData,
      loading
    } = this.state;
    return loading ? <LoaderComponent/> :<div className="main-pdf-container">
    <div
    className="ipn-pdf-container"
  >
    <div>
      <div className="container-pdf-head">
        <h3>INCENTIVE PAYMENT NOTE</h3>
        <hr/>
      </div>
      <div className="container-logoAndDetails">
        <div className="logo-container">
          <div className="logo">
            <img
              className="ESB-logo"
              src={ESBLogo}
              alt="Energy Savings Business"
            />
          </div>
        </div>
        <div className="application-details">
          <div className="container-application-head">
            <div className="application-details-title">
              <span>IPN # :</span>
            </div>
            <div className="application-details-title">
              <span>Payout Approved Date :</span>
            </div>
            <div className="application-details-title">
              <span>Payout Approver :</span>
            </div>
            <div className="application-details-title">
              <span>No. of Applications :</span>
            </div>
            <div className="application-details-title">
              <span>Total Approved Incentive Payout :</span>
            </div>
          </div>
          <div className="container-application-data">
            <div className="application-data">
              <span>IPN-{ipnData.ipnNo}</span>
            </div>
            <div className="application-data">
              <span>{ipnData.approvedDate}</span>
            </div>
            <div className="application-data">
              <span>{ipnData.approver}</span>
            </div>
            <div className="application-data">
              <span>{ipnData.apps.length}</span>
            </div>
            <div className="application-data">
              <span>{`$${ipnData.incentiveTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="container-IPNDetails">
        <div className="IPNDetails">
          <span>IPN Details :</span>
        </div>
      </div>
      <div className="pdf-table-container">
          <div className="pdf-table-header">
            <div className="head-details-cell">
              <span>Application ID</span>
            </div>
            <div className="head-details-cell">
              <span>Payee Name</span>
            </div>
            <div className="head-details-cell">
              <span>Applicant Name</span>
            </div>
            <div className="head-details-cell">
              <span>Payable Incentive</span>
            </div>
          </div>
          {ipnData.apps
            ? ipnData.apps.map((app, index) => {
                return (
                  <div
                    className="pdf-table-row"
                    key={`row-${index}`}
                  >
                    <div className="details-cell">
                      <span>{app.appNo}</span>
                    </div>
                    <div className="details-cell">
                      <span>{app.payeeName}</span>
                    </div>
                    <div className="details-cell">
                      <span>{app.customerName}</span>
                    </div>
                    <div className="details-cell">
                      <span>{app.incentive ? `$${app.incentive.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : '$0.00'}</span>
                    </div>
                  </div>
                );
              })
            : ""}
          <div className="pdf-table-row pdf-table-total-row">
            <div className="details-cell"></div>
            <div className="details-cell"></div>
            <div className="details-cell">
              <span className="total-title">Total</span>
            </div>
            <div className="details-cell">
              <span>{`$${ipnData.incentiveTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
            </div>
          </div>
        </div>
    </div>
    <div className="container-approversNote">
      <div className="approversNote-title">
        <span>Approver Notes:</span>
      </div>
      <div className="approversNote" dangerouslySetInnerHTML={{__html : ipnData.notes }}>
      </div>
    </div>
    <div className="pdf-footer">
    <hr/>
      <div className="autoGeneratedText">
        This is an automatically generated document.
      </div>
      <div className="container-logo-footer">
        <div className="footer-logo-container">
          <span>Generated by</span>
          <img
            className="footer-logo"
            src={EnervaLogo}
            alt="Enerva Logo"
          />
        </div>
      </div>
    </div>
  </div></div>
  }

}
export default IPNSummary;
