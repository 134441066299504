import React from "react";
import { ReactComponent as DownArrow } from '../../../images/DownArrow.svg';
import { connect } from 'react-redux';
import { utils } from '../../../helpers/utils';
import { userActions } from '../../../redux/actions/userActions';
import { AuthContext } from '../../../firebaseAuthContext';
import { Link } from "react-router-dom";
import Input from '../../Common/Input/Input';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import './ProgramHeader.css'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class AdminHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileContextOpen: false,
            changePasswordModalOpen: false,
            passwordValid: false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            errors:{}
        }
    }

    static contextType = AuthContext;
    componentDidMount() {
        this.props.getUserDetails(this.context.user.uid);
    }

    setProfileContextOpen = () => {
        this.setState({
            profileContextOpen: !this.state.profileContextOpen
        })
    }

    clearChangePassword = () => {
        this.props.clearChangePassword();
        this.setState({
          errors: {},
          oldPassword: '',
          newPassword: '',
          passwordValid:false,
          confirmNewPassword: ''
        });
      }

      openChangePassword = (status) => {
        this.setState({ changePasswordModalOpen: status });
      }
    
      openChangePassword = (status) => {
        this.setState({ changePasswordModalOpen: status });
      }
    
      validateForm = () => {
        const { oldPassword, newPassword, confirmNewPassword, errors } = this.state;
        let errorCount = 0;
    
        if (utils.isNullOrEmpty(oldPassword)) {
          errors.oldPassword = 'Current Password is required.';
          errorCount++;
        }
    
        if (!utils.isNullOrEmpty(newPassword)) {
          if (!utils.validatePassword(newPassword)) {
            errors.newPassword = 'Password does not meet requirements.';
            errorCount++;
          }
          if (newPassword === oldPassword) {
            errors.newPassword = 'Cannot re-use same password as before.';
            errorCount++;
          }
        } else {
          errors.newPassword = 'New Password is required.'
        }
    
        if (!utils.isNullOrEmpty(confirmNewPassword)) {
          if (newPassword !== confirmNewPassword) {
            errors.confirmNewPassword = 'Passwords do not match.';
            errorCount++;
          }
        } else {
          errors.confirmNewPassword = 'Please confirm password.';
          errorCount++;
        }
    
        if (errorCount > 0) {
          this.setState({ errors });
        }
    
        return errorCount <= 0;
      }
    
      submitPasswordChange = () => {
        if (this.validateForm()) {
          //TODO: Change Password
          const { oldPassword, newPassword } = this.state;
          this.props.changePassword(this.context.user.email, oldPassword, newPassword);
        }
      }
    
      onChangeHandler = (name, value) => {
        const { errors } = this.state;
        delete errors[name];
        this.setState({
            [name]: value,
            errors
        });
      }
    
      onPasswordChangeHandler = (name, value) => {
        const { newPassword, confirmNewPassword, errors } = this.state;
        delete errors[name];
        if(utils.isNullOrEmpty(value)){
          let obj = {
                [name]: value
           };
           if(name === 'newPassword'){
                obj.passwordValid = false;
           }
           obj.errors = errors;
          this.setState(obj);
        } else {
            if(name === 'newPassword'){
                let passwordValid = false;
                if(value === confirmNewPassword){
                    delete errors.confirmNewPassword;
                } else {
                    if(!utils.isNullOrEmpty(confirmNewPassword)){
                      errors.confirmNewPassword= 'Password does not match';
                    }
                }
                if(utils.validatePassword(value)){
                    passwordValid = true;
                } else {
                    errors.newPassword = 'Password does not meet requirements.';
                }
                this.setState({
                  [name]: value,
                  errors,
                  passwordValid
              });
            } else {
                if(value === newPassword){
                } else {
                    errors.confirmNewPassword= 'Password does not match';
                }
                this.setState({
                  [name]: value,
                  errors
              });
            }
        }
      }

    render() {
        const { adminUserInfo, showLogoutModal, passwordChanging, passwordChanged,changePasswordError, currentProgram } = this.props;
        const { profileContextOpen, changePasswordModalOpen } = this.state
        const { oldPassword, newPassword, confirmNewPassword, errors, passwordValid } = this.state;
        return (
            <header className="program-header">
                            <Modal
                HeaderText={'Log Out'}
                className="esb-modal"
                IsOpen={this.props.logoutConfirmationModalShown}
                handleCloseModal={() => showLogoutModal(false)}
            >
                <p>Are you sure you want to log out?</p>
                <div className='button-set'>
                    <Button
                        className='inversed-btn'
                        title={'YES, LOG ME OUT'}
                        uppercase
                        onClick={() => { showLogoutModal(false); this.props.logout(); }}
                    />
                    <Button
                        title={'CANCEL'}
                        uppercase
                        onClick={() => showLogoutModal(false)}
                    />
                </div>
            </Modal>
            <Modal
            className="esb-modal"
          HeaderText={passwordChanged ? 'PASSWORD CHANGED' : 'KEEP YOUR ACCOUNT SECURE'}
          IsOpen={changePasswordModalOpen}
          handleCloseModal={() => { this.openChangePassword(false); this.clearChangePassword(); }}
          loading={passwordChanging}
        >
          <p>{passwordChanged ? 'Successfully changed your password' : 'Change the password to access your account securely.'}</p>
          {passwordChanged ? 
          <Button
          title={'CLOSE'}
          uppercase
          onClick={() => { this.openChangePassword(false); this.clearChangePassword(); }}
          />
          :
          <React.Fragment>
            <div className="password-inputs">
              <Input
                label={'Enter Current Password'}
                value={oldPassword}
                name='oldPassword'
                type='password'
                error={changePasswordError || errors['oldPassword']}
                onChange={this.onChangeHandler}
                placeholder={'Set your password'}
              />
              <Input
                label={'Enter New Password'}
                value={newPassword}
                type='password'
                name='newPassword'
                successMsg={passwordValid ? 'This password passes the requirements' : null}
                error={errors['newPassword']}
                onChange={this.onPasswordChangeHandler}
                placeholder={'Type your password again'}
                showExtra
              />
              <Input
                label={'Confirm Password'}
                value={confirmNewPassword}
                type='password'
                name='confirmNewPassword'
                error={errors['confirmNewPassword']}
                onChange={this.onPasswordChangeHandler}
                placeholder={'Type your password again'}
              />
            </div>
          <Button
            title={'RESET PASSWORD'}
            uppercase
            onClick={this.submitPasswordChange}
          /></React.Fragment>}
        </Modal>
                <div className="program-header-container">
                    <div className={`${currentProgram}-logo`}>
                    </div> 
                 { currentProgram ==="speed" ? <div className="speed-dashboard-label">DASHBOARD</div> : <div className="dashboard-label">DASHBOARD</div>
                 } 
                 <ClickAwayListener onClickAway={()=> this.setState({
            profileContextOpen: false
        })} >
                    <div className="program-profile">
                        {adminUserInfo ?
                            <div className="profile-box">
                                <div className="pic-and-name" onClick={this.setProfileContextOpen}>
                                    <div className="profile-image">{utils.getProfilePicFromName(adminUserInfo.firstName, adminUserInfo.lastName)}</div>
                                    <div className="profile-details">
                                        <div className="profile-name">{`${adminUserInfo.firstName} ${adminUserInfo.lastName}`}</div>
                                        <div className="profile-role">{`${adminUserInfo.role.name}`}</div>
                                    </div>
                                    <DownArrow width="14" height="7" />
                                </div>
                                <div className={`expand-profile-menu ${profileContextOpen ? 'profile-menu-open' : ''}`}>
                                    <div className="expanded-menu-panel">
                                        <div onClick={()=>{window.location.href = '/home';}}>Home</div>
                                        <div onClick={()=>this.openChangePassword(true)}>Change Password</div>
                                        <div onClick={this.props.showLogoutModal}>Log Out</div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                 </ClickAwayListener>
                </div>
            </header>
        )
    }
}

function mapState(state) {
    const { currentProgram, adminUserInfo,logoutConfirmationModalShown,passwordChanging, passwordChanged, changePasswordError } = state.authentication;
    return { currentProgram, adminUserInfo,logoutConfirmationModalShown,passwordChanging, passwordChanged, changePasswordError };
}

const actionCreators = {
    getUserDetails: userActions.getUserDetails,
    logout: userActions.logout,
    showLogoutModal:userActions.showLogoutConfirmationModal,
    changePassword: userActions.changePassword,
    clearChangePassword: userActions.clearChangePassword,
}

export default connect(mapState, actionCreators)(AdminHeader);