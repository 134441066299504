import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../redux/reducers';

// const loggerMiddleware = createLogger({
//     predicate: () => process.env.REACT_APP_STAGE !== 'development'
// });

const loggerMiddleware = createLogger({
    predicate: () => false
});

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);