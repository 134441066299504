import React from 'react';
import { connect } from 'react-redux'
import { userActions } from '../../../../redux/actions/userActions';
import './ChangeRequestReview.css';
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import Input from '../../../Common/Input/Input';
import Button from '../../../Common/Button/Button';
import { AuthContext } from '../../../../firebaseAuthContext';
import { utils } from '../../../../helpers/utils';
import { applicationService } from '../../../../services/applicationService';
import LoaderComponent from '../../../Common/Loader/Loader';

class ChangeRequestReview extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            applicationObj : {},
            changeReqObj : {},
            newProjectDeadline : null,
            errors:{},
            reviewLoading: true,
            readyForApprove: false,
            approvedSuccessfully: false,
            confirmingApproval:false,
            changeNote:'',
            reviewStatus : { value : '', label : 'Select status from list'}
        }
    }

    handleChangeNoteChange = (name, value) => {
        this.setState({
          changeNote: value
        })
      }

    async componentDidMount() {
        applicationService.getApplicationChangeRequest(this.props.changeReqId).then((changeReq)=>{
            applicationService.getApplication(changeReq.applicationId).then((appObj)=>{
                this.setState({
                    applicationObj: appObj,
                    changeReqObj : changeReq,
                    newProjectDeadline : appObj['Project Completion Deadline'] ? utils.getDateFromAmericanDateString(appObj['Project Completion Deadline']): '',
                    reviewLoading : false
                })
            });
        });
    }

    verifyForm = ()=>{
        let errors = {};
        let errorCount = 0;
        const { reviewStatus, changeReqObj,newProjectDeadline } = this.state;
        if(utils.isNullOrEmpty(reviewStatus.value)){
            errors.changeReqStatus = 'Please set a change request status';
            errorCount++;
        }
        if(changeReqObj && changeReqObj.requestType.value === 'Extension-Deadline' && reviewStatus.value !== 'Rejected' && utils.isNullOrEmpty(newProjectDeadline)){
            errors.newProjectDeadline = 'Please set a Revised Project Completion Deadline, this is required.';
            errorCount++;
        }
        if(errorCount > 0){
            this.setState({errors},()=>{
              let allErrorRefs = document.querySelectorAll('.input-box-error,.error-box');
              if(allErrorRefs.length > 0){
                let firstErrorRef = allErrorRefs[0];
                firstErrorRef.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
              }
            });
        }
        return errorCount === 0;
      }

    completeReview = ()=>{
        if(this.verifyForm()){
            this.setState({
                confirmingApproval: !this.state.confirmingApproval
              });
        }
      }

    approveChangeRequest  = () => {
        let { adminUserInfo } = this.props
        let { changeReqObj } = this.state;
        let updateObj = {
            status : this.state.reviewStatus.value,
            actionTaker : `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
            changeNote : this.state.changeNote,
        };
        if(changeReqObj.requestType.value === 'Extension-Deadline' && this.state.newProjectDeadline){
            updateObj['newProjectDeadline'] = utils.formatAmericanDate(this.state.newProjectDeadline);
        }
        applicationService.updateChangeRequest(
            this.props.changeReqId,
            updateObj
        ).then((res) => {
            this.setState({
                approvedSuccessfully: true
            }, () => {
                setTimeout(() => {
                    this.props.closeActionModal(true);
                }, 5000);
            })
        })
    }

    onChangeHandler = (name, value) => {
        const { errors } = this.state;
        delete errors[name];
        this.setState({
          [name]: value,
          errors
        });
      }

    renderAppDetails = ()=>{
        const { applicationObj, changeReqObj,errors } = this.state;
        const {program} = applicationObj;
        return !utils.isNullOrEmpty(applicationObj) ? <React.Fragment>
    <div className="change-request-review-inner-container">
            <div className="contractor-review-details">
                <div className="review-detail">Application ID</div>
                <div className="review-detail"><a href={`viewapplication/${changeReqObj.applicationId}`} target="_blank" rel="noreferrer">{program === "speed" ? "SPEED" : applicationObj.stream === "ces" ? "CES" : "ESB"}-{applicationObj.appNo}</a></div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Project Name</div>
                <div className="review-detail">{applicationObj.projectName}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Contractor Name</div>
                <div className="review-detail">{applicationObj.contractor.label}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Project Completion Deadline</div>
                <div className="review-detail">{applicationObj['Project Completion Deadline']}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Pre-Project Reviewer Estimated Incentive</div>
                <div className="review-detail">{utils.formatAsCAD(applicationObj['Reviewer Estimated Incentive'])}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Change Request Type</div>
                <div className="review-detail">{changeReqObj.requestType.label}</div>
            </div>
            <div className="contractor-review-details">
                <div className="review-detail">Change Request Description</div>
                <div className="review-detail">{changeReqObj.description}</div>
            </div>
    </div>
    </React.Fragment> : '';
    }

    onStatusChange = (val)=>{
        const { errors } = this.state;
        delete errors['changeReqStatus'];
        if(val.value === 'Rejected'){
            delete errors['newProjectDeadline'];
        }
        this.setState({
          reviewStatus : val,
          errors
        });
    }

    render() {
        const { confirmingApproval, review, changeNote, reviewLoading, reviewCompleted, userInfo, measureList,approvedSuccessfully, applicationObj, changeReqObj,reviewStatus,errors } = this.state;
        return (
            <div>
                <div className="change-review-container">
                    {reviewLoading ? <LoaderComponent/> : <React.Fragment>
                     
                        <ModalAppDataComponent  applicationObj={{...applicationObj,...{changeReqNo:changeReqObj.changeReqNo}}}></ModalAppDataComponent>
                    <div className="section-below-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'CHANGE REQUEST REVIEW' }}></h2>
                        <div className="review-status-selection">
                            <Input
                                error={errors['changeReqStatus']}
                                disabled={confirmingApproval}
                                type='dropdown'
                                label={'CHANGE STATUS'}
                                name='changeReqStatus'
                                onChange={this.onStatusChange}
                                value={reviewStatus}
                                options={[{ value : '', label : 'Select status from list'},{ value: 'Approved', label: 'Approved' }, { value: 'Rejected', label: 'Rejected' }]}
                                placeholder={'Select status from list'}
                            />
                        </div>
                    </div>
                   <div className='content-container'> 
                    <div className="change-request-review-container">
                        <div className={`contractor-review-table ${!confirmingApproval ? 'review-mode' : ''}`}>
                            <div className="contractor-review-table-left">
                                {this.renderAppDetails()}
                            </div>
                            <div className='vertical-separator-box'>
                                <span className='separator'></span>
                            </div>
                            <div className="contractor-review-table-right">
                                {approvedSuccessfully ? <p>Change request reviewed successfully!</p>
                                :
                                <p>You are about to complete of a Change Request.<br/><br/>
                                Please verify the details and the notes and their accuracy before confirming
                                </p>}
                            </div>
                        </div>
                    </div>
                   <div className="review-notes-container">
                            {(changeReqObj.requestType.value === 'Extension-Deadline' && this.state.reviewStatus.value === 'Approved') ? 
                            <div className="project-deadline-container">
                            <Input
                            label={'Revised Project Completion Deadline'}
                            type={'date'}
                            disabled={confirmingApproval}
                            name={'newProjectDeadline'}
                            minDate={new Date(applicationObj.projectStartDate)}
                            value={this.state.newProjectDeadline}
                            error={errors['newProjectDeadline']}
                            onChange={this.onChangeHandler}
                            />
                            </div> : ''}
                            <label>Notes:</label>
                            {!confirmingApproval ?
                                <Input disabled={confirmingApproval} type={'richtext'} value={changeNote} onChange={this.handleChangeNoteChange} name="changeNote" />
                                :
                                <div className="review-notes-text" dangerouslySetInnerHTML={{__html:changeNote }}/>
                            }
                        </div>
                        </div>
                    {approvedSuccessfully ? '' :
                    <React.Fragment>
                    {!confirmingApproval ?
                        <div className="review-btn-container">
                            <Button
                                title={'Complete Review'}
                                className='signup-btn'
                                onClick={this.completeReview}
                                disabled={!utils.cleanHTMLText(changeNote)}
                                uppercase
                            />
                        </div>
                        :
                        <div className="review-btn-container">
                        <Button
                        className='inversed-btn'
                        title={'Edit Review'}
                        uppercase
                        onClick={this.completeReview}
                        /> 
                        <Button
                                title={'Confirm Review'}
                                className='signup-btn'
                                uppercase
                                onClick={this.approveChangeRequest }
                            />
                        </div>
                    }</React.Fragment>}
                    </React.Fragment>}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { measureList,applicationMeasuresLoading } = state.application;
    const { adminUserInfo } = state.authentication;
    return { measureList, adminUserInfo,applicationMeasuresLoading };
}

const actionCreators = {
    showModal: userActions.showEditParticipantModal
}

export default connect(mapState, actionCreators)(ChangeRequestReview);