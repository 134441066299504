import React from 'react';
import './DrawerWithDynamicForm.css';
import Drawer from "@material-ui/core/Drawer";
import Button from '../Button/Button'
import FormElement from './FormElement'
import { utils } from '../../../helpers/utils';

class DrawerWithDynamicForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            errors: {},
            confirm: false,
        }
        this.updateFormData();
    }


    componentDidUpdate(prevProps, nextProps) {
        if (prevProps.formJSON !== this.props.formJSON) {
            this.updateFormData()
        }
    }

    componentWillReceiveProps() {
        this.updateFormData()
      }

    updateFormData = () => {
        let formData = {};
        this.props.formJSON && this.props.formJSON.fields && this.props.formJSON.fields.map(ele => {
            formData[ele.name] = ''
        })
        this.setState({
            formData: this.props.formJSON && this.props.formJSON.data ? this.props.formJSON.data : formData,
            confirm: false
        })
    }

    onChange = (name, value) => {

        if(value.name=="client" && name.label){
            // console.log("===>",value,name)
            // console.log("name",value.name)
            // console.log("value",name.label)
            const { formData, errors } = this.state;
            delete errors[value.name];
            this.setState({
                formData: {
                    ...formData,
                    [value.name]: {
                        id:name.value,
                        name:name.label
                    },
                    status:"Active"
                },
                errors
            });
        }else{
        const { formData, errors } = this.state;
        delete errors[name];
        this.setState({
            formData: {
                ...formData,
                [name]: value
            },
            errors
        });
    }
}

    submitForm = (trueSubmit = false) => {
        const { formData } = this.state;
        if(trueSubmit){
            this.toggleDrawer();
            this.props.submitForm(this.state.formData);
        } else {
            if (this.verifyForm(formData)) {
                this.setState({
                    confirm: !this.state.confirm
                })
            }
        }
    }

    verifyForm = (formData) => {
        let { fields } = this.props.formJSON;
        let errors = {};
        let errorCount = 0;
        fields.map(ele => {
            if (ele['mandatory']) {
                if (utils.isNullOrEmpty(formData[ele.name])) {
                    errors[ele.name] = `${ele.label} is required.`;
                    errorCount++;
                }
            }
        })
        if (errorCount > 0) {
            this.setState({ errors });
        }
        return errorCount === 0;
    }

    resetState = ()=>{
        this.setState({
            formData:{},
            errors: {},
            confirm: false
        });
    }

    toggleDrawer = () => {
        this.props.toggleDrawer(!this.props.openDrawer);
        if(this.props.openDrawer){
            this.resetState();
        }
    }

    render() {
        const { formData, errors, confirm } = this.state
        const { formJSON } = this.props;
        return (<div className={`drawer-component ${this.props.classname ? this.props.classname : ''}`}>
            <div>
                <React.Fragment key={"right"}>
                    <Drawer
                        anchor={"right"}
                        open={this.props.openDrawer}
                        onClose={() => this.toggleDrawer()}
                        className={this.props.className}
                    >
                        <div className='dynamic-drawer-container'>
                            <div className="drawer-heading">{confirm ? 'CONFIRM' : this.props.formJSON.title}</div>
                            <div className="drawer-form-container">
                                {this.props.formJSON && this.props.formJSON.fields && this.props.formJSON.fields.map((element, i) => (
                                    <div className="drawer-input-container" key={`element-${i}`}>
                                        <FormElement
                                            element={element}
                                            formData={formData}
                                            errors={errors}
                                            onChange={this.onChange}
                                            confirm={confirm}
                                        />
                                    </div>
                                ))}
                            </div>
                            {confirm ?
                                <React.Fragment>
                                    <Button
                                        title={'EDIT DETAILS'}
                                        className='inversed-btn drawer-submit-btn'
                                        uppercase
                                        onClick={()=>{this.submitForm(false);}}
                                    />
                                    <Button
                                        title={'SAVE'}
                                        className='drawer-submit-btn'
                                        uppercase
                                        onClick={()=>{this.submitForm(true);}}
                                    />
                                </React.Fragment>
                                :
                                <Button
                                    title={formJSON.actionButton && formJSON.actionButton.title}
                                    className='drawer-submit-btn'
                                    uppercase
                                    onClick={()=>{this.submitForm(false);}}
                                />
                            }
                        </div>
                    </Drawer>
                </React.Fragment>
            </div>
        </div>
        )
    }
}

export default DrawerWithDynamicForm;