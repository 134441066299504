import { appActionTypes } from './appActionTypes';
import { utils } from '../../helpers/utils';
import { applicationService } from '../../services/applicationService';
import { labelService } from '../../services/labelService';

export const appActions = {
    getActiveContractors,
    createApplication,
    updateApplication,
    getFacilitiesConfig,
    getappFlagsConfig,
    getappStatusesConfig,
    getIPNStatusesConfig,
    createFacility,
    getFacilitiesForCustomer,
    getApplicationsForParticipant,
    getApplicationsByStage,
    getApplicationsByStageRetrofit,
    getApplicationsToReview,
    getMeasureCategories,
    getMeasureTypes,
    getMeasures,
    getMeasureConfig,
    setMeasureConfig,
    createAppMeasure,
    updateAppMeasure,
    getAppMeasures,
    deleteAppMeasure,
    setMeasureCategory,
    setMeasureType,
    setMeasure,
    clearMeasureDrawer,
    createAppDocument,
    deleteAppDocument,
    updateAppDocument,
    getAppDocuments,
    getApplicationsStatusLogs,
    getApplicationChangeRequestLogs,
    getApplicationChangeRequests,
    getApplicationsPortLogs,
    searchChangeRequests,
    getApplicationsNotes,
    getFlagLogs,
    getMeasureLogs,
    getMeasureDetailLogs,
    getApplicationsByStatus,
    getApplicationsForDIAR,
    getApplicationsTotalIncentive,
    getIPNSByStatus,
    getLabels,
    createAppReviewDocument,
    updateAppReviewDocument,
    deleteAppReviewDocument,
    getAppReviewDocuments,   
    getAppReviewDocumentsLogs,
    getApplicationsStateLogs,
    getFacilitiesForCustomerRetrofit,
    getRetrofitAppReviewDocuments,
    createRetrofitAppReviewDocument,
    updateRetrofitAppReviewDocument,
    deleteRetrofitAppReviewDocument,
    getRetrofitAppReviewDocumentsLogs,
    getRetrofitApplicationsNotes,
    getRetrofitApplicationsForParticipant,
    getApplicationsToReviewRetrofit,
    getRetrofitFlagLogs,
    getRetrofitApplicationsReviewProgress,
    createRetrofitReviewProcessDocument,
    updateRetrofitReviewProcessDocument,
    deleteRetrofitReviewProcessDocument,
    getRetrofitReviewProcessDocuments,
    updateRetrofitReviewProcessDocument,
    getRetrofitReviewProcessDocumentsLogs
};

function mapErrors(firebaseError) {
    let errors = {};
    let { code, message } = firebaseError;
    if (code === 'auth/wrong-password') {
        errors['password'] = 'You have entered an invalid username or password';
    } else if (code === 'auth/user-not-found') {
        errors['password'] = 'You have entered an invalid username or password';
    } else {
        errors['password'] = message;
    }
    return errors;
}

function getLabels() {
    return dispatch => {
        dispatch(request());
        labelService.getLabels()
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_LABELS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_LABELS_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_LABELS_FAILURE, error: extractErrMessage(error) } }
}

function setMeasureCategory(measureCategory) {
    return { type: appActionTypes.SET_MEASURE_CATEGORY, measureCategory };
}

function setMeasureType(measureType) {
    return { type: appActionTypes.SET_MEASURE_TYPE, measureType };
}
function setMeasure(measureCode) {
    return { type: appActionTypes.SET_MEASURE, measureCode };
}

function extractErrMessage(firebaseError) {
    return firebaseError.message;
}

function setMeasureConfig(config) {
    return { type: appActionTypes.SET_MEASURE_CONFIG, config };
}

function getMeasureCategories() {
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureCategories()
            .then((data) => {
                dispatch(success(data.categories));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURE_CATEGORIES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURE_CATEGORIES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURE_CATEGORIES_FAILURE, error: extractErrMessage(error) } }
}

function getMeasureTypes(measureCategory) {
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureTypes(measureCategory)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURE_TYPES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURE_TYPES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURE_TYPES_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsTotalIncentive() {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsTotalIncentive()
            .then((applicationsTotalIncentive) => {
                dispatch(success(applicationsTotalIncentive));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_REQUEST } }
    function success(applicationsTotalIncentive) { return { type: appActionTypes.GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_SUCCESS,  applicationsTotalIncentive } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_FAILURE, error: extractErrMessage(error) } }
}

function getMeasures(measureType) {
    return dispatch => {
        dispatch(request());
        applicationService.getMeasures(measureType)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURES_FAILURE, error: extractErrMessage(error) } }
}

function getMeasureConfig(stage, measureCode) {
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureConfig(stage, measureCode)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_MEASURE_CONFIG_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_MEASURE_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_MEASURE_CONFIG_FAILURE, error: extractErrMessage(error) } }
}

function getActiveContractors() {
    return dispatch => {
        dispatch(request());
        applicationService.getActiveContractors()
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_ACTIVE_CONTRACTORS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_ACTIVE_CONTRACTORS_SUCCESS,data } }
    function failure(error) { return { type: appActionTypes.GET_ACTIVE_CONTRACTORS_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsForParticipant(participantType, participantId, stage) {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsForParticipant(participantType, participantId, stage)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_SUCCESS, apps } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_FAILURE, error: extractErrMessage(error) } }
}

function getRetrofitApplicationsForParticipant(participantId) {
    return dispatch => {
        dispatch(request());
        applicationService.getParticipantApplicationsRetrofit(participantId)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_SUCCESS, apps } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsByStage(stage,searchTerm,config,offset) {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsByStage(stage, searchTerm,config, offset)
            .then((res) => {
                let data = res.data;
                let appList = [];
                for (let key in data) {
                    const application = data[key];
                    let item = {
                        id: application.id,
                        appNo: application.appNo,
                        flag:application.flag,
                        ipnNo:application.ipnNo ? `IPN-${application.ipnNo}` : "N/A",
                        customerCompanyName: application.companyName,
                        contractorCompanyName: application.contractor && application.contractor.label ? application.contractor.label : '',
                        payeeName: application.payeeInfo && application.payeeInfo.payeeName ? application.payeeInfo.payeeName : '',
                        stage: application.stage,
                        status: application.status,
                        customerId: application.customerId,
                        customerCategory: application.customerInfo ? application.customerInfo.accountCategory : 'customer',
                        reviewer:application.reviewer,
                        reviewerName:application.reviewer ? application.reviewer.label : '',
                        ProjectCost:application['Actual Project Cost'],
                        postProjectReviewer:application.postProjectReviewer?application.postProjectReviewer:'',
                        paymentReviewer:application.paymentReviewer?application.paymentReviewer:'',
                        createdDate: `${utils.formatDate(application.createdAt)}`,
                        applicationDraftApprovedDate: application['Pre-Approval - Application Draft Approved Time'] ? `${utils.formatDate(application['Pre-Approval - Application Draft Approved Time'])}` : 'N/A',
                        incentiveApprovedDate : application['Payment - Incentive Approved Time'] ? `${utils.formatDate(application['Payment - Incentive Approved Time'])}` : 'N/A',
                        recommendedDate : application['Payment - Incentive Recommended Time'] ? `${utils.formatDate(application['Payment - Incentive Recommended Time'])}` : 'N/A',
                        'Post-Project - Application Submitted Time' : application['Post-Project - Application Submitted Time'] ? `${utils.formatDate(application['Post-Project - Application Submitted Time'])}` : 'N/A',
                        applicationSubmittedDate: application['Pre-Approval - Application Submitted Time'] ? `${utils.formatDate(application['Pre-Approval - Application Submitted Time'])}` : 'N/A',
                        submissionDate: application['Pre-Project - Application Draft Submitted Time'] ? `${utils.formatDate(application['Pre-Project - Application Draft Submitted Time'])}` : 'N/A',
                        IRResponseReceived : application.IRResponseReceived ? 'Yes' : 'No',
                        estimatedIncentive: application['Estimated Incentive'] ? parseFloat(application['Estimated Incentive']) : 0,
                        reviewerEstimatedIncentive: application['Reviewer Estimated Incentive'] ? parseFloat(application['Reviewer Estimated Incentive']) : 0,
                        recommendedIncentive : application['PostProjectReviewerApprovedIncentive'] ? parseFloat(application['PostProjectReviewerApprovedIncentive']) : 0,
                        isActive : application.hasOwnProperty('isActive') ? application.isActive : true,
                        isLinkedToCES : application.isLinkedToCES,
                        stream : application.stream,
                    };
                    appList.push(item);
                }
                dispatch(success(appList));
            }).catch((error) => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_BY_STAGE_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_BY_STAGE_SUCCESS, stage, apps, offset } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_BY_STAGE_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsByStageRetrofit(stage,searchTerm,config,offset) {
    return (dispatch) => {
      dispatch(request());
      applicationService
        .getApplicationsByStageRetrofit(stage, searchTerm, config, offset)
        .then((res) => {
          let data = res.data;
          let appList = [];
          for (let key in data) {
            const application = data[key];
            const preProjectFields = [
              "reviewer",
              "secondaryReviewer",
            ];

            function isPreProjectApplication(applicationObj) {
              for (var key in applicationObj) {
                if (preProjectFields.includes(key)) {
                  return true;
                }
              }
              return false;
            }
            let item = {
              id: application.application_id,
              appNo: application.application_id,
              applicationProjectType: application.application_project_type,
              applicationName: application.application_name,
              applicantEmail: application.applicant_account_id,
              customerId: application.applicant_person_id,
              applicantName: `${application.applicant_first_name} ${application.applicant_last_name}`,
              createdDate: application.creation_dt ? `${utils.formatDate(application.creation_dt)}` : "N/A",
              submissionDate: application.pre_project_submission_date
                ? `${utils.formatDate(application.pre_project_submission_date)}`
                : "N/A",
              postProjectSubmissionDate: application.post_project_submission_date
                ? `${utils.formatDate(application.post_project_submission_date)}`
                : "N/A",
              preProjectApprovalDate: application.pre_project_approval_date
                ? `${utils.formatDate(application.pre_project_approval_date)}`
                : "N/A",
              actualIncentive: application.total_actual_app_incentive ? application.total_actual_app_incentive : "N/A",
              status: application.application_status_name,
              estimatedIncentive: application.total_est_app_incentive,
              isActive: application.hasOwnProperty("isActive") ? application.isActive : true,
              multiSite: application.multi_site === "Y" ? "Yes" : "No",
              stage: application.stage,
              flag: application.flag,
              reviewer: application.reviewer,
              secondaryReviewer: application.secondaryReviewer,
              postProjectReviewer: application.postProjectReviewer,
              postProjectSecondaryReviewer: application.postProjectSecondaryReviewer,
              finalInvoiceReviewer: application.finalInvoiceReviewer,
              preProjectPrimaryReviewNote: application.preProjectPrimaryReviewNote,
              postProjectPrimaryReviewNote: application.postProjectPrimaryReviewNote,
              isPrimaryReviewer: application.isPrimaryReviewer,
              invoice: application.invoice ? application.invoice : "N/A",
              reviewState: application.reviewState ? application.reviewState : "Not Started",
              secondaryReviewState: application.secondaryReviewState ? application.secondaryReviewState : "Not Started",
              invoiceSubmittedDate:
                application.invoice && application.invoice.submission_dt ? `${utils.formatDate(application.invoice.submission_dt)}` : "N/A",
              preProjectQA: isPreProjectApplication(application) ? application.previousFlagStatus
                ? application.previousFlagStatus === "QA-QC - Mandatory"
                  ? "Mandatory"
                  : application.previousFlagStatus === "QA-QC - Random Selection"
                  ? "Random "
                  : "No"
                : "No" : "N/A",
            };
            appList.push(item);
          }
          dispatch(success(appList));
        })
        .catch((error) => {
          dispatch(failure(error));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_BY_STAGE_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_BY_STAGE_SUCCESS, stage, apps, offset } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_BY_STAGE_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsByStatus(searchTerm,config,offset) {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsByStatus(searchTerm,config, offset)
            .then((res) => {
                let data = res.data;
                let appList = [];
                for (let key in data) {
                    const application = data[key];
                    //console.log("rpow  level==",application);
                    let item = {
                        id: application.id,
                        appNo: application.appNo,
                        payeeName:application.payeeInfo?application.payeeInfo.payeeName:'',
                        customerCompanyName: application.companyName,
                        contractorCompanyName: application.contractor && application.contractor.label ? application.contractor.label : '',
                        PostProjectReviewerApprovedIncentive:application.PostProjectReviewerApprovedIncentive?application.PostProjectReviewerApprovedIncentive:0.00,
                        ProjectCost:application['Actual Project Cost'],
                        incentiveRecommendedDate: `${utils.formatDate(application['Payment - Incentive Recommendation Approval Pending Time'])}`,
                        IncentivePercentage:(application['Actual Project Cost'] !=='0.00')  ?((application.PostProjectReviewerApprovedIncentive/application['Actual Project Cost'])*Number(100)).toFixed(2):0.00,
                        payeeNameIsPersonalName:application.payeeNameIsPersonalName,
                        labels:application.labels 
                    };
                    appList.push(item);
                }
                dispatch(success(appList));
            }).catch((error) => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_BY_STATUS_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_BY_STATUS_SUCCESS, apps, offset } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_BY_STATUS_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsForDIAR(searchTerm,config) {
    config.statuses = ['Incentive Recommendation Approval Pending'];
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsByStatus(searchTerm, config, 0, 300)
            .then((res) => {
                let data = res.data;
                let appList = [];
                for (let key in data) {
                    const application = data[key];
                    let item = {
                        id: application.id,
                        appNo: application.appNo,
                        payeeName:application.payeeInfo?application.payeeInfo.payeeName:'',
                        customerCompanyName: application.companyName,
                        contractorCompanyName: application.contractor && application.contractor.label ? application.contractor.label : '',
                        PostProjectReviewerApprovedIncentive:application.PostProjectReviewerApprovedIncentive?application.PostProjectReviewerApprovedIncentive:0.00,
                        ProjectCost:application['Actual Project Cost'],
                        // incentiveRecommendedDate: `${utils.formatDate(application['Payment - Incentive Recommendation Approval Pending Time'])}`,
                        IncentivePercentage:(application['Actual Project Cost'] !=='0.00')  ?((application.PostProjectReviewerApprovedIncentive/application['Actual Project Cost'])*Number(100)).toFixed(2):0.00,
                        payeeNameIsPersonalName:application.payeeNameIsPersonalName,
                        estimatedEmissionsReduction:application['Estimated Emissions Reduction'],
                        expectedProjectStartDate:`${utils.formatDate(application.projectStartDate)}`,
                        expectedProjectEndDate:`${utils.formatDate(application.projectCompletionDate)}`,
                        isReceivingOtherFunding:application.isReceivingOtherFunding,
                        preProjectApplicationDraftSubmittedDate: application['Pre-Project - Application Draft Submitted Time'] ? `${utils.formatDate(application['Pre-Project - Application Draft Submitted Time'])}` : '',
                        projectCompletionDeadline:application['Project Completion Deadline'],
                        preProjectApplicationDraftApprovedDate:application['Pre-Approval - Application Draft Approved Time'] ? `${utils.formatDate(application['Pre-Approval - Application Draft Approved Time'])}` : '',
                        postProjectApplicationSubmittedDate:application['Post-Project - Application Submitted Time'] ?  `${utils.formatDate(application['Post-Project - Application Submitted Time'])}` : '',
                        incentiveRecommendedDate : application['Payment - Incentive Recommended Time'] ? `${utils.formatDate(application['Payment - Incentive Recommended Time'])}` : '',
                        recommendedIncentive : application['PostProjectReviewerApprovedIncentive'] ? `${utils.formatAsCAD(application['PostProjectReviewerApprovedIncentive'])}` : 0,
                        payeeAccountCategory:application.payeeInfo?application.payeeInfo.payeeAccountCategory:'',
                        linkedMeasures : application.linkedMeasures || [],
                        actualProjectEndDate:application.actualProjectEndDate ? `${utils.formatDate(application.actualProjectEndDate)}` : '',
                        paymentMode:application.paymentMode,
                        qaQcType: application.qaQcType ? application.qaQcType.value : 'N/A',
                        customerCompanyIncentiveCapHit: application.customerCompanyIncentiveCapHit,
                        differenceAmountPost: application.differenceAmountPost ? application.differenceAmountPost : 'N/A',
                        explanationForDifference: application.explanationForDifference ? utils.cleanHTMLText(application.explanationForDifference) : 'N/A',
                        differentNameOnBankAccount: application.differentNameOnBankAccount ? 'Yes' : 'No',
                        labels:application.labels,
                        followedUpViaPhoneCall: application.followedUpViaPhoneCall === true ? "Yes" : application.followedUpViaPhoneCall === false ? "No" : "",
                        followedUpViaEmail: application.followedUpViaEmail === true ? "Yes" : application.followedUpViaEmail === false ? "No" : "",
                        approvedBankDetailsNote: application.approvedBankDetailsNote || ""
                    };
                    appList.push(item);
                }
                dispatch(success(appList));
            }).catch((error) => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_BY_STATUS_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_BY_STATUS_SUCCESS, apps, offset : 0 } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_BY_STATUS_FAILURE, error: extractErrMessage(error) } }
}

function getIPNSByStatus( searchTerm = "", config, offset = 0) {
    return dispatch => {
        dispatch(request());
        applicationService.getIPNSByStatus(searchTerm,config, offset)
            .then((res) => {
                let data = res.data;
                let IPNList = data;
                // for (let key in data) {
                //     const application = data[key];
                //    //console.log("rpow  level==",application);
                //     let item = {
                //         id: application.id,
                //         appNo: application.appNo,
                //         payeeName:application.payeeInfo?application.payeeInfo.payeeName:'',
                //         customerCompanyName: application.companyName,
                //         contractorCompanyName: application.contractor && application.contractor.label ? application.contractor.label : '',
                //         PostProjectReviewerApprovedIncentive:application.PostProjectReviewerApprovedIncentive?application.PostProjectReviewerApprovedIncentive:0.00,
                //         ProjectCost:application['Actual Project Cost'],
                //         incentiveRecommendedDate: `${utils.formatDate(application['Payment - Incentive Recommendation Approval Pending Time'])}`,
                //         IncentivePercentage:(application['Actual Project Cost'] !=='0.00')  ?((application.PostProjectReviewerApprovedIncentive/application['Actual Project Cost'])*Number(100)).toFixed(2):0.00,
                //         payeeNameIsPersonalName:application.payeeNameIsPersonalName 
                //     };
                //     appList.push(item);
                // }
                dispatch(success(IPNList));
            }).catch((error) => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: appActionTypes.GET_IPNS_BY_STATUS_REQUEST } }
    function success(IPNS) { return { type: appActionTypes.GET_IPNS_BY_STATUS_SUCCESS, IPNS, offset } }
    function failure(error) { return { type: appActionTypes.GET_IPNS_BY_STATUS_FAILURE, error: extractErrMessage(error) } }
}
function getApplicationsToReview(stage, searchTerm, config, offset) {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsToReview(stage, searchTerm, config, offset)
            .then((res) => {
                let data = res.data;
                let appList = [];
                for (let key in data) {
                    const application = data[key];
                    let item = {
                        id: application.id,
                        appNo: application.appNo,
                        flag:application.flag,
                        customerCompanyName: application.companyName,
                        customerId: application.customerId,
                        customerCategory: application.customerInfo ? application.customerInfo.accountCategory : '',
                        contractorCompanyName: application.contractor && application.contractor.label ? application.contractor.label : '',
                        stage: application.stage,
                        status: application.status,
                        createdDate: `${utils.formatDate(application.createdAt)}`,
                        'Post-Project - Application Submitted Time' : application['Post-Project - Application Submitted Time'] ? `${utils.formatDate(application['Post-Project - Application Submitted Time'])}` : '',
                        'Pre-Project - Application Draft Under Review Time' : application['Pre-Project - Application Draft Under Review Time'] ? `${utils.formatDate(application['Pre-Project - Application Draft Under Review Time'])}` : '',
                        'Post-Project - Application Under Review Time' : application['Post-Project - Application Under Review Time'] ? `${utils.formatDate(application['Post-Project - Application Under Review Time'])}` : '',
                        'Post-Project - QA/QC In Progress Time' : application['Post-Project - QA/QC In Progress Time'] ? `${utils.formatDate(application['Post-Project - QA/QC In Progress Time'])}` : 'N/A',
                        daysInReview : utils.getDaysFromToday(application['Pre-Project - Application Draft Under Review Time']),
                        submissionDate: application['Pre-Project - Application Draft Submitted Time'] ? `${utils.formatDate(application['Pre-Project - Application Draft Submitted Time'])}` : '',
                        estimatedIncentive: application['Estimated Incentive'] ? parseFloat(application['Estimated Incentive']) : 0,
                        IRResponseReceived : application.IRResponseReceived ? 'Yes' : 'No',
                        PostProjectReviewerApprovedIncentive: application['PostProjectReviewerApprovedIncentive'] ? parseFloat(application['PostProjectReviewerApprovedIncentive']) : 0,
                        isActive: application.isActive,
                        payeeName:application.payeeInfo?application.payeeInfo.payeeName:'',
                    };
                    appList.push(item);
                }
                dispatch(success(appList));
            }).catch((error) => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_TO_REVIEW_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_TO_REVIEW_SUCCESS, stage, apps, offset  } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_TO_REVIEW_FAILURE, error: extractErrMessage(error) } }
}

function getApplicationsToReviewRetrofit(stage, searchTerm,config, offset) {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsToReviewRetrofit(stage, searchTerm,config, offset)
            .then((res) => {
                let data = res.data;
                let appList = [];
                for (let key in data) {
                    const application = data[key];
                    let item = {
                        id: application.application_id,
                        appNo: application.application_id,
                        applicationProjectType: application.application_project_type,
                        applicantName : `${application.applicant_first_name} ${application.applicant_last_name}`,
                        applicantEmail : application.applicant_account_id,
                        applicantAddress : `${application.applicant_address1}, ${application.applicant_address2 ? application.applicant_address2+"," : ""} ${application.applicant_address3 ? application.applicant_address3+"," : ""} ${application.applicant_city}, ${application.applicant_province}, ${application.applicant_postal_code}`,
                        applicantAddress1 : `${application.address1}, ${application.address2 ? application.address2+"," : ""} ${application.address3 ? application.address3+"," : ""} ${application.city}, ${application.province}, ${application.postal_code}`,
                        customerId : application.applicant_person_id,
                        applicationName : application.application_name,
                        createdDate: application.creation_dt ? `${utils.formatDate(application.creation_dt)}` : 'N/A',
                        submissionDate: application.pre_project_submission_date ? `${utils.formatDate(application.pre_project_submission_date)}` : 'N/A',
                        postProjectSubmissionDate: application.post_project_submission_date ? `${utils.formatDate(application.post_project_submission_date)}` : 'N/A',
                        preProjectApprovalDate : application.pre_project_approval_date ? `${utils.formatDate(application.pre_project_approval_date)}` : 'N/A',
                        actualIncentive :  application.total_actual_app_incentive ? application.total_actual_app_incentive : 'N/A',
                        status: application.application_status_name,
                        estimatedIncentive: application.total_est_app_incentive,
                        isActive: application.hasOwnProperty('isActive') ? application.isActive : true,
                        multiSite: application.multi_site === 'Y' ? 'Yes' : 'No',
                        stage: application.stage,
                        flag:application.flag,
                        reviewer: application.reviewer,
                        secondaryReviewer: application.secondaryReviewer,
                        postProjectReviewer: application.postProjectReviewer,
                        postProjectSecondaryReviewer: application.postProjectSecondaryReviewer,
                        finalInvoiceReviewer: application.finalInvoiceReviewer,
                        preProjectPrimaryReviewNote: application.preProjectPrimaryReviewNote,
                        postProjectPrimaryReviewNote: application.postProjectPrimaryReviewNote,
                        isPrimaryReviewer: application.isPrimaryReviewer,
                        invoice : application.invoice ? application.invoice : "N/A",
                        invoiceSubmittedDate: application.invoice && application.invoice.submission_dt ? `${utils.formatDate(application.invoice.submission_dt)}` : "N/A",
                        hst: application.hst ? application.hst : "Null",
                        totalActualAppIncentive: application.total_actual_app_incentive,
                        preProjectResubmission: application.pre_project_resubmission === "Y" ? "Yes" : "No",
                        postProjectResubmission: application.post_project_resubmission === "Y" ? "Yes" : "No",
                        companyName: application.company,
                        finalSHAValue: application.total_final_sha_amount ? application.total_final_sha_amount : 0,
                    };
                    appList.push(item);
                }
                dispatch(success(appList));
            }).catch((error) => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_TO_REVIEW_REQUEST } }
    function success(apps) { return { type: appActionTypes.GET_APPLICATIONS_TO_REVIEW_SUCCESS, stage, apps, offset  } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_TO_REVIEW_FAILURE, error: extractErrMessage(error) } }
}

function createFacility(data) {
    const customerId = data.customerId;
    return dispatch => {
        dispatch(request());
        applicationService.createFacility(data)
            .then((result) => {
                applicationService.getFacilitiesForCustomer(customerId)
                    .then((facilities) => {
                        dispatch(success(facilities));
                    }).catch((error) => {
                        dispatch(failure(error));
                    }
                    );
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.CREATE_FACILITY_REQUEST } }
    function success(facilities) { return { type: appActionTypes.CREATE_FACILITY_SUCCESS, facilities } }
    function failure(error) { return { type: appActionTypes.CREATE_FACILITY_FAILURE, error: extractErrMessage(error) } }
}

function getFacilitiesForCustomer(customerId) {
    return dispatch => {
        dispatch(request());
        applicationService.getFacilitiesForCustomer(customerId)
            .then((facilities) => {
                dispatch(success(facilities));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_REQUEST } }
    function success(facilities) { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_SUCCESS, facilities } }
    function failure(error) { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_FAILURE, error: extractErrMessage(error) } }
}

function getFacilitiesForCustomerRetrofit(facilitiesId) {
    return dispatch => {
        dispatch(request());
        applicationService.getFacilitiesForCustomerRetrofit(facilitiesId)
            .then((facilities) => {
                dispatch(success(facilities));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_REQUEST } }
    function success(facilities) { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_SUCCESS, facilities } }
    function failure(error) { return { type: appActionTypes.GET_FACILITIES_FOR_CUSTOMER_FAILURE, error: extractErrMessage(error) } }
}


function createApplication(data) {
    return dispatch => {
        dispatch(request());
        applicationService.createApplication(data)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.CREATE_APPLICATION_REQUEST } }
    function success(data) { return { type: appActionTypes.CREATE_APPLICATION_SUCCESS, applicationId: data.id } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_FAILURE, error: extractErrMessage(error) } }
}

function updateApplication(appId, data) {
    return dispatch => {
        dispatch(request());
        applicationService.updateApplication(appId, data)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_REQUEST } }
    function success(data) { return { type: appActionTypes.UPDATE_APPLICATION_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_FAILURE, error: extractErrMessage(error) } }
}

function getFacilitiesConfig() {
    return dispatch => {
        dispatch(request());
        applicationService.getFacilitiesConfig()
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_FACILITY_CONFIG_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_FACILITY_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_FACILITY_CONFIG_FAILURE, error: extractErrMessage(error) } }
}

function getappFlagsConfig() {
    return dispatch => {
        dispatch(request());
        applicationService.getappFlagsConfig()
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_FLAGS_CONFIG_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_FLAGS_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_FLAGS_CONFIG_FAILURE, error: extractErrMessage(error) } }
}

function getappStatusesConfig() {
    return dispatch => {
        dispatch(request());
        applicationService.getappStatusConfig()
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_STATUSES_CONFIG_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_STATUSES_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_STATUSES_CONFIG_FAILURE, error: extractErrMessage(error) } }
}
function getIPNStatusesConfig() {
    return dispatch => {
        dispatch(request());
        applicationService.getIPNStatusesConfig()
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_IPN_STATUSES_CONFIG_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_IPN_STATUSES_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_IPN_STATUSES_CONFIG_FAILURE, error: extractErrMessage(error) } }
}

function clearMeasureDrawer(drawerState = false) {
    return { type: appActionTypes.CLEAR_MEASURE_DRAWER, drawerState };
}

function createAppMeasure(data) {
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.createAppMeasure(data)
            .then((data) => {
                applicationService.getAppMeasures(applicationId).then((measures) => {
                    dispatch(success(measures));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };
    function request() { return { type: appActionTypes.CREATE_APPLICATION_MEASURE_REQUEST } }
    function success(data) { return { type: appActionTypes.CREATE_APPLICATION_MEASURE_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_MEASURE_FAILURE, error: extractErrMessage(error) } }
}

function updateAppMeasure(measureId, data) {
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.updateAppMeasure(measureId, data)
            .then((data) => {
                applicationService.getAppMeasures(applicationId).then((measures) => {
                    dispatch(success(measures));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_MEASURE_REQUEST } }
    function success(data) { return { type: appActionTypes.UPDATE_APPLICATION_MEASURE_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_MEASURE_FAILURE, error: extractErrMessage(error) } }
}

function deleteAppMeasure(measureId, applicationId) {
    return dispatch => {
        dispatch(request());
        applicationService.deleteAppMeasure(measureId)
            .then((data) => {
                applicationService.getAppMeasures(applicationId).then((measures) => {
                    dispatch(success(measures));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.DELETE_APPLICATION_MEASURE_REQUEST } }
    function success(data) { return { type: appActionTypes.DELETE_APPLICATION_MEASURE_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.DELETE_APPLICATION_MEASURE_FAILURE, error: extractErrMessage(error) } }
}

function getAppMeasures(applicationId) {
    if (utils.isNullOrEmpty(applicationId)) {
        return dispatch => {
            dispatch(request());
            dispatch(success([]));
        };
    } else {
        return dispatch => {
            dispatch(request());
            applicationService.getAppMeasures(applicationId)
                .then((data) => {
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
                );
        };
    }

    function request() { return { type: appActionTypes.GET_APPLICATION_MEASURES_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATION_MEASURES_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_MEASURES_FAILURE, error: extractErrMessage(error) } }
}

function createAppDocument(data) {
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.createAppDocument(data)
            .then((data) => {
                applicationService.getAppDocuments(applicationId).then((documentList) => {
                    dispatch(success(documentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };
    function request() { return { type: appActionTypes.CREATE_APPLICATION_DOCUMENT_REQUEST } }
    function success(documentList) { return { type: appActionTypes.CREATE_APPLICATION_DOCUMENT_SUCCESS, documentList } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function updateAppDocument(documentId, data) {
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.updateAppDocument(documentId, data)
            .then((data) => {
                applicationService.getAppDocuments(applicationId).then((documentList) => {
                    dispatch(success(documentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_DOCUMENT_REQUEST } }
    function success(documentList) { return { type: appActionTypes.UPDATE_APPLICATION_DOCUMENT_SUCCESS, documentList } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function deleteAppDocument(documentId, applicationId) {
    return dispatch => {
        dispatch(request());
        applicationService.deleteAppDocument(documentId)
            .then((data) => {
                applicationService.getAppDocuments(applicationId).then((documentList) => {
                    dispatch(success(documentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.DELETE_APPLICATION_DOCUMENT_REQUEST } }
    function success(documentList) { return { type: appActionTypes.DELETE_APPLICATION_DOCUMENT_SUCCESS, documentList } }
    function failure(error) { return { type: appActionTypes.DELETE_APPLICATION_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

//APPLICATION REVIEW DOCUMENTS
function createAppReviewDocument(data) {
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.createAppReviewDocument(data)
            .then((data) => {
                applicationService.getAppReviewDocuments(applicationId).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };
    function request() { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function updateAppReviewDocument(documentId, data) {
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.updateAppReviewDocument(documentId, data)
            .then((data) => {
                applicationService.getAppReviewDocuments(applicationId).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function deleteAppReviewDocument(documentId, applicationId) {
    return dispatch => {
        dispatch(request());
        applicationService.deleteAppReviewDocument(documentId)
            .then((data) => {
                applicationService.getAppReviewDocuments(applicationId).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function getAppReviewDocuments(applicationId) {
    if (utils.isNullOrEmpty(applicationId)) {
        return dispatch => {
            dispatch(request());
            dispatch(success([]));
        };
    } else {
        return dispatch => {
            dispatch(request());
            applicationService.getAppReviewDocuments(applicationId)
                .then((data) => {
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
                );
        };
    }
    function request() { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_FAILURE, error: extractErrMessage(error) } }
}

function getAppReviewDocumentsLogs(appId) {
    return dispatch => {
        // console.log("In getAppReviewDocumentsLogs", appId)
        dispatch(request());
        applicationService.getAppReviewDocumentsLogs(appId).then((reviewDocumentLogs)=>{
            // console.log("Review Document Logs ===>", reviewDocumentLogs)
            dispatch(success(reviewDocumentLogs)); 
        
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_FAILURE, error } }
}

// Retrofit Review Documents calls
function createRetrofitAppReviewDocument(data) {
    const { application_id } = data;
    return dispatch => {
        dispatch(request());
        applicationService.createRetrofitAppReviewDocument(data)
            .then((data) => {
                applicationService.getRetrofitAppReviewDocuments(application_id).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };
    function request() { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function updateRetrofitAppReviewDocument(documentId, data) {
    const { applicationId } = data;
    return dispatch => {
        dispatch(request());
        applicationService.updateRetrofitAppReviewDocument(documentId, data)
            .then((data) => {
                applicationService.getRetrofitAppReviewDocuments(applicationId).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function deleteRetrofitAppReviewDocument(documentId, applicationId) {
    return dispatch => {
        dispatch(request());
        applicationService.deleteRetrofitAppReviewDocument(documentId)
            .then((data) => {
                applicationService.getRetrofitAppReviewDocuments(applicationId).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function getRetrofitAppReviewDocuments(application_id) {
    if (utils.isNullOrEmpty(application_id)) {
        return dispatch => {
            dispatch(request());
            dispatch(success([]));
        };
    } else {
        return dispatch => {
            dispatch(request());
            applicationService.getRetrofitAppReviewDocuments(application_id)
                .then((data) => {
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
                );
        };
    }
    function request() { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_FAILURE, error: extractErrMessage(error) } }
}

function getRetrofitAppReviewDocumentsLogs(application_id) {
    return dispatch => {
        // console.log("In getAppReviewDocumentsLogs", appId)
        dispatch(request());
        applicationService.getRetrofitAppReviewDocumentsLogs(application_id).then((reviewDocumentLogs)=>{
            // console.log("Review Document Logs ===>", reviewDocumentLogs)
            dispatch(success(reviewDocumentLogs)); 
        
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_FAILURE, error } }
}

//Retrofit Review Documents end

function getApplicationsStateLogs(appId) {
    // console.log("ID", appId)
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationsStateLogs(appId).then((appStateLogs)=>{
            // console.log("appStateLogs", appStateLogs);
            dispatch(success(appStateLogs)); 
        
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_STATE_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_STATE_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_STATE_LOGS_FAILURE, error } }
}

function getApplicationsStatusLogs(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getAppStatusLogs(appId).then((logs)=>{
            dispatch(success(logs));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_STATUS_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_STATUS_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_STATUS_LOGS_FAILURE, error } }
}

function getApplicationsPortLogs(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getAppPortLogs(appId).then((portLogs)=>{
            dispatch(success(portLogs));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_PORT_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_PORT_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_PORT_LOGS_FAILURE, error } }
}

function getApplicationsNotes(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getAppNotes(appId).then((Notes)=>{
            dispatch(success(Notes));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_NOTES_REQUEST } }
    function success(Notes) { return { type: appActionTypes.GET_APPLICATION_NOTES_SUCCESS, Notes } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_NOTES_FAILURE, error } }
}

function getRetrofitApplicationsNotes(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getRetrofitAppNotes(appId).then((Notes)=>{
            dispatch(success(Notes));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_NOTES_REQUEST } }
    function success(Notes) { return { type: appActionTypes.GET_APPLICATION_NOTES_SUCCESS, Notes } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_NOTES_FAILURE, error } }
}

function getFlagLogs(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getFlagLogs(appId).then((flagLogs)=>{
            let crList = [];
            for (let key in flagLogs) {
                const cr = flagLogs[key];
                let item ={};
                item.createdAt = cr.createdAt;
                item.changedFrom = cr.changedFrom;
               // item.changedFromColorCode = cr.changedFrom.colorCode;
                item.changedTo = cr.changedTo;
               // item.changedToColorCode = cr.changedTo.colorCode;
                item.changeNotes = cr.changeNotes;
                item.updatedBy = cr.updatedBy;
                crList.push(item); 
            }
             dispatch(success(crList)); 
        
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_FLAG_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_FLAG_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_FLAG_LOGS_FAILURE, error } }
}

function getRetrofitFlagLogs(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getRetrofitFlagLogs(appId).then((flagLogs)=>{
            let crList = [];
            for (let key in flagLogs) {
                const cr = flagLogs[key];
                let item ={};
                item.createdAt = cr.createdAt;
                item.changedFrom = cr.changedFrom;
               // item.changedFromColorCode = cr.changedFrom.colorCode;
                item.changedTo = cr.changedTo;
               // item.changedToColorCode = cr.changedTo.colorCode;
                item.changeNotes = cr.changeNotes;
                item.updatedBy = cr.updatedBy;
                crList.push(item); 
            }
             dispatch(success(crList)); 
        
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_FLAG_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_FLAG_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_FLAG_LOGS_FAILURE, error } }
}

function getMeasureLogs(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureLogs(appId).then((measureLogs)=>{
            let crList = [];
            for (let key in measureLogs) {
                const cr = measureLogs[key];
                let item ={};
                item.stage = cr.applicationStage;
                item.status = cr.applicationStatus;
                item.createdAt = `${utils.formatLogTime(cr.createdAt)}`;
                item.measureName = cr.changedFrom ? cr.changedFrom.measure.label : '';
                item.measureCategory = cr.changedFrom ? cr.changedFrom.measureCategory.label : '';
                let changes = [];
                for(let logKey in cr.changedTo){
                    let itemToPush = null;
                    if(utils.isNullOrEmpty(cr.changedFrom[logKey])){
                        if(cr.changedTo[logKey].label){
                            itemToPush = { name : logKey, oldValue : "", newValue : cr.changedTo[logKey]};
                        } 
                        else if(cr.changedTo[logKey].url){
                            itemToPush = { name : logKey, oldValue : "", newValue : cr.changedTo[logKey]};
                        } 
                        else if(cr.changedTo[logKey]._seconds){
                            //SKIP PUSHING
                        } 
                        else{
                            itemToPush = { name : logKey, oldValue : "", newValue : cr.changedTo[logKey]};
                        }
                    } else {
                        if(cr.changedTo[logKey].label){
                            if(cr.changedTo[logKey].value !== cr.changedFrom[logKey].value ){
                                itemToPush = { name : logKey, oldValue : cr.changedFrom[logKey], newValue : cr.changedTo[logKey]};
                            }
                        } 
                        else if(cr.changedTo[logKey].url){
                            if(cr.changedTo[logKey].url !== cr.changedFrom[logKey].url ){
                                itemToPush = { isURL : true, name : logKey, oldValue : <a href={cr.changedFrom[logKey].url} rel="noreferrer" target="_blank">{cr.changedFrom[logKey].name}</a> , newValue : <a href={cr.changedTo[logKey].url} rel="noreferrer" target="_blank">{cr.changedTo[logKey].name}</a>};
                            }
                        } 
                        else if(cr.changedTo[logKey]._seconds){
                            //SKIP PUSHING
                        } 
                        else if(cr.changedFrom[logKey] !== cr.changedTo[logKey]){
                            itemToPush = { name : logKey, oldValue : cr.changedFrom[logKey], newValue : cr.changedTo[logKey]};
                        }
                    }
                    if(!utils.isNullOrEmpty(itemToPush) && (!utils.isNullOrEmpty(itemToPush.oldValue) || !utils.isNullOrEmpty(itemToPush.newValue))){
                        changes.push(itemToPush);
                    }
                }
                item.changes = changes;
                item.updatedBy = cr.updatedBy;
                crList.push(item); 
            }
            dispatch(success(crList)); 
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_MEASURE_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_MEASURE_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_MEASURE_LOGS_FAILURE, error } }
}

function getMeasureDetailLogs(measureId) {
    return dispatch => {
        dispatch(request());
        applicationService.getMeasureDetailLogs(measureId).then((measureLogs)=>{
            let crList = [];
            for (let key in measureLogs) {
                const cr = measureLogs[key];
                let item ={};
                item.stage = cr.applicationStage;
                item.status = cr.applicationStatus;
                item.createdAt = `${utils.formatLogTime(cr.createdAt)}`;
                item.measureName = cr.changedFrom ? cr.changedFrom.measure.label : '';
                item.measureCategory = cr.changedFrom ? cr.changedFrom.measureCategory.label : '';
                let changes = [];
                for(let logKey in cr.changedTo){
                    let itemToPush = null;
                    if(utils.isNullOrEmpty(cr.changedFrom[logKey])){
                        if(cr.changedTo[logKey].label){
                            itemToPush = { name : logKey, oldValue : "", newValue : cr.changedTo[logKey]};
                        } 
                        else if(cr.changedTo[logKey].url){
                            itemToPush = { name : logKey, oldValue : "", newValue : cr.changedTo[logKey]};
                        } 
                        else if(cr.changedTo[logKey]._seconds){
                            //SKIP PUSHING
                        } 
                        else{
                            itemToPush = { name : logKey, oldValue : "", newValue : cr.changedTo[logKey]};
                        }
                    } else {
                        if(cr.changedTo[logKey].label){
                            if(cr.changedTo[logKey].value !== cr.changedFrom[logKey].value ){
                                itemToPush = { name : logKey, oldValue : cr.changedFrom[logKey], newValue : cr.changedTo[logKey]};
                            }
                        } 
                        else if(cr.changedTo[logKey].url){
                            if(cr.changedTo[logKey].url !== cr.changedFrom[logKey].url ){
                                itemToPush = { isURL : true, name : logKey, oldValue : <a href={cr.changedFrom[logKey].url} rel="noreferrer" target="_blank">{cr.changedFrom[logKey].name}</a> , newValue : <a href={cr.changedTo[logKey].url} rel="noreferrer" target="_blank">{cr.changedTo[logKey].name}</a>};
                            }
                        } 
                        else if(cr.changedTo[logKey]._seconds){
                            //SKIP PUSHING
                        } 
                        else if(cr.changedFrom[logKey] !== cr.changedTo[logKey]){
                            itemToPush = { name : logKey, oldValue : cr.changedFrom[logKey], newValue : cr.changedTo[logKey]};
                        }
                    }
                    if(!utils.isNullOrEmpty(itemToPush) && (!utils.isNullOrEmpty(itemToPush.oldValue) || !utils.isNullOrEmpty(itemToPush.newValue))){
                        changes.push(itemToPush);
                    }
                }
                item.changes = changes;
                item.updatedBy = cr.updatedBy;
                crList.push(item); 
            }
            dispatch(success(crList)); 
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_MEASURE_DETAIL_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_MEASURE_DETAIL_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_MEASURE_DETAIL_LOGS_FAILURE, error } }
}

function getApplicationChangeRequests(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationChangeRequests(appId).then((data)=>{
            let crList = [];
                for (let key in data) {
                    const cr = data[key];
                    let item = cr;
                    item.createdAt = `${utils.formatDate(cr.createdAt)}`;
                    item.appNo = `ESB-${cr.appNo}`;
                    item.changeReqNo = `CR-${cr.changeReqNo}`;
                    item.reqType = cr.requestType.label;
                    item.status = cr.status;
                    item.applicationId = cr.applicationId;
                    item.description = cr.description;
                    item.note = cr.changeNote;
                    item.actionURL = `<a target="_blank" href='/esb/viewapplication/${cr.applicationId}'>View</a>`;
                    crList.push(item);
                }
            dispatch(success(crList));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQS_REQUEST } }
    function success(changeReqs) { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQS_SUCCESS, changeReqs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQS_FAILURE, error } }
}



function getApplicationChangeRequestLogs(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getApplicationChangeRequestLogs(appId).then((logs)=>{
            let crList = [];
                for (let key in logs) {
                    const cr = logs[key];
                    let item = cr;
                    item.createdAt = `${utils.formatDate(cr.createdAt)}`;
                    item.changeReqNo = `CR-${cr.changeReqNo}`;
                    item.reqType = cr.reqType;
                    item.fromStatus = cr.fromStatus || 'N/A';
                    item.toStatus = cr.toStatus;
                    item.updatedBy = cr.updatedBy;
                    item.description = cr.description;
                    item.note = cr.changeNotes;
                    crList.push(item);
                }
                // console.log('CR Log List  =>', crList);
            dispatch(success(crList));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_FAILURE, error } }
}

function getAppDocuments(applicationId) {
    if (utils.isNullOrEmpty(applicationId)) {
        return dispatch => {
            dispatch(request());
            dispatch(success([]));
        };
    } else {
        return dispatch => {
            dispatch(request());
            applicationService.getAppDocuments(applicationId)
                .then((data) => {
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
                );
        };
    }
    function request() { return { type: appActionTypes.GET_APPLICATION_DOCUMENTS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATION_DOCUMENTS_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_DOCUMENTS_FAILURE, error: extractErrMessage(error) } }
}

function searchChangeRequests(searchTerm,config,offset) {
    return dispatch => {
        dispatch(request());
        applicationService.searchChangeRequests(searchTerm,config, offset)
            .then((res) => {
                let data = res.data;
                let crList = [];
                for (let key in data) {
                    const cr = data[key];
                    let item = cr;
                    item.createdAt = `${utils.formatDate(cr.createdAt)}`;
                    crList.push(item);
                }
                if (!config.stream.length) {
                    //nothing
                } else if(config.stream.length === 1){
                    if (config.stream.includes("esb")) {
                        crList = crList.filter((ele) => typeof ele.stream === "undefined")
                    } else if (config.stream.includes("ces")) {
                        crList = crList.filter((ele) => ele.stream === "ces")
                    } 
                }
                dispatch(success(crList));
            }).catch((error) => {
                dispatch(failure(error));
            });
    };

    function request() { return { type: appActionTypes.SEARCH_CHANGE_REQS_REQUEST } }
    function success(changeReqs) { return { type: appActionTypes.SEARCH_CHANGE_REQS_SUCCESS, changeReqs, offset } }
    function failure(error) { return { type: appActionTypes.SEARCH_CHANGE_REQS_FAILURE, error: extractErrMessage(error) } }
}

function getRetrofitApplicationsReviewProgress(appId) {
    return dispatch => {
        dispatch(request());
        applicationService.getRetrofitApplicationsReviewProgress(appId)
            .then((data) => {
                dispatch(success(data));
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.GET_APPLICATIONS_REVIEW_PROGRESS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATIONS_REVIEW_PROGRESS_SUCCESS,  data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATIONS_REVIEW_PROGRESS_FAILURE, error: error } }
}

// Retrofit Review Process Documents Upload calls
function createRetrofitReviewProcessDocument(data) {
    const { facility_id } = data;
    return dispatch => {
        dispatch(request());
        applicationService.createRetrofitReviewProcessDocument(data)
            .then((data) => {
                applicationService.getRetrofitReviewProcessDocuments(facility_id).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };
    function request() { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function updateRetrofitReviewProcessDocument(documentId, data) {
    const { facility_id } = data;
    return dispatch => {
        dispatch(request());
        applicationService.updateRetrofitReviewProcessDocument(documentId, data)
            .then((data) => {
                applicationService.getRetrofitReviewProcessDocuments(facility_id).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function deleteRetrofitReviewProcessDocument(documentId, applicationId) {
    return dispatch => {
        dispatch(request());
        applicationService.deleteRetrofitReviewProcessDocument(documentId)
            .then((data) => {
                applicationService.getRetrofitReviewProcessDocuments(applicationId).then((reviewDocumentList) => {
                    dispatch(success(reviewDocumentList));
                })
            }).catch((error) => {
                dispatch(failure(error));
            }
            );
    };

    function request() { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_REQUEST } }
    function success(reviewDocumentList) { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_SUCCESS, reviewDocumentList } }
    function failure(error) { return { type: appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_FAILURE, error: extractErrMessage(error) } }
}

function getRetrofitReviewProcessDocuments(facilityId) {
    if (utils.isNullOrEmpty(facilityId)) {
        return dispatch => {
            dispatch(request());
            dispatch(success([]));
        };
    } else {
        return dispatch => {
            dispatch(request());
            applicationService.getRetrofitReviewProcessDocuments(facilityId)
                .then((data) => {
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
                );
        };
    }
    function request() { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_REQUEST } }
    function success(data) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_SUCCESS, data } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_FAILURE, error: extractErrMessage(error) } }
}

function getRetrofitReviewProcessDocumentsLogs(facilityId) {
    return dispatch => {
        // console.log("In getAppReviewDocumentsLogs", appId)
        dispatch(request());
        applicationService.getRetrofitReviewProcessDocumentsLogs(facilityId).then((reviewDocumentLogs)=>{
            // console.log("Review Document Logs ===>", reviewDocumentLogs)
            dispatch(success(reviewDocumentLogs)); 
        
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_REQUEST } }
    function success(logs) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_FAILURE, error } }
}

//Retrofit Review Process Documents end