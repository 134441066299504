import React from 'react';
import './RadioButton.css';

class RadioButton extends React.Component{
constructor(props){
    super(props);
    let showInput = false;
    let inputValue = '';
    if(props.value){
        if(props.value.name.toLowerCase().trim() === 'other'){
            showInput = true;
            inputValue = props.value.value;
        }
    };

    this.state = {
        showInput,
        inputValue
    }
}

handleSelect = (optionName) =>{
    const { inputValue } = this.state;
    let showInput = optionName.toLowerCase().trim() === 'other';
    const valObj = { name : optionName, value : showInput ? inputValue : optionName};
    this.props.onChange(this.props.name,valObj);
    this.setState({showInput});
}

handleOtherInputChange = (e)=>{
    const {value} = e.target;
    const valObj = { name : "Other", value };
    this.setState({inputValue:value});
    this.props.onChange(this.props.name, valObj);
}

render(){
    const { value, options, className } = this.props;
    const { showInput, inputValue } = this.state;
    return <div className={className}>
            <div className="options-container">{
                options.map((optionName,index)=>
                <div 
                    key={`option-${index}`} 
                    onClick={()=>this.handleSelect(optionName)}
                    className={`option-btn${(value ? value.name : '') === optionName ? ' option-btn-selected' : ''}`}>
                    {optionName}
                </div>)
            }
            {showInput ? <div className={`other-input`}><input value={inputValue} type='text' placeholder={`Type your role in the company here`} onChange={this.handleOtherInputChange}/></div> : ''}
            </div>
            {/* <input name={name} onKeyDown={onKeyDown} onChange={onChange} className='pass-input' placeholder={placeholder} type={`${shown ? 'text' : 'password'}`} value={value}/> */}
        </div>
}

}

export default RadioButton;