import React, { Component } from "react";
import DynamicReviewQuestions from "./DynamicReviewQuestions";
import { utils } from "../../../helpers/utils";
import { applicationService } from "../../../services/applicationService";
import ContainerLoader from "../../Common/ContainerLoader/ContainerLoader";

export class DynamicFormBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      errors: {},
      currentQuestions: {},
      confirm: false,
      reviewProgressLoading: true,
      applicationSecondaryReview: false,
    };
  }
  componentDidMount = () => {
    this.updateComponentState();
  };
  // componentDidUpdate = (prevProps) => {
  //   // console.log("Changes", prevProps);
  //   if (this.props !== prevProps) {
  //     this.updateComponentState();
  //   }
  // };
  updateComponentState = () => {
    const { applicationObj, reviewQuestions, tab, step, facilityId, currentMeasureId } = this.props;
    applicationService.getRetrofitApplicationsReviewProgress(applicationObj.application_id).then((data) => {
      let preProjectReview = false;
      let questions = (reviewQuestions && reviewQuestions[tab]) || [];
      let answeredQuestions = {};
      let formData = {};
      let currentQuestions = [];
      if (!utils.isNullOrEmpty(data)) {
        preProjectReview = data["Pre-Project"] ? true : false;
        if (applicationObj.stage === "Pre-Project") {
          if (tab === "Participant") {
            answeredQuestions = (data[applicationObj.stage] && data[applicationObj.stage].participantQuestions) || {};
          }
          if (tab === "Facility") {
            if (step === "Measures") {
              answeredQuestions =
                data[applicationObj.stage].facilityQuestions && data[applicationObj.stage].facilityQuestions[facilityId]
                  ? data[applicationObj.stage].facilityQuestions[facilityId][step]
                    ? data[applicationObj.stage].facilityQuestions[facilityId][step][currentMeasureId]
                      ? data[applicationObj.stage].facilityQuestions[facilityId][step][currentMeasureId]
                      : {}
                    : {}
                  : {};
            } else {
              answeredQuestions =
                data[applicationObj.stage] &&
                data[applicationObj.stage].facilityQuestions &&
                data[applicationObj.stage].facilityQuestions[facilityId]
                  ? data[applicationObj.stage].facilityQuestions[facilityId][step]
                  : {};
            }
          }
        } else if (applicationObj.stage === "Post-Project") {
          if (tab === "Participant") {
            let preProjectAns = data["Pre-Project"] && data["Pre-Project"].participantQuestions;
            let postProjectAns = {};
            if (data["Post-Project"]) {
              postProjectAns = data["Post-Project"] && data["Post-Project"].participantQuestions;
            } else {
              postProjectAns = preProjectAns;
            }
            answeredQuestions = {...postProjectAns, ...preProjectAns} || {};
          }
          if (tab === "Facility") {
            if (step === "Measures") {
              let preProjectAns =
                data["Pre-Project"] &&
                data["Pre-Project"].facilityQuestions &&
                data["Pre-Project"].facilityQuestions[facilityId] &&
                data["Pre-Project"].facilityQuestions[facilityId][step]
                  ? data["Pre-Project"].facilityQuestions[facilityId][step][currentMeasureId]
                  : {};
              let postProjectAns = {};
              if (
                data["Post-Project"] &&
                data["Post-Project"].facilityQuestions &&
                data["Post-Project"].facilityQuestions[facilityId] &&
                data["Post-Project"].facilityQuestions[facilityId][step] &&
                data["Post-Project"].facilityQuestions[facilityId][step][currentMeasureId]
              ) {
                postProjectAns =
                  data["Post-Project"] &&
                  data["Post-Project"].facilityQuestions &&
                  data["Post-Project"].facilityQuestions[facilityId] &&
                  data["Post-Project"].facilityQuestions[facilityId][step] &&
                  data["Post-Project"].facilityQuestions[facilityId][step][currentMeasureId];
              } else {
                postProjectAns = preProjectAns;
              }
              answeredQuestions = {...postProjectAns, ...preProjectAns} || {};
            } else {
              let preProjectAns =
                data["Pre-Project"] && data["Pre-Project"].facilityQuestions && data["Pre-Project"].facilityQuestions[facilityId]
                  ? data["Pre-Project"].facilityQuestions[facilityId][step]
                  : {};
              let postProjectAns = {};
              if (
                data["Post-Project"] &&
                data["Post-Project"].facilityQuestions &&
                data["Post-Project"].facilityQuestions[facilityId] &&
                data["Post-Project"].facilityQuestions[facilityId][step]
              ) {
                postProjectAns =
                  data["Post-Project"] &&
                  data["Post-Project"].facilityQuestions &&
                  data["Post-Project"].facilityQuestions[facilityId] &&
                  data["Post-Project"].facilityQuestions[facilityId][step];
              } else {
                postProjectAns = preProjectAns;
              }
              answeredQuestions = {...postProjectAns, ...preProjectAns} || {};
            }
          }
        }
      }
      if (applicationObj.isPrimaryReviewer === true) {
        if (applicationObj.stage === "Pre-Project") {
          questions.map((ele) => {
            if (step && step === ele.reviewSubSection) {
              if ((ele.stage === "Pre-Project" || ele.stage === "Both") && ele.reviewerType === "Primary") {
                if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                  formData[ele.fieldName] = "";
                  currentQuestions.push(ele);
                  if (!utils.isNullOrEmpty(answeredQuestions)) {
                    if (answeredQuestions[ele.fieldName] && answeredQuestions[ele.fieldName].label) {
                      formData[ele.fieldName] = {
                        label: answeredQuestions[ele.fieldName].label,
                        value: answeredQuestions[ele.fieldName].value,
                      };
                    } else {
                      formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                    }
                  }
                }
              }
            } else if (!step) {
              if ((ele.stage === "Pre-Project" || ele.stage === "Both") && ele.reviewerType === "Primary") {
                if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                  currentQuestions.push(ele);
                  if (ele.inputType === "toggle") {
                    formData[ele.fieldName] = false;
                  }
                  if (!utils.isNullOrEmpty(answeredQuestions)) {
                    if (typeof answeredQuestions[ele.fieldName] === "object") {
                      formData[ele.fieldName] = {
                        label: answeredQuestions[ele.fieldName].label,
                        value: answeredQuestions[ele.fieldName].value,
                      };
                    } else {
                      formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                    }
                  }
                }
              }
            }
          });
        } else if (applicationObj.stage === "Post-Project") {
          questions.map((ele) => {
            if (step && step === ele.reviewSubSection) {
              if (ele.reviewerType === "Primary") {
                formData[ele.fieldName] = "";
                if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                  currentQuestions.push(ele);
                  if (!utils.isNullOrEmpty(answeredQuestions)) {
                    if (answeredQuestions[ele.fieldName] && answeredQuestions[ele.fieldName].label) {
                      formData[ele.fieldName] = {
                        label: answeredQuestions[ele.fieldName].label,
                        value: answeredQuestions[ele.fieldName].value,
                      };
                    } else {
                      formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                    }
                  }
                }
              }
            } else if (!step) {
              if (ele.reviewerType === "Primary") {
                if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                  currentQuestions.push(ele);
                  if (ele.inputType === "toggle") {
                    formData[ele.fieldName] = false;
                  }
                  if (!utils.isNullOrEmpty(answeredQuestions)) {
                    if (typeof answeredQuestions[ele.fieldName] === "object") {
                      formData[ele.fieldName] = {
                        label: answeredQuestions[ele.fieldName].label,
                        value: answeredQuestions[ele.fieldName].value,
                      };
                    } else {
                      formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                    }
                  }
                }
              }
            }
          });
        }
      } else {
        // console.log("in secondary review ------->", step, questions);
        questions.map((ele) => {
          if (applicationObj.stage === "Pre-Project") {
            if (step && step === ele.reviewSubSection) {
              if (ele.stage === "Pre-Project" || ele.stage === "Both") {
                if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                  formData[ele.fieldName] = "";
                  currentQuestions.push(ele);
                  if (!utils.isNullOrEmpty(answeredQuestions)) {
                    if (answeredQuestions[ele.fieldName] && answeredQuestions[ele.fieldName].label) {
                      formData[ele.fieldName] = {
                        label: answeredQuestions[ele.fieldName].label,
                        value: answeredQuestions[ele.fieldName].value,
                      };
                    } else {
                      formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                    }
                  }
                }
              }
            } else if (!step) {
              if (ele.stage === "Pre-Project" || ele.stage === "Both") {
                if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                  formData[ele.fieldName] = "";
                  currentQuestions.push(ele);
                  if (ele.inputType === "toggle") {
                    formData[ele.fieldName] = false;
                  }
                  if (!utils.isNullOrEmpty(answeredQuestions)) {
                    if (typeof answeredQuestions[ele.fieldName] === "object") {
                      formData[ele.fieldName] = {
                        label: answeredQuestions[ele.fieldName].label,
                        value: answeredQuestions[ele.fieldName].value,
                      };
                    } else {
                      formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                    }
                  }
                }
              }
            }
          } else if (applicationObj.stage === "Post-Project") {
            if (step && step === ele.reviewSubSection) {
              if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                currentQuestions.push(ele);
                formData[ele.fieldName] = "";
                if (!utils.isNullOrEmpty(answeredQuestions)) {
                  if (answeredQuestions[ele.fieldName] && answeredQuestions[ele.fieldName].label) {
                    formData[ele.fieldName] = {
                      label: answeredQuestions[ele.fieldName].label,
                      value: answeredQuestions[ele.fieldName].value,
                    };
                  } else {
                    formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                  }
                }
              }
            } else if (!step) {
              if ((ele.stage === "Pre-Project" && ele.reviewerType === "Primary") || ele.stage === "Post-Project" || ele.stage === "Both") {
                if (this.props.currentMeasureType === ele.measureType || ele.measureType === "Both" || ele.measureType === "") {
                  currentQuestions.push(ele);
                  formData[ele.fieldName] = "";
                  if (ele.inputType === "toggle") {
                    formData[ele.fieldName] = false;
                  }
                  if (!utils.isNullOrEmpty(answeredQuestions)) {
                    if (typeof answeredQuestions[ele.fieldName] === "object") {
                      formData[ele.fieldName] = {
                        label: answeredQuestions[ele.fieldName].label,
                        value: answeredQuestions[ele.fieldName].value,
                      };
                    } else {
                      formData[ele.fieldName] = answeredQuestions[ele.fieldName];
                    }
                  }
                }
              }
            }
          }
        });
      }
      let applicationSecondaryReview = applicationObj.flag && applicationObj.flag.status === "Peer Review Assigned" ? true : false;
      this.setState({
        currentQuestions: currentQuestions,
        formData: formData,
        applicationSecondaryReview: applicationSecondaryReview,
        reviewProgressLoading: false,
        preProjectReview: preProjectReview,
      });
    });
  };
  onChange = (value, opt) => {
    const { formData, errors } = this.state;
    if (opt.name) {
      delete errors[opt.name];
      this.setState({
        formData: {
          ...formData,
          [opt.name]: value,
        },
        errors,
      });
    } else if (opt === true || opt === false) {
      delete errors[value];
      this.setState({
        formData: {
          ...formData,
          [value]: opt === true ? { label: "Yes", value: "Yes" } : { label: "No", value: "No" },
        },
        errors,
      });
    } else if (value.includes("CommentBox")) {
      delete errors[value];
      this.setState({
        formData: {
          ...formData,
          [value]: opt,
        },
        errors,
      });
    } else if(opt === "uploadError"){
      delete errors[value];
    } else {
      delete errors[value];
      this.setState({
        formData: {
          ...formData,
          [value]: {
            value: opt,
          },
        },
        errors,
      });
    }
  };
  verifyForm = () => {
    let { currentQuestions, formData, preProjectReview } = this.state;
    const {
      submitForm,
      applicationObj,
      tab,
      step,
      facilityId,
      stayOnMeasures,
      startMeasureReview,
      uploadedFileList,
      currentMeasureId,
      currentMeasureType,
      changeSlide,
      steps,
    } = this.props;
    let alreadyUploadedFilesQuestion = utils.isNullOrEmpty(uploadedFileList) ? [] : uploadedFileList.map((ele) => ele.fieldName);
    let errors = {};
    let errorCount = 0;
    // console.log("All formdata ------------>", formData)
    if (applicationObj.isPrimaryReviewer) {
      !utils.isNullOrEmpty(currentQuestions) &&
        currentQuestions.map((ele) => {
          if (ele["mandatory"] && !alreadyUploadedFilesQuestion.includes(ele.fieldName)) {
            // console.log("All formdata 2 ------------>", formData)
            if (applicationObj.stage === "Post-Project") {
              if ((ele["stage"] === "Pre-Project" || ele["stage"] === "Both") && !preProjectReview) {
                if (formData[ele.fieldName] === undefined || formData[ele.fieldName] === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                } else if (!utils.isNullOrEmpty(formData[ele.fieldName].question) || !utils.isNullOrEmpty(formData[ele.fieldName].label)) {
                  if (utils.isNullOrEmpty(formData[ele.fieldName].value)) {
                    errors[ele.fieldName] = `This field is required.`;
                    errorCount++;
                  }
                } else if (formData[ele.fieldName].value === undefined && formData[ele.fieldName].value === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                }
              } else if (ele["stage"] === "Post-Project") {
                if (formData[ele.fieldName] === undefined || formData[ele.fieldName] === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                } else if (!utils.isNullOrEmpty(formData[ele.fieldName].question) || !utils.isNullOrEmpty(formData[ele.fieldName].label)) {
                  if (utils.isNullOrEmpty(formData[ele.fieldName].value)) {
                    errors[ele.fieldName] = `This field is required.`;
                    errorCount++;
                  }
                } else if (formData[ele.fieldName].value === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                }
              }
            } else if (applicationObj.stage === "Pre-Project") {
              if (formData[ele.fieldName] === undefined || formData[ele.fieldName] === "") {
                errors[ele.fieldName] = `This field is required.`;
                errorCount++;
              } else if (!utils.isNullOrEmpty(formData[ele.fieldName].question) || !utils.isNullOrEmpty(formData[ele.fieldName].label)) {
                if (utils.isNullOrEmpty(formData[ele.fieldName].value)) {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                }
              } else if (formData[ele.fieldName].value === "") {
                errors[ele.fieldName] = `This field is required.`;
                errorCount++;
              }
            }
          }
        });
    } else {
      !utils.isNullOrEmpty(currentQuestions) &&
        currentQuestions.map((ele) => {
          if (ele.reviewerType === "Secondary" && ele["mandatory"] && !alreadyUploadedFilesQuestion.includes(ele.fieldName)) {
            if (applicationObj.stage === "Post-Project") {
              if ((ele["stage"] === "Pre-Project" || ele["stage"] === "Both") && !preProjectReview) {
                if (formData[ele.fieldName] === undefined || formData[ele.fieldName] === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                } else if (!utils.isNullOrEmpty(formData[ele.fieldName].question) || !utils.isNullOrEmpty(formData[ele.fieldName].label)) {
                  if (utils.isNullOrEmpty(formData[ele.fieldName].value)) {
                    errors[ele.fieldName] = `This field is required.`;
                    errorCount++;
                  }
                } else if (formData[ele.fieldName].value === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                }
              } else if (ele["stage"] === "Post-Project") {
                if (formData[ele.fieldName] === undefined || formData[ele.fieldName] === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                } else if (!utils.isNullOrEmpty(formData[ele.fieldName].question) || !utils.isNullOrEmpty(formData[ele.fieldName].label)) {
                  if (utils.isNullOrEmpty(formData[ele.fieldName].value)) {
                    errors[ele.fieldName] = `This field is required.`;
                    errorCount++;
                  }
                } else if (formData[ele.fieldName].value === "") {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                }
              }
            } else if (applicationObj.stage === "Pre-Project") {
              if (formData[ele.fieldName] === undefined || formData[ele.fieldName] === "") {
                errors[ele.fieldName] = `This field is required.`;
                errorCount++;
              } else if (!utils.isNullOrEmpty(formData[ele.fieldName].question) || !utils.isNullOrEmpty(formData[ele.fieldName].label)) {
                if (utils.isNullOrEmpty(formData[ele.fieldName].value)) {
                  errors[ele.fieldName] = `This field is required.`;
                  errorCount++;
                }
              } else if (formData[ele.fieldName].value === "") {
                errors[ele.fieldName] = `This field is required.`;
                errorCount++;
              }
            }
          }
        });
    }
    if (errorCount > 0) {
      console.log("errorrrrrrrrrrrs", errors, formData);
      submitForm(false);
      this.setState({ errors });
    }
    if (errorCount === 0) {
      submitForm(false);
      let progress = { ...formData };
      let rId = "";
      let reviewer = "";
      if (applicationObj.isPrimaryReviewer) {
        if (applicationObj.stage === "Pre-Project") {
          reviewer = applicationObj.reviewer;
          rId = applicationObj.reviewer && applicationObj.reviewer.value;
        } else {
          reviewer = applicationObj.postProjectReviewer;
          rId = applicationObj.postProjectReviewer && applicationObj.postProjectReviewer.value;
        }
      } else {
        if (applicationObj.stage === "Pre-Project") {
          reviewer = applicationObj.secondaryReviewer;
          rId = applicationObj.secondaryReviewer && applicationObj.secondaryReviewer.value;
        } else {
          reviewer = applicationObj.postProjectSecondaryReviewer;
          rId = applicationObj.postProjectSecondaryReviewer && applicationObj.postProjectSecondaryReviewer.value;
        }
      }
      !utils.isNullOrEmpty(currentQuestions) &&
        currentQuestions.map((ele) => {
          if (ele.fieldName.includes("CommentBox")) {
          } else if (applicationObj.isPrimaryReviewer) {
            progress[ele.fieldName] = { ...progress[ele.fieldName], question: ele.question };
            progress["reviewState"] = "Completed";
          } else {
            progress[ele.fieldName] = { ...progress[ele.fieldName], question: ele.question };
            progress["secondaryReviewState"] = "Completed";
          }
        });
      let updateObj = {};
      if (tab === "Participant") {
        updateObj[applicationObj.stage] = {
          participantQuestions: progress,
        };
        applicationService.postRetrofitApplicationsReviewProgress(applicationObj.application_id, updateObj).then((progressData) => {
          let updateStateObj = {
            reviewerId: reviewer,
            applicationId: applicationObj.application_id,
          };
          updateStateObj[applicationObj.application_id] = { currentReviewTab: "Facility", currentStepperName: "Facility" };
          submitForm(false, "Facility");
        });
      }
      if (tab === "Facility") {
        let nextStep = "";
        let indexOfStep = steps.indexOf(step);
        if (step === "Measures") {
          nextStep = stayOnMeasures ? "Measures" : steps[indexOfStep + 1];
          updateObj[applicationObj.stage] = {
            facilityQuestions: {
              [facilityId]: {
                [step]: {
                  [currentMeasureId]: progress,
                },
              },
            },
          };
        } else if (step === "Final Document Checklist") {
          nextStep = "Facility";
          if (applicationObj.isPrimaryReviewer) {
            updateObj[applicationObj.stage] = {
              facilityQuestions: {
                [facilityId]: {
                  [step]: progress,
                  reviewState: "Completed",
                },
              },
            };
          } else {
            updateObj[applicationObj.stage] = {
              facilityQuestions: {
                [facilityId]: {
                  [step]: progress,
                  secondaryReviewState: "Completed",
                },
              },
            };
          }
        } else {
          nextStep = steps[indexOfStep + 1];
          if (applicationObj.isPrimaryReviewer) {
            updateObj[applicationObj.stage] = {
              facilityQuestions: {
                [facilityId]: {
                  [step]: progress,
                  // reviewState: "In-Progress",
                },
              },
            };
          } else {
            updateObj[applicationObj.stage] = {
              facilityQuestions: {
                [facilityId]: {
                  [step]: progress,
                  // secondaryReviewState: "In-Progress",
                },
              },
            };
          }
        }
        applicationService.postRetrofitApplicationsReviewProgress(applicationObj.application_id, updateObj).then((progressData) => {
          let updateStateObj = {
            reviewerId: reviewer,
            applicationId: applicationObj.application_id,
          };
          updateStateObj[applicationObj.application_id] = {
            currentReviewTab: "Facility",
            facilityId: facilityId,
            currentStepperName: nextStep,
          };
          applicationService.postRetrofitApplicationReviewState(rId, updateStateObj).then((stateData) => {
            if (step === "Project Eligibility") {
              submitForm(false, "Facility", true);
              changeSlide(nextStep);
            } else if (step === "Measures") {
              if (!stayOnMeasures) {
                changeSlide(nextStep);
                startMeasureReview(true, currentMeasureId);
              } else {
                startMeasureReview(false, currentMeasureId);
              }
            } else if (step === "Final Document Checklist") {
              submitForm(false, "", false, step);
            } else {
              changeSlide(nextStep);
              // submitForm(false, "Facility", false);
            }
          });
        });
      }
    }
  };
  render() {
    let { currentQuestions, formData, errors, reviewProgressLoading, applicationSecondaryReview, preProjectReview } = this.state;
    const {
      saveAndProceed,
      handleDocumentUpload,
      uploadedFileList,
      applicationObj,
      currentMeasureId,
      currentMeasureType,
      measuresDetails,
    } = this.props;
    if (saveAndProceed) {
      this.verifyForm();
    }
    let primaryQuestions = [];
    let secondaryQuestions = [];
    // console.log("all question ----------->", currentQuestions, formData)
    if (currentQuestions.length) {
      primaryQuestions = currentQuestions.filter((q) => q.reviewerType === "Primary");
      secondaryQuestions = currentQuestions.filter((q) => q.reviewerType === "Secondary");
    }
    primaryQuestions = primaryQuestions.filter((q) => {
      let showQuestion = true;
      let currentMeasureTypeQuestion = true;
      if (q.condition.length === 1 && q.reviewSubSection !== "Measures") {
        let formVal = null;
        if (formData[q.condition[0].fieldName]) {
          formVal = formData[q.condition[0].fieldName].value;
        }
        if (q.condition[0] && q.condition[0].answer.length) {
          if (q.condition[0].answer.includes(formVal)) {
            showQuestion = true;
          } else {
            showQuestion = false;
          }
        } else {
          if (q.condition[0].answer === formVal) {
            showQuestion = true;
          } else {
            showQuestion = false;
          }
        }
      } else if (q.reviewSubSection === "Measures") {
        for (const key in q.condition) {
          let formVal = null;
          if (q.condition[key].fieldName === "measure_type") {
            if (q.condition[key].answer.includes(currentMeasureType)) {
              showQuestion = true;
              currentMeasureTypeQuestion = true;
            } else {
              showQuestion = false;
              currentMeasureTypeQuestion = false;
            }
          } else {
            if (currentMeasureTypeQuestion) {
              if (formData[q.condition[key].fieldName]) {
                formVal = formData[q.condition[key].fieldName].value;
              }
              if (q.condition[key] && q.condition[key].answer.length) {
                if (q.condition[key].answer.includes(formVal)) {
                  showQuestion = true;
                } else {
                  showQuestion = false;
                }
              } else {
                if (q.condition[key].answer === formVal) {
                  showQuestion = true;
                } else {
                  showQuestion = false;
                }
              }
            } else {
              showQuestion = false;
            }
          }
        }
      }
      return showQuestion;
    });
    secondaryQuestions = secondaryQuestions.filter((q) => {
      let showQuestion = true;
      let currentMeasureTypeQuestion = true;
      if (q.condition.length === 1 && q.reviewSubSection !== "Measures") {
        let formVal = null;
        if (formData[q.condition[0].fieldName]) {
          formVal = formData[q.condition[0].fieldName].value;
        }
        if (q.condition[0] && q.condition[0].answer.length) {
          if (q.condition[0].answer.includes(formVal)) {
            showQuestion = true;
          } else {
            showQuestion = false;
          }
        } else {
          if (q.condition[0].answer === formVal) {
            showQuestion = true;
          } else {
            showQuestion = false;
          }
        }
      } else if (q.reviewSubSection === "Measures") {
        for (const key in q.condition) {
          let formVal = null;
          if (q.condition[key].fieldName === "measure_type") {
            if (q.condition[key].answer.includes(currentMeasureType)) {
              currentMeasureTypeQuestion = true;
              showQuestion = true;
            } else {
              currentMeasureTypeQuestion = false;
              showQuestion = false;
            }
          } else {
            if (currentMeasureTypeQuestion) {
              if (formData[q.condition[key].fieldName]) {
                formVal = formData[q.condition[key].fieldName].value;
              }
              if (q.condition[key] && q.condition[key].answer.length) {
                if (q.condition[key].answer.includes(formVal)) {
                  showQuestion = true;
                } else {
                  showQuestion = false;
                }
              } else {
                if (q.condition[key].answer === formVal) {
                  showQuestion = true;
                } else {
                  showQuestion = false;
                }
              }
            } else {
              showQuestion = false;
            }
          }
        }
      }
      return showQuestion;
    });

    // console.log("Participant secondaryQuestions", secondaryQuestions, primaryQuestions, currentQuestions)

    return (
      <React.Fragment>
        {reviewProgressLoading ? (
          <ContainerLoader />
        ) : (
          <>
            <div className={`form-container ${applicationSecondaryReview ? "disabled-fields" : ""}`}>
              {primaryQuestions.length &&
                primaryQuestions.map((element, i) => (
                  <div className="input-container" key={`element-${i}`}>
                    <DynamicReviewQuestions
                      handleDocumentUpload={handleDocumentUpload}
                      uploadedFileList={uploadedFileList}
                      element={element}
                      formData={formData}
                      errors={errors}
                      applicationSecondaryReview={applicationSecondaryReview}
                      onChange={this.onChange}
                      stage={applicationObj.stage}
                      preProjectReview={preProjectReview}
                    />
                  </div>
                ))}
            </div>
            <div className={`form-container`}>
              {secondaryQuestions.length > 0 &&
                secondaryQuestions.map((element, i) => (
                  <div className="input-container" key={`element-${i}`}>
                    <DynamicReviewQuestions
                      handleDocumentUpload={handleDocumentUpload}
                      uploadedFileList={uploadedFileList}
                      element={element}
                      formData={formData}
                      errors={errors}
                      applicationSecondaryReview={applicationSecondaryReview}
                      onChange={this.onChange}
                      stage={applicationObj.stage}
                      preProjectReview={preProjectReview}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

export default DynamicFormBuilder;
