import React from 'react';
import Input from "../Input/Input"

const FormElement = (props) => {
    const { element, formData, errors, confirm } = props;
    return <React.Fragment>
        <Input
            label={element.label}
            type={element.type}
            placeholder={formData[element.name]?.name?formData[element.name].name:element.placeholder}
            name={element.name}
            value={formData[element.name]?.name?formData[element.name]?.name:formData[element.name]}
            error={errors[element.name]}
            onChange={props.onChange}
            options={element.options}
            checked={element.checked}
            maxFiles={element.maxFiles}
            isSearchable={element.isSearchable}
            disabled={confirm}
        />
    </React.Fragment>
}

export default FormElement;