import React from "react";
import "./RejectRecommendation.css";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import { applicationService } from "../../../../services/applicationService";
import { connect } from "react-redux";
import LoaderComponent from "../../../Common/Loader/Loader";
import { utils } from "../../../../helpers/utils";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
class RejectRecommendation extends React.Component {
  constructor(props) {
    super(props);
    const { adminUserInfo } = this.props;
    this.state = {
      rejectRecommendation: false,
      rejectedRecommendationSuccessfully: false,
      confirmingRejectRecommendation: false,
      activeAppId: "",
      rejectRecommendationLoading: true,
      applicationObj: {},
      statuses:[{ value: 'Application Under Review', label: 'Application Under Review' }, { value: 'QA/QC In Progress', label: 'QA/QC In Progress' }],
      rejectRecommendationDetails: {
        status: null,
        actionNote: "",
        actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
      },
    };
  }
  async componentDidMount() {
    this.props.loadAdminConfig();
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
        rejectRecommendationLoading: false,
      });
    });
  }
  onStatusChange = async  (value) => {
    const {rejectRecommendationDetails}= this.state;
    rejectRecommendationDetails['status']=value;
    this.setState({
      rejectRecommendationDetails:rejectRecommendationDetails,
    });
  
  };

  handleNotesChange = async (name, value) => {
    const {rejectRecommendationDetails}= this.state;
    rejectRecommendationDetails['actionNote']=value
    this.setState({
      rejectRecommendationDetails:rejectRecommendationDetails,
    });
  };
  confirmingRejectAction = () => {
    this.setState({
      confirmingRejectRecommendation: !this.state.confirmingRejectRecommendation,
    });
  };
  rejectRecommendationforApp = () => {
    let { rejectRecommendationDetails } = this.state;
    applicationService.updateApplication(this.props.applicationId, { stage : 'Post-Project', status:rejectRecommendationDetails['status']['value'],actionNote:rejectRecommendationDetails['actionNote'],actionTaker:rejectRecommendationDetails['actionTaker']}).then((res) => {
      this.setState(
        {
          rejectedRecommendationSuccessfully: true,
        },
        () => {
          setTimeout(() => {
            this.props.closeActionModal(true);
          }, 5000);
        }
      );
    });
  };
  render() {
    const { configData } = this.props;
    const { statuses,rejectRecommendationLoading, approvalDetails, paymentApprovalParameters, rejectedRecommendationSuccessfully,rejectRecommendationDetails } = this.state;
    // let flagsList = [];
    // if (configData) {
    //   flagsList = configData.appFlags.flags.filter((item)=>item.trigger !== 'Automated');
    // }
    const { applicationObj, rejectRecommendation, confirmingRejectRecommendation } = this.state;
    return (
      <div className="assign-payment-forapproval-container">
        <React.Fragment>
          {rejectRecommendationLoading ? (
            <LoaderComponent />
          ) : (
            <React.Fragment>
              {!rejectRecommendation ? (
                <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
              ) : (
                // <div className="section-header">
                //   <div className="section-top-details">ESB-{applicationObj.appNo}</div>
                //   <div className="section-top-details name-section">{applicationObj.companyName}</div>
                //   {<div className="section-top-details">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>}
                // </div>
                ""
              )}
            </React.Fragment>
          )}
          <div className="section-below-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Reject Incentive Recommendation" }}></h2>
          </div>
          <div className="container-box">
            <div className="new-form-sections">
              <div className="form-inner-section">
                <div className="form-label">Select a status for the application</div>
                <div className="form-element">
                  <Input
                    placeholder={"Please select a status"}
                    type="dropdown"
                    name="appStatus"
                    disabled={confirmingRejectRecommendation}
                    onChange={this.onStatusChange}
                    value={rejectRecommendationDetails.status}
                    options={statuses.map(status=>{ return { label: `${status.label}`,
                    value: status.value }})}
                    // options={flagsList.map((flag) => {
                    //   return {
                    //     label: `${flag.status}`,
                    //     value: flag.status,
                    //     definition: flag.definition,
                    //     colorCode: flag.colorCode,
                    //     icon: <FlagIcon color={flag.colorCode} />,
                    //   };
                    // })}
                  />
                </div>
                {confirmingRejectRecommendation ? (
                  <React.Fragment>
                    <div className="form-label info-text">
                      {rejectedRecommendationSuccessfully
                        ? `This incentive recommendation has been rejected successfully.`
                        : `You are about to reject an incentive recommendation. Please verify the status and the notes and confirm their accuracy.`}
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
              <div className="form-inner-section">
                <div className="form-label bold full">Rejection note:</div>
              </div>
              <div className="form-inner-section">
                <div className="form-element full">
                  <Input
                    type="richtext"
                    disabled={confirmingRejectRecommendation}
                    value={rejectRecommendationDetails.actionNote}
                    onChange={this.handleNotesChange}
                    name="note"
                  />
                </div>
              </div>
            </div>
          </div>
          {rejectedRecommendationSuccessfully ? (
            ""
          ) : (
            <div className="button-block">
              <div className="button-set">
                {confirmingRejectRecommendation ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmingRejectAction} /> 
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.props.closeActionModal()} />
                )}
                {confirmingRejectRecommendation ? (
                  <Button className="signup-btn" title={"Confirm Rejection"} uppercase onClick={this.rejectRecommendationforApp} />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Reject Recommendation"}
                    uppercase
                    onClick={this.confirmingRejectAction}
                    disabled={utils.isNullOrEmpty(rejectRecommendationDetails.status) || utils.isNullOrEmpty(utils.cleanHTMLText(rejectRecommendationDetails.actionNote))}
                  />
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}
function mapState(state) {
  const { adminUserInfo } = state.authentication;
  const { configData } = state.settings;
  return {
    adminUserInfo,
    configData,
  };
}
const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
};
export default connect(mapState, actionCreators)(RejectRecommendation);
