import React from "react";
import FieldCardList from "../../Common/FieldCardList/FieldCardList";
import AppMeasureLogs from "../../Pages/ApplicationsBoard/AppMeasureLogs/AppMeasureLogs";
import "./AppSummary.css";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import { utils } from "../../../helpers/utils";
import { AuthContext } from "../../../firebaseAuthContext";
import ProgramLayout from "../../Common/ProgramLayout";
import Loader from "../../Common/Loader/Loader";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";
import { ReactComponent as HistoryIcon } from "../../../images/History.svg";
import { userService } from "../../../services/userService";
import { applicationService } from "../../../services/applicationService";
import firebase from "../../../helpers/Firebase";
import Modal from "../../Common/Modal/Modal";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";
import CustomerEdit from "../ParticipantBoard/Customer/CustomerEdit/CustomerEdit";
import StatusLogs from "../ReviewBoard/StatusLogs/StatusLogs";
import ChangeReqLogs from "../../Common/ChangeReqLogs/ChangeReqLogs";
import RedInfoVector from "../../../images/RedInfoIcon.svg";
import TooltipInfo from "../../Common/TooltipInfo/TooltipInfo";

class AppSummary extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      currentProgram,
      payeDetails: undefined,
      applicationId: props.match.params.id,
      appReviewData: {},
      showCustomerDetailsModal: false,
      userInfo: null,
      editing: false,
      measureDetailModalOpen: false,
      measureLogsModalOpen: false,
      facilityDetailModalOpen: false,
      dataLoaded: false,
      errors: {},
      documentsList: [],
      selectedFacility: {},
      selectedMeasure: {},
      currentMeasureLoading: false,
      currentMeasureObj: null,
      currentMeasureConfig: [],
      currentFacilityLoading: false,
      currentFacilityObj: null,
      currentFacilityConfig: [],
      linkedAppsToCES: [],
      applicationObj: {
        stage: "Pre-Project",
        status: "Application Draft",
        facilityId: "",
        contractorId: "",
        contractor: "",
        contractorCanEdit: false,
        projectName: "",
        projectStartDate: "",
        projectCompletionDate: "",
        isReceivingOtherFunding: "",
        sourcesOfAllOtherFunding: "",
        totalAmountOfAllOtherFunding: "",
        amountOfEligibleExpenses: "",
        agreeToApplicationTerms: false,
      },
    };
  }

  componentDidMount() {
    const appIdToLoad = this.props.match.params.id;
    userService.getUserDetails(firebase.auth.currentUser.uid, (success, userInfo) => {
      this.props.loadContractors();
      this.props.loadFacilityConfig();
      if (!utils.isNullOrEmpty(appIdToLoad)) {
        applicationService.getApplication(appIdToLoad).then(async (data) => {
          let appReviewData = null;
          let postProjectAppReviewData = null;
          let applicationObj = { ...data };
          let linkedAppsToCES =
            data.stream === "ces" && !utils.isNullOrEmpty(data.linkedESBApplications)
              ? Object.keys(data.linkedESBApplications).map((ele) => {
                  return {
                    label: `ESB-${data.linkedESBApplications[ele]["appNo"]}`,
                    value: ele,
                  };
                })
              : [];
          try {
            appReviewData = await applicationService.getApplicationReview(appIdToLoad);
            if (applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") {
              postProjectAppReviewData = await applicationService.getPostProjectApplicationsReview(appIdToLoad);
            }
          } catch (err) {}

          this.props.getLogs(appIdToLoad);
          this.props.getChangeReqLogs(appIdToLoad);
          userService.getUserDetails(data.customerId, (success, customerData) => {
            this.props.loadFacilities(data.customerId);
            this.props.loadAppMeasures(appIdToLoad);
            this.props.getAppDocuments(appIdToLoad);

            this.setState({
              appReviewData: appReviewData,
              postProjectAppReviewData: postProjectAppReviewData,
              customerId: data.customerId,
              customerData,
              userInfo,
              editing: true,
              applicationObj,
              linkedAppsToCES,
              dataLoaded: true,
            });
            if (applicationObj.payeeAssigned && applicationObj.payeeAssigned === true) {
              //  console.log("Payeee etails==>",applicationObj.payeeInfo.uid);
              if (this.props.currentProgram === "speed") {
                userService.getFrontUserDetailsSpeed(applicationObj.payeeInfo.uid, (success, payData) => {
                  //console.log("Payee data pulled===",payData);
                  this.setState({
                    payeDetails: payData,
                  });
                });
              } else {
                userService.getFrontUserDetails(applicationObj.payeeInfo.uid, (success, payData) => {
                  //console.log("Payee data pulled===",payData);
                  this.setState({
                    payeDetails: payData,
                  });
                });
              }
            } else {
              console.log("No Payee Data Found");
            }
          });
        });
      }
    });
  }

  showMeasureDetailModal = (shown) => {
    this.setState({
      measureDetailModalOpen: shown,
    });
  };

  showMeasureLogsModal = (shown) => {
    this.setState({
      measureLogsModalOpen: shown,
    });
  };

  showCustomerDetailsModal = (shown) => {
    this.setState({
      showCustomerDetailsModal: shown,
    });
  };

  showFacilityDetailModal = (shown) => {
    this.setState({
      facilityDetailModalOpen: shown,
    });
  };

  viewMeasureLogs = (measureId) => {
    this.props.getMeasureDetailLogs(measureId);
    this.setState({
      measureLogsModalOpen: true,
      currentMeasureLoading: true,
    });
  };

  viewMeasureDetails = (measureObj) => {
    const { applicationObj } = this.state;
    this.setState(
      {
        measureDetailModalOpen: true,
        currentMeasureLoading: true,
      },
      () => {
        applicationService.getMeasureConfig(applicationObj.stage, measureObj.measure.value, this.props.currentProgram).then((mConfig) => {
          measureObj["Measure Code"] = measureObj.measure.value;
          this.setState({
            currentMeasureLoading: false,
            measureDetailModalOpen: true,
            currentMeasureConfig: [{ label: "Measure Code", type: "mCode", name: "Measure Code" }, ...mConfig.fields],
            currentMeasureObj: measureObj,
          });
        });
      }
    );
  };

  viewFacilityDetails = (chosenFacility) => {
    let facilityObj = { ...chosenFacility };
    facilityObj.address = `${facilityObj.facilityAddressLine1.label}, ${
      facilityObj.facilityAddressLine2 ? ", " + facilityObj.facilityAddressLine2 : ""
    } ${facilityObj.facilityCity} - ${facilityObj.facilityPostalCode}`;
    delete facilityObj.facilityAddressLine1;
    delete facilityObj.facilityAddressLine2;
    delete facilityObj.facilityCity;
    delete facilityObj.facilityPostalCode;
    if (this.props.currentProgram === "speed") {
      this.setState(
        {
          facilityDetailModalOpen: true,
          currentMeasureLoading: true,
        },
        () => {
          utils.getAdminConfigDocForSPP("facilityConfig").then((fConfig) => {
            let facilityFields = [...fConfig.fields];
            facilityFields.splice(1, 4, { name: "address", label: "Facility Address", type: "text" });
            this.setState({
              currentFacilityLoading: false,
              facilityDetailModalOpen: true,
              currentFacilityConfig: facilityFields,
              currentFacilityObj: facilityObj,
            });
          });
        }
      );
    } else {
      this.setState(
        {
          facilityDetailModalOpen: true,
          currentMeasureLoading: true,
        },
        () => {
          utils.getAdminConfigDoc("facilityConfig").then((fConfig) => {
            let facilityFields = [...fConfig.fields];
            facilityFields.splice(1, 4, { name: "address", label: "Facility Address", type: "text" });
            this.setState({
              currentFacilityLoading: false,
              facilityDetailModalOpen: true,
              currentFacilityConfig: facilityFields,
              currentFacilityObj: facilityObj,
            });
          });
        }
      );
    }
  };

  onChangeHandler = (name, value) => {
    const { applicationObj, errors } = this.state;
    delete errors[name];
    this.setState({
      applicationObj: {
        ...applicationObj,
        [name]: value,
      },
      errors,
    });
  };

  viewDocument = (document) => {
    window.open(document.url);
  };
  getTotalProjectCost = (list) => {
    const { applicationObj } = this.state;
    let cost = 0;
    list.map((measure) => {
      let measureTotalCost = 0;
      if (applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") {
        measureTotalCost = +(measure["Actual Total Cost"] ? measure["Actual Total Cost"] : measure["Total Cost"]);
      } else {
        measureTotalCost = +measure["Total Cost"];
      }
      cost = cost + measureTotalCost;
    });
    return cost;
  };
  getESBAppDetails = (id, detail) => {
    const { linkedAppsToCES } = this.state;
    if (detail === "appNo") {
      let appNo = linkedAppsToCES && linkedAppsToCES.length && linkedAppsToCES.filter((ele) => ele.value === id);
      return appNo && appNo[0] ? appNo[0].label : "";
    }
    if (detail === "projectCost") {
      let appNo = linkedAppsToCES && linkedAppsToCES.length && linkedAppsToCES.filter((ele) => ele.value === id);
      return appNo && appNo[0] ? appNo[0].projectCost : "";
    }
  };

  applicationSummary = () => {
    const { applicationObj, appReviewData, postProjectAppReviewData, payeDetails, linkedAppsToCES } = this.state;
    const { measureList, documentList, facilityList, currentProgram } = this.props;
    let draftApproved = applicationObj.status === "Application Draft Approved" && applicationObj.stage === "Pre-Approval";
    let appSubmitted = applicationObj.status === "Application Submitted" && applicationObj.stage === "Pre-Approval";
    let postProjectOrPayment = applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment";
    let chosenFacility = null;
    facilityList.forEach((f) => {
      if (f.id === applicationObj.facilityId) {
        chosenFacility = f;
      }
    });

    //SET UP FACILITY TABLE
    let facilityObj = {
      headings: [
        { title: "Facility Name", value: "Facility Name" },
        { title: "Region", value: "Region" },
        { title: "Facility Address", value: "Facility Address" },
        { title: "Facility Ownership", value: "Facility Ownership" },
        { title: "Facility Type", value: "Facility Type" },
        { title: "Facility Owner Approval Obtained", value: "Facility Owner Approval Obtained" },
        { title: "View Details", value: "View Details" },
      ],
      rows: [
        {
          "Facility Name": chosenFacility ? chosenFacility.facilityName : "",
          Region: chosenFacility ? chosenFacility.facilityApplicableRegion.value : "",
          "Facility Address": chosenFacility
            ? `${chosenFacility.facilityAddressLine1.label}, ${
                chosenFacility.facilityAddressLine2 ? ", " + chosenFacility.facilityAddressLine2 : ""
              } ${chosenFacility.facilityCity} - ${chosenFacility.facilityPostalCode}`
            : "",
          "Facility Ownership": chosenFacility ? chosenFacility.facilityOwnership : "",
          "Facility Type": chosenFacility ? chosenFacility.facilityType.label : "",
          "Facility Owner Approval Obtained": chosenFacility ? chosenFacility.hasLandlordApprovedProject.label : "",
          "View Details": (
            <div className="view-icon-container">
              <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewFacilityDetails(chosenFacility)} />
            </div>
          ),
        },
      ],
    };

    //SET UP MEASURES TABLE
    let measureHeadings = [
      { title: "Measure", value: "measure" },
      { title: "Quantity", value: "quantity" },
      { title: "Estimated Emissions Reduction (Lifetime)", value: "estimatedEmissionsReduction" },
      {
        title: postProjectOrPayment ? "Pre-Approved Incentive ($)" : "Estimated Incentive",
        value:
          applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? "preApprovedIncentive" : "estimatedIncentive",
      },
    ];
    if (applicationObj.stream === "ces") {
      measureHeadings = [
        { title: "Measure", value: "measure" },
        { title: "Application ID", value: "applicationId" },
        { title: "Quantity", value: "quantity" },
        { title: "Estimated Emissions Reduction (Lifetime)", value: "estimatedEmissionsReduction" },
        {
          title:
            applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment"
              ? "Pre-Approved Incentive ($)"
              : "Estimated Incentive",
          value:
            applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment" ? "preApprovedIncentive" : "estimatedIncentive",
        },
        { title: "Project Cost", value: "projectCost" },
      ];
    }
    if (currentProgram === "speed") {
      delete measureHeadings[2];
    }
    if (draftApproved || appSubmitted) {
      measureHeadings.push({ title: "Pre-Approved Incentive", value: "preApprovedIncentive" });
    }
    if (applicationObj.stage === "Post-Project" || applicationObj.stage === "Payment") {
      measureHeadings.push({ title: "Post-Project Applicable Incentive ($)", value: "postProjectApplicableIncentive" });
    }
    if (applicationObj.stage === "Payment") {
      measureHeadings.push({ title: "Recommended Incentive ($)", value: "postProjectApprovedIncentive" });
    }

    measureHeadings.push({
      title: "View",
      value: "view",
    });

    let measureRows =
      currentProgram === "speed"
        ? measureList.map((measure) => {
            return {
              measure: `${measure.measure.label} [${measure.measure.value}]`,
              quantity: measure.Quantity,
              estimatedIncentive: utils.formatAsCAD(utils.getTEMI(measure)),
              preApprovedIncentive:
                appReviewData && appReviewData.measuresReviewerEstimatedIncentive
                  ? utils.formatAsCAD(appReviewData.measuresReviewerEstimatedIncentive[measure.id])
                  : "",
              postProjectApplicableIncentive: utils.formatAsCAD(utils.getApplicableTEMI(measure)),
              postProjectApprovedIncentive:
                postProjectAppReviewData && postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive
                  ? utils.formatAsCAD(postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive[measure.id])
                  : "0.00",
              view: (
                <div className="table-detail-icons">
                  <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureDetails(measure)} />
                  <HistoryIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureLogs(measure.id)} />
                </div>
              ),
            };
          })
        : measureList.map((measure) => {
            return {
              measure: `${measure.measure.label} [${measure.measure.value}]`,
              quantity: measure.Quantity,
              estimatedEmissionsReduction: `${utils.formatNumberWithCommas(utils.getTEM(measure))} tonnes`,
              estimatedIncentive: utils.formatAsCAD(utils.getTEMI(measure, applicationObj.stream)),
              preApprovedIncentive:
                appReviewData && appReviewData.measuresReviewerEstimatedIncentive
                  ? utils.formatAsCAD(appReviewData.measuresReviewerEstimatedIncentive[measure.id])
                  : "",
              postProjectApplicableIncentive: utils.formatAsCAD(utils.getApplicableTEMI(measure)),
              postProjectApprovedIncentive:
                postProjectAppReviewData && postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive
                  ? utils.formatAsCAD(postProjectAppReviewData.measuresPostProjectReviewerApprovedIncentive[measure.id])
                  : "0.00",
              projectCost: measure["Actual Total Cost"]
                ? utils.formatAsCAD(measure["Actual Total Cost"])
                : utils.formatAsCAD(measure["Total Cost"]),
              applicationId: utils.isNullOrEmpty(measure.applicationId)
                ? `#CES-${applicationObj.appNo}`
                : `#${this.getESBAppDetails(measure.applicationId, "appNo")}`,
              view: (
                <div className="table-detail-icons">
                  <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureDetails(measure)} />
                  <HistoryIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewMeasureLogs(measure.id)} />
                </div>
              ),
            };
          });

    //SET UP DOCUMENTS TABLE
    let documentHeadings = [
      { title: "Document Name", value: "docName" },
      { title: "Date of Upload", value: "dateOfUpload" },
      { title: "View", value: "view" },
    ];
    let showOnlyDocs = documentList.filter((doc) => doc.url && !doc.url.includes("verification-document"));
    let documentRows = showOnlyDocs.map((document) => {
      return {
        docName: document.name,
        dateOfUpload: utils.formatAmericanDate(document.createdAt),
        view: <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(document)} />,
      };
    });

    let measureFooter = (
      <div className="responsive-table-row">
        <div className="responsive-table-details">Total</div>
        <div className="responsive-table-details"></div>
        {applicationObj.stream === "ces" ? <div className="responsive-table-details"></div> : ""}
        {currentProgram === "speed" ? (
          ""
        ) : (
          <div className="responsive-table-details">
            <label>Total Estimated Emissions Reduction (Lifetime)</label>
            <span>{utils.formatNumberWithCommas(utils.sumOfTEMs(measureList))} tonnes</span>
          </div>
        )}
        <div className="responsive-table-details">
          <label>{postProjectOrPayment ? "Total Pre-Approved Incentive" : "Total Estimated Incentive"}</label>
          <span>
            {applicationObj.stream === "ces"
              ? ""
              : postProjectOrPayment
              ? utils.formatAsCAD(applicationObj["Reviewer Estimated Incentive"] || 0.0)
              : utils.formatAsCAD(utils.sumOfTEMIs(measureList, currentProgram, applicationObj.stream))}
          </span>
        </div>
        {applicationObj.stream === "ces" ? (
          <div className="responsive-table-details">
            <label>Total Project Cost ($)</label> <span>{utils.formatAsCAD(this.getTotalProjectCost(measureList))}</span>
          </div>
        ) : null}
        {draftApproved || appSubmitted ? (
          <div className="responsive-table-details">
            <label>Total Pre-Approved Incentive</label>
            <span>{utils.formatAsCAD(appReviewData.totalReviewerEstimatedIncentive)}</span>
          </div>
        ) : (
          ""
        )}
        {postProjectOrPayment ? (
          <div className="responsive-table-details">
            <label>Total Post-Project Applicable Incentive ($)</label>
            <span>{applicationObj.stream === "ces" ? "" : utils.formatAsCAD(utils.sumOfApplicableTEMIs(measureList, applicationObj))}</span>
          </div>
        ) : (
          ""
        )}
        {applicationObj.stage === "Payment" ? (
          <div className="responsive-table-details">
            <label>Total Recommended Incentive ($)</label>
            <span>
              {applicationObj.stream === "esb" && postProjectAppReviewData && postProjectAppReviewData.totalReviewerEstimatedIncentive
                ? utils.formatAsCAD(postProjectAppReviewData.totalReviewerEstimatedIncentive)
                : ""}
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="responsive-table-details"></div>
      </div>
    );
    let nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(applicationObj["Estimated Category CES Bonus"])
      ? {}
      : Object.values(applicationObj["Estimated Category CES Bonus"]).filter((F) => F["isMaxCategoryIncentive"])[0];
    let preApprovalCategoryBonus = {};
    if (applicationObj.stage === "Pre-Approval" && !utils.isNullOrEmpty(applicationObj["Pre-Approval Category Bonus"])) {
      preApprovalCategoryBonus = applicationObj["Pre-Approval Category Bonus"];
      nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(preApprovalCategoryBonus)
        ? {}
        : Object.values(preApprovalCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    }

    let applicableCategoryBonus = {};
    if (applicationObj.stage === "Post-Project" && !utils.isNullOrEmpty(applicationObj["Post-Project Applicable Category CES Bonus"])) {
      applicableCategoryBonus = applicationObj["Post-Project Applicable Category CES Bonus"];
      nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(applicableCategoryBonus)
        ? {}
        : Object.values(applicableCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    }
    let approvedCategoryBonus = {};
    if (applicationObj.stage === "Payment" && !utils.isNullOrEmpty(applicationObj["Approved Category Bonus"])) {
      approvedCategoryBonus = applicationObj["Approved Category Bonus"];
      nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(approvedCategoryBonus)
        ? {}
        : Object.values(approvedCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    }
    return (
      <div className="form-sections application-summary">
        <div className="step-header-container">
          <div className="step-header-container-left">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Application Summary" }}></h2>
          </div>
        </div>
        <div className="form-inner-section">
          <h2 className="step-header-title">Customer Details</h2>
          <div className="enerva-btn" onClick={() => this.showCustomerDetailsModal(true)}>
            View Customer Details
          </div>
        </div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Facility Details" }}></h2>
          {chosenFacility ? (
            <ResponsiveTable headings={facilityObj.headings} rows={facilityObj.rows} />
          ) : (
            <div className="empty-summary-container">This application is not attached to any facilities currently</div>
          )}
        </div>
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Measures Details" }}></h2>
          {measureList.length > 0 ? (
            <>
              <ResponsiveTable headings={measureHeadings} rows={measureRows} customFooter={measureFooter} />
              {applicationObj.stream === "ces" ? (
                applicationObj.stage === "Payment" ? (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        <div className="responsive-table-details">{"Approved Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Final Bonus Eligibility"}</div>
                        <div className="responsive-table-details">{"Final Eligible Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Approved Category Bonus ($)"}</div>
                      </div>
                      {Object.values(approvedCategoryBonus).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.isOnlyPaidOutIncentive
                                ? "not-eligible"
                                : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                                  nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                                ? "not-eligible"
                                : bonusEle.sumOfSolarPVIncentive > 0
                                ? ""
                                : bonusEle["Final Bonus Eligibility"]
                                ? bonusEle["Final Eligible Category Incentive"] === 0 &&
                                  bonusEle["Approved Category Incentive"] > 0 &&
                                  bonusEle.sumOfSolarPVIncentive === 0
                                  ? "not-eligible"
                                  : ""
                                : "not-eligible"
                            }`}
                          >
                            <label>{"Approved Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Approved Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Approved Category Incentive"])}`}
                            </span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle["Final Bonus Eligibility"] ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Final Bonus Eligibility</label>
                            <span>{`${bonusEle["Final Bonus Eligibility"] ? "Yes" : "No"}`}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0
                                ? "not-eligible"
                                : ""
                            }`}
                          >
                            <label>{"Final Eligible Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Final Eligible Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Final Eligible Category Incentive"])}`}
                            </span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>{"Approved Category Bonus ($)"}</label>
                            <span>
                              {!bonusEle["Approved Category Bonus"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Approved Category Bonus"])}`}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="summary-header">Total Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">{"Gross Approved Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Total Approved Bonus ($)"}</div>
                        <div className="responsive-table-details">{"Total Approved Incentive ($)"}</div>
                      </div>
                      <div className={`responsive-table-row`}>
                        <div className="responsive-table-details">
                          <label>{"Gross Approved Incentive ($)"}</label>
                          <span>
                            {!applicationObj["Gross Approved Incentive"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Gross Approved Incentive"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Approved Bonus ($)"}</label>
                          <span>
                            {!applicationObj["Total Approved Bonus"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Total Approved Bonus"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Approved Incentive ($)"}</label>
                          <span>{`${utils.formatNumberWithOnlyCommas(applicationObj.PostProjectReviewerApprovedIncentive)}`}</span>
                          {applicationObj["Post-Project Approved Cap Notice"] ? (
                            <div className="info-icon custom-info-height">
                              <TooltipInfo dynamicPosition={true} helpText={applicationObj["Post-Project Approved Cap Notice"]} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : applicationObj.stage === "Post-Project" ? (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        <div className="responsive-table-details">{"Applicable Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Applicable Bonus Eligibility"}</div>
                        <div className="responsive-table-details">{"Applicable Eligible Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Applicable Category Bonus ($)"}</div>
                      </div>
                      {Object.values(applicableCategoryBonus).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.isOnlyPaidOutIncentive
                                ? "not-eligible"
                                : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                                  nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                                ? "not-eligible"
                                : bonusEle.sumOfSolarPVIncentive > 0
                                ? ""
                                : bonusEle.bonusEligibilityCES
                                ? bonusEle.finalCESCategoryBonusEligibleSum === 0 &&
                                  bonusEle.categoryIncentiveCES > 0 &&
                                  bonusEle.sumOfSolarPVIncentive === 0
                                  ? "not-eligible"
                                  : ""
                                : "not-eligible"
                            }`}
                          >
                            <label>{"Applicable Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle.categoryIncentiveCES ? 0 : `${utils.formatNumberWithOnlyCommas(bonusEle.categoryIncentiveCES)}`}
                            </span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle.bonusEligibilityCES ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Applicable Bonus Eligibility</label>
                            <span>{`${bonusEle.bonusEligibilityCES ? "Yes" : "No"}`}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0
                                ? "not-eligible"
                                : ""
                            }`}
                          >
                            <label>{"Applicable Eligible Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Applicable Eligible Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle.finalCESCategoryBonusEligibleSum)}`}
                            </span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>{"Applicable Category Bonus ($)"}</label>
                            <span>
                              {!bonusEle.applicableCategoryCESBonus
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle.applicableCategoryCESBonus)}`}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="summary-header">Total Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">{"Gross Applicable Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Total Applicable Bonus ($)"}</div>
                        <div className="responsive-table-details">{"Total Applicable Incentive ($)"}</div>
                      </div>
                      <div className={`responsive-table-row`}>
                        <div className="responsive-table-details">
                          <label>{"Gross Applicable Incentive ($)"}</label>
                          <span>
                            {!applicationObj["Post-Project Gross Applicable Incentive"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Post-Project Gross Applicable Incentive"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Applicable Bonus ($)"}</label>
                          <span>
                            {!applicationObj["Post-Project Applicable CES Bonus"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Post-Project Applicable CES Bonus"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Applicable Incentive ($)"}</label>
                          <span>{`${utils.formatNumberWithOnlyCommas(applicationObj["Post-Project Applicable Incentive"])}`}</span>
                          {applicationObj["Post-Project Cap Notice"] ? (
                            <div className="info-icon custom-info-height">
                              <TooltipInfo dynamicPosition={true} helpText={applicationObj["Post-Project Cap Notice"]} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : draftApproved || appSubmitted ? (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        <div className="responsive-table-details">{"Pre-Approved Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Pre-Approved Bonus Eligibility"}</div>
                        <div className="responsive-table-details">{"Pre-Approved Eligible Category Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Pre-Approved Category Bonus ($)"}</div>
                      </div>
                      {Object.values(preApprovalCategoryBonus).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.isOnlyPaidOutIncentive
                                ? "not-eligible"
                                : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                                  nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                                ? "not-eligible"
                                : bonusEle.sumOfSolarPVIncentive > 0
                                ? ""
                                : bonusEle["Pre-Approval Bonus Eligibility"]
                                ? bonusEle["Pre-Approval Eligible Category Incentive"] === 0 &&
                                  bonusEle["Pre-Approval Category Incentive"] > 0 &&
                                  bonusEle.sumOfSolarPVIncentive === 0
                                  ? "not-eligible"
                                  : ""
                                : "not-eligible"
                            }`}
                          >
                            <label>{"Pre-Approved Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Pre-Approval Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Category Incentive"])}`}
                            </span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle["Pre-Approval Bonus Eligibility"] ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Pre-Approved Bonus Eligibility</label>
                            <span>{`${bonusEle["Pre-Approval Bonus Eligibility"] ? "Yes" : "No"}`}</span>
                          </div>
                          <div
                            className={`responsive-table-details ${
                              bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0
                                ? "not-eligible"
                                : ""
                            }`}
                          >
                            <label>{"Pre-Approved Eligible Category Incentive ($)"}</label>
                            <span>
                              {!bonusEle["Pre-Approval Eligible Category Incentive"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Eligible Category Incentive"])}`}
                            </span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>{"Pre-Approved Category Bonus ($)"}</label>
                            <span>
                              {!bonusEle["Pre-Approval Category Bonus"]
                                ? 0
                                : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Category Bonus"])}`}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="summary-header">Total Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">{"Gross Pre-Approved Incentive ($)"}</div>
                        <div className="responsive-table-details">{"Total Pre-Approved Bonus ($)"}</div>
                        <div className="responsive-table-details">{"Total Pre-Approved Incentive ($)"}</div>
                      </div>
                      <div className={`responsive-table-row`}>
                        <div className="responsive-table-details">
                          <label>{"Gross Pre-Approved Incentive ($)"}</label>
                          <span>
                            {!applicationObj["Gross Pre-Approved Incentive"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Gross Pre-Approved Incentive"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Pre-Approved Bonus ($)"}</label>
                          <span>
                            {!applicationObj["Total Pre-Approved Bonus"]
                              ? 0
                              : `${utils.formatNumberWithOnlyCommas(applicationObj["Total Pre-Approved Bonus"])}`}
                          </span>
                        </div>
                        <div className="responsive-table-details">
                          <label>{"Total Pre-Approved Incentive ($)"}</label>
                          <span>{`${utils.formatNumberWithOnlyCommas(applicationObj["Reviewer Estimated Incentive"])}`}</span>
                          {applicationObj["Pre-Project Approved Cap Notice"] ? (
                            <div className="info-icon custom-info-height">
                              <TooltipInfo dynamicPosition={true} helpText={applicationObj["Pre-Project Approved Cap Notice"]} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="summary-header">Incentive Bonus</div>
                    <div className="responsive-table-container">
                      <div className="responsive-table-row responsive-table-header">
                        <div className="responsive-table-details">Category Name</div>
                        <div className="responsive-table-details">No. of Measures in Category</div>
                        <div className="responsive-table-details">Category Incentive</div>
                        <div className="responsive-table-details">Bonus Eligibility</div>
                        <div className="responsive-table-details">Eligible Category Incentive</div>
                        <div className="responsive-table-details">Category Bonus</div>
                      </div>
                      {Object.values(applicationObj["Estimated Category CES Bonus"]).map((bonusEle, index) => (
                        <div className={`responsive-table-row`} key={index}>
                          <div className="responsive-table-details">
                            <label>Category Name</label>
                            <span>{bonusEle.category}</span>
                          </div>
                          <div className="responsive-table-details">
                            <label>No. of Measures in Category</label>
                            <span>{bonusEle.measureCount}</span>
                          </div>
                          <div className={`responsive-table-details ${bonusEle.bonusEligibilityCES ? "" : "not-eligible"}`}>
                            <label>Category Incentive</label>
                            <span>{utils.formatAsCAD(bonusEle.categoryIncentiveCES)}</span>
                            {bonusEle.isOnlyPaidOutIncentive ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                                />
                              </div>
                            ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                              nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                                />
                              </div>
                            ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                              ""
                            ) : bonusEle.bonusEligibilityCES ? (
                              ""
                            ) : (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Bonus Eligibility</label>
                            <span>{bonusEle.bonusEligibilityCES ? "Yes" : "No"}</span>
                          </div>
                          <div className="responsive-table-details">
                            <label>Bonus Eligible Category Incentive</label>
                            <span>{utils.formatAsCAD(bonusEle.finalCESCategoryBonusEligibleSum)}</span>
                            {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo
                                  customIcon={RedInfoVector}
                                  dynamicPosition={true}
                                  helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="responsive-table-details">
                            <label>Category Bonus</label>
                            <span>{utils.formatAsCAD(bonusEle.estimatedCategoryCESBonus)}</span>
                          </div>
                        </div>
                      ))}
                      <div className="summary-items-row for-small-screens">
                        <div className="summary-item-details">
                          <label>Gross Estimated Incentive</label>
                          <span>{utils.formatAsCAD(applicationObj["Gross Estimated Incentive"])}</span>
                        </div>
                        <div className="summary-item-details">
                          <label>Total Bonus</label>
                          <span>{utils.formatAsCAD(applicationObj["Estimated CES Bonus"])}</span>
                        </div>
                        <div className="summary-item-details">
                          <label>Total Estimated Incentive</label>
                          <span>{utils.formatAsCAD(applicationObj["Estimated Incentive"])}</span>
                        </div>
                      </div>
                      <div className="bonus-totals">
                        <div className="bonus-row">
                          <div className="row-cell row-head">Gross Estimated Incentive</div>
                          <div className="row-cell">{utils.formatAsCAD(applicationObj["Gross Estimated Incentive"])}</div>
                        </div>
                        <div className="bonus-row">
                          <div className="row-cell row-head">Total Bonus</div>
                          <div className="row-cell">{utils.formatAsCAD(applicationObj["Estimated CES Bonus"])}</div>
                        </div>
                        <div className="bonus-row">
                          <div className="row-cell row-head">Total Estimated Incentive</div>
                          <div className="row-cell">
                            {utils.formatAsCAD(applicationObj["Estimated Incentive"])}
                            {applicationObj["Pre-Project Cap Notice"] ? (
                              <div className="info-icon custom-info-height">
                                <TooltipInfo dynamicPosition={true} helpText={applicationObj["Pre-Project Cap Notice"]} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : null}
            </>
          ) : (
            <div className="empty-summary-container">There are no measures added to this application</div>
          )}
        </div>
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Documents Summary" }}></h2>
          {utils.isNullOrEmpty(documentList) ? (
            <div className="empty-summary-container">There are no documents uploaded currently</div>
          ) : (
            <ResponsiveTable headings={documentHeadings} rows={documentRows} />
          )}
        </div>
        <div className="horizontal-bar"></div>
        {applicationObj ? (
          <div className="form-inner-section">
            <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Project Summary" }}></h2>
            <div className="form-inner-elements">
              <div className="application-form-label">Contractor Name</div>
              <div className="application-form-element">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Application Name</div>
              <div className="application-form-element">{applicationObj.projectName || `ESB-${applicationObj.appNo}`}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Project Start Date</div>
              <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectStartDate)}</div>
            </div>
            <div className="form-inner-elements">
              <div className="application-form-label">Estimated Date of Project Completion</div>
              <div className="application-form-element">{utils.formatAmericanDate(applicationObj.projectCompletionDate)}</div>
            </div>
            {draftApproved || appSubmitted || postProjectOrPayment ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Project Completion Deadline</div>
                <div className="application-form-element">
                  {utils.formatAmericanDate(applicationObj["Project Completion Deadline"])}
                  {/* {utils.addDays(applicationObj["Pre-Approval - Application Draft Approved Time"], 184)} */}
                </div>
              </div>
            ) : (
              ""
            )}
            {postProjectOrPayment ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Actual Project Completion Date</div>
                <div className="application-form-element">{utils.formatAmericanDate(applicationObj.actualProjectEndDate)}</div>
              </div>
            ) : (
              ""
            )}
            {postProjectOrPayment ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Approval Date</div>
                <div className="application-form-element">
                  {utils.formatAmericanDate(applicationObj["Pre-Approval - Application Draft Approved Time"])}
                </div>
              </div>
            ) : (
              ""
            )}
            {postProjectOrPayment ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Application Submitted Date</div>
                <div className="application-form-element">
                  {utils.formatAmericanDate(applicationObj["Pre-Approval - Application Submitted Time"])}
                </div>
              </div>
            ) : (
              ""
            )}
            {postProjectOrPayment ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Post-Project Application Submitted Date</div>
                <div className="application-form-element">
                  {utils.formatAmericanDate(applicationObj["Post-Project - Application Submitted Time"])}
                </div>
              </div>
            ) : (
              ""
            )}
            {applicationObj.stage === "Payment" ? (
              <div className="form-inner-elements">
                <div className="application-form-label">Incentive Recommended Date</div>
                <div className="application-form-element">
                  {utils.formatAmericanDate(applicationObj["Payment - Incentive Recommended Time"])}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">
                Is the project receiving other funding, financial incentives, grants or subsidies?
              </div>
              <div className="application-form-element">{applicationObj.isReceivingOtherFunding}</div>
            </div>
            {applicationObj.isReceivingOtherFunding.toLowerCase() === "yes" ? (
              <React.Fragment>
                <div className="form-inner-elements">
                  <div className="application-form-label">Sources of all other funding, financial incentives, grants and subsidies</div>
                  <div className="application-form-element">{applicationObj.sourcesOfAllOtherFunding}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">
                    Total amount of all other funding, financial incentives, grants and subsidies
                  </div>
                  <div className="application-form-element">{utils.formatAsCAD(applicationObj.totalAmountOfAllOtherFunding)}</div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <div className="form-inner-elements">
              <div className="application-form-label">Amount of eligible expenses incurred Prior to Application Submission</div>
              <div className="application-form-element">{utils.formatAsCAD(applicationObj.amountOfEligibleExpenses)}</div>
            </div>
            {applicationObj.approvedByBERA ? (
              <React.Fragment>
                <div className="form-inner-elements">
                  <div className="application-form-label">Has this project been approved by BERA for funding?</div>
                  <div className="application-form-element">{applicationObj.approvedByBERA ? "Yes" : "No"}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">
                    Total amount of all other funding, financial incentives, grants and subsidies
                  </div>
                  <div className="application-form-element">{utils.formatAsCAD(applicationObj.fundingApprovedByBERA || 0.0)}</div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {payeDetails && applicationObj.payeeAssigned === true ? (
          <React.Fragment>
            <div className="horizontal-bar"></div>
            <div className="form-inner-section">
              <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Payee Summary" }}></h2>

              <div className="form-inner-elements">
                <div className="application-form-label">Payee Full Legal Name</div>
                <div className="application-form-element">{payeDetails.companyDetails.name}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Payee Address</div>
                <div className="application-form-element">{`${payeDetails.companyDetails.address1}, ${
                  payeDetails.companyDetails.address2 ? payeDetails.companyDetails.address2 + ", " : ""
                }${payeDetails.companyDetails.city}, ${payeDetails.companyDetails.province} - ${
                  payeDetails.companyDetails.postalCode
                }`}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Payee Contact Name</div>
                <div className="application-form-element">
                  {payeDetails.firstName} {payeDetails.lastName}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Phone</div>
                <div className="application-form-element">{utils.formatPhoneNumber(payeDetails.phoneNum)}</div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Status Logs" }}></h2>
          <StatusLogs skipTopBit={true} applicationId={this.state.applicationId} activeStatusLogs={this.props.activeStatusLogs} />
        </div>
        <div className="horizontal-bar"></div>
        <div className="form-inner-section">
          <h2 className="step-header-title" dangerouslySetInnerHTML={{ __html: "Change Requests" }}></h2>
          <ChangeReqLogs activeChangeRequests={this.props.activeChangeRequests} />
        </div>
      </div>
    );
  };
  sumIt = (list, key) => {
    let ans = 0.0;
    for (let item of list) {
      ans += parseFloat(item[key]);
    }
    return ans;
  };

  goToAccount = () => {
    this.props.history.push("/account");
  };

  render() {
    const {
      applicationObj,
      editing,
      dataLoaded,
      currentMeasureObj,
      currentMeasureLoading,
      currentMeasureConfig,
      currentFacilityLoading,
      currentFacilityConfig,
      currentFacilityObj,
      showCustomerDetailsModal,
    } = this.state;
    const { applicationProcessing, measureDetailLogs, currentProgram } = this.props;
    const selectedUserData = { uid: applicationObj.customerId };
    return (
      <ProgramLayout showFooter={false} showSideBarMenu={true} props={this.props}>
        <div className="view-application-container">
          {/* <button onClick={() => { this.props.history.length > 2 ? this.props.history.goBack() : this.props.history.push('/') }} className="back-button"><img src={LeftArrow} alt='arrow' />Back</button> */}
          {dataLoaded ? (
            <div className="section-header">
              <h2 className="section-title">
                {editing
                  ? `Application ID: ${currentProgram === "esb" ? (applicationObj.stream === "ces" ? "CES" : "ESB") : "SPEED"}-${
                      applicationObj.appNo
                    }`
                  : "Start an Application"}
              </h2>
            </div>
          ) : (
            ""
          )}
          <Modal
            IsOpen={showCustomerDetailsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.showCustomerDetailsModal(false)}
            modalSize={"large"}
            // loading={activeParticipantLoading}
          >
            <CustomerEdit selectedCustomer={selectedUserData} canEdit={false} />
          </Modal>
          <Modal
            IsOpen={this.state.measureDetailModalOpen}
            className={"esb-modal measure-details-modal"}
            handleCloseModal={() => this.showMeasureDetailModal(false)}
            modalSize={"large"}
            loading={currentMeasureLoading}
          >
            <h3>Measure Specific Fields</h3>
            <div className="measure-details-container">
              {currentMeasureObj ? <FieldCardList config={currentMeasureConfig} obj={currentMeasureObj} /> : ""}
              {/* <div><h3>Measure Logs</h3><AppMeasureLogs skipTopBit={true} MeasureLogs={measureDetailLogs} /></div> */}
            </div>
          </Modal>
          <Modal
            IsOpen={this.state.measureLogsModalOpen}
            className={"esb-modal measure-details-modal"}
            handleCloseModal={() => this.showMeasureLogsModal(false)}
            modalSize={"large"}
          >
            <h3>Measure Logs</h3>
            <div className="measure-details-container">
              <AppMeasureLogs skipTopBit={true} MeasureLogs={measureDetailLogs} />
            </div>
          </Modal>

          <Modal
            IsOpen={this.state.facilityDetailModalOpen}
            className={"esb-modal measure-details-modal"}
            handleCloseModal={() => this.showFacilityDetailModal(false)}
            modalSize={"large"}
            loading={currentFacilityLoading}
          >
            <h3>Facility Details</h3>
            <div className="measure-details-container">
              {currentFacilityObj ? <FieldCardList config={currentFacilityConfig} obj={currentFacilityObj} /> : ""}
            </div>
          </Modal>
          {dataLoaded && !applicationProcessing ? <div className="container-box">{this.applicationSummary()}</div> : <Loader />}
        </div>
      </ProgramLayout>
    );
  }
}

function mapState(state) {
  const {
    facilityConfig,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
    activeStatusLogs,
    activeChangeRequests,
    measureDetailLogs,
  } = state.application;
  const { currentProgram } = state.authentication;
  return {
    facilityConfig,
    currentProgram,
    facilityList,
    loadingFacilityConfig,
    loadingActiveContractors,
    activeContractors,
    loadingActiveContractorsError,
    applicationProcessing,
    applicationCreated,
    applicationId,
    measureCategories,
    measureCategoriesLoading,
    measureTypesLoading,
    measureTypes,
    measures,
    measuresLoading,
    measureConfig,
    measureConfigLoading,
    measureList,
    measureCategory,
    measureType,
    measureCode,
    measureDrawerConfig,
    documentList,
    editingMeasureId,
    activeStatusLogs,
    measureDetailLogs,
    activeChangeRequests,
  };
}

const actionCreators = {
  loadFacilityConfig: appActions.getFacilitiesConfig,
  loadContractors: appActions.getActiveContractors,
  loadUserDetails: userActions.getUserDetails,
  loadMeasureCategories: appActions.getMeasureCategories,
  loadMeasureTypes: appActions.getMeasureTypes,
  loadMeasures: appActions.getMeasures,
  loadConfig: userActions.getAdminConfig,
  loadFacilities: appActions.getFacilitiesForCustomer,
  updateUserDetails: userActions.updateUserDetails,
  createFacility: appActions.createFacility,
  createApplication: appActions.createApplication,
  updateApplication: appActions.updateApplication,
  setMeasureConfig: appActions.setMeasureConfig,
  createAppMeasure: appActions.createAppMeasure,
  updateAppMeasure: appActions.updateAppMeasure,
  loadAppMeasures: appActions.getAppMeasures,
  deleteAppMeasure: appActions.deleteAppMeasure,
  logout: userActions.logout,
  setMeasureCategory: appActions.setMeasureCategory,
  setMeasureType: appActions.setMeasureType,
  setMeasure: appActions.setMeasure,
  clearMeasureDrawer: appActions.clearMeasureDrawer,
  createDocument: appActions.createAppDocument,
  updateDocument: appActions.updateAppDocument,
  deleteDocument: appActions.deleteAppDocument,
  getAppDocuments: appActions.getAppDocuments,
  getLogs: appActions.getApplicationsStatusLogs,
  getChangeReqLogs: appActions.getApplicationChangeRequests,
  getMeasureDetailLogs: appActions.getMeasureDetailLogs,
  setActiveProgram: userActions.setActiveProgram,
};

export default connect(mapState, actionCreators)(AppSummary);
