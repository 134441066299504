import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import InfoVector from '../../../images/InfoVector.svg';
import { withStyles } from '@material-ui/core/styles';


const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#aac3a0',
        color: '#FFF',
        maxWidth: 220
    },
    arrow: {
        color: '#aac3a0',
    }
}))(Tooltip);

const CustomTooltip = (props) => {
    return (
        <HtmlTooltip
            title={<React.Fragment>
                {props.helpText}
            </React.Fragment>}
            placement={props.position || 'right'} arrow>
            <img src={InfoVector} alt='Info' />
        </HtmlTooltip>
    );
}

export default CustomTooltip;