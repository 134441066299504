import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../../redux/actions/userActions";
import "./CESApplicationReview.css";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import { AuthContext } from "../../../../firebaseAuthContext";
import { utils } from "../../../../helpers/utils";
import { applicationService } from "../../../../services/applicationService";
import Loader from "../../../Common/Loader/Loader";
import ResponsiveTable from "../../../Common/ResponsiveTable/ResponsiveTable";
import RedInfoVector from "../../../../images/RedInfoIcon.svg";
import TooltipInfo from "../../../Common/TooltipInfo/TooltipInfo";

class ApplicationReview extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      applicationObj: {},
      estimatedCategoryCESBonus: {},
      measureCategoriesCES: [],
      measureTypesCES: [],
      newCalculations: [],
      reviewLoading: false,
      measureList: props.measureList || [],
      readyForApprove: false,
      approvedSuccessfully: false,
      confirmingApproval: false,
      approvalMessage: "",
      totalOfPostlnvoices: 0,
      differenceAmountPost: 0,
      explanationForDifference: "",
      calculationsDone: false,
      calculating: false,
      allCalculatedValues: {},
      qaQcTypes: [
        { label: "N/A", value: "N/A" },
        { label: "Site Visit", value: "Site Visit" },
        { label: "Sampling", value: "Sampling" },
      ],
      qaQcType: {},
      // approvedByBERA: false,
      // fundingApprovedByBERA: ''
    };
  }

  handleApprovalMessageChange = (name, value) => {
    this.setState({
      approvalMessage: value,
    });
  };

  handleQaQcType = (val) => {
    this.setState({
      qaQcType: val,
    });
  };

  handleAdditionalReview = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    const MCADminConfigCESObj = await utils.getAdminConfigDoc("measureCategoriesCES");
    const measureTypes = await utils.getCollectionAsArray("measureTypes");
    applicationService.getApplication(this.props.selectedAppId).then((appObj) => {
      this.setState({
        applicationObj: { ...appObj },
        measureList: this.props.measureList || [],
        measureCategoriesCES: MCADminConfigCESObj,
        measureTypesCES: measureTypes,
        estimatedCategoryCESBonus: { ...appObj["Estimated Category CES Bonus"] },
      });
    });
    if (this.props.appData.stage === "Post-Project") {
      applicationService.getApplicationReview(this.props.selectedAppId).then((data) => {
        this.setState({
          appReviewData: data,
          measureList: this.props.measureList || [],
          measureCategoriesCES: MCADminConfigCESObj,
          measureTypesCES: measureTypes,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.measureList !== this.props.measureList) {
      this.setState({
        measureList: this.props.measureList || [],
      });
    }
  }

  handleChangeReviewerIncentive = (name, value) => {
    let { measureList } = this.state;
    let fIndex = measureList.findIndex((f) => f.id === name);
    if (fIndex > -1) measureList[fIndex]["Reviewer Estimated Incentive"] = value;
    this.setState({ measureList });
  };
  handleChangePostProjectReviewerIncentive = (name, value) => {
    let { measureList } = this.state;
    let fIndex = measureList.findIndex((f) => f.id === name);
    if (fIndex > -1) measureList[fIndex]["PostProjectReviewerApprovedIncentive"] = value;
    this.setState({ measureList });
  };
  handleChangeApprovedByBERA = (name, approvedByBERA) => {
    let updateObj = {
      approvedByBERA,
    };
    if (!approvedByBERA) {
      updateObj["fundingApprovedByBERA"] = "";
    }
    this.setState(updateObj);
  };

  handleChangeFundingApprovedByBERA = (name, fundingApprovedByBERA) => {
    this.setState({ fundingApprovedByBERA });
  };

  confirmApproval = () => {
    var el = document.getElementById("myel");
    el.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.setState({
      confirmingApproval: !this.state.confirmingApproval,
    });
  };

  validateReviewerIncentive = (measures) => {
    const { appData } = this.props;
    let paidMeasureIDs = utils.isNullOrEmpty(appData)
      ? []
      : Object.keys(appData.linkedESBApplications).map((key) => {
        let paidoutStatus = ["Incentive Approved", "Incentive Payment in Progress", "Incentive Payment Completed"];
        if (paidoutStatus.includes(appData.linkedESBApplications[key].status)) {
          return appData.linkedESBApplications[key].measureIds.map((id) => id);
        }
      });
    paidMeasureIDs = paidMeasureIDs.flatMap((ele) => ele);
    let readyForApprove = true;
    let noOfInvalidFields = 0;
    for (let item of measures) {
      if (appData.stage === "Post-Project") {
        if (!item["PostProjectReviewerApprovedIncentive"]) {
          if (!paidMeasureIDs.includes(item.id)) {
            noOfInvalidFields = noOfInvalidFields + 1;
          }
        }
      } else {
        if (!item["Reviewer Estimated Incentive"]) {
          if (!paidMeasureIDs.includes(item.id)) {
            noOfInvalidFields = noOfInvalidFields + 1;
          }
        }
      }
    }
    if (!noOfInvalidFields) {
      return readyForApprove;
    }
  };

  validatePostProjectReviewerIncentive = (measures) => {
    let readyForApprove = true;
    for (let item of measures) {
      if (!item.PostProjectReviewerApprovedIncentive) readyForApprove = false;
    }
    return readyForApprove;
  };
  validateFundingApprovedByBERA = (measures) => {
    let readyForApprove = false;
    let { approvedByBERA, fundingApprovedByBERA } = this.state;
    if (approvedByBERA) {
      if (!fundingApprovedByBERA) readyForApprove = true;
    }
    return readyForApprove;
  };

  sumOfReviewerIncentive = (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      if (item["Reviewer Estimated Incentive"]) ans += parseFloat(item["Reviewer Estimated Incentive"]);
    }
    return ans.toFixed(2);
  };
  sumOfPostprojectReviewerIncentive = (measures) => {
    let ans = 0.0;
    for (let item of measures) {
      if (item.PostProjectReviewerApprovedIncentive) ans += parseFloat(item.PostProjectReviewerApprovedIncentive);
    }
    return ans.toFixed(2);
  };

  approveApplication = () => {
    let { allCalculatedValues, measureList } = this.state;
    let { adminUserInfo, appData } = this.props;
    let paidMeasureIDs = utils.isNullOrEmpty(appData)
      ? []
      : Object.keys(appData.linkedESBApplications).map((key) => {
          let paidoutStatus = ["Incentive Approved", "Incentive Payment in Progress", "Incentive Payment Completed"];
          if (paidoutStatus.includes(appData.linkedESBApplications[key].status)) {
            return appData.linkedESBApplications[key].measureIds.map((id) => id);
          }
        });
    paidMeasureIDs = paidMeasureIDs.flatMap((ele) => ele);
    let measuresReviewerEstimatedIncentive = {},
      applicationId = "";
    measureList.map((measure) => {
      applicationId = measure.applicationId;
      measuresReviewerEstimatedIncentive[measure.id] = paidMeasureIDs.includes(measure.id)
        ? `${utils.getTEMI(measure)}`
        : measure["Reviewer Estimated Incentive"]
        ? parseFloat(measure["Reviewer Estimated Incentive"])
        : 0.0;
    });
    applicationService
      .createApplicationReview(this.props.selectedAppId, {
        totalReviewerEstimatedIncentive: parseFloat(this.sumOfReviewerIncentive(measureList)),
        ...allCalculatedValues,
        reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
        reviewStatus: "Application Draft Approved",
        informationRequest: this.state.approvalMessage,
        measuresReviewerEstimatedIncentive,
      })
      .then((res) => {
        this.setState(
          {
            approvedSuccessfully: true,
          },
          () => {
            setTimeout(() => {
              this.props.closeActionModal(true);
              this.setState({
                measureList: [],
              });
            }, 5000);
          }
        );
      });
  };
  validateAdditionalReviewer = () => {
    const { totalOfPostlnvoices, explanationForDifference } = this.state;
    const { appData } = this.props;
    if (totalOfPostlnvoices) {
      let difference = appData["Actual Project Cost"] - totalOfPostlnvoices;
      if (difference) {
        if (utils.cleanHTMLText(explanationForDifference)) {
          return false;
        } else return true;
      }
    } else return true;
  };
  approvePostProjectApplication = () => {
    let { measureList, totalOfPostlnvoices, explanationForDifference, qaQcType, allCalculatedValues } = this.state;
    let { adminUserInfo, appData } = this.props;
    let paidMeasureIDs = utils.isNullOrEmpty(appData)
      ? []
      : Object.keys(appData.linkedESBApplications).map((key) => {
          let paidoutStatus = ["Incentive Approved", "Incentive Payment in Progress", "Incentive Payment Completed"];
          if (paidoutStatus.includes(appData.linkedESBApplications[key].status)) {
            return appData.linkedESBApplications[key].measureIds.map((id) => id);
          }
        });
    paidMeasureIDs = paidMeasureIDs.flatMap((ele) => ele);

    let measuresPostProjectReviewerApprovedIncentive = {},
      applicationId = "";
    measureList.map((measure) => {
      applicationId = measure.applicationId;
      measuresPostProjectReviewerApprovedIncentive[measure.id] = paidMeasureIDs.includes(measure.id)
        ? `${utils.getApplicableTEMI(measure)}`
        : measure["PostProjectReviewerApprovedIncentive"]
        ? parseFloat(measure["PostProjectReviewerApprovedIncentive"])
        : 0.0;
    });

    applicationService
      .createPostProjectApplicationReview(this.props.selectedAppId, {
        totalReviewerEstimatedIncentive: parseFloat(this.sumOfPostprojectReviewerIncentive(measureList)),
        ...allCalculatedValues,
        totalOfPostlnvoices: totalOfPostlnvoices,
        explanationForDifference: explanationForDifference,
        differenceAmountPost: parseFloat(appData["Actual Project Cost"]) - parseFloat(totalOfPostlnvoices),
        qaQcType: qaQcType,
        // approvedByBERA: approvedByBERA,
        // fundingApprovedByBERA: fundingApprovedByBERA,
        reviewer: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
        reviewStatus: "Incentive Recommended",
        informationRequest: this.state.approvalMessage,
        measuresPostProjectReviewerApprovedIncentive,
      })
      .then((res) => {
        this.setState(
          {
            approvedSuccessfully: true,
          },
          () => {
            setTimeout(() => {
              this.props.closeActionModal(true);
              this.setState({
                measureList: [],
              });
            }, 5000);
          }
        );
      });
  };
  getAppNo = (measure, ESBAppDetails) => {
    let num = "";
    ESBAppDetails.map((ele) => {
      if (ele.measureIds.includes(measure.id)) {
        num = ele.appNo;
      }
    });
    return num;
  };
  renderMeasureDetails = () => {
    const { measureList, confirmingApproval, appReviewData, applicationObj, calculationsDone } = this.state;
    const { appData } = this.props;
    let ESBAppDetails = [];
    let paidMeasureIDs = utils.isNullOrEmpty(appData)
      ? []
      : Object.keys(appData.linkedESBApplications).map((key) => {
          let paidoutStatus = ["Incentive Approved", "Incentive Payment in Progress", "Incentive Payment Completed"];
          let obj = { appNo: appData.linkedESBApplications[key].appNo, measureIds: appData.linkedESBApplications[key].measureIds };
          ESBAppDetails.push(obj);
          if (paidoutStatus.includes(appData.linkedESBApplications[key].status)) {
            return appData.linkedESBApplications[key].measureIds.map((id) => id);
          }
        });
    paidMeasureIDs = paidMeasureIDs.flatMap((ele) => ele);
    var additional_class = "";
    if (appData.stage === "Post-Project") {
      additional_class = "post-project";
    }
    return measureList.length > 0 ? (
      <React.Fragment>
        <div className="measures-application-review-container">
          <div className={`contractor-review-header ${additional_class}`}>
            <div className="review-detail">Measure</div>
            <div className="review-detail">Application ID</div>
            <div className="review-detail">Quantity</div>
            <div className="review-detail">Estimated Emissions Reduction (Lifetime)</div>
            {appData.stage === "Post-Project" ? (
              <React.Fragment>
                <div className="review-detail">Pre-Approved Incentive</div>
                <div className="review-detail">Applicable Incentive</div>
                <div className="review-detail">Approved Incentive</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="review-detail">Estimated Incentive</div>
                <div className="review-detail">Reviewer Estimated Incentive</div>
              </React.Fragment>
            )}
          </div>
          <div className="contractor-review-table-left">
            <div className="contractor-review-inner-container">
              {measureList.map((measure) => (
                <div className={`contractor-review-details ${additional_class}`}>
                  <div className="review-detail">
                    <label>Measure</label>
                    <span>{measure.measure.label}</span>
                  </div>
                  <div className="review-detail">
                    <label>Application ID</label>
                    <span>{measure.applicationId ? `ESB-${this.getAppNo(measure, ESBAppDetails)}` : `CES-${appData.appNo}`}</span>
                  </div>
                  <div className="review-detail">
                    <label>Quantity</label>
                    <span>{utils.formatNumberWithCommas(measure.Quantity)}</span>
                  </div>
                  <div className="review-detail">
                    <label>Estimated Emissions Reduction (Lifetime)</label>
                    <span>{utils.formatNumberWithCommas(utils.getTEM(measure))} tonnes</span>
                  </div>
                  <div className="review-detail">
                    <label>{appData.stage !== "Post-Project" ? "Estimated Incentive" : "Pre-Approved Incentive"}</label>
                    {appData.stage === "Post-Project" ? (
                      <span>
                        {`$${
                          (appReviewData && appReviewData.measuresReviewerEstimatedIncentive
                            ? appReviewData.measuresReviewerEstimatedIncentive[measure.id]
                            : 0.0) || 0.0
                        }`}
                      </span>
                    ) : (
                      <span>{`$${utils.getTEMI(measure)}`}</span>
                    )}
                  </div>
                  {appData.stage === "Post-Project" ? (
                    <div className="review-detail">
                      <label>Applicable Incentive</label>
                      <span>{`$${utils.getApplicableTEMI(measure)}`}</span>
                    </div>
                  ) : (
                    <div className="review-detail">
                      <label>Reviewer Estimated Incentive</label>
                      {calculationsDone || confirmingApproval ? (
                        <span>
                          {utils.formatAsCAD(
                            paidMeasureIDs.includes(measure.id)
                              ? utils.getTEMI(measure)
                              : measure["Reviewer Estimated Incentive"]
                              ? measure["Reviewer Estimated Incentive"]
                              : 0.0
                          )}
                        </span>
                      ) : (
                        <Input
                          type="dollar"
                          value={
                            paidMeasureIDs.includes(measure.id)
                              ? utils.getTEMI(measure)
                              : measure["Reviewer Estimated Incentive"]
                              ? measure["Reviewer Estimated Incentive"]
                              : 0.0
                          }
                          name={`${measure.id}`}
                          disabled={paidMeasureIDs.includes(measure.id)}
                          isAllowed={(obj) => {
                            let { value } = obj;
                            let estimatedValue = utils.getTEMI(measure);
                            if (utils.isNullOrEmpty(value)) {
                              return true;
                            }
                            let valid = true;
                            valid = parseFloat(value) <= parseFloat(estimatedValue);
                            if (valid) {
                              let tempMeasureList = JSON.parse(JSON.stringify(measureList));
                              let fIndex = tempMeasureList.findIndex((f) => f.id === measure.id);
                              if (fIndex > -1) tempMeasureList[fIndex]["Reviewer Estimated Incentive"] = value;
                              // let newEstimatedSum = parseFloat(this.sumOfReviewerIncentive(tempMeasureList));
                              // if (applicationObj && applicationObj.program === "esb" && newEstimatedSum > 250000) {
                              //   valid = false;
                              // }
                            }
                            return valid;
                          }}
                          onChange={(name, value) => this.handleChangeReviewerIncentive(measure.id, value)}
                        />
                      )}
                    </div>
                  )}
                  {appData.stage !== "Post-Project" ? (
                    ""
                  ) : (
                    <div className="review-detail">
                      <label>Approved Incentive</label>
                      {calculationsDone || confirmingApproval ? (
                        <span>
                          {utils.formatAsCAD(
                            paidMeasureIDs.includes(measure.id)
                              ? utils.getApplicableTEMI(measure)
                              : measure["PostProjectReviewerApprovedIncentive"]
                              ? measure["PostProjectReviewerApprovedIncentive"]
                              : 0.0
                          )}
                        </span>
                      ) : (
                        <Input
                          type="dollar"
                          value={
                            paidMeasureIDs.includes(measure.id)
                              ? utils.getApplicableTEMI(measure)
                              : measure["PostProjectReviewerApprovedIncentive"]
                              ? measure["PostProjectReviewerApprovedIncentive"]
                              : 0.0
                          }
                          name={`${measure.id}`}
                          disabled={paidMeasureIDs.includes(measure.id)}
                          isAllowed={(obj) => {
                            let { value } = obj;
                            let estimatedValue = utils.getApplicableTEMI(measure);
                            if (utils.isNullOrEmpty(value)) {
                              return true;
                            }
                            let valid = true;
                            valid = parseFloat(value) <= parseFloat(estimatedValue);
                            if (valid) {
                              let tempMeasureList = JSON.parse(JSON.stringify(measureList));
                              let fIndex = tempMeasureList.findIndex((f) => f.id === measure.id);
                              if (fIndex > -1) tempMeasureList[fIndex]["PostProjectReviewerApprovedIncentive"] = value;
                              // let newEstimatedSum = parseFloat(this.sumOfPostprojectReviewerIncentive(tempMeasureList));
                              // if (applicationObj && applicationObj.program === "esb" && newEstimatedSum > 250000) {
                              //   valid = false;
                              // } else if (applicationObj && applicationObj.program === "speed" && newEstimatedSum > 1000000) {
                              //   valid = false;
                              // }
                            }
                            return valid;
                          }}
                          onChange={(name, value) => this.handleChangePostProjectReviewerIncentive(measure.id, value)}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="app-review-totals-inner-container">
            <div className={`app-review-totals ${additional_class}`}>
              <div className="review-detail mobile-total">Total</div>
              <div className="review-detail"></div>
              <div className="review-detail"></div>
              <div className="review-detail">
                <label>Estimated Emissions Reduction (Lifetime)</label>
                <span>{utils.formatNumberWithCommas(utils.sumOfTEMs(measureList))} tonnes</span>
              </div>
              <div className="review-detail">
                <label>{appData.stage !== "Post-Project" ? "Estimated Incentive" : "Pre-Approved Incentive"}</label>
                {appData.stage === "Post-Project" ? (
                  <span>{utils.formatAsCAD(appData["Reviewer Estimated Incentive"] || "0")}</span>
                ) : (
                  <span>${utils.sumOfTEMIs(measureList, applicationObj ? applicationObj.program : "")}</span>
                )}
              </div>
              {appData.stage === "Post-Project" ? (
                <div className="review-detail">
                  <label>Applicable Incentive</label>
                  <span>{utils.formatAsCAD(appData["Post-Project Applicable Incentive"] || "0")}</span>
                </div>
              ) : (
                ""
              )}
              <div className="review-detail">
                {/* <label> {appData.stage === "Post-Project" ? "Approved Incentive" : "Reviewer Estimated Incentive"}</label>
                <span>
                  {appData.stage === "Post-Project" ? utils.formatAsCAD(this.sumOfPostprojectReviewerIncentive(measureList)) : ""}
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      ""
    );
  };

  renderBERADetails = () => {
    const { approvedByBERA, fundingApprovedByBERA, confirmingApproval } = this.state;
    const { appData } = this.props;
    return (
      <div className="measures-application-review-container">
        <div className="contractor-review-header post-project">
          <div className="review-detail">BERA Funding Review</div>
          <div className="review-detail">Review</div>
          <div className="review-detail">Disclosed Sources</div>
          <div className="review-detail">Disclosed Funding</div>
        </div>
        <div className="contractor-review-table-left">
          <div className="contractor-review-inner-container">
            <div className="contractor-review-details post-project">
              <div className="review-detail">
                <label>BERA Funding Review</label>
                <span>Has this project been approved by BERA for funding?</span>
              </div>
              <div className="review-detail">
                <label>Review</label>
                {confirmingApproval ? (
                  <span>{approvedByBERA ? "Yes" : "No"}</span>
                ) : (
                  <Input
                    type="switch"
                    checked={approvedByBERA}
                    name={`approvedByBERA`}
                    disabled={confirmingApproval}
                    onChange={this.handleChangeApprovedByBERA}
                  />
                )}
              </div>
              <div className="review-detail">
                <label>Disclosed Sources</label>
                <span>{appData.sourcesOfAllOtherFunding ? appData.sourcesOfAllOtherFunding : "N/A"}</span>
              </div>
              <div className="review-detail">
                <label>Disclosed Funding</label>
                <span>{utils.formatAsCAD(appData.totalAmountOfAllOtherFunding)}</span>
              </div>
            </div>
          </div>
          <div className="contractor-review-inner-container bera-funding">
            {approvedByBERA && (
              <div className="contractor-review-details post-project">
                <div className="review-detail">What is the amount of funding approved by BERA?</div>
                <div className="review-detail">
                  {confirmingApproval ? (
                    <span>{utils.formatAsCAD(fundingApprovedByBERA)}</span>
                  ) : (
                    <Input
                      type="dollar"
                      value={fundingApprovedByBERA}
                      name={`fundingApprovedByBERA`}
                      disabled={confirmingApproval}
                      onChange={(name, value) => this.handleChangeFundingApprovedByBERA(name, value)}
                    />
                  )}
                </div>
                <div className="review-detail"></div>
                <div className="review-detail"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderAdditionalReview = () => {
    const { totalOfPostlnvoices, explanationForDifference, confirmingApproval } = this.state;
    const { appData } = this.props;
    return (
      <div className="measures-application-review-container">
        <div className="contractor-review-header post-project additional-review">
          <div className="review-detail">Additional Review</div>
          <div className="review-detail">Response</div>
          <div className="review-detail">Actual Project Cost</div>
          <div className="review-detail">Difference</div>
        </div>
        <div className="contractor-review-table-left">
          <div className="contractor-review-inner-container">
            <div className="contractor-review-details post-project additional-review">
              <div className="review-detail">
                <label>Additional Review</label>
                <span>What is the total of all the invoice amounts?</span>
              </div>
              <div className="review-detail">
                <label>Response</label>
                {confirmingApproval ? (
                  <span>{totalOfPostlnvoices}</span>
                ) : (
                  <Input type="dollar" name={`totalOfPostlnvoices`} value={totalOfPostlnvoices} onChange={this.handleAdditionalReview} />
                )}
              </div>
              <div className="review-detail">
                <label>Actual Project Cost</label>
                <span>{utils.formatAsCAD(appData["Actual Project Cost"])}</span>
              </div>
              <div className="review-detail">
                <label>Difference</label>
                <span>
                  {totalOfPostlnvoices
                    ? `${parseFloat(appData["Actual Project Cost"]) < parseFloat(totalOfPostlnvoices) ? "-" : ""}${utils.formatAsCAD(
                        Math.abs(parseFloat(appData["Actual Project Cost"]) - parseFloat(totalOfPostlnvoices)).toFixed(2)
                      )}`
                    : "Enter Invoice Value"}
                </span>
              </div>
            </div>
          </div>
          <div className="contractor-review-inner-container bera-funding">
            {totalOfPostlnvoices !== 0 && appData["Actual Project Cost"] - totalOfPostlnvoices !== 0 && (
              <div className="contractor-review-details post-project">
                <div className="review-detail">Explanation for Difference</div>
                <div className="review-detail explanation-text">
                  {!confirmingApproval ? (
                    <Input
                      type="richtext"
                      value={explanationForDifference}
                      name={`explanationForDifference`}
                      disabled={confirmingApproval}
                      onChange={(name, value) => this.handleAdditionalReview(name, value)}
                    />
                  ) : (
                    <div className="review-notes-text" dangerouslySetInnerHTML={{ __html: explanationForDifference }} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  getCalculationTable = () => {
    const { applicationObj, allCalculatedValues } = this.state;
    let preApprovalCategoryBonus = allCalculatedValues["Pre-Approval Category Bonus"];
    let nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(preApprovalCategoryBonus)
      ? {}
      : Object.values(preApprovalCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    let postApprovedCategoryBonus = "";
    if (applicationObj.stage === "Post-Project") {
      postApprovedCategoryBonus = allCalculatedValues["Approved Category Bonus"];
      nonBonusEligibileMeasureCategory = utils.isNullOrEmpty(postApprovedCategoryBonus)
        ? {}
        : Object.values(postApprovedCategoryBonus).filter((F) => F["isMaxCategoryIncentive"])[0];
    }
    return (
      <>
        {applicationObj.stage === "Post-Project" ? (
          <div className="responsive-table-container">
            <div className="responsive-table-row responsive-table-header">
              <div className="responsive-table-details">Category Name</div>
              <div className="responsive-table-details">{"Pre-Approved Category Incentive ($)"}</div>
              <div className="responsive-table-details">{"Pre-Approved Category Bonus ($)"}</div>
              <div className="responsive-table-details">{"Approved Category Incentive ($)"}</div>
              <div className="responsive-table-details">{"Final Bonus Eligibility"}</div>
              <div className="responsive-table-details">{"Final Eligible Category Incentive ($)"}</div>
              <div className="responsive-table-details">{"Approved Category Bonus ($)"}</div>
            </div>
            {Object.values(postApprovedCategoryBonus).map((bonusEle, index) => (
              <div className={`responsive-table-row`} key={index}>
                <div className="responsive-table-details">
                  <label>Category Name</label>
                  <span>{bonusEle.category}</span>
                </div>
                <div className="responsive-table-details">
                  <label>{"Pre-Approved Category Incentive ($)"}</label>
                  <span>
                    {!applicationObj["Post-Project Applicable Category CES Bonus"][bonusEle.category].categoryIncentiveCES
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(
                          applicationObj["Post-Project Applicable Category CES Bonus"][bonusEle.category].categoryIncentiveCES
                        )}`}
                  </span>
                </div>
                <div className={`responsive-table-details`}>
                  <label>{"Pre-Approved Category Bonus ($)"}</label>
                  <span>
                    {!applicationObj["Post-Project Applicable Category CES Bonus"][bonusEle.category].applicableCategoryCESBonus
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(
                          applicationObj["Post-Project Applicable Category CES Bonus"][bonusEle.category].applicableCategoryCESBonus
                        )}`}
                  </span>
                </div>
                <div
                  className={`responsive-table-details ${
                    bonusEle.isOnlyPaidOutIncentive
                      ? "not-eligible"
                      : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                        nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                      ? "not-eligible"
                      : bonusEle.sumOfSolarPVIncentive > 0
                      ? ""
                      : bonusEle["Final Bonus Eligibility"]
                      ? bonusEle["Final Eligible Category Incentive"] === 0 &&
                        bonusEle["Approved Category Incentive"] > 0 &&
                        bonusEle.sumOfSolarPVIncentive === 0
                        ? "not-eligible"
                        : ""
                      : "not-eligible"
                  }`}
                >
                  <label>{"Approved Category Incentive ($)"}</label>
                  <span>
                    {!bonusEle["Approved Category Incentive"]
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(bonusEle["Approved Category Incentive"])}`}
                  </span>
                  {bonusEle.isOnlyPaidOutIncentive ? (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                      />
                    </div>
                  ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                    nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                      />
                    </div>
                  ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                    ""
                  ) : bonusEle["Final Bonus Eligibility"] ? (
                    ""
                  ) : (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                      />
                    </div>
                  )}
                </div>
                <div className="responsive-table-details">
                  <label>Final Bonus Eligibility</label>
                  <span>{`${bonusEle["Final Bonus Eligibility"] ? "Yes" : "No"}`}</span>
                </div>
                <div
                  className={`responsive-table-details ${
                    bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? "not-eligible" : ""
                  }`}
                >
                  <label>{"Final Eligible Category Incentive ($)"}</label>
                  <span>
                    {!bonusEle["Final Eligible Category Incentive"]
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(bonusEle["Final Eligible Category Incentive"])}`}
                  </span>
                  {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="responsive-table-details">
                  <label>{"Approved Category Bonus ($)"}</label>
                  <span>
                    {!bonusEle["Approved Category Bonus"] ? 0 : `${utils.formatNumberWithOnlyCommas(bonusEle["Approved Category Bonus"])}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="responsive-table-container">
            <div className="responsive-table-row responsive-table-header">
              <div className="responsive-table-details">Category Name</div>
              <div className="responsive-table-details">{"Category Incentive ($)"}</div>
              <div className="responsive-table-details">{"Category Bonus ($)"}</div>
              <div className="responsive-table-details">{"Pre-Approval Category Incentive ($)"}</div>
              <div className="responsive-table-details">{"Pre-Approval Bonus Eligibility"}</div>
              <div className="responsive-table-details">{"Pre-Approval Eligible Category Incentive ($)"}</div>
              <div className="responsive-table-details">{"Pre-Approval Category Bonus ($)"}</div>
            </div>
            {Object.values(preApprovalCategoryBonus).map((bonusEle, index) => (
              <div className={`responsive-table-row`} key={index}>
                <div className="responsive-table-details">
                  <label>Category Name</label>
                  <span>{bonusEle.category}</span>
                </div>
                <div className="responsive-table-details">
                  <label>{"Category Incentive ($)"}</label>
                  <span>
                    {!applicationObj["Estimated Category CES Bonus"][bonusEle.category].categoryIncentiveCES
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(
                          applicationObj["Estimated Category CES Bonus"][bonusEle.category].categoryIncentiveCES
                        )}`}
                  </span>
                </div>
                <div className={`responsive-table-details`}>
                  <label>{"Category Bonus ($)"}</label>
                  <span>
                    {!applicationObj["Estimated Category CES Bonus"][bonusEle.category].estimatedCategoryCESBonus
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(
                          applicationObj["Estimated Category CES Bonus"][bonusEle.category].estimatedCategoryCESBonus
                        )}`}
                  </span>
                </div>
                <div
                  className={`responsive-table-details ${
                    bonusEle.isOnlyPaidOutIncentive
                      ? "not-eligible"
                      : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                        nonBonusEligibileMeasureCategory["category"] === bonusEle.category
                      ? "not-eligible"
                      : bonusEle.sumOfSolarPVIncentive > 0
                      ? ""
                      : bonusEle["Pre-Approval Bonus Eligibility"]
                      ? bonusEle["Pre-Approval Eligible Category Incentive"] === 0 &&
                        bonusEle["Pre-Approval Category Incentive"] > 0 &&
                        bonusEle.sumOfSolarPVIncentive === 0
                        ? "not-eligible"
                        : ""
                      : "not-eligible"
                  }`}
                >
                  <label>{"Pre-Approval Category Incentive ($)"}</label>
                  <span>
                    {!bonusEle["Pre-Approval Category Incentive"]
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Category Incentive"])}`}
                  </span>
                  {bonusEle.isOnlyPaidOutIncentive ? (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`The measures within this category are all paid out via ESB Program and are not eligible for an Incentive or Bonus.`}
                      />
                    </div>
                  ) : !utils.isNullOrEmpty(nonBonusEligibileMeasureCategory) &&
                    nonBonusEligibileMeasureCategory["category"] === bonusEle.category ? (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`${bonusEle.category} category has the highest incentive amongst all categories within this application and hence, is eligible to only receive the incentive without a bonus.`}
                      />
                    </div>
                  ) : bonusEle.sumOfSolarPVIncentive > 0 ? (
                    ""
                  ) : bonusEle["Pre-Approval Bonus Eligibility"] ? (
                    ""
                  ) : (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`This ${bonusEle.category} category is currently not eligible because it does not meet the CES minimum incentive requirements.`}
                      />
                    </div>
                  )}
                </div>
                <div className="responsive-table-details">
                  <label>Pre-Approval Bonus Eligibility</label>
                  <span>{`${bonusEle["Pre-Approval Bonus Eligibility"] ? "Yes" : "No"}`}</span>
                </div>
                <div
                  className={`responsive-table-details ${
                    bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? "not-eligible" : ""
                  }`}
                >
                  <label>{"Pre-Approval Eligible Category Incentive ($)"}</label>
                  <span>
                    {!bonusEle["Pre-Approval Eligible Category Incentive"]
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Eligible Category Incentive"])}`}
                  </span>
                  {bonusEle.category === "On-Site Generation and Renewables" && bonusEle.sumOfSolarPVIncentive > 0 ? (
                    <div className="info-icon custom-info-height">
                      <TooltipInfo
                        customIcon={RedInfoVector}
                        dynamicPosition={true}
                        helpText={`Solar PV measures are not eligible for a bonus within CES.`}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="responsive-table-details">
                  <label>{"Pre-Approval Category Bonus ($)"}</label>
                  <span>
                    {!bonusEle["Pre-Approval Category Bonus"]
                      ? 0
                      : `${utils.formatNumberWithOnlyCommas(bonusEle["Pre-Approval Category Bonus"])}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  getTotalProjectCost = () => {
    const { applicationObj, measureList } = this.state;
    let cost = 0;
    measureList.map((measure) => {
      let measureTotalCost = 0;
      if (applicationObj.stage === "Post-Project") {
        measureTotalCost = +(measure["Actual Total Cost"] ? measure["Actual Total Cost"] : measure["Total Cost"]);
      } else {
        measureTotalCost = +measure["Total Cost"];
      }
      cost = cost + measureTotalCost;
    });
    return cost;
  };

  getInctiveTable = () => {
    const { applicationObj, allCalculatedValues } = this.state;
    return (
      <>
        {applicationObj.stage === "Post-Project" ? (
          <div className="responsive-table-container">
            <div className="responsive-table-row responsive-table-header">
              <div className="responsive-table-details">{"Gross Approved Incentive ($)"}</div>
              <div className="responsive-table-details">{"Total Approved Bonus ($)"}</div>
              <div className="responsive-table-details">{"Total Approved Incentive ($)"}</div>
            </div>
            <div className={`responsive-table-row`}>
              <div className="responsive-table-details">
                <label>{"Gross Approved Incentive ($)"}</label>
                <span>
                  {!allCalculatedValues["Gross Approved Incentive"]
                    ? 0
                    : `${utils.formatNumberWithOnlyCommas(allCalculatedValues["Gross Approved Incentive"])}`}
                </span>
              </div>
              <div className="responsive-table-details">
                <label>{"Total Approved Bonus ($)"}</label>
                <span>
                  {!allCalculatedValues["Total Approved Bonus"]
                    ? 0
                    : `${utils.formatNumberWithOnlyCommas(allCalculatedValues["Total Approved Bonus"])}`}
                </span>
              </div>
              <div className="responsive-table-details">
                <label>{"Total Approved Incentive ($)"}</label>
                <span>{utils.formatNumberWithOnlyCommas(allCalculatedValues["PostProjectReviewerApprovedIncentive"])}</span>
                {allCalculatedValues["Post-Project Approved Cap Notice"] ? (
                  <div className="info-icon custom-info-height">
                    <TooltipInfo dynamicPosition={true} helpText={allCalculatedValues["Post-Project Approved Cap Notice"]} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="responsive-table-container">
            <div className="responsive-table-row responsive-table-header">
              <div className="responsive-table-details">{"Gross Pre-Approved Incentive ($)"}</div>
              <div className="responsive-table-details">{"Total Pre-Approved Bonus ($)"}</div>
              <div className="responsive-table-details">{"Total Pre-Approved Incentive ($)"}</div>
            </div>
            <div className={`responsive-table-row`}>
              <div className="responsive-table-details">
                <label>{"Gross Pre-Approved Incentive ($)"}</label>
                <span>
                  {!allCalculatedValues["Gross Pre-Approved Incentive"]
                    ? 0
                    : `${utils.formatNumberWithOnlyCommas(allCalculatedValues["Gross Pre-Approved Incentive"])}`}
                </span>
              </div>
              <div className="responsive-table-details">
                <label>{"Total Pre-Approved Bonus ($)"}</label>
                <span>
                  {!allCalculatedValues["Total Pre-Approved Bonus"]
                    ? 0
                    : `${utils.formatNumberWithOnlyCommas(allCalculatedValues["Total Pre-Approved Bonus"])}`}
                </span>
              </div>
              <div className="responsive-table-details">
                <label>{"Total Pre-Approved Incentive ($)"}</label>
                <span>{utils.formatNumberWithOnlyCommas(allCalculatedValues["Reviewer Estimated Incentive"])}</span>
                {allCalculatedValues["Pre-Project Approved Cap Notice"] ? (
                  <div className="info-icon custom-info-height">
                    <TooltipInfo dynamicPosition={true} helpText={allCalculatedValues["Pre-Project Approved Cap Notice"]} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  calculateAllMeasureIncentives = (id, stage, measureList) => {
    const { appData } = this.props;

    let paidMeasureIDs = utils.isNullOrEmpty(appData)
      ? []
      : Object.keys(appData.linkedESBApplications).map((key) => {
          let paidoutStatus = ["Incentive Approved", "Incentive Payment in Progress", "Incentive Payment Completed"];
          if (paidoutStatus.includes(appData.linkedESBApplications[key].status)) {
            return appData.linkedESBApplications[key].measureIds.map((id) => id);
          }
        });
    paidMeasureIDs = paidMeasureIDs.flatMap((ele) => ele);

    let data = measureList.map((ele) => {
      if (stage === "Post-Project") {
        if (paidMeasureIDs.includes(ele.id)) {
          ele["PostProjectReviewerApprovedIncentive"] = utils.getApplicableTEMI(ele);
        }
      } else {
        if (paidMeasureIDs.includes(ele.id)) {
          ele["Reviewer Estimated Incentive"] = utils.getTEMI(ele);
        }
      }
      return ele;
    });
    this.setState({ calculationsDone: true, calculating: true });
    applicationService.getCESIncentiveBonusCalculations(id, stage, data).then((res) => {
      let allCalculatedValues = res.result;
      this.setState({ calculationsDone: true, allCalculatedValues: allCalculatedValues, calculating: false });
    });
  };

  render() {
    const { appData } = this.props;
    const {
      applicationObj,
      confirmingApproval,
      qaQcTypes,
      qaQcType,
      approvalMessage,
      reviewLoading,
      measureList,
      approvedSuccessfully,
      measureTypesCES,
      measureCategoriesCES,
      calculationsDone,
      calculating,
      allCalculatedValues,
    } = this.state;
    if (reviewLoading) {
      return <p>Loading</p>;
    }
    return (
      <div>
        <div className="app-review-container">
          {calculating ? (
            <Loader />
          ) : appData ? (
            <React.Fragment>
              <div className="section-header">
                <div className="section-top-details">{applicationObj ? `CES-${applicationObj.appNo}` : ""}</div>
                <div className="section-top-details name-section">{applicationObj && applicationObj.companyName}</div>
                <div className="section-top-details">
                  {applicationObj && applicationObj.contractor ? applicationObj.contractor.label : ""}
                </div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Application Approval" }}></h2>
                {appData.stage === "Post-Project" ? (
                  <div className="qaQcType">
                  {this.state.calculationsDone && <Input
                      disabled={confirmingApproval}
                      placeholder="Select QA/QC Type"
                      type="dropdown"
                      name="qaQcType"
                      onChange={this.handleQaQcType}
                      value={utils.isNullOrEmpty(qaQcType) ? undefined : qaQcType}
                      options={qaQcTypes.map((qaQcType) => {
                        return { label: qaQcType.label, value: qaQcType.value };
                      })}
                      isSearchable={false}
                    />}  
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="ces-contractor-review-container" id="myel">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Review Measures" }}></h2>
                <div className={`contractor-review-table ${!confirmingApproval ? "review-mode" : ""}`}>
                  {this.renderMeasureDetails()}
                  <div className="vertical-separator-box">
                    <span className="separator"></span>
                  </div>
                  <div className="contractor-review-table-right">
                    {approvedSuccessfully ? (
                      <p>Application approved successfully!</p>
                    ) : (
                      <p>
                        You are about to approve the application.
                        <br />
                        <br />
                        Please verify the details and the notes and confirm their accuracy
                      </p>
                    )}
                  </div>
                </div>
                {calculationsDone ? (
                  <>
                    <div className="horizontal-bar"></div>
                    <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Calculations" }}></h2>
                    <div className="incentive-table-container">{this.getCalculationTable()}</div>
                    <div className="horizontal-bar"></div>
                    <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Incentives" }}></h2>
                    <div className="incentive-table-container">{this.getInctiveTable()}</div>
                    <div className="horizontal-bar"></div>
                    {appData.stage === "Post-Project" ? (
                      <>
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Additional Review" }}></h2>
                        {/* <div className="contractor-review-table review-mode bera">{this.renderBERADetails()}</div> */}
                        <div className="contractor-review-table review-mode bera">{this.renderAdditionalReview()}</div>
                        <div className="horizontal-bar"></div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="review-notes-container">
                      <label>Notes:</label>
                      {!confirmingApproval ? (
                        <Input
                          disabled={confirmingApproval}
                          type={"richtext"}
                          value={approvalMessage}
                          onChange={this.handleApprovalMessageChange}
                          name="approvalMessage"
                        />
                      ) : (
                        <div className="review-notes-text" dangerouslySetInnerHTML={{ __html: approvalMessage }} />
                      )}
                      <p className="notice-message">
                        <span>*</span> The maximum allowed Reviewer Estimated Incentive per application is $1,000,000
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="review-btn-container">
                {!calculationsDone ? (
                  <Button
                  title={"Calculate Incentives"}
                  className="signup-btn cal"
                  onClick={() => this.calculateAllMeasureIncentives(this.props.selectedAppId, appData.stage, measureList)}
                  disabled={!this.validateReviewerIncentive(measureList)}
                  uppercase
                />
                ) : approvedSuccessfully ? (
                  ""
                ) : (
                  <React.Fragment>
                    {!confirmingApproval ? (
                      <React.Fragment>
                        {appData.stage === "Post-Project" ? (
                          <div className="review-btn-container">
                            <Button
                              title={"Edit Incentives"}
                              className="inversed-btn"
                              onClick={() => this.setState({ calculationsDone: false })}
                              uppercase
                            />
                            <Button
                              title={"Complete Approval"}
                              className="signup-btn"
                              onClick={this.confirmApproval}
                              disabled={
                                allCalculatedValues.meetsCESRequirements ?
                                !utils.cleanHTMLText(approvalMessage) ||
                                this.validateAdditionalReviewer() ||
                                utils.isNullOrEmpty(qaQcType) : true
                              }
                              uppercase
                            />
                          </div>
                        ) : (
                          <div className="review-btn-container">
                            <Button
                              title={"Edit Incentives"}
                              className="inversed-btn"
                              onClick={() => this.setState({ calculationsDone: false })}
                              uppercase
                            />
                            <Button
                              title={"Complete Approval"}
                              className="signup-btn"
                              onClick={this.confirmApproval}
                              disabled={
                                allCalculatedValues.meetsCESRequirements
                                  ? !utils.cleanHTMLText(approvalMessage) || !this.validateReviewerIncentive(measureList)
                                  : true
                              }
                              uppercase
                            />
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {appData.stage === "Post-Project" ? (
                          <div className="review-btn-container">
                            <Button className="inversed-btn" title={"EDIT"} onClick={this.confirmApproval} />
                            <Button
                              title={"Confirm Approval"}
                              className="signup-btn"
                              uppercase
                              onClick={this.approvePostProjectApplication}
                            />
                          </div>
                        ) : (
                          <div className="review-btn-container">
                            <Button className="inversed-btn" title={"EDIT"} onClick={this.confirmApproval} />
                            <Button title={"Confirm Approval"} className="signup-btn" uppercase onClick={this.approveApplication} />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { measureList, applicationMeasuresLoading } = state.application;
  const { adminUserInfo, currentProgram } = state.authentication;
  return { measureList, adminUserInfo, applicationMeasuresLoading };
}

const actionCreators = {
  showModal: userActions.showEditParticipantModal,
};

export default connect(mapState, actionCreators)(ApplicationReview);
