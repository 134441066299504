import React from "react";
import "./Programs.css";
import SearchInput from "../../../../Common/SearchInput/SearchInput";
import { ReactComponent as EditUser } from "../../../../../images/EditUser.svg";
import FilterDropdown from "../../../../Common/FilterDropdown/FilterDropdown";
import DrawerWithDynamicForm from "../../../../Common/DrawerWithDynamicForm/DrawerWithDynamicForm";
import { utils } from "../../../../../helpers/utils";
import { connect } from "react-redux";
import { adminSettingsActions } from "../../../../../redux/actions/adminSettingsActions";
import { userService } from "../../../../../services/userService";
import SkeletonLoading from "../../../../Common/SkeletonLoading/SkeletonLoading";

class Programs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedFilters: [],
      searchInput: null,
      openDrawer: false,
      dynamicForm: {},
      AddProgramJSON: {},
      EditProgramJSON: {},
      programList: props.programList || [],
      type: "add",
    };
  }

  componentDidMount() {
    const { clientList } = this.props;
    let AddProgramJSON = {
      title: "ADD PROGRAM",
      actionButton: {
        title: "ADD PROGRAM",
      },
      fields: [
        {
          label: "Program Name",
          type: "text",
          mandatory: true,
          name: "name",
          placeholder: "Type the organization name",
        },
        {
          label: "Program Display Name",
          type: "text",
          mandatory: true,
          name: "displayName",
          placeholder: "Organization Display Name with Dolphin",
        },
        {
          label: "Assign to Client",
          type: "dropdown",
          mandatory: true,
          name: "client",
          placeholder: "Select a Client",
          options: clientList.map((item) => {
            return { label: item.displayName, value: item.id };
          }),
        },
        {
          label: "Program Start Date",
          type: "date",
          mandatory: true,
          name: "startDate",
        },
        {
          label: "Program End Date",
          type: "date",
          mandatory: true,
          name: "endDate",
        },
        // {
        //     "label": "Program Specific Legalese",
        //     "type": "multiselect-checkbox",
        //     "mandatory": false,
        //     "name": "legalese",
        //     "placeholder": "Select all that are required",
        //     "options": []//TODO: Dynamically Load This
        // },
        {
          label: "Program Logo",
          type: "file",
          mandatory: false,
          name: "programlogo",
        },
      ],
    };

    let EditProgramJSON = {
      title: "EDIT PROGRAM",
      actionButton: {
        title: "UPDATE PROGRAM",
      },
      fields: [
        {
          label: "Program Name",
          type: "text",
          mandatory: true,
          name: "name",
          placeholder: "Type the organization name",
        },
        {
          label: "Program Display Name",
          type: "text",
          mandatory: false,
          name: "displayName",
          placeholder: "Organization Display Name with Dolphin",
        },
        {
          label: "Assign to Client",
          type: "dropdown",
          mandatory: true,
          name: "client",
          placeholder: "Select a Client",
          options: clientList.map((item) => {
            return { label: item.displayName, value: item.id };
          }),
        },
        {
          label: "Program Start Date",
          type: "date",
          mandatory: false,
          name: "startDate",
        },
        {
          label: "Program End Date",
          type: "date",
          mandatory: false,
          name: "endDate",
        },
        {
          label: "Program Logo",
          type: "file",
          maxFiles: 1,
          mandatory: false,
          name: "programlogo",
        },
      ],
      data: {},
    };
    this.setState({
      AddProgramJSON,
      EditProgramJSON,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.programList !== this.props.programList) {
      this.SearchAndFilter(this.state.searchInput, this.state.SelectedFilters);
    }
  }

  upsertProgram = (flag, program) => {
    const { AddProgramJSON, EditProgramJSON } = this.state;
    if (flag === "add") {
      this.setState({
        dynamicForm: AddProgramJSON,
      });
    } else if (flag === "edit") {
      EditProgramJSON.data = program;
      this.setState({
        dynamicForm: EditProgramJSON,
      });
    }
    this.setState({
      openDrawer: true,
      type: flag,
    });
  };

  onFilterChange = (SelectedFilters) => {
    this.SearchAndFilter(this.state.searchInput, SelectedFilters);
  };

  onSearch = (searchInput) => {
    this.SearchAndFilter(searchInput, this.state.SelectedFilters);
  };

  SearchAndFilter = (searchInput, SelectedFilters) => {
    const { programList } = this.props;
    let FilteredList = [];
    if (SelectedFilters.length) {
      this.setState(
        {
          SelectedFilters,
        },
        () => {
          SelectedFilters.map((filter) => {
            let ArrList = [];
            ArrList = programList.filter((program) => {
              return program && program.client.name === filter;
            });
            FilteredList.push(...ArrList);
          });

          if (searchInput) {
            let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
            FilteredList = FilteredList.filter((program) => {
              return (
                program.name.match(SearchPattern) ||
                program.displayName.match(SearchPattern) ||
                (program.client && program.client.name.match(SearchPattern))
              );
            });
          }

          this.setState({
            programList: FilteredList,
            searchInput,
          });
        }
      );
    } else {
      if (searchInput) {
        let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
        FilteredList = programList.filter((program) => {
          return (
            program.name.match(SearchPattern) ||
            program.displayName.match(SearchPattern) ||
            (program.client && program.client.name.match(SearchPattern))
          );
        });
      } else {
        FilteredList = programList;
      }
      this.setState({
        programList: FilteredList,
        SelectedFilters: [],
        searchInput,
      });
    }
  };

  toggleDrawer = (status) => {
    this.setState({
      openDrawer: status,
    });
  };

  handleFormSubmit = (formData) => {
    const { type } = this.state;
    // console.log("props.clientList formData", formData)
    if (type == "edit") {
      userService.updateProgram(formData.id, formData).then((res) => {
        // console.log('updateClient', res)
        this.props.loadPrograms();
      });
    } else {
      userService.createProgram(formData).then(() => {
        this.props.loadPrograms();
      });
    }
  };

  render() {
    const { SelectedFilters, openDrawer, dynamicForm, programList, programsLoading } = this.state;
    const { adminUserInfo } = this.props;
    const FilterOptions = ["ERA"];
    let actions = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions.admin.actions;
    }
    return (
      <div className="program-list-container">
        <DrawerWithDynamicForm
          toggleDrawer={this.toggleDrawer}
          openDrawer={openDrawer}
          className={"admin-panel-drawer program-panel"}
          formJSON={dynamicForm}
          submitForm={this.handleFormSubmit}
        />
        <div className="admin-dashboard-top-bar">
          <div className="admin-dashboard-search">
            <SearchInput placeholder="Search for Clients" onClick={this.onSearch} />
          </div>
          <div className="admin-dashboard-filter">
            <FilterDropdown
              FilterOptions={FilterOptions}
              SelectedFilters={SelectedFilters}
              onChange={this.onFilterChange}
              label={"Filter By Client"}
              onSubmit={this.handleFormSubmit}
            />
          </div>
          {actions["Settings-Create Program"] ? (
            <div className="add-program" onClick={() => this.upsertProgram("add")}>
              Add Program
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="program-details-header program-details-row">
          <div className="program-details">
            <div>Program Name</div>
            <div>Display Name</div>
            <div>Client</div>
            <div>Created Date</div>
            <div></div>
          </div>
        </div>
        <div className="program-details-list">
          {programsLoading ? (
            <SkeletonLoading />
          ) : programList.length ? (
            programList.map((program, i) => (
              <div className="program-details-row" key={`program-${i}`}>
                <div className="program-details">
                  <div>
                    <label>Program Name:</label>
                    <span>{program.name}</span>
                  </div>
                  <div>
                    <label>Display Name:</label>
                    <span>{program.displayName}</span>
                  </div>
                  <div>
                    <label>Client:</label>
                    <span>{program.client.name}</span>
                  </div>
                  <div>
                    <label>Created Date:</label>
                    <span>{utils.formatDate(program.createdAt)}</span>
                  </div>
                  <div>
                    {actions["Settings-Edit Program"] ? (
                      <div className="program-edit-link" onClick={() => this.upsertProgram("edit", program)}>
                        Edit Program
                        <EditUser width="13" height="13" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { adminUserInfo } = state.authentication;
  const { clientList, clientsLoading, programList, programsLoading } = state.settings;
  return { adminUserInfo, programList, programsLoading, clientList, clientsLoading };
}

const actionCreators = {
  loadClients: adminSettingsActions.getClients,
  loadPrograms: adminSettingsActions.getPrograms,
};

export default connect(mapState, actionCreators)(Programs);
