import React from 'react';
import EmptyContainer from '../EmptyContainer/EmptyContainer';
import LoaderComponent from '../Loader/Loader';
import './ResponsiveTable.css';

export default function ResponsiveTable(props) {
  const {headings, rows} = props;
  // console.log(rows)
  return (rows.length ? <div className="responsive-table-container">
  <div className="responsive-table-row responsive-table-header">
  {headings.map(
    (heading,index)=>{
     return <div className="responsive-table-details" key={`res-table-header-${index}`}>{heading.title}</div>
  })}
  </div>
  {rows.map((row, rowIndex)=>{
      return <div className={`responsive-table-row ${row.colorCode}`} key={`responsive-table-row-${rowIndex}`}>
      {props.headings.map(
        (heading,colIndex)=>{
        return <div className="responsive-table-details" key={`responsive-table-col-${rowIndex}-${colIndex}`} >
        <label>{heading.title}</label>
        <span>{row[heading.value] ? row[heading.value] : ""}</span>
      </div>})}
      </div>
  })}
  {props.customFooter ? props.customFooter : ''}
</div> : '');
}