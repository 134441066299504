import React from 'react';
import './PostalCodeInput.css';
import CloseIcon from '../../../images/CloseIcon.svg';
import EditIcon from '../../../images/EditIcon.svg';
class PostalCodeInput extends React.Component{

onEditClick = ()=>{
    const { editable } = this.props;
    this.props.onEdit(!editable);
}

render(){
    const { value, className, placeholder,onKeyDown,onChange, name, disabled, editable } = this.props;
    return <div className={className}>
            <input 
            name={name} 
            onKeyDown={onKeyDown} 
            onChange={(e)=>{
                const { name, value} = e.target;
                onChange(name, value);
            }}
            disabled={!editable}
            className='postal-input' 
            placeholder={placeholder} type={'text'} value={value}/>
            <img className='eye-icon' onClick={this.onEditClick} src={editable ? CloseIcon : EditIcon} alt='show password'/>
        </div>
}

}

export default PostalCodeInput;