import React from 'react';
import './StatusLogs.css';
import Table from '../../../Common/Table/Table';
import EmptyContainer from '../../../Common/EmptyContainer/EmptyContainer';
import { applicationService } from '../../../../services/applicationService';
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
class ApplicationStatusLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        logsLoading : true,
        applicationObj : {}
    }
}
  async componentDidMount() {
    applicationService.getApplication(this.props.applicationId).then((appObj)=>{
        this.setState({
            applicationObj: appObj,
            logsLoading : false
        })
    });
}

  render() {
      const {activeStatusLogs, skipTopBit } = this.props;
      const {applicationObj,logsLoading} = this.state;
      return (
        <div className="status-logs-container">
          {skipTopBit ? '' : <React.Fragment>
            {logsLoading ? '' : 
                    // <div className="section-header">
                    //     <div className="section-top-details">ESB-{applicationObj.appNo}</div>
                    //     <div className="section-top-details name-section">{applicationObj.companyName}</div>
                    //     <div className="section-top-details">{applicationObj.contractor.label}</div>
                    // </div>
                    <ModalAppDataComponent  applicationObj={applicationObj}></ModalAppDataComponent>
            }
                    <div className="section-below-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'Status Logs' }}></h2>
                    </div></React.Fragment>}
          { activeStatusLogs.length ?
              <Table rows={activeStatusLogs} headings={[
                {title:'Changed From', value:'changedFrom'}, 
                {title : 'Changed To', value : 'changedTo'},
                {title :'Changed Date & Time', value : 'createdAt'},
                {title :'Change Notes', value : 'changeNotes'},
                {title :'Status Updated By', value : 'updatedBy'}
                ]}/> 
          : <EmptyContainer>No Logs to Show</EmptyContainer>}
        </div>
      );
    }
}


export default ApplicationStatusLogs;
