import React from "react";
import "./Users.css";
import SearchInput from "../../../../Common/SearchInput/SearchInput";
import Button from "../../../../Common/Button/Button";
import FilterDropdown from "../../../../Common/FilterDropdown/FilterDropdown";
import { ReactComponent as EditUser } from "../../../../../images/EditUser.svg";
import { ReactComponent as ResetPassword } from "../../../../../images/reset-password.svg";
import Modal from "../../../../Common/Modal/Modal";
import AddUser from "./AddUser/AddUser";
import { utils } from "../../../../../helpers/utils";
import { connect } from "react-redux";
import { adminSettingsActions } from "../../../../../redux/actions/adminSettingsActions";
import { userService } from "../../../../../services/userService";
import { AuthContext } from "../../../../../firebaseAuthContext";
import SkeletonLoading from "../../../../Common/SkeletonLoading/SkeletonLoading";
class Users extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      SelectedFilters: [],
      selectedUser: {},
      searchInput: null,
      passwordResetEmailSent: false,
      showPasswordResetModal: false,
      stateUserModalOpen: props.userModalOpen,
      userList: props.userList || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userList !== this.props.userList) {
      this.SearchAndFilter(this.state.searchInput, this.state.SelectedFilters);
    }
  }

  showPasswordResetModal = (selectedUser) => {
    this.setState({
      showPasswordResetModal: true,
      selectedUser,
    });
  };

  editUser = (user) => {
    this.props.showUserModal(!this.state.stateUserModalOpen, "edit", user);
    this.setState({ stateUserModalOpen: !this.state.stateUserModalOpen });
  };

  addUser = () => {
    this.props.showUserModal(!this.state.stateUserModalOpen, "add");
    this.setState({ stateUserModalOpen: !this.state.stateUserModalOpen });
  };

  onFilterChange = (SelectedFilters) => {
    this.SearchAndFilter(this.state.searchInput, SelectedFilters);
  };

  onSearch = (searchInput) => {
    this.SearchAndFilter(searchInput, this.state.SelectedFilters);
  };

  SearchAndFilter = (searchInput, SelectedFilters) => {
    const { userList } = this.props;
    let FilteredList = [];
    if (SelectedFilters.length) {
      this.setState(
        {
          SelectedFilters,
        },
        () => {
          SelectedFilters.map((filter) => {
            let ArrList = [];
            ArrList = userList.filter((user) => {
              return user && user.team === filter;
            });
            FilteredList.push(...ArrList);
          });

          if (searchInput) {
            let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
            FilteredList = FilteredList.filter((user) => {
              return (
                user.firstName.match(SearchPattern) ||
                user.lastName.match(SearchPattern) ||
                user.email.match(SearchPattern) ||
                (user.role && user.role.name.match(SearchPattern))
              );
            });
          }

          this.setState({
            userList: FilteredList,
            searchInput,
          });
        }
      );
    } else {
      if (searchInput) {
        let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
        FilteredList = userList.filter((user) => {
          return (
            (user.firstName && user.firstName.match(SearchPattern)) ||
            (user.lastName && user.lastName.match(SearchPattern)) ||
            (user.email && user.email.match(SearchPattern)) ||
            (user.role && user.role.name.match(SearchPattern))
          );
        });
      } else {
        FilteredList = userList;
      }
      this.setState({
        userList: FilteredList,
        SelectedFilters: [],
        searchInput,
      });
    }
  };

  resetUserPassword = () => {
    userService.resetPassword(this.state.selectedUser.uid).then((sent) => {
      userService.unblockUserAccount({email: this.state.selectedUser.email, program: "" });
      this.setState(
        {
          passwordResetEmailSent: true,
        },
        () => {
          window.setTimeout(() => {
            this.closePasswordResetModal();
          }, 2500);
        }
      );
    });
  };

  closePasswordResetModal = () => {
    this.setState({
      showPasswordResetModal: false,
      passwordResetEmailSent: false,
      selectedUser: {},
    });
  };
  setStateModal = () => {
    this.setState({ stateUserModalOpen: false });
  };
  render() {
    const { SelectedFilters, userList, showPasswordResetModal, passwordResetEmailSent, selectedUser } = this.state;
    const { adminUserInfo, usersLoading, userModalOpen, userModalMode, configData } = this.props;
    const FilterOptions = ["Core Team", "Client", "Contractor"];
    let actions = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions.admin.actions;
    }

    const loggedInUser = this.context.user;
    return (
      <div className="user-list-container">
        {/* The below section is for Success modal for Add Participant */}
        <Modal
          className="admin-modal"
          HeaderText={userModalMode === "add" ? "ADD A USER" : "UPDATE USER"}
          IsOpen={userModalOpen}
          handleCloseModal={() => this.addUser()}
          modalSize={"large"}
        >
          <AddUser actions={actions} handleUserModal={this.setStateModal} />
        </Modal>
        <Modal
          className="admin-modal"
          HeaderText={"RESET PASSWORD"}
          IsOpen={showPasswordResetModal}
          handleCloseModal={this.closePasswordResetModal}
          modalSize={"small"}
        >
          {passwordResetEmailSent ? (
            <p>
              Password successfully reset for <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
            </p>
          ) : (
            <React.Fragment>
              <p>
                You are about to reset the password for <span>{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
              </p>
              <p>
                A new temporary password will be sent via email to <span>{selectedUser.email}</span>
              </p>
              <p>Are you sure you want to reset the credentials for this account?</p>
            </React.Fragment>
          )}
          {!passwordResetEmailSent ? (
            <div className="button-set">
              <Button className="inversed-btn" title={"Go Back"} onClick={this.closePasswordResetModal} uppercase />
              <Button title={"Reset Password"} onClick={this.resetUserPassword} uppercase />
            </div>
          ) : (
            ""
          )}
        </Modal>
        {
          <React.Fragment>
            <div className="admin-dashboard-top-bar">
              <div className="admin-dashboard-search">
                <SearchInput placeholder="Search for a User" onClick={this.onSearch} />
              </div>
              <div className="admin-dashboard-filter">
                <FilterDropdown
                  FilterOptions={FilterOptions}
                  SelectedFilters={SelectedFilters}
                  onChange={this.onFilterChange}
                  label={"Filter By Team"}
                />
              </div>
              {actions["Settings-Create User"] ? (
                <div className="add-user" onClick={() => this.addUser()}>
                  Add User
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="user-details-header user-details-row">
              <div className="user-details">
                <div>User Name</div>
                <div>Team</div>
                <div>Role</div>
                <div>Created Date</div>
                <div>Mobile Number</div>
                <div>Email Id</div>
                <div></div>
              </div>
            </div>
            <div className="user-details-list">
              {usersLoading ? (
                <SkeletonLoading />
              ) : userList.length ? (
                userList.map((user, i) => (
                  <div className={`user-details-row ${user.isBlocked ? "blocked" : "" }`} key={`user-${i}`}>
                    <div className="user-details">
                      <div>
                        <label>User Name:</label>
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                      </div>
                      <div>
                        <label>User Name:</label>
                        <span>{user.team}</span>
                      </div>
                      <div>
                        <label>Role:</label>
                        <span>{user.role ? user.role.name : ""}</span>
                      </div>
                      <div>
                        <label>Created Date:</label>
                        <span>{utils.formatDate(user.createdAt)}</span>
                      </div>
                      <div>
                        <label>Mobile Number:</label>
                        <span>{utils.formatPhoneNumber(user.phoneNum)}</span>
                      </div>
                      <div>
                        <label>Email Id:</label>
                        <span>{user.email}</span>
                      </div>
                      <div>
                        <label>Actions:</label>
                        <span className="icon-set">
                          {actions["Settings-Edit User"] ? (
                            <div className="user-edit-link" onClick={() => this.editUser(user)}>
                              <EditUser width="16" height="16" />
                            </div>
                          ) : (
                            ""
                          )}
                          {loggedInUser.uid === user.uid ? (
                            ""
                          ) : actions["Settings-Reset Password"] ? (
                            <div className="user-edit-link" onClick={() => this.showPasswordResetModal(user)}>
                              <ResetPassword width="16" height="16" />
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-records-found">No records.</div>
              )}
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

function mapState(state) {
  const { adminUserInfo } = state.authentication;
  const { activeAdminUser, configData, userList, usersLoading, userModalOpen, userModalMode } = state.settings;
  return { adminUserInfo, activeAdminUser, configData, userList, usersLoading, userModalOpen, userModalMode };
}

const actionCreators = {
  loadUsers: adminSettingsActions.getAdminUsers,
  showUserModal: adminSettingsActions.showUserModal,
};

export default connect(mapState, actionCreators)(Users);
