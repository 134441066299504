import firebase from 'firebase/compat/app';
import 'firebase/compat/app'
import 'firebase/compat/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import 'firebase/compat/auth';
import { getPerformance } from "firebase/performance";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const isLive = firebaseConfig.projectId !== 'dolphin-21153';
const Firebase = { 
  perf : getPerformance(firebaseApp),
  fb:firebase, db : firebaseApp.firestore(), 
  auth : firebaseApp.auth(), 
  storage : getStorage(),
  uploadBytes,
  getDownloadURL,
  storageRef : ref,
  deleteObject,
  firestore : firebase.firestore,
  hostUrl : `https://${firebaseConfig.projectId === 'dolphin-21153' ? 'staging-dolphin.' : 'dolphin.'}enerva.ca`,
  // apiUrl : `https://northamerica-northeast1-${firebaseConfig.projectId}.cloudfunctions.net`,
  apiUrl : isLive ? `https://services.enerva.ca` : `https://services.enerva.ca/staging`,
  fileUploadUrl : isLive ? `https://services.enerva.ca/scanfiles` : `https://services.enerva.ca/staging/scanfiles`,
  socketUrl:  `https://services.enerva.ca`,
  socketUrlPath : isLive ? '/productionio' : '/stagingio'
}
export default Firebase;