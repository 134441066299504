import React from "react";
import './FormStep.css';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      paddingTop:20,
      paddingBottom:20,
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  const theme = createMuiTheme({
    palette: {
      primary: { 
          main : '#048041'
      }
    }
  });

const GreenConnector = withStyles({
    alternativeLabel: {
      top: '10',
    },
    active: {
      '& $line': {
        backgroundColor:'#686c6d'
      },
    },
    completed: {
      '& $line': {
        backgroundColor:'#686c6d'
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#e3ddda',
      borderRadius: 1,
    },
  })(StepConnector);

export default function FormStep(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const steps = props.steps || [];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => {
    if(step < props.slideIndex){
      setActiveStep(step);
      props.onChange(step)
    }
  };

  function isStepComplete(step) {
    return completed.has(step);
  }
  return (
    <div className={classes.root}>
        <ThemeProvider theme={theme}>
      <Stepper alternativeLabel nonLinear activeStep={props.slideIndex} connector={<GreenConnector />}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepButton
                onClick={() => handleStep(index)}
                completed={isStepComplete(index)}
              >
                <span className="step-label">{label}</span>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      </ThemeProvider>
      <div>
      </div>
    </div>
  );
}