import React from "react";
import './Button.css';

const Button = (props) => {
        return (
            <button
                disabled={props.disabled}
                className={`enerva-btn${(props.disabled || false) ? ' disabled-btn' : ''}${(props.large || false) ? ' large-btn' : ''}${(props.uppercase || false) ? ' uppercase-btn' : ''}${props.className ? ` ${props.className}` : ''}`}
                onClick={props.disabled ? null : props.onClick}>
                {props.title} {props.svg ? <img src={props.svg} alt='Arrow' /> : ""}
            </button>
        );
}

export default Button;