import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { adminSettingsActions } from "../../../redux/actions/adminSettingsActions";
import { AuthContext } from "../../../firebaseAuthContext";

class AdminActionButton extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      disableClick: false,
    };
  }
  handleMultipleClicks = (application) => {
    if(!this.state.disableClick) {
      this.props.reviewRetrofitApplication(application)
      this.setState({disableClick: true,});
    }
    setTimeout(() => {
      this.setState({disableClick: false,});
    }, 3000);
  }
  render() {
    const {
      iconClass,
      actionName,
      showActionModal,
      application,
      appModalType,
      title,
      viewApplication,
      reviewRetrofitApplication,
      adminUserInfo,
      currentProgram,
      configData,
      appModalSubType,
    } = this.props;
    let actions = {};
    let retrofitButtonsDisplay = {};
    if (currentProgram && adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions[currentProgram].actions;
    }
    if (currentProgram === "retrofit" && configData && configData.buttonConfig) {
      if (application.stage === "Pre-Project") {
        let internalStatus = `Pre-Project-${application.flag ? application.flag.status : ""}`;
        retrofitButtonsDisplay = configData.buttonConfig[internalStatus] ? configData.buttonConfig[internalStatus].actions : "";
      } else if (application.stage === "Post-Project") {
        let internalStatus = `Post-Project-${application.flag ? application.flag.status : ""}`;
        retrofitButtonsDisplay = configData.buttonConfig[internalStatus] ? configData.buttonConfig[internalStatus].actions : "";
      } else if (application.stage === "Payment") {
        let internalStatus = `Payment-${application.flag ? application.flag.status : ""}`;
        retrofitButtonsDisplay = configData.buttonConfig[internalStatus] ? configData.buttonConfig[internalStatus].actions : "";
      }
    }
    if (actions && actions[actionName]) {
      if (title === "View Application") {
        return (
          <div className={`more-details-links ${iconClass}`} onClick={() => viewApplication(application.id)}>
            {title}
          </div>
        );
      }  else {
        if (currentProgram === "retrofit" && (actionName.includes("Review Board") || actionName.includes("Application Board"))) {
          if (retrofitButtonsDisplay[actionName]) {
            if (title === "Start Primary Review" || title === "Start Secondary Review") {
              return (
                <div className={`more-details-links ${iconClass}`} onClick={() => this.handleMultipleClicks(application)}>
                  {title}
                </div>
              );
            } else {
              return (
                <div
                  className={`more-details-links ${iconClass}`}
                  onClick={() => showActionModal({ ...application, modalType: appModalType, subType: appModalSubType })}
                >
                  {title}
                </div>
              );
            }
          } else {
            return null;
          }
        } else {
          return (
            <div className={`more-details-links ${iconClass}`} onClick={() => showActionModal({ ...application, modalType: appModalType, subType: appModalSubType })}>
              {title}
            </div>
          );
        }
      }
    } else {
      return null;
    }
  }
}

function mapState(state) {
  const { adminUserInfo, currentProgram } = state.authentication;
  const { configData } = state.settings;
  return { adminUserInfo, currentProgram, configData };
}

const actionCreators = {
  getUserDetails: userActions.getUserDetails,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
};

export default connect(mapState, actionCreators)(AdminActionButton);
