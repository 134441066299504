import React from "react";
import "./AssignLabels.css";
import LoaderComponent from "../../../Common/Loader/Loader";
import Button from "../../../Common/Button/Button";
import Input from "../../../Common/Input/Input";
import { applicationService } from "../../../../services/applicationService";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import LeftArrow from "../../../../images/leftArrow.svg";
import LabelCheck from "../../../../images/LabelCheck.svg";
import { utils } from "../../../../helpers/utils";
import { labelService } from "../../../../services/labelService";
import { CirclePicker } from "react-color";
import { connect } from "react-redux";
import { appActions } from "../../../../redux/actions/appActions";
class AssignLabels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignLabelModalLoading: true,
      confirming: false,
      selectedLabels: {},
      creatingNewLabel: false,
      currentLabelName: "",
      selectedColor: { hex: "#f44336" },
      labelsSuccessfullyAssigned: false,
      applicationObj: {},
    };
  }
  async componentDidMount() {
    this.props.loadLabels();
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
        selectedLabels: appObj.labels || {},
        assignLabelModalLoading: false,
      });
    });
  }

  toggleCreating = (start) => {
    this.setState({
      creatingNewLabel: start,
    });
  };

  handleLabelChange = (name, value) => {
    this.setState({
      currentLabelName: value,
    });
  };

  handleColorChange = (color, event) => {
    this.setState({
      selectedColor: color,
    });
  };

  selectLabel = (label) => {
    const { selectedLabels } = this.state;
    if (selectedLabels[label.id]) {
      delete selectedLabels[label.id];
    } else {
      delete label.createdAt;
      selectedLabels[label.id] = label;
    }
    this.setState({ selectedLabels });
  };

  createLabel = () => {
    const { currentLabelName, selectedColor } = this.state;
    this.setState(
      {
        assignLabelModalLoading: true,
      },
      () => {
        labelService
          .createLabel({
            name: currentLabelName,
            color: selectedColor.hex,
          })
          .then((res) => {
            this.props.loadLabels();
            this.setState({
              currentLabelName: "",
              assignLabelModalLoading: false,
              creatingNewLabel: false,
            });
          });
      }
    );
  };

  setConfirming = (isConfirming) => {
    this.setState({
      confirming: isConfirming,
    });
  };

  assignLabels = () => {
    //TODO: Make API Call to Assign Labels to Application
    applicationService
      .updateApplication(this.props.applicationId, {
        labels: this.state.selectedLabels,
      })
      .then((success) => {
        this.props.loadLabels();
        this.setState(
          {
            labelsSuccessfullyAssigned: true,
          },
          () => {
            setTimeout(() => {
              this.props.closeActionModal(true);
            }, 2500);
          }
        );
      });
  };

  render() {
    const {
      applicationObj,
      creatingNewLabel,
      assignLabelModalLoading,
      currentLabelName,
      selectedLabels,
      selectedColor,
      confirming,
      labelsSuccessfullyAssigned,
    } = this.state;
    const { labels, labelsLoading } = this.props;
    const loadingStuff = assignLabelModalLoading || labelsLoading;
    if (loadingStuff) {
      return <LoaderComponent />;
    }
    return (
      <div className="assign-labels-container">
        {assignLabelModalLoading ? (
          ""
        ) : (
          <React.Fragment>
            <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>

            {creatingNewLabel ? (
              <button onClick={() => this.toggleCreating(false)} className="back-button">
                <img src={LeftArrow} alt="arrow" />
                Back
              </button>
            ) : (
              ""
            )}
            <div className="section-below-header">
              <h2
                className="section-title"
                dangerouslySetInnerHTML={{ __html: creatingNewLabel ? "Create New Label" : "Assign Label" }}
              ></h2>
              {creatingNewLabel || confirming ? (
                ""
              ) : (
                <Button title={"New Label"} className="signup-btn" onClick={() => this.toggleCreating(true)} uppercase />
              )}
            </div>
          </React.Fragment>
        )}
        <div className="selected-labels-container">
          {creatingNewLabel ? (
            <div className="new-form-sections">
              <div className="form-inner-section">
                <div className="form-label">LABEL NAME</div>
                <div className="form-element">
                  <Input
                    placeholder={"Enter Label Name"}
                    style={{ backgroundColor: selectedColor.hex ? selectedColor.hex : "#222222", color: "#FFFFFF" }}
                    className={"label-input"}
                    type="text"
                    name="currentLabelName"
                    onChange={this.handleLabelChange}
                    value={currentLabelName}
                  />
                </div>
              </div>
              <div className="form-inner-section">
                <div className="form-label">SELECT A COLOUR</div>
                <div className="form-element">
                  <CirclePicker className="color-picker" color={selectedColor} onChange={this.handleColorChange} />
                </div>
              </div>
            </div>
          ) : (
            <div className={`selected-labels-table ${!confirming ? "action-mode" : ""}`}>
              <div className="selected-labels-table-left">
                <p className="box-subtitle">{confirming ? "SELECTED LABELS" : "SELECT LABEL(S)"}</p>
                {confirming ? (
                  <div className="label-list">
                    {labels
                      .filter((item, index) => selectedLabels[item.id])
                      .map((item, index) => {
                        return (
                          <div className="label-tag" style={{ backgroundColor: item.color }}>
                            {item.name}
                            {selectedLabels[item.id] ? <img src={LabelCheck} alt="tick mark" /> : ""}
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="label-list">
                    {labels.map((item, index) => {
                      return (
                        <div
                          className="label-tag"
                          style={{ backgroundColor: item.color }}
                          onClick={() => {
                            this.selectLabel(item);
                          }}
                        >
                          {item.name}
                          {selectedLabels[item.id] ? <img src={LabelCheck} alt="tick mark" /> : ""}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {confirming ? (
                <React.Fragment>
                  <div className="vertical-separator-box">
                    <span className="separator"></span>
                  </div>
                  {labelsSuccessfullyAssigned ? (
                    <div className="selected-labels-table-right">
                      <p>The labels are successfully assigned to the application.</p>
                    </div>
                  ) : (
                    <div className="selected-labels-table-right">
                      <p>You are about to assign the following Label(s) to the application.</p>
                      <p>Are you sure?</p>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {creatingNewLabel ? (
          <div className="button-block">
            <div className="button-set">
              <Button
                title={"Create"}
                uppercase
                onClick={this.createLabel}
                disabled={utils.isNullOrEmpty(currentLabelName) || utils.isNullOrEmpty(selectedColor.hex)}
              />
            </div>
          </div>
        ) : (
          <div className="button-block">
            {labelsSuccessfullyAssigned ? (
              ""
            ) : (
              <div className="button-set">
                {confirming ? (
                  <React.Fragment>
                    <Button className="inversed-btn" title={"Edit Details"} uppercase onClick={() => this.setConfirming(false)} />
                    <Button title={"Confirm"} uppercase onClick={this.assignLabels} />
                  </React.Fragment>
                ) : (
                  <Button
                    title={"Assign Label"}
                    uppercase
                    onClick={() => this.setConfirming(true)}
                    disabled={utils.isNullOrEmpty(selectedLabels)}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { labels, labelsLoading } = state.application;
  return {
    labels,
    labelsLoading,
  };
}

const actionCreators = {
  loadLabels: appActions.getLabels,
};

export default connect(mapState, actionCreators)(AssignLabels);
