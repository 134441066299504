import React from "react";
import PreApprovalNoticeRow from "./PreApprovalNoticeRow";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import "./PreApprovalNotice.css";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import Input from "../../../Common/Input/Input";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";
import Button from "../../../Common/Button/Button";
import Drawer from "../../../Common/Drawer/Drawer";
import FilterVector from "../../../../images/FilterVector.svg";

class PreApprovalNoticeTab extends React.Component {
  constructor(props) {
    super(props);
    this.searchDebouncedData = debounce(this.searchData, 300);
    this.state = {
      openDrawer: false,
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      state: "Show All Applications",
      programStream: [],
    };
  }

  componentDidMount() {
    this.props.loadAdminConfig();
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".preapproval-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters("Pre-Approval", {
      SelectedStatusFilters,
    });
  };

  onFlagFilterChange = (SelectedFlagFilters) => {
    this.props.setFilters("Pre-Approval", {
      SelectedFlagFilters,
    });
  };

  onSearch = (searchInput) => {
    this.props.setFilters("Pre-Approval", {
      searchInput,
    });
  };

  searchData = (refresh = false) => {
    const { sortConfig, SelectedStatusFilters, SelectedFlagFilters, onlyActiveApps, currentProgram } = this.props;
    let config = {
      statuses: SelectedStatusFilters,
      flags: SelectedFlagFilters,
      sort: sortConfig,
      activeApps: onlyActiveApps,
      program: currentProgram,
      stream: this.state.programStream,
    };
    this.props.getApplicationsByStage("Pre-Approval", this.props.searchInput, config, refresh ? 0 : this.props.appListOffset);
  };

  handleListScroll = () => {
    if (this.props.moreApps) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    this.props.setFilters("Pre-Approval", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };

  onStateChange = (name, val) => {
    this.props.setFilters("Pre-Approval", {
      onlyActiveApps: !val ? [true] : [true, false],
    });
  };

  toggleDrawer = (status) => {
    let updateObj = {
      openDrawer: status,
    };
    this.setState(updateObj);
  };

  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let checkedStatus = this.state[name];
    if (checked) {
      // push selected value in list
      checkedStatus = [...checkedStatus, value];
    } else {
      // remove unchecked value from the list
      checkedStatus = checkedStatus.filter((x) => x !== value);
      // setLang(prev => prev.filter(x => x !== value));
    }
    this.setState({
      [name]: checkedStatus,
    });
  };
  onChangeRadio = (e) => {
    const { name, value } = e.target;
    if (value === "Show All Applications") {
      this.setState({ [name]: "Show All Applications" });
    } else if (value === "Hide Inactive Applications") {
      this.setState({ [name]: "Hide Inactive Applications" });
    } else {
      this.setState({ [name]: value });
    }
  };

  applyFiters = () => {
    const { state, selectedFlagFilters, selectedStatusFilters, programStream } = this.state;
    this.props.setFilters("Pre-Approval", {
      SelectedFlagFilters: selectedFlagFilters,
      SelectedStatusFilters: selectedStatusFilters,
      stream: programStream,
      onlyActiveApps: state === "Hide Inactive Applications" ? [true] : state === "Hide Active Applications" ? [false] : [true, false],
    });
    this.toggleDrawer(false);
  };

  clearFilters = () => {
    this.props.setFilters("Pre-Approval", {
      SelectedFlagFilters: [],
      SelectedStatusFilters: [],
      stream: [],
      onlyActiveApps: [true, false],
    });
    this.setState({
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      state: "Show All Applications",
      programStream: [],
    });
  };

  render() {
    const {
      appStage,
      SelectedStatusFilters,
      applicationsByStageLoading,
      SelectedFlagFilters,
      appList,
      configData,
      onlyActiveApps,
      currentProgram,
    } = this.props;
    const { openDrawer, state, programStream, selectedFlagFilters, selectedStatusFilters } = this.state;
    // const FilterOptions = [
    //     'Application Draft Under Review',
    //     'Application Draft',
    //     'Application Information Requested',
    //     'Application Submitted',
    //     'Application Draft Submitted',
    //     'Application Draft Approved',
    //     'Application Rejected'
    // ]
    let flagsList = [];
    let FilterOptions = [];
    if (configData) {
      flagsList = configData.appFlags.flags.map((item) => {
        return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
      });
      FilterOptions = configData.appStatuses["Pre-Approval"].map((item) => item.status);
    }
    let diffPrograms = [
      { label: "Energy Savings For Business", value: "esb" },
      { label: "Comprehensive Energy Savings", value: "ces" },
    ];
    return (
      <div className="preapproval-tab">
        <Drawer toggleDrawer={this.toggleDrawer} openDrawer={openDrawer} className={"program-panel-drawer"}>
          <div className="filter-drawer">
            <h3>FILTER BY</h3>
            {configData ? (
              <div className="all-filters">
                <div className="filter">
                  <label className="filter-label">Status</label>
                  <div className="filter-options">
                    {FilterOptions.map((status, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="selectedStatusFilters"
                            checked={selectedStatusFilters.includes(status)}
                            value={status}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />{" "}
                          {status}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">Flag</label>
                  <div className="filter-options">
                    {flagsList.map((flagItem, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="selectedFlagFilters"
                            checked={selectedFlagFilters.includes(flagItem.value)}
                            value={flagItem.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />{" "}
                          <span>{flagItem.icon}</span>
                          {flagItem.value}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">State</label>
                  <div className="filter-options">
                    {
                      <div onChange={(e) => this.onChangeRadio(e)}>
                        <label>
                          <input type="radio" checked={state === "Show All Applications"} value="Show All Applications" name="state" /> Show
                          All Applications
                        </label>
                        <label>
                          <input
                            type="radio"
                            checked={state === "Hide Inactive Applications"}
                            value="Hide Inactive Applications"
                            name="state"
                          />{" "}
                          Hide Inactive Applications
                        </label>
                        <label>
                          <input
                            type="radio"
                            checked={state === "Hide Active Applications"}
                            value="Hide Active Applications"
                            name="state"
                          />{" "}
                          Hide Active Applications
                        </label>
                      </div>
                    }
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">Program</label>
                  <div className="filter-options">
                    {diffPrograms.map((program, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="programStream"
                            checked={programStream.includes(program.value)}
                            value={program.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />
                          {program.label}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Button className="inversed-btn drawer-btn first-btn" title="CLEAR ALL FILTERS" onClick={this.clearFilters} />
          <Button className="drawer-btn" title="APPLY FILTERS" onClick={this.applyFiters} />
        </Drawer>
        <div className="applications-top-bar">
          <div className="applications-top-bar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for Applications" onClick={this.onSearch} />
          </div>
          {currentProgram === "esb" ? (
            <div className="applications-top-bar-filter-btn">
              <Button
                className="filter-btn"
                uppercase
                title={"Filters"}
                svg={FilterVector}
                onClick={() => this.setState({ openDrawer: true })}
              />
            </div>
          ) : null}
          {currentProgram === "esb" ? null : (
            <>
              <div className="applications-top-bar-filter">
                {configData ? (
                  <FilterDropdown
                    label={"Filter by Status"}
                    FilterOptions={FilterOptions}
                    SelectedFilters={SelectedStatusFilters}
                    onChange={this.onStatusFilterChange}
                    className={"program-filter"}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="applications-top-bar-filter">
                {configData ? (
                  <FilterDropdown
                    label={"Filter by Flag"}
                    iconBasedOption={true}
                    iconClass="flag-filter"
                    FilterOptions={flagsList}
                    SelectedFilters={SelectedFlagFilters}
                    onChange={this.onFlagFilterChange}
                    className={"program-filter"}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="appstate-details">
                {configData ? (
                  <React.Fragment>
                    <Input type="switch" name="appState" checked={onlyActiveApps.includes(false)} onChange={this.onStateChange} />
                    <div className="appstate-text">
                      {onlyActiveApps.includes(false) ? "Show All Applications" : "Hide Inactive Applications"}
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
        <div className="preapproval-details-header preapproval-details-row">
          <div className="preapproval-details">
            <div onClick={() => this.sortData("appNo", "string")}>ID</div>
            <div onClick={() => this.sortData("companyName", "string")}>Customer</div>
            <div onClick={() => this.sortData("contractor.label", "string")}>Contractor</div>
            <div onClick={() => this.sortData("status", "string")}>Status</div>
            <div onClick={() => this.sortData("Pre-Approval - Application Draft Approved Time", "date")}>Draft Approved Date</div>
            <div onClick={() => this.sortData("Pre-Approval - Application Submitted Time", "date")}>Submission Date</div>
            <div onClick={() => this.sortData("reviewer.label", "string")}>Reviewer</div>
            <div onClick={() => this.sortData("Estimated Incentive", "number")}>Estimated Incentive</div>
            <div onClick={() => this.sortData("Reviewer Estimated Incentive", "number")}>Pre-Approved Incentive</div>
            <div></div>
          </div>
        </div>
        <div className="preapproval-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {applicationsByStageLoading && appStage !== "Pre-Approval" ? (
            <SkeletonLoading />
          ) : appList && appList.length ? (
            appList.map((application, i) => (
              <PreApprovalNoticeRow
                application={application}
                key={`application-${i}`}
                id={`application-${i}`}
                showActionModal={this.props.showActionModal}
                ShowMoreDetails={this.ShowMoreDetails}
              />
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { currentProgram } = state.authentication;
  const { appList, appStage, appListOffset, applicationsByStageLoading, appStatusLogsLoading, activeStatusLogs, moreApps } =
    state.application;
  return {
    currentProgram,
    appList,
    appListOffset,
    applicationsByStageLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    moreApps,
    configData,
    appStage,
  };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getApplicationsByStage: appActions.getApplicationsByStage,
};

export default connect(mapState, actionCreators)(PreApprovalNoticeTab);
