import React, {createContext,useState, useEffect} from 'react';
import Firebase from './helpers/Firebase';
export const AuthContext= createContext({userPresent:false,user:null})
export default function FirebaseAuthContext(props){
    
   
    let [state,changeState] = useState({
        userDataPresent:false,
        user:null,
        listener:null
    })

    useEffect(()=>{
        if(state.listener==null){
        changeState({...state,listener:Firebase.auth.onAuthStateChanged(async (user)=>{
           if(user){
                const result = await user.getIdTokenResult();
                if(result.claims.role){
                    user.role = result.claims.role;
                    changeState(oldState=>({...oldState,userDataPresent:true,user:user}));
                } else {
                    changeState(oldState=>({...oldState,userDataPresent:false,user:null}));
                }
           } else {
            changeState(oldState=>({...oldState,userDataPresent:true,user:null}));
           }
        })});
        
    }
    return ()=>{
      if(state.listener)
        state.listener()
    }
    
    },[])
  
  
    return (
        <AuthContext.Provider value={state}>
            {props.children}
        </AuthContext.Provider>
    )
}