import React from "react";
import Payouts from "./Payouts/Payouts";
import ProgramLayout from "../../Common/ProgramLayout";
import Modal from "../../Common/Modal/Modal";
import Drawer from '../../Common/Drawer/Drawer';
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import Button from "../../Common/Button/Button";
import { AuthContext } from "../../../firebaseAuthContext";
import { userService } from "../../../services/userService";
import { applicationService } from "../../../services/applicationService";
import { ReactComponent as ViewIcon } from "../../../images/EyeGrey.svg";
import { ReactComponent as TrashIcon } from "../../../images/TrashGrey.svg";
import { ReactComponent as DownloadIcon } from "../../../images/DownloadGrey.svg";
import { ReactComponent as TickMark } from "../../../images/VerifiedTick.svg";
import Input from "../../Common/Input/Input";
import "./PayoutBoard.css";
import { utils } from "../../../helpers/utils";
import { adminSettingsActions } from "../../../redux/actions/adminSettingsActions";
import { debounce } from "debounce";
import IncentivePaymentNote from "../IncentiveReviewBoard/IncentivePaymentNote/IncentivePaymentNote";
import IPNSpotCheck from "./IPNSpotCheck/IPNSpotCheck";
import ViewIPNSpotCheckDetails from './ViewIPNSpotCheckDetails/ViewIPNSpotCheckDetails';

class PayoutBoard extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      showIpnApplicationsModal: false,
      showIpnStatusChangeModal: false,
      ipnStatusChangeactionType: "",
      ipnStatusChangeSubmitted: false,
      showIpnStatusToPVRRModal: false,
      showSpotCheckDetailsModal: false,
      selectedIpnId: "",
      incentiveTotal: "",
      ipnNo: "",
      ipnData: [],
      ipnApplications: [],
      selectedTab: "Payout",
      approvingMessage: "",
      pvrrApprovalDate: "",
      finalPVRRFile: [],
      pvrrActionNote: "",
      modalEdit: "",
      openDrawer: false,
      PayoutFilters: {
        SelectedStatusFilters: [],
        SelectedFlagFilters: [],
        SelectedDateRange: { startDate: new Date(), endDate: new Date() },
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
      },
    };
    this.searchDebouncedData = debounce(this.searchData, 450);
  }
  componentDidMount() {
    //this.props.loadappFlags();
    this.props.loadAdminConfig();
    userService.getReviewers().then((reviewerList) => {
      this.setState({
        reviewerList,
      });
    });
  }
  setFilters = (stage, newState) => {
    let updateObj = {};
    updateObj = {
      [`${stage}Filters`]: {
        ...this.state[`${stage}Filters`],
        ...newState,
      },
    };
    this.setState(updateObj, () => {
      this.searchDebouncedData();
    });
  };
  searchData = () => {
    const { sortConfig, searchInput, SelectedStatusFilters, dateRange } = this.state[`PayoutFilters`];
    let config = { statuses: SelectedStatusFilters, sort: sortConfig, dateRange, program: this.props.currentProgram };
    this.props.getIPNSByStatus(searchInput, config, 0);
  };

  showActionModal = (selectedapplicationData) => {
    if (selectedapplicationData.modalType === "view-ipn-apps") {
      this.setState({
        showIpnApplicationsModal: true,
        ipnData: selectedapplicationData.ipn,
      });
    } else if (selectedapplicationData.modalType === "update-ipn") {
      var updateObj = {
        showIpnStatusChangeModal: true,
        ipnStatusChangeactionType: selectedapplicationData.ipnStatusChangeactionType,
        selectedIpnId: selectedapplicationData.id,
        ipnData: selectedapplicationData.ipn,
      };
      // if(selectedapplicationData.ipnStatusChangeactionType==='transfered-to-plooto'){
      //   updateObj['ipnApplications']= selectedapplicationData.ipnApplications;
      // }
      this.setState(updateObj);
    } else if (selectedapplicationData.modalType === "spot-check") {
      var updateObj = {
        openDrawer: selectedapplicationData.drawer,
        showSpotCheckDetailsModal: !selectedapplicationData.drawer,
        ipnStatusChangeactionType: selectedapplicationData.ipnStatusChangeactionType,
        selectedIpnId: selectedapplicationData.id,
        ipnData: selectedapplicationData.ipn,
      }
      if(window.innerWidth < 600) {
        // alert("Kindly review the banking details on a larger screen!!");
        var txt;
        if (window.confirm("Kindly review the banking details on a larger screen!!")) {
          txt = "You pressed OK!";
        } else {
          txt = "You pressed Cancel!";
          updateObj.openDrawer = false;
        }
      }
      this.setState(updateObj);
    } else if (selectedapplicationData.modalType === "pvrr") {
      var updateObj = {
        showIpnStatusToPVRRModal: true,
        ipnStatusChangeactionType: selectedapplicationData.ipnStatusChangeactionType,
        selectedIpnId: selectedapplicationData.id,
        ipnData: selectedapplicationData.ipn,
        ipnApplications: selectedapplicationData.ipnApplications,
        incentiveTotal: selectedapplicationData.incentiveTotal,
        ipnNo: selectedapplicationData.ipnNo,
        modalEdit: selectedapplicationData.modalEdit,
        pvrrActionNote: selectedapplicationData.pvrrActionNote ? selectedapplicationData.pvrrActionNote : "",
        finalPVRRFile: selectedapplicationData.finalPVRRFile ? selectedapplicationData.finalPVRRFile : [],
        pvrrApprovalDate: selectedapplicationData.pvrrApprovalDate ? selectedapplicationData.pvrrApprovalDate : "",
        pvrrApproverName: selectedapplicationData.pvrrApproverName ? selectedapplicationData.pvrrApproverName : "",
      };
      this.setState(updateObj);
    }
  };

  closeActionModal = (reload) => {
    this.setState(
      {
        showIpnApplicationsModal: false,
        showIpnStatusChangeModal: false,
        ipnStatusChangeactionType: "",
        ipnStatusChangeSubmitted: false,
        showIpnStatusToPVRRModal: false,
        showSpotCheckDetailsModal: false,
        activeAppNo: "",
        pvrrActionNote: '',
        pvrrApprovalDate: '',
        finalPVRRFile: [],
        pvrrApproverName: '',
        approvingMessage: '',
      },
      () => {
        if (reload) {
          this.searchData();
        }
      }
    );
  };
  ConfirmIpnStatusChange = () => {
    const { selectedIpnId, ipnStatusChangeactionType, ipnApplications } = this.state;
    const { adminUserInfo } = this.props;
    var ipnStatus = "Money Received";
    if (ipnStatusChangeactionType === "transfered-to-plooto") {
      ipnStatus = "PINs Generated";
    } else if (ipnStatusChangeactionType === "payout-initiated") {
      ipnStatus = "Payout Initiated";
    } else if (ipnStatusChangeactionType === "eft-completed") {
      ipnStatus = "EFT Completed";
    }
    applicationService
      .updateIPN(selectedIpnId, {
        status: ipnStatus,
        actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
        actionNote: "",
      })
      .then((res) => {
        this.setState(
          {
            ipnStatusChangeSubmitted: true,
            pvrrActionNote: "",
          },
          () => {
            setTimeout(() => {
              this.closeActionModal(true);
            }, 5000);
          }
        );
      });
  };

  approvingPVRR = (mess) => {
    const { selectedIpnId, finalPVRRFile, pvrrApproverName, pvrrApprovalDate, pvrrActionNote } = this.state;
    const { adminUserInfo } = this.props;
    if (mess === "Confirmed") {
      applicationService
        .updateIPN(selectedIpnId, {
          status: "PVRR Approved",
          actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
          pvrrActionNote: pvrrActionNote ? pvrrActionNote : "",
          finalPVRRFile: !utils.isNullOrEmpty(finalPVRRFile) ? finalPVRRFile : [],
          pvrrApproverName: pvrrApproverName ? pvrrApproverName : "",
          pvrrApprovalDate: pvrrApprovalDate ? pvrrApprovalDate : "",
        })
        .then((res) => {
          this.setState(
            {
              ipnStatusChangeSubmitted: true,
              approvingMessage: mess,
              pvrrActionNote: "",
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 5000);
            }
          );
        });
    } else {
      this.setState({
        approvingMessage: mess,
      });
    }
  };

  deleteDocument = (url) => {
    utils.deleteFileFromFirebaseStorage(url);
    this.setState({
      finalPVRRFile: [],
    });
  };

  viewFile = (url) => {
    window.open(url);
  };

  goBackPVRR = () => {
    this.setState({
      approvingMessage: "",
    });
  };

  handleDocumentUpload = (name, fileObj) => {
    if (!fileObj.isInfected) {
      this.setState({
        [name]: fileObj,
      });
    }
  };

  handleOnChangePVRR = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  toggleDrawer = (status) => {
    let updateObj = {
      openDrawer: status,
    };
    this.setState(updateObj);
  };

  render() {
    const {
      showIpnApplicationsModal,
      showIpnStatusChangeModal,
      ipnStatusChangeactionType,
      ipnStatusChangeSubmitted,
      showIpnStatusToPVRRModal,
      showSpotCheckDetailsModal,
      ipnApplications,
      incentiveTotal,
      ipnNo,
      approvingMessage,
      pvrrApprovalDate,
      finalPVRRFile,
      pvrrApproverName,
      pvrrActionNote,
      modalEdit,
      ipnData,
      openDrawer,
    } = this.state;
    var statusChangeConfirmationMessage = "You are about to Mark IPN as 'Money Received'";
    if (ipnStatusChangeactionType === "transfered-to-plooto") {
      statusChangeConfirmationMessage = "You are about to Generate Payment PINs";
    } else if (ipnStatusChangeactionType === "payout-initiated") {
      statusChangeConfirmationMessage = "You are about to mark IPN as 'Payout Initiated'";
    } else if (ipnStatusChangeactionType === "eft-completed") {
      statusChangeConfirmationMessage = "You are about to mark IPN as 'EFT Completed'";
    }
    return (
      <ProgramLayout showFooter={false} props={this.props}>
        <React.Fragment>
          <Modal
            // HeaderText={`Label Logs`}
            IsOpen={showIpnApplicationsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}

            // loading={appChangeReqLogsLoading}
          >
            {" "}
            <IncentivePaymentNote ipnData={this.state.ipnData}></IncentivePaymentNote>
          </Modal>
          <Modal
            IsOpen={showIpnStatusToPVRRModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            // loading={appChangeReqLogsLoading}
          >
            <React.Fragment>
              <div className="section-summary">
                <div className="section-block">
                  <div className="label">No of Applications</div>
                  <div className="info">{ipnApplications.length}</div>{" "}
                </div>
                <div className="section-block">
                  <div className="label">Total Incentive</div>{" "}
                  <div className="totals">{`$${
                    incentiveTotal
                      ? incentiveTotal
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : ""
                  }`}</div>
                </div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={modalEdit ? { __html: `MARK ${ipnNo ? ipnNo : ""} AS 'PVRR APPROVED'` } : { __html: `PVRR Details For ${ipnNo ? ipnNo : ""}` }}></h2>
              </div>
              <div className="main-container">
                {modalEdit === true ? (
                  approvingMessage ? (
                    <p>You are about to mark IPN as "PVRR Approval".</p>
                  ) : (
                    <p>You are about to make IPN as "PVRR Approved". Please update the following details regarding the PVRR.</p>
                  )
                ) : (
                  ""
                )}
                <div className="content-container">
                  <div className="pvrr-details">
                    {!utils.isNullOrEmpty(approvingMessage) || !modalEdit ? (
                      <Input
                        label="PVRR Approval Date"
                        type="text"
                        disabled={true}
                        value={pvrrApprovalDate ? utils.formatAmericanDate(pvrrApprovalDate) : ""}
                      />
                    ) : (
                      <Input
                        label="PVRR Approval Date"
                        name="pvrrApprovalDate"
                        value={pvrrApprovalDate}
                        disabled={approvingMessage}
                        max={new Date()}
                        type="date"
                        onChange={this.handleOnChangePVRR}
                      />
                    )}
                    {(!utils.isNullOrEmpty(finalPVRRFile) && !finalPVRRFile.isInfected) || approvingMessage || !modalEdit ? (
                      <div className="uploaded-file">
                        <label className="file-label">Final PVRR File</label>
                        <div className={approvingMessage || !modalEdit ? "disabled-file-details" : "file-details"}>
                          <span
                            className="file-name"
                            title={utils.isNullOrEmpty(finalPVRRFile) ? "" : `${finalPVRRFile.name}${finalPVRRFile.extension}`}
                          >
                            {utils.isNullOrEmpty(finalPVRRFile) ? "" : `${finalPVRRFile.name}${finalPVRRFile.extension}`}
                          </span>
                          {approvingMessage ? (
                            ""
                          ) : modalEdit ? (
                            <span className="more">
                              <ViewIcon
                                className="view-eye-icon"
                                width="30px"
                                height="20px"
                                onClick={() => this.viewFile(finalPVRRFile.url)}
                              />
                              <TrashIcon className="delete-icon" width="30px" height="20px" onClick={() => this.deleteDocument(finalPVRRFile.url)} />
                            </span>
                          ) : (
                            <span className="more">
                              {!utils.isNullOrEmpty(finalPVRRFile) ? (
                                <DownloadIcon
                                  className="download-icon"
                                  width="30px"
                                  height="20px"
                                  onClick={() => this.viewFile(finalPVRRFile.url)}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Input
                        label="Final PVRR File"
                        disabled={approvingMessage}
                        type="file"
                        name="finalPVRRFile"
                        maxFiles={1}
                        onChange={this.handleDocumentUpload}
                        placeholder="Select from computer (or) Drag and Drop to upload"
                      />
                    )}
                    <Input
                      label="PVRR Approver Name"
                      disabled={approvingMessage || !modalEdit}
                      type="text"
                      name="pvrrApproverName"
                      value={pvrrApproverName}
                      onChange={this.handleOnChangePVRR}
                    />
                  </div>
                  {modalEdit === true ? (
                    <div className={`${approvingMessage ? "vertical-separator" : "separator-hidden"}`}>
                      <span className="separator"></span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="message-block">
                    {modalEdit === true ? (
                      approvingMessage === "Confirmation" ? (
                        <>
                          <p>Please review the details of the PVRR are accurate before confirming the status change.</p>{" "}
                          <p>
                            Within 56 days from today, the banking details for all the applications within this IPN will be deleted for
                            security purposes.
                          </p>
                        </>
                      ) : approvingMessage === "Confirmed" ? (
                        <>
                          <TickMark width="50px" height="50px" />
                          <p>This IPN has been successfully marked as 'PVRR Approved'</p>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="note">
                  <div className="note-label">PVRR Approval Note:</div>
                  <Input
                    type="richtext"
                    disabled={approvingMessage || !modalEdit}
                    name="pvrrActionNote"
                    value={pvrrActionNote}
                    onChange={this.handleOnChangePVRR}
                  />
                </div>
              </div>
              {modalEdit === true ? (
                !approvingMessage ? (
                  <div className="pvrr-buttons">
                    <Button className="inversed-btn" title={"Cancel"} onClick={() => this.closeActionModal()} uppercase />
                    <Button
                      title={"Confirm Details"}
                      disabled={!pvrrApprovalDate || !pvrrApproverName || utils.isNullOrEmpty(finalPVRRFile)}
                      uppercase
                      onClick={() => this.approvingPVRR("Confirmation")}
                    />
                  </div>
                ) : approvingMessage === "Confirmation" ? (
                  <div className="pvrr-buttons">
                    <Button className="inversed-btn" title={"Back"} uppercase onClick={this.goBackPVRR} />
                    <Button title={"Mark as 'PVRR APPROVED'"} uppercase onClick={() => this.approvingPVRR("Confirmed")} />
                  </div>
                ) : (
                  ""
                )
              ) : (
                <Button title={"Close"} uppercase onClick={this.closeActionModal} />
              )}
            </React.Fragment>
          </Modal>
          <Modal
            // HeaderText={`Label Logs`}
            IsOpen={showSpotCheckDetailsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}

            // loading={appChangeReqLogsLoading}
          >
            <ViewIPNSpotCheckDetails id={this.state.selectedIpnId} ipnData={this.state.ipnData} />
          </Modal>
          <Drawer toggleDrawer={this.toggleDrawer} openDrawer={openDrawer} className="spot-check-drawer">
            <IPNSpotCheck searchData={this.searchData} adminUserInfo={this.props.adminUserInfo} id={this.state.selectedIpnId} ipnData={this.state.ipnData} toggleDrawer={this.toggleDrawer}></IPNSpotCheck>
          </Drawer>
          <Modal
            HeaderText={`CHANGE IPN STATUS`}
            IsOpen={showIpnStatusChangeModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"small"}

            // loading={appChangeReqLogsLoading}
          >
            <React.Fragment>
              {ipnStatusChangeSubmitted ? (
                <p>IPN Status Updated!</p>
              ) : (
                <React.Fragment>
                  <p>{statusChangeConfirmationMessage}</p>
                  <p>Are you sure?</p>
                </React.Fragment>
              )}
              <div className="button-block">
                {ipnStatusChangeSubmitted ? (
                  ""
                ) : (
                  <div className="button-set">
                    <Button title={"Cancel"} className="inversed-btn" uppercase onClick={() => this.closeActionModal()} />
                    <Button title={"Confirm Status Change"} uppercase onClick={() => this.ConfirmIpnStatusChange()} />
                  </div>
                )}
              </div>
            </React.Fragment>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Payouts
            SelectedStatusFilters={this.state["PayoutFilters"].SelectedStatusFilters}
            SelectedDateRange={this.state["PayoutFilters"].SelectedDateRange}
            searchInput={this.state["PayoutFilters"].searchInput}
            sortConfig={this.state["PayoutFilters"].sortConfig}
            setFilters={this.setFilters}
            showActionModal={this.showActionModal}
          />
        </React.Fragment>
      </ProgramLayout>
    );
  }
}
function mapState(state) {
  const { adminUserInfo, currentProgram } = state.authentication;
  const { configData } = state.settings;
  return {
    adminUserInfo,
    currentProgram,
    configData,
  };
}

const actionCreators = {
  setActiveProgram: userActions.setActiveProgram,
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getIPNSByStatus: appActions.getIPNSByStatus,
};

export default connect(mapState, actionCreators)(PayoutBoard);
