import React from "react";
import CancelledRow from "./CancelRow";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import "./Cancel.css";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import Input from "../../../Common/Input/Input";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";

class CancelledTab extends React.Component {
  constructor(props) {
    super(props);
    this.searchDebouncedData = debounce(this.searchData, 300);
  }

  componentDidMount() {
    if (this.props.currentProgram === "retrofit") {
      this.props.loadAdminConfigRetrofit();
      console.log("loadAdminConfigRetrofit", this.props)
    } 
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".preproject-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters("Cancelled", {
      SelectedStatusFilters,
    });
  };

  onFlagFilterChange = (SelectedFlagFilters) => {
    this.props.setFilters("Cancelled", {
      SelectedFlagFilters,
    });
  };

  onSearch = (searchInput) => {
    this.props.setFilters("Cancelled", {
      searchInput,
    });
  };

  searchData = (refresh = false) => {
    const { sortConfig, SelectedStatusFilters, SelectedFlagFilters, onlyActiveApps, currentProgram, StageFilters } = this.props;
    let config = {
      statuses: ["Cancelled"],
      stage: StageFilters,
      flags: SelectedFlagFilters,
      sort: sortConfig,
      activeApps: onlyActiveApps,
      program: currentProgram,
    };
    if (this.props.currentProgram === "retrofit") {
      this.props.getApplicationsByStageRetrofit("Cancelled", this.props.searchInput, config, refresh ? 0 : this.props.appListOffset);
    }
  };

  handleListScroll = () => {
    if (this.props.moreApps) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    this.props.setFilters("Cancelled", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };

  onStateChange = (name, val) => {
    this.props.setFilters("Cancelled", {
      onlyActiveApps: !val ? [true] : [true, false],
    });
  };

  render() {
    const {
      appStage,
      applicationsByStageLoading,
      SelectedStatusFilters,
      SelectedFlagFilters,
      appList,
      configData,
      onlyActiveApps,
      currentProgram,
    } = this.props;
    // const FilterOptions = [
    //   "Application Under Review",
    //   "Application Draft",
    //   "Application Information Requested",
    //   "Application Submitted",
    //   "Application Rejected",
    // ];
    let flagsList = [];
    let FilterOptions = [];
    if (configData) {
      if (currentProgram === "retrofit") {        
        FilterOptions = configData.appStatuses["Cancelled"].map((item) => item.applicationStatusName);
        let cancelFlags = configData.appFlags.flags.filter((item) => item.stage === "Cancelled");
        flagsList = cancelFlags.map((item) => {
          return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
        });
      } 
    }
    return (
      <div className="preproject-tab">
        <div className="applications-top-bar">
          <div className="applications-top-bar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for Applications" onClick={this.onSearch} />
          </div>
          {/* <div className="applications-top-bar-filter">
            {configData ? (
              <FilterDropdown
                label={"Filter by Status"}
                FilterOptions={FilterOptions}
                SelectedFilters={SelectedStatusFilters}
                onChange={this.onStatusFilterChange}
                className={"program-filter"}
              />
            ) : (
              ""
            )}
          </div>
          <div className="applications-top-bar-filter">
            {configData ? (
              <FilterDropdown
                label={"Filter by Flag"}
                iconBasedOption={true}
                iconClass="flag-filter"
                FilterOptions={flagsList}
                SelectedFilters={SelectedFlagFilters}
                onChange={this.onFlagFilterChange}
                className={"program-filter"}
              />
            ) : (
              ""
            )}
          </div> */}
          <div className="appstate-details">
            {configData && currentProgram !== "retrofit" ? (
              <React.Fragment>
                <Input type="switch" name="appState" checked={onlyActiveApps.includes(false)} onChange={this.onStateChange} />
                <div className="appstate-text">
                  {onlyActiveApps.includes(false) ? "SHOW ALL APPLICATIONS" : "HIDE INACTIVE APPLICATIONS"}
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="preproject-details-header preproject-details-row">
          <div className="preproject-details">
            {currentProgram === "retrofit" ? ((
              <React.Fragment>
                <div onClick={() => this.sortData("application_id", "string")}>ID</div>
                <div onClick={() => this.sortData("application_name", "string")}>Application Name</div>
                <div onClick={() => this.sortData("application_project_type", "string")}>Application Project Type</div>
                <div onClick={() => this.sortData("applicant_account_id", "string")}>Email</div>
                <div onClick={() => this.sortData("multi_site", "string")}>Multi-Site</div>
                <div onClick={() => this.sortData("application_status_name", "string")}>Status</div>
                <div onClick={() => this.sortData("pre_project_approval_date", "date")}>Pre-Project Approved Date</div>
                <div onClick={() => this.sortData("post_project_submission_date", "date")}>Post Project Submission Date</div>
                <div onClick={() => this.sortData("total_actual_app_incentive", "number")}>Actual Incentive</div>
                <div></div>
              </React.Fragment>
            )): ""}
          </div>
        </div>
        <div className="preproject-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {applicationsByStageLoading && appStage !== "Cancelled" ? (
            <SkeletonLoading />
          ) : appList && appList.length ? (
            appList.map((application, i) => (
              <CancelledRow
                currentProgram={currentProgram}
                application={application}
                key={`application-${i}`}
                id={`application-${i}`}
                showActionModal={this.props.showActionModal}
                ShowMoreDetails={this.ShowMoreDetails}
              />
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { currentProgram } = state.authentication;
  const { appList, appListOffset, appStage, applicationsByStageLoading, appStatusLogsLoading, activeStatusLogs, moreApps } =
    state.application;
  return {
    currentProgram,
    appStage,
    appList,
    appListOffset,
    applicationsByStageLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    moreApps,
    configData,
  };
}

const actionCreators = {
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getApplicationsByStageRetrofit: appActions.getApplicationsByStageRetrofit,
};

export default connect(mapState, actionCreators)(CancelledTab);
