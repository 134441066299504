import React, { Component } from "react";
import './ViewIPNSpotCheckDetails.css';
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import { applicationService } from "../../../../services/applicationService";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import Loader from "../../../Common/Loader/Loader";
import Button from "../../../Common/Button/Button";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import CheckBox from "../../../Common/CheckBox/CheckBox";
import Input from "../../../Common/Input/Input.js";

export class ViewIPNSpotCheckDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cibcData: [],
      loading: true,
      showSelectedApps: false,
      searchInput: "",
      selectedApps: [],
      selectedApplicationData: [],
      spotCheckActionNote: "",
      searchCibcData: [],
      showMessage: false,
    };
  }
  componentDidMount() {
    const { id } = this.props;
    applicationService.getCIBCTransactionExport(id).then((cibcData) => {
      this.setState({
        cibcData: cibcData,
        searchCibcData: cibcData,
        loading: false,
      });
    });
  }
  render() {
    const { ipnData } = this.props;
    const {
      cibcData,
      loading,
      showMessage,
      searchCibcData,
      selectedApps,
      searchInput,
      selectedApplicationData,
      showSelectedApps,
      spotCheckActionNote,
    } = this.state;
    let selectedAppsToViewOnly = cibcData.filter((app) => ipnData.spotCheckedApplications.includes(app.appNo));
    return (
      <div className="ipn-spot-check">
        {loading && utils.isNullOrEmpty(cibcData) ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="section-summary">
              <div className="section-block">
                <div className="label">NO OF APPLICATIONS CHECKED</div>
                <div className="info">{`${ipnData.spotCheckedApplications.length}/${ipnData.applications.length}`}</div>
              </div>
              <div className="section-block">
                <div className="label">TOTAL IPN VALUE</div>
                <div className="totals">{`$${
                  ipnData.incentiveTotal
                    ? ipnData.incentiveTotal
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : ""
                }`}</div>
              </div>
            </div>
            <div className="section-below-header">
              <h2
                className="section-title"
                dangerouslySetInnerHTML={{ __html: `Spot Check Details For ${ipnData ? ipnData.ipnNo : ""}` }}
              ></h2>
            </div>
              <>
                <div className="main-container">
                  <div className="message-container">
                    <div className="message-block">
                      <div className="message-para">
                        <Input
                          label="Spot-Check Date"
                          type="text"
                          disabled={true}
                          value={ipnData.spotCheckedDate ? utils.formatAmericanDate(ipnData.spotCheckedDate) : ""}
                        />
                        <Input
                          label="Reviewer Name"
                          type="text"
                          disabled={true}
                          value={ipnData.spotCheckReviewer ? ipnData.spotCheckReviewer : ""}
                        />
                      </div>
                      <div className="vertical-separator">
                        <span className="separator"></span>
                      </div>
                    </div>
                    <div className="outer-container">
                      <div className="eft-details">
                        <div>
                          <span>Application ID</span>
                        </div>
                        <div>
                          <span>Payee Name</span>
                        </div>
                        <div>
                          <span>Incentive Amount</span>
                        </div>
                      </div>
                      <div className="apps-container">
                        {selectedAppsToViewOnly.map((app, ind) => {
                          return (
                            <div className="eft-apps" id={`app-${ind}`} key={ind}>
                              <div className="eft-details-row">
                                <div>
                                  <label>Application ID</label>
                                  <span>{app.applicationId}</span>
                                </div>
                                <div>
                                  <label>Payee Name</label>
                                  <span>{app.payeeCompanyName}</span>
                                </div>
                                <div>
                                  <label>Incentive Amount</label>
                                  <span>
                                    $
                                    {app.postProjectReviewerApprovedIncentive
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="note">
                    <div className="note-label">Notes:</div>
                    <div className="note-value"><span>{utils.cleanHTMLText(ipnData.spotCheckActionNote)}</span></div>
                  </div>
                </div>
              </>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ViewIPNSpotCheckDetails;
