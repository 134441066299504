import React from 'react';
import AdminActionButton from '../../../Common/AdminActionButton/AdminActionButton';

class ContractorsTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { Contractor } = this.props
        const status = Contractor.Status.toLowerCase();
        const statusConfig = {
            showReviewContractor : status === 'pending approval',
            showFlagForQA : ['pending approval','details submission pending','participating'].includes(status),
            showSuspend : ['pending approval','participating'].includes(status),
            showReinstate : ['flagged for qa/qc', 'suspended'].includes(status),
            showActive : ['inactive'].includes(status),
            showInactive : ['email verification pending', 'details submission pending', 'pending approval', 'participating' , 'flagged for qa/qc','active'].includes(status),
            showViewApplications : ['participating','flagged for qa/qc', 'suspended'].includes(status),
            showDocs:false
            // showDocs: ['pending approval','participating','flagged for qa/qc', 'suspended'].includes(status)
        }

        return (
            <div className={`customer-details-row ${Contractor.isBlocked ? "blocked" : ''}`} id={this.props.id}>
                <div className="customer-details">
                    <div><label>ID:</label><span>{Contractor.ContractorID}</span></div>
                    <div><label>Name:</label><span>{Contractor.Name}</span></div>
                    <div><label>Status:</label><span>{Contractor.Status}</span></div>
                    <div><label>Created Date:</label><span>{Contractor.CreatedDate}</span></div>
                    <div><label>Email ID:</label><span>{Contractor.EmailID}</span></div>
                    <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
                </div>
                    <div className="customer-more-details">
                        <div>
                            <AdminActionButton 
                            iconClass={`edit-icon`}
                            actionName={`Participant Board-View/Edit Contractor Details`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'edit'}
                            title={'View/Edit Contractor Details'}
                        />
                            {Contractor.Status.toLowerCase() === 'email verification pending' ? 
                            <AdminActionButton 
                            iconClass={`mail-icon`}
                            actionName={`Participant Board-Resend Verification Email`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'send-mail'}
                            title={'Resend Verification Email'}
                        />
                            : ''}
                            {statusConfig.showReviewContractor ? 
                            <AdminActionButton 
                            iconClass={`review-icon`}
                            actionName={`Participant Board-Review Contractor Status`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'review'}
                            title={'Review Contractor Status'}
                        />
                            : ''}
                            {statusConfig.showViewApplications ? 
                            <AdminActionButton 
                            iconClass={`view-icon`}
                            actionName={`Participant Board-View Applications`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'show-submitted-apps'}
                            title={'View Applications'}
                        />
                            : ''}
                            {statusConfig.showDocs ? 
                            <AdminActionButton 
                            iconClass={`doc-icon`}
                            actionName={`Participant Board-View Uploaded Documents`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'view-docs'}
                            title={'View Uploaded Documents'}
                        />
                            : ''}
                            {statusConfig.showFlagForQA ? 
                            <AdminActionButton 
                            iconClass={`flag-icon`}
                            actionName={`Participant Board-Flag Contractor for QC`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'flag-qa'}
                            title={'Flag Contractor for QC'}
                        />
                            : ''}
                            {statusConfig.showSuspend ? 
                            <AdminActionButton 
                            iconClass={`suspend-icon`}
                            actionName={`Participant Board-Suspend Contractor`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'suspend'}
                            title={'Suspend Contractor'}
                        />
                            : ''}
                            {statusConfig.showReinstate ? 
                            <AdminActionButton 
                            iconClass={`reinstate-icon`}
                            actionName={`Participant Board-Reinstate Contractor`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'reinstate'}
                            title={'Reinstate Contractor'}
                        />
                            : ''}
                            {statusConfig.showActive ? 
                            <AdminActionButton 
                            iconClass={`flag-icon`}
                            actionName={`Participant Board-Mark as Active`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'active'}
                            title={'Mark as Active'}
                        />
                            : ''}
                            {statusConfig.showInactive ? 
                            <AdminActionButton 
                            iconClass={`flag-icon`}
                            actionName={`Participant Board-Mark as Inactive`}
                            showActionModal={this.props.showActionModal}
                            application={Contractor}
                            appModalType={'inactive'}
                            title={'Mark as Inactive'}
                        />
                            : ''}
                        </div>
                    </div>
            </div>
        );
    }
}

export default ContractorsTab;
