import React from "react";
import './Input.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select, {components} from 'react-select';
import AsyncSelect from 'react-select/async';
import LocationInput from '../LocationInput/LocationInput';
import PasswordInput from '../PasswordInput/PasswordInput';
import ButtonSelection from '../ButtonSelection/ButtonSelection';
import MultiSelectWithCheckBox from '../MultiSelectWithCheckBox/MultiSelectWithCheckBox';
import DatePicker from '../DatePicker/DatePicker';
import FileUpload from '../FileUpload/FileUpload';
import RadioButton from '../RadioButton/RadioButton';
import Switch from '../Switch/Switch';
import ErrorIcon from '../../../images/ErrorIcon.svg';
import CancelXIcon from '../../../images/CancelXIcon.svg';
import SuccessIcon from '../../../images/SuccessTick.svg';
import MagnifyingGlass from '../../../images/SearchVector.svg';
import { utils } from '../../../helpers/utils';
import NumberFormat from 'react-number-format';
import TooltipInfo from '../TooltipInfo/TooltipInfo';
import ReactQuill from 'react-quill';
import PostalCodeInput from "../PostalCodeInput/PostalCodeInput";
import 'react-quill/dist/quill.snow.css';

const { Option, DropdownIndicator } = components;

const OptionWithIcon = props =>(
  <Option {...props}>
    {props.data.icon}
    {props.data.label}
  </Option>
)

const SearchIcon = props => {
  return (
    <DropdownIndicator {...props}>
      <img src={MagnifyingGlass} alt="search icon"/>
    </DropdownIndicator>
  );
};

const MultiSelectStyles = {
  menuList: (styles, state) => ({
    ...styles,
    color: 'red',
    padding: 0,
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: '#FFF',
    padding: 0,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      paddingTop: '0.7em',
      paddingBottom: '0.7em',
      backgroundColor: isFocused ? 'rgba(0,150,57,0.3)' : isSelected ? '#FFF' : '#FFF',
      fontWeight: isFocused ? 'bold' : isSelected ? 'bold' : 'normal',
      color: isFocused ? '#009639' : isSelected ? '#333131' : '#333131',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        backgroundColor: '#FFF',
        color: '#009639',
      },
    };
  }
};

const LocationInputStyles = {
  dropdownIndicator: (styles, state) => ({
    ...styles,
    display: 'none'
  }),
  menuList: (styles, state) => ({
    ...styles,
    borderBottom: '1px dotted pink',
    color: 'red',
    padding: 0,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      paddingTop: '0.7em',
      paddingBottom: '0.7em',
      backgroundColor: isFocused ? 'rgba(0,150,57,0.3)' : isSelected ? '#FFF' : '#FFF',
      fontWeight: isFocused ? 'bold' : isSelected ? 'bold' : 'normal',
      color: isFocused ? '#009639' : isSelected ? '#333131' : '#333131',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        backgroundColor: '#009639',
        color: '#FFF',
      }
    };
  }
};

const InputComponent = (props) => {
  function renderPasswordReqs() {
    const { type, value } = props;
    let config = {};
    let validCount = 0;
    if (type === 'password') {
      config.isLong = value.length >= 8;
      config.containsNumber = utils.hasNumber(value);
      if (config.containsNumber) { validCount++; }
      config.containsUpper = utils.hasUpperCase(value);
      if (config.containsUpper) { validCount++; }
      config.containsLower = utils.hasLowerCase(value);
      if (config.containsLower) { validCount++; }
      config.containsSpecial = utils.hasSpecialChar(value);
      if (config.containsSpecial) { validCount++; }
    }
    let isValid = validCount >= 3;
    return value.length > 0 ? <ul className='passwordReqs'>
      {!isValid ? <li className={config.isLong ? 'success-line' : 'error-line'}><div className='success-icon'><img alt='success icon' src={config.isLong ? SuccessIcon : CancelXIcon} /></div> Minimum 8 characters</li> : ''}
      {isValid ? <li className={config.containsUpper ? 'success-line' : 'error-line'}><div className='success-icon'><img alt='success icon' src={config.containsUpper ? SuccessIcon : CancelXIcon} /></div> Meets secure password requirements</li> : ''}
      {!isValid ? <li className={config.containsUpper ? 'success-line' : 'error-line'}><div className='success-icon'><img alt='success icon' src={config.containsUpper ? SuccessIcon : CancelXIcon} /></div> At least 1 uppercase character</li> : ''}
      {!isValid ? <li className={config.containsLower ? 'success-line' : 'error-line'}><div className='success-icon'><img alt='success icon' src={config.containsLower ? SuccessIcon : CancelXIcon} /></div> At least 1 lowercase character</li> : ''}
      {!isValid ? <li className={config.containsNumber ? 'success-line' : 'error-line'}><div className='success-icon'><img alt='success icon' src={config.containsNumber ? SuccessIcon : CancelXIcon} /></div> At least 1 digit</li> : ''}
      {!isValid ? <li className={config.containsSpecial ? 'success-line' : 'error-line'}><div className='success-icon'><img alt='success icon' src={config.containsSpecial ? SuccessIcon : CancelXIcon} /></div> At least 1 special character</li> : ''}
    </ul> : null;
  }
  let component = null;
  if (props.type === 'phone') {
    component = <PhoneInput
      containerClass={`input-box${props.error ? ' input-box-error' : ''}${props.disabled ? ' disable-input' : ''}`}
      country={'ca'}
      onlyCountries={['ca']}
      value={props.value}
      onChange={(val)=>{
        props.onChange(props.name, val)
      }}
      placeholder={'(XXX)-(XXX)-XXXX'}
      inputProps={{
        name: props.name
      }
      }
      disableDropdown={true}
      showDropdown={false}
      countryCodeEditable={false}
      disabled={props.disabled}
    />;
  } else if (props.type === 'dropdown') {
    if (props.disabled) {
      component = <div
          className={`input-box${props.disabled ? ' no-border' : ''}${props.error ? ' input-box-error' : ''}${props.disabled ? ' disabled-input' : ''}`}
        >
        {(props.iconEnabled &&  props.value) ? props.value.icon : ''}
        <input
        disabled={props.disabled}
        value={(props.value && props.value.label) || ''}
        type={props.type}
        className={`${props.disabled ? ' no-border' : ''}`}
        />
        </div>
    } else {
      component = <Select
        value={props.value ? props.value : ''}
        onChange={props.onChange}
        options={props.options}
        name={props.name}
        placeholder={props.placeholder ? props.placeholder : 'Select From List'}
        className={`input-box enerva-dropdown${props.error ? ' input-box-error' : ''}`}
        classNamePrefix="enerva-dropdown"
        components={props.iconEnabled ? {Option : OptionWithIcon} : undefined}
        isDisabled={props.disabled}
      />;
    }
  }  else if (props.type === 'search') {
    if (props.disabled) {
      component = <div
          className={`search-box input-box${props.disabled ? ' no-border' : ''}${props.error ? ' input-box-error' : ''}${props.disabled ? ' disabled-input' : ''}`}
        >
        {(props.iconEnabled &&  props.value) ? props.value.icon : ''}
        <input
        disabled={props.disabled}
        value={(props.value && props.value.label) || ''}
        type={props.type}
        className={`${props.disabled ? ' no-border' : ''}`}
        />
        </div>
    } else {
      component = <AsyncSelect
        value={props.value ? props.value : ''}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        defaultOptions
        loadOptions={props.loadOptions}
        name={props.name}
        placeholder={props.placeholder ? props.placeholder : 'Select from the list'}
        className={`input-box enerva-dropdown${props.error ? ' input-box-error' : ''}`}
        classNamePrefix="enerva-dropdown"
        components={{DropdownIndicator : SearchIcon}}
        isDisabled={props.disabled}
      />;
    }
  } else if (props.type === 'dollar') {
    component = <NumberFormat
      allowNegative={false}
      allowLeadingZeros={false}
      isAllowed={props.isAllowed || undefined}
      name={props.name}
      disabled={props.disabled}
      value={props.value || ''}
      placeholder={props.placeholder}
      className={`input-box${props.disabled ? ' no-border' : ''}${props.error ? ' input-box-error' : ''}${props.disabled ? ' disable-input' : ''}`}
      onValueChange={(values) => {
        const { name } = props;
        const { value } = values
        props.onChange(name, value);
      }}
      decimalScale={2}
      fixedDecimalScale="true"
      thousandSeparator
      isNumericString
      prefix="$"
    />
  } else if (props.type === 'multiselect') {
    component = <Select
      defaultValue={props.value}
      onChange={(val, obj) => { props.onChange(obj.name, val) }}
      options={props.options}
      name={props.name}
      placeholder={props.placeholder ? props.placeholder : 'Select from the list'}
      isMulti={true}
      className={`input-box enerva-multiselect${props.error ? ' input-box-error' : ''}`}
      classNamePrefix="enerva-multiselect"
      isDisabled={props.disabled}
      styles={MultiSelectStyles}
    />;

  } else if (props.type === 'location') {
    component = <LocationInput
      className={`input-box location-dropdown${props.error ? ' input-box-error' : ''}${props.disabled ? ' disable-input' : ''}`}
      value={props.value}
      placeholder={props.placeholder}
      classNamePrefix="location-dropdown"
      selectStyles={LocationInputStyles}
      onChange={props.onChange}
      disabled={props.disabled}
    />;
  } else if (props.type === 'postalCode') {
    component = <PostalCodeInput
      name={props.name}
      onKeyDown={props.onKeyDown}
      className={`input-box input-box-pass${props.editable ? '' : ' no-border'}${props.error ? ' input-box-error' : ''}${props.editable ? '' : ' disable-input'}`}
      placeholder={props.placeholder || ''}
      onChange={props.onChange}
      onEdit={props.onEdit}
      editable={props.editable}
      value={props.value}
    />;
  } else if (props.type === 'password') {
    component = <PasswordInput
      name={props.name}
      onKeyDown={props.onKeyDown}
      className={`input-box input-box-pass${props.error ? ' input-box-error' : ''}${props.disabled ? ' disable-input' : ''}`}
      placeholder={props.placeholder || ''}
      onChange={props.onChange}
      value={props.value}
    />;
  } else if (props.type === 'radio') {
    component = <RadioButton
      name={props.name}
      className={`input-box enerva-radio${props.error ? ' input-box-error' : ''}`}
      onChange={props.onChange}
      value={props.value}
      options={props.options}
    />;
  } else if (props.type === 'buttonselect') {
    component = <ButtonSelection
      label={props.label}
      name={props.name}
      className={`button-selection-container`}
      onChange={props.onChange}
      options={props.options}
      value={props.value}
    />;
  } else if (props.type === 'multiselect-checkbox') {
    component = <MultiSelectWithCheckBox
      label={props.label}
      name={props.name}
      placeholder={props.placeholder || ''}
      options={props.options}
      value={props.value || {}}
      onChange={props.onChange}
    />;
  } else if (props.type === 'date') {
    component = <DatePicker
    disabled={props.disabled}
    id={props.name}
    onChange={(e) => {
      const { name } = props;
      const value = e && e.toISOString();
      props.onChange(name, value);
    }}
    minDate={props.minDate}
    maxDate={props.max}
    name={props.name}
    value={props.value || null}
  />;
  } else if (props.type === 'file') {
    component = <FileUpload
      disabled={props.disabled}
      onChange={props.onChange}
      folderPath={props.folderPath}
      maxFiles={props.maxFiles}
      value={props.value}
      placeholder={props.placeholder}
      name={props.name}
    />;
  } else if (props.type === 'switch') {
    component = <Switch
      name={props.name}
      onChange={props.onChange}
      checked={props.checked}
      label={props.label}
      mode={props.mode}
      disabled={props.disabled}
    />;
  } else if (props.type === 'textarea') {
    component = <textarea
      name={props.name}
      disabled={props.disabled}
      value={props.value || ''}
      placeholder={props.placeholder}
      type={props.type}
      className={`input-box textarea-box${props.disabled ? ' no-border' : ''}${props.error ? ' input-box-error' : ''}${props.disabled ? ' disable-input' : ''}`}
      onChange={(e) => {
        const { name, value } = e.target;
        props.onChange(name, value);
      }}
      rows={props.rows || "3"}
      cols={props.cols || "50"} />
  } else if (props.type === 'richtext'){
    component = <ReactQuill 
      theme="snow" 
      value={props.value || ''}
      readOnly={props.disabled}
      onChange={(value) => {
        props.onChange(props.name, value);
      }}
      />
  } else {
    component = <input
      name={props.name}
      disabled={props.disabled}
      value={props.value || ''}
      placeholder={props.placeholder}
      type={props.type}
      style={props.style}
      className={`input-box${props.disabled ? ' no-border' : ''}${props.error ? ' input-box-error' : ''}${props.disabled ? ' disable-input' : ''}`}
      onChange={(e) => {
        const { name, value } = e.target;
        props.onChange(name, value);
      }}
    />
  }

  return (
    <div className="input-parent">
      <div className={`input-container${props.className ? ` ${props.className}` : ""}${props.disabled ? " disable-input-container" : ""}`}>
        {props.label ? (
          <label className={`input-label ${props.labelclassname}`}>
            {props.label}
          </label>
        ) : (
          ""
        )}
        {component}
      </div>
      {props.error ? (
        <div className="error-message">
          <div className="error-icon">
            <img alt="error icon" src={ErrorIcon} />
          </div>
          {props.error}
        </div>
      ) : (
        ""
      )}
      {props.type === "password" && props.showExtra ? (!props.successMsg ? renderPasswordReqs() : "") : ""}
      {props.successMsg ? (
        <div className="success-message">
          <div className="success-icon">
            <img alt="success icon" src={SuccessIcon} />
          </div>
          {props.successMsg}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default InputComponent;