import React, { Component } from "react";
import { utils } from "../../../../helpers/utils";
import "./StartSecondaryReview.css";
import Button from "../../../Common/Button/Button";
import { applicationService } from "../../../../services/applicationService";
import Input from "../../../Common/Input/Input";

export default class StartPrimaryReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startReview: false,
      dataLoading: true,
      secondaryReviewDecision: "",
      preProjectSecondaryReviewNote: "",
      finalConfirmation: false,
      reviewOptions: [
        { label: "Peer Review Approved", value: "Peer Review Approved" },
        { label: "Peer Review Rejected", value: "Peer Review Rejected" },
      ],
    };
  }
  componentDidMount = () => {
    const { applicationId } = this.props;
    applicationService.getFacilitiesByApplicationIdRetrofit(applicationId).then((data) => {
      this.setState({
        facilities: data,
        dataLoading: false,
      });
    });
  };
  selectSecondaryReviewerOption = (opt) => {
    this.setState({
      secondaryReviewDecision: opt,
    });
  };
  getNote = (name, value) => {
    this.setState({
      preProjectSecondaryReviewNote: value,
    });
  };
  completingSecondaryReview = () => {
    const { adminUserInfo, applicationId, application } = this.props;
    const { preProjectSecondaryReviewNote, secondaryReviewDecision } = this.state;
    let assignReviewObj = {
      applicationId: `${applicationId}`,
      stage: application.stage,
      actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
      flag: secondaryReviewDecision.label,
      reviewNote: preProjectSecondaryReviewNote,
    };
    applicationService.completeSecondaryReview(assignReviewObj).then((res) => {
      this.setState(
        {
          preProjectSecondaryReviewNote: "",
          secondaryReviewDecision: "",
          finalConfirmation: false,
        },
        () => {
          this.props.closeActionModal(true);
        }
      );
    });
  };
  render() {
    const { application, closeActionModal } = this.props;
    const { reviewOptions, finalConfirmation, secondaryReviewDecision, preProjectSecondaryReviewNote } = this.state;
    return (
      <div className="retrofit-review-container">
        <div className="section-header">
          <div className="section-top-details">{application.appNo}</div>
          <div className="section-top-details name-section">{application.applicantName}</div>
          <div className="section-top-details">
            $
            {application.estimatedIncentive
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        </div>
        <div className="section-below-header">
          <h2 className="title" dangerouslySetInnerHTML={{ __html: `Peer Review of ${application.appNo}` }}></h2>
        </div>
        <div className="container">
          {finalConfirmation ? <p>
            You are about to mark the review for this application as <span>{secondaryReviewDecision.label}</span>. This might send out an email to the applicants & applicant reps. Please verify the accuracy of the information and the note.
          </p> : <>
            <Input
              label="Review Decision"
              placeholder="Please select a Review Decision"
              type="dropdown"
              disabled={finalConfirmation}
              name="secondaryReviewDecision"
              className="decision-options"
              onChange={this.selectSecondaryReviewerOption}
              value={secondaryReviewDecision ? secondaryReviewDecision : undefined}
              options={reviewOptions.map((type) => type)}
              isSearchable={false}
            />
            <div className="note-section">
              <label>Note</label>
              <Input
                type="richtext"
                disabled={finalConfirmation}
                name="secondaryReviewDecisionNote"
                value={preProjectSecondaryReviewNote}
                onChange={this.getNote}
              />
            </div>
          </>}
        </div>
        {finalConfirmation ? (
          <div className="button-set">
            <Button title={"Back"} className="inversed-btn" uppercase onClick={() => this.setState({ finalConfirmation: false })} />
            <Button title={"Confirm Review"} uppercase onClick={() => this.completingSecondaryReview()} />
          </div>
        ) : (
          <div className="button-set">
            <Button title={"CANCEL"} className="inversed-btn" uppercase onClick={() => closeActionModal()} />
            <Button
              title={"Complete Review"}
              disabled={!secondaryReviewDecision || !utils.cleanHTMLText(preProjectSecondaryReviewNote)}
              uppercase
              onClick={() => this.setState({ finalConfirmation: true })}
            />
          </div>
        )}
      </div>
    );
  }
}
