import React from 'react';
import './DateRangePicker.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { utils } from '../../../helpers/utils';
import SelectedDateRange from '../../../images/SelectedDateRangeCancel.svg';
import UnselectedDateRange from '../../../images/UnselectedDateRange.svg';
const moment = require('moment');
class DateRangePickerCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconSelected : false,
      value : null,
      focusedInput: null,
    };
  }

  componentDidUpdate(prevProps){
if(this.props.value && this.props.value !== prevProps.value ){
this.setState({value:this.props.value})
}
  }
  componentDidMount(){
if(this.props.value ){
this.setState({value:this.props.value})
}
  }
  handleDateChange = (value)=>{
     this.setState({ value });
     if(this.props.onChange){
     let dateRange = {
        startDate : utils.formatDateForES(value[0]),
        endDate : utils.formatDateForES(value[1])
      };
      this.props.onChange(dateRange);
     }
  }

  toggleSelection = ()=>{
    let updateObj = {
      iconSelected : !this.state.iconSelected
    };
    if(this.state.iconSelected){
      updateObj.value = null;
      this.props.onChange([]);
    }
    this.setState(updateObj);
  } 

  render() {
    const { iconSelected, value } = this.state;
    return (
      <div className="enerva-react-date-picker-container">
        <img 
        className="datePicker-calendar-icon" 
        src={iconSelected ? SelectedDateRange : UnselectedDateRange} 
        alt='calendar icon'
        onClick={this.toggleSelection}
        />
        {!utils.isNullOrEmpty(this.state.value) && value[0] !== undefined || iconSelected ? 
        <DateRangePicker
          className={this.props.className}
          dayPlaceholder={"DD"}
          monthPlaceholder={"MM"}
          yearPlaceholder={"YYYY"}
          format={'MM-dd-yyyy'}
          rangeDivider={' / '}
          calendarIcon={<div></div>}
          clearIcon={<div></div>}
          value={this.state.value}
          onChange={this.handleDateChange}
        /> : <p onClick={this.toggleSelection} className="daterange-filter-label">Filter By Date</p>}
      </div>
    );
  }
}

export default DateRangePickerCustom;