import React from 'react';
import './LocationInput.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import CloseIcon from '../../../images/CloseIcon.svg';

class LocationInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chosen : props.value || null,
            disabled: props.disabled
        }
    }

    extractData = (placeId) => {
        const { chosen } = this.state;
        const data = {};
        geocodeByPlaceId(placeId)
            .then(results => {
                if (results.length > 0) {
                    const add = results[0];
                    for (let item of add.address_components) {
                        if (item.types.includes('postal_code')) {
                            data.postalCode = item.long_name;
                        }
                        else if (item.types.includes('locality')) {
                            data.city = item.long_name;
                        }
                        else if (item.types.includes('administrative_area_level_1')) {
                            data.province = item.long_name;
                        }
                    }
                }
          
      data.address = chosen.value.structured_formatting.main_text;
      data.location_data = chosen;
      this.props.onChange(data);
  })
  .catch(error => console.error(error));
}

    setVal = (val) => {
        this.setState({ chosen: val, disabled: true }, () => {
            this.extractData(val.value.place_id);
        });
    }

    clearSelection = () => {
        this.setState({ chosen: null, disabled: false });
        this.props.onChange({ postalCode: '', city: '', province: '' });
    }

    render() {
        const { chosen, disabled } = this.state;
        return <div className={`location-input-wrapper ${this.props.className}`}>
            {chosen ? <img className='clear-button' onClick={this.clearSelection} src={CloseIcon} alt='clear button' /> : null}
            <GooglePlacesAutocomplete
                apiKey="AIzaSyD1DJmKx0D1gStSrH_buK8z7yrDunFquXE"
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ['ca'],
                    }
                }}
                selectProps={{
                    value: chosen,
                    isDisabled: disabled,
                    noOptionsMessage: () => null,
                    placeholder: this.props.placeholder,
                    chosen,
                    classNamePrefix: this.props.classNamePrefix,
                    styles: this.props.selectStyles,
                    onChange: this.setVal
                }}
            />
        </div>
    }

}

export default LocationInput;