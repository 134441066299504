import React from "react";
import DolphinLogo from '../../../images/Dolphin-Logo.svg';
import './AdminFooter.css'
import { ReferenceData } from '../../../contentFile'

const FooterComponent = () => {
    return (
        <footer className="admin-footer">
            <img src={DolphinLogo} alt="DolphinLogo" /> {ReferenceData.FooterText}
        </footer>
    )
}

export default FooterComponent;