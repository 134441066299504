import React from "react";
import { utils } from "../../../../helpers/utils";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import AdminActionButton from "../../../Common/AdminActionButton/AdminActionButton";
class ApplicationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  viewApplication = (id) => {
    window.open(`viewapplication/${id}`, "_blank");
  };

  render() {
    const { application, currentProgram } = this.props;
    let showAssignForReview = true;
    let showPortCustomer = false;
    if (["Application Draft", "Application Information Requested"].includes(application.status)) {
      showPortCustomer = true;
    }
    if (application.status === "Application Draft") {
      showAssignForReview = false;
    }
    let showRetrofitAssignBtn = true;
    if (application.isPrimaryReviewer !== undefined) {

      let documentData = this.props.retrofitAppsDisableStartReview.filter(F => F?.application_id === String(application.id) && F?.reviewerState === 'In-Progress');
      documentData = documentData.length > 0 ? documentData[0] : null;
      if (documentData) {
        const reviewrLastUpdateTime = typeof documentData.updatedAt === 'object' ? documentData.updatedAt.toDate() : new Date(documentData.updatedAt);
        const timeDifferenceInSeconds = Math.abs(reviewrLastUpdateTime - new Date()) / 1000;
        if (timeDifferenceInSeconds > 3 && documentData.reviewerState === "In-Progress") {
          documentData.reviewerState = 'Idle';
          showRetrofitAssignBtn = true;
        } else {
          showRetrofitAssignBtn = false;
        }
      } else {
        showRetrofitAssignBtn = true;
      }
    }
    // if (application.isPrimaryReviewer) {
    //   if (application.reviewState === "In-Progress") {
    //     showRetrofitAssignBtn = false;
    //   }
    // } else if (!application.isPrimaryReviewer) {
    //   if (application.secondaryReviewState === "In-Progress") {
    //     showRetrofitAssignBtn = false;
    //   }
    // }
    return (
      <div className="preproject-details-row" id={this.props.id}>
        <div
          className={`preproject-details ${application.isLinkedToCES ? "linked-app" : ""} ${application.hasOwnProperty("isActive") && !application.isActive ? "inactive" : ""
            }`}
        >
          <div>
            <label>ID:</label>
            <span>
              {application.flag ? (
                <React.Fragment>
                  <FlagIcon currentStatus={application.flag.status} className="flag-app-icon" color={application.flag.colorCode} />
                </React.Fragment>
              ) : (
                ""
              )}
              {application.appNo}
            </span>
          </div>
          {currentProgram !== "retrofit" ? (
            <>
              <div>
                <label>Customer:</label>
                <span>{application.customerCompanyName}</span>
              </div>
              <div>
                <label>Contractor:</label>
                <span>{application.contractorCompanyName}</span>
              </div>
              {/* <div><label>Stage:</label><span>{application.stage}</span></div> */}
              <div>
                <label>Status:</label>
                <span>{application.status}</span>
              </div>
              <div>
                <label>Submitted Date:</label>
                <span>{application["Post-Project - Application Submitted Time"]}</span>
              </div>
              {/* <div><label>Submitted Date:</label><span>{application.submissionDate}</span></div> */}
              <div>
                <label>Pre-Approved Incentive:</label>
                <span>{utils.formatAsCAD(application.reviewerEstimatedIncentive)}</span>
              </div>
              <div>
                <label>Project Cost:</label>
                <span>{utils.formatAsCAD(application.ProjectCost)}</span>
              </div>
              <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
            </>
          ) : (
            <>
              <div>
                <label>Application Name:</label>
                <span>{application.applicationName}</span>
              </div>
              <div>
                <label>Application Project Type:</label>
                <span>{application.applicationProjectType}</span>
              </div>
              <div>
                <label>Email:</label>
                <span>{application.applicantEmail}</span>
              </div>
              <div>
                <label>Multi-Site:</label>
                <span>{application.multiSite}</span>
              </div>
              <div>
                <label>Status:</label>
                <span>{application.status}</span>
              </div>
              <div>
                <label>Pre-Project Approved Date:</label>
                <span>{application.preProjectApprovalDate}</span>
              </div>
              <div>
                <label>Post Project Submission Date:</label>
                <span>{application.postProjectSubmissionDate}</span>
              </div>
              <div>
                <label>Actual Incentive:</label>
                <span>{utils.formatAsCAD(application.actualIncentive)}</span>
              </div>
              <div>
                <label>Pre-Project QA:</label>
                <span>{application.preProjectQA}</span>
              </div>
              <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
            </>
          )}
        </div>
        <div className="preproject-more-details">
          <div>
            <AdminActionButton
              iconClass={`view-icon`}
              actionName={`Application Board-View Application`}
              viewApplication={this.viewApplication}
              application={application}
              title={"View Application"}
            />
            <AdminActionButton
              iconClass={`notes-icon`}
              actionName={`Application Board-Internal Notes`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"view-notes"}
              title={"Internal Notes"}
            />
            <AdminActionButton
              iconClass={`upload-icon`}
              actionName={`Application Board-Review Documents`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"review-documents"}
              title={"Review Documents"}
            />
            {application.isLinkedToCES ? null : application.hasOwnProperty("isActive") && !application.isActive ? (
              ""
            ) : (
              <React.Fragment>
                {showAssignForReview ? (
                  <AdminActionButton
                    iconClass={`assign-icon`}
                    actionName={`Application Board-Assign for Review`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalType={"assign-for-review"}
                    title={"Assign for Review"}
                  />
                ) : (
                  ""
                )}
                <AdminActionButton
                  iconClass={`view-icon`}
                  actionName={`Application Board-View Customer Applications`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-customer-applications"}
                  title={"View Customer Applications"}
                />
                <AdminActionButton
                  iconClass={`view-icon`}
                  actionName={`Application Board-View Applicant Projects`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-customer-applications"}
                  title={"View Applicant Projects"}
                />
                <AdminActionButton
                  iconClass={`status-log-icon`}
                  actionName={`Application Board-Internal Status Logs`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"internal-status-logs"}
                  title={"Internal Status Logs"}
                />
                {showRetrofitAssignBtn && (
                  <AdminActionButton
                    iconClass={`assign-icon`}
                    actionName={`Application Board-Assign for Primary Review`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalSubType={"assign"}
                    appModalType={"assign-for-primary-review"}
                    title={"Assign for Primary Review"}
                  />
                )}
                {showRetrofitAssignBtn && (
                  <AdminActionButton
                    iconClass={`change-reviewer`}
                    actionName={`Application Board-Change Primary Reviewer`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalSubType={"change"}
                    appModalType={"assign-for-primary-review"}
                    title={"Change Primary Reviewer"}
                  />
                )}
                {showRetrofitAssignBtn && (
                  <AdminActionButton
                    iconClass={`assign-icon`}
                    actionName={`Application Board-Assign for Secondary Review`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalType={"assign-for-secondary-review"}
                    title={"Assign for Secondary Review"}
                  />
                )}
                <AdminActionButton
                  iconClass={`flag-icon`}
                  actionName={`Application Board-Application Flags`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"flag-logs-application"}
                  title={"Application Flag"}
                />
                <AdminActionButton
                  iconClass={`status-log-icon`}
                  actionName={`Application Board-Status Logs`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-status-log"}
                  title={"Status Logs"}
                />
                {/* <div className="more-details-links change-request-icon" onClick={() => this.props.showActionModal({ ...application, modalType: 'view-change-requests' })}>View Change Requests</div> */}
                <AdminActionButton
                  iconClass={`change-request-icon`}
                  actionName={`Application Board-View Change Requests`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-change-requests"}
                  title={"View Change Requests"}
                />
                {/* <div className="more-details-links status-log-icon" onClick={() => this.props.showActionModal({ ...application, modalType: 'view-change-request-logs' })}>Change Request Logs</div> */}
                {/* <AdminActionButton 
                            iconClass={`status-log-icon`}
                            actionName={`Application Board-Change Request Logs`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'view-change-request-logs'}
                            title={'Change Request Logs'}
                        /> */}
                {showPortCustomer ? (
                  <AdminActionButton
                    iconClass={"port-customer-icon"}
                    actionName={"Application Board-Port Customer"}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalType={"port-customer"}
                    title={"Port Customer"}
                  />
                ) : null}
              </React.Fragment>
            )}
            {application.isLinkedToCES ? null : (
              <AdminActionButton
                iconClass={`state-icon`}
                actionName={`Application Board-Application State`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"application-state"}
                title={"Application State"}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationTab;
