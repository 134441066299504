import React, { Component } from "react";
import { AuthContext } from "../../../../firebaseAuthContext";
import { applicationService } from "../../../../services/applicationService";
import { userService } from "../../../../services/userService";
import { userActions } from "../../../../redux/actions/userActions";
import { appActions } from "../../../../redux/actions/appActions";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import LoaderComponent from "../../../Common/Loader/Loader";
import ProgramLayout from "../../../Common/ProgramLayout";
import Modal from "../../../Common/Modal/Modal";
import ResponsiveTable from "../../../Common/ResponsiveTable/ResponsiveTable";
import { ReactComponent as DownloadIcon } from "../../../../images/DownloadGrey.svg";
import "./ReviewResponses.css";

export class ReviewResponses extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
      }
      if (this.props.history.location.pathname.includes("retrofit")) {
        currentProgram = "retrofit";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      dataLoaded: false,
      applicationObj: {},
      detailsName: "",
      tableName: "",
      eppListTableRows: {},
      allMeasuresDetailsHeader: [],
      allMeasuresDetailsRow: [],
      openTextModal: false,
      summaryText: "",
      viewUploadedFilesModal: false,
      currentViewFileSection: "",
      currentViewFileFacilityId: "",
      currentViewFileMeasureId: "",
      facilities: {},
      currentQuestionFieldName: "",
    };
  }

  componentDidMount = async () => {
    const { id } = this.props.match.params;
    await this.props.getRetrofitApplicationsReviewProgress(id);
    applicationService.getApplicationRetrofit(id).then(async(appData) => {
      let facilities = "";
        try {
          facilities = await applicationService.getFacilitiesByApplicationIdRetrofit(id);
        } catch (err) {
          console.log("Facilities", err);
        }
      userService.getAdminConfigRetrofit().then((configData)=> {
        this.setState({
          applicationObj: appData,
          dataLoaded: true,
          configData: configData,
          facilities:facilities,
        });
      })
    });
  };

  applicationSummary = () => {
    const { applicationObj } = this.state;
    const { stage } = this.props.match.params;
    let preProjectReviewEndDate = applicationObj["First Internal Status - Pre-Project Application Pre-Approved Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Pre-Project Application Pre-Approved Time"])
      : applicationObj["First Internal Status - Pre-Project Application Rejected as Ineligible Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Pre-Project Application Rejected as Ineligible Time"])
      : applicationObj["First Internal Status - Pre-Project Cancelled Application Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Pre-Project Cancelled Application Time"])
      : utils.formatDate(applicationObj["First Internal Status - Pre-Project Pre-Project Application Withdrawn Time"]);

    let postProjectReviewEndDate = applicationObj["First Internal Status - Post-Project Application Post-Approved Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Post-Project Application Post-Approved Time"])
      : applicationObj["First Internal Status - Post-Project Application Rejected as Ineligible Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Post-Project Application Rejected as Ineligible Time"])
      : applicationObj["First Internal Status - Post-Project Cancelled Application Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Post-Project Cancelled Application Time"])
      : utils.formatDate(applicationObj["First Internal Status - Post-Project Post-Project Application Withdrawn Time"]);

    let preProjectReviewStartDate = applicationObj["First Internal Status - Pre-Project New Assigned Pre-Project Application Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Pre-Project New Assigned Pre-Project Application Time"])
      : applicationObj["First Internal Status - Pre-Project QA-QC - Mandatory Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Pre-Project QA-QC - Mandatory Time"])
      : utils.formatDate(applicationObj["First Internal Status - Pre-Project QA-QC - Random Selection Time"]);

    if (applicationObj["First Internal Status - Pre-Project New Assigned Pre-Project Application Time"]) {
      preProjectReviewStartDate =
        preProjectReviewStartDate <
        utils.formatDate(applicationObj["First Internal Status - Pre-Project New Assigned Pre-Project Application Time"])
          ? preProjectReviewStartDate
          : utils.formatDate(applicationObj["First Internal Status - Pre-Project New Assigned Pre-Project Application Time"]);
    }
    if (applicationObj["First Internal Status - Pre-Project QA-QC - Mandatory Time"]) {
      preProjectReviewStartDate =
        preProjectReviewStartDate < utils.formatDate(applicationObj["First Internal Status - Pre-Project QA-QC - Mandatory Time"])
          ? preProjectReviewStartDate
          : utils.formatDate(applicationObj["First Internal Status - Pre-Project QA-QC - Mandatory Time"]);
    }
    if (applicationObj["First Internal Status - Pre-Project QA-QC - Random Selection Time"]) {
      preProjectReviewStartDate =
        preProjectReviewStartDate < utils.formatDate(applicationObj["First Internal Status - Pre-Project QA-QC - Random Selection Time"])
          ? preProjectReviewStartDate
          : utils.formatDate(applicationObj["First Internal Status - Pre-Project QA-QC - Random Selection Time"]);
    }

    let postProjectReviewStartDate = applicationObj["First Internal Status - Post-Project New Assigned Post-Project Application Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Post-Project New Assigned Post-Project Application Time"])
      : applicationObj["First Internal Status - Post-Project QA-QC - Mandatory Time"]
      ? utils.formatDate(applicationObj["First Internal Status - Post-Project QA-QC - Mandatory Time"])
      : utils.formatDate(applicationObj["First Internal Status - Post-Project QA-QC - Random Selection Time"]);

    if (applicationObj["First Internal Status - Post-Project New Assigned Post-Project Application Time"]) {
      postProjectReviewStartDate =
        postProjectReviewStartDate <
        utils.formatDate(applicationObj["First Internal Status - Post-Project New Assigned Post-Project Application Time"])
          ? postProjectReviewStartDate
          : utils.formatDate(applicationObj["First Internal Status - Post-Project New Assigned Post-Project Application Time"]);
    }
    if (applicationObj["First Internal Status - Post-Project QA-QC - Mandatory Time"]) {
      postProjectReviewStartDate =
        postProjectReviewStartDate < utils.formatDate(applicationObj["First Internal Status - Post-Project QA-QC - Mandatory Time"])
          ? postProjectReviewStartDate
          : utils.formatDate(applicationObj["First Internal Status - Post-Project QA-QC - Mandatory Time"]);
    }
    if (applicationObj["First Internal Status - Post-Project QA-QC - Random Selection Time"]) {
      postProjectReviewStartDate =
        postProjectReviewStartDate < utils.formatDate(applicationObj["First Internal Status - Post-Project QA-QC - Random Selection Time"])
          ? postProjectReviewStartDate
          : utils.formatDate(applicationObj["First Internal Status - Post-Project QA-QC - Random Selection Time"]);
    }
    return (
      <div className="application-summary">
        <div className="step-header-container">
          <h2 className="step-header-container-title" dangerouslySetInnerHTML={{ __html: "Application Summary" }}></h2>
        </div>
        {applicationObj && (
          <div className="form-section">
            <div className={`details col-left ${stage === "Pre-Project" ? "pre-project-details" : ""}`}>
              <div className="form-inner-elements">
                <div className="application-form-label">Company</div>
                <div className="application-form-element">
                  {applicationObj.applicationDetails ? applicationObj.applicationDetails.application.company : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Applicant Name</div>
                <div className="application-form-element">{`${applicationObj.applicant_first_name} ${applicationObj.applicant_last_name}`}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Applicant Rep Name</div>
                <div className="application-form-element">
                  {applicationObj.app_rep_first_name ? `${applicationObj.app_rep_first_name} ${applicationObj.app_rep_last_name}` : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Submission Recieved Date</div>
                <div className="application-form-element">
                  {applicationObj.pre_project_submission_date ? utils.formatDate(applicationObj.pre_project_submission_date) : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Review Start Date</div>
                <div className="application-form-element">{preProjectReviewStartDate}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Primary Reviewer</div>
                <div className="application-form-element">{(applicationObj.reviewer && applicationObj.reviewer.label) || "N/A"}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Secondary Reviewer</div>
                <div className="application-form-element">
                  {(applicationObj.secondaryReviewer && applicationObj.secondaryReviewer.label) || "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Review End Date</div>
                <div className="application-form-element">{preProjectReviewEndDate}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Secondary Review Result</div>
                <div className="application-form-element">
                  {applicationObj.preProjectReviewResult ? applicationObj.preProjectReviewResult.label : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Secondary Review Internal QA Result</div>
                <div className="application-form-element">
                  {applicationObj.preProjectInternalQAResults ? applicationObj.preProjectInternalQAResults.label : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Affected Review Sections</div>
                <div className="application-form-element">
                  {applicationObj.preProjectAffectedReviewSections
                    ? Object.values(applicationObj.preProjectAffectedReviewSections)
                        .map((ele) => {
                          return ele.value;
                        })
                        .join()
                    : "N/A"}
                </div>
              </div>
            </div>
            {stage === "Post-Project" ? (
              <div className="vertical-separator-box">
                <div className="separator"></div>
              </div>
            ) : (
              ""
            )}
            {stage === "Post-Project" ? (
              <div className="details col-right">
                <div className="form-inner-elements">
                  <div className="application-form-label">Post-Project Review Start Date</div>
                  <div className="application-form-element">{postProjectReviewStartDate}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Post-Project Primary Reviewer</div>
                  <div className="application-form-element">
                    {(applicationObj.postProjectReviewer && applicationObj.postProjectReviewer.label) || "N/A"}
                  </div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Post-Project Secondary Reviewer</div>
                  <div className="application-form-element">
                    {(applicationObj.postProjectSecondaryReviewer && applicationObj.postProjectSecondaryReviewer.label) || "N/A"}
                  </div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Post-Project Review End Date</div>
                  <div className="application-form-element">{postProjectReviewEndDate}</div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Post-Project Secondary Review Result</div>
                  <div className="application-form-element">
                    {applicationObj.postProjectReviewResult
                      ? applicationObj.postProjectReviewResult.label
                      : applicationObj.postProjectReviewResult}
                  </div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Post-Project Secondary Review Internal QA Result</div>
                  <div className="application-form-element">
                    {applicationObj.postProjectInternalQAResults ? applicationObj.postProjectInternalQAResults.label : "N/A"}
                  </div>
                </div>
                <div className="form-inner-elements">
                  <div className="application-form-label">Post-Project Affected Review Sections</div>
                  <div className="application-form-element">
                    {applicationObj.postProjectAffectedReviewSections
                      ? Object.values(applicationObj.postProjectAffectedReviewSections)
                          .map((ele) => {
                            return ele.value;
                          })
                          .join()
                      : "N/A"}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  };

  setSelectedMeasure = (facilityId, measureId) => {
    const { applicationObj } = this.state;
    window.open(
      `/retrofit/secondaryreview/inforeview/${"measures"}/${measureId}/${facilityId}/${applicationObj.application_id}`,
      "",
      "resizable=no,width=800,height=900"
    );
  };

  viewFacilityDetails = (facilityId) => {
    const { applicationObj } = this.state;
    window.open(
      `/retrofit/secondaryreview/inforeview/${"facility"}/${facilityId}/${facilityId}/${applicationObj.application_id}`,
      "",
      "resizable=no,width=800,height=900"
    );
  };

  openUploadedDocumentModal = (facilityId, section, mid, fieldName) => {
    this.props.getRetrofitReviewProcessDocuments(facilityId);
    this.props.getRetrofitReviewProcessDocumentsLogs(facilityId);
    this.setState({
      viewUploadedFilesModal: true,
      currentViewFileSection: section,
      currentViewFileFacilityId: facilityId,
      currentViewFileMeasureId: mid,
      currentQuestionFieldName: fieldName
    });
  };

  getDeletedStatus = (arr, measureId, facilityId) => {
    let returnValue = false;
    for(let key in arr){
      if(arr[key].measureDeletedFacId == facilityId && arr[key].deletedMeasure == measureId) {
        returnValue = true
      }
    }
    return returnValue;
  }

  reviewResponses = () => {
    const { applicationObj, configData, facilities } = this.state;
    const { stage } = this.props.match.params;
    const { reviewProgress } = this.props;
    console.log(reviewProgress)
    let SelectedFilters = "";

    let preProjectSteps = [
      "Facility",
      "Project Eligibility",
      "Measures",
      "Project Costs",
      "Emergency Replacement",
      "QA/QC",
      "Final Document Checklist",
    ];

    let postProjectSteps = [
      "Facility",
      "Project Eligibility",
      "Measures",
      "Project Costs",
      "Emergency Replacement",
      "QA/QC",
      "Disposal",
      "Final Document Checklist",
    ];
    let steps = stage === "Post-Project" ? postProjectSteps : preProjectSteps;
    let allDeletedMeasureIds = [];
    facilities.map((fele)=>{
      fele.facilitiesDetails.prescriptiveMeasures.map((sele) => {
        if(sele.isActive === false) {
          allDeletedMeasureIds.push({measureDeletedFacId:fele.facility_id, deletedMeasure:sele.prescriptive_measure_id})
        }
      })
      if(fele.facilitiesDetails.customMeasures.length > 0){
        fele.facilitiesDetails.customMeasures.map((sele) => {
          if(sele.isActive === false) {
            allDeletedMeasureIds.push({measureDeletedFacId:fele.facility_id, deletedMeasure:sele.custom_measure_id})
          }
        })
      }
    })
    return (
      <div className="summary-tab">
        <div className="summary-container">
          <div className={`flag-${applicationObj.flag && applicationObj.flag.status}`}>
            {applicationObj.flag && applicationObj.flag.status}
          </div>
          <div className={`review-summar-info ${applicationObj.flag && applicationObj.flag.status}`}>
            <div className="questions-ans">
              {reviewProgress && reviewProgress[stage] && reviewProgress[stage].participantQuestions && (
                <div className="participant-question-ans">
                  <div className="q-header">Participant</div>
                  {reviewProgress &&
                    reviewProgress[stage] &&
                    reviewProgress[stage].participantQuestions &&
                    Object.keys(reviewProgress[stage].participantQuestions)
                      .filter((fEle) => {
                        if (applicationObj.isPrimaryReviewer) {
                          if (utils.isNullOrEmpty(SelectedFilters)) {
                            if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                              return fEle;
                            }
                          } else if (SelectedFilters.includes(reviewProgress[stage].participantQuestions[fEle].value)) {
                            return fEle;
                          }
                        } else {
                          if (utils.isNullOrEmpty(SelectedFilters)) {
                            if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                              return fEle;
                            }
                          } else if (SelectedFilters.includes(reviewProgress[stage].participantQuestions[fEle].value)) {
                            return fEle;
                          }
                        }
                      })
                      .sort((a, b) => {
                        let arrayA = a.split("").filter((val)=> val.match(/\d+/g));
                        let arrayB = b.split("").filter((val)=> val.match(/\d+/g));
                        let numA = Number(arrayA.join(""));
                        let numB = Number(arrayB.join(""));

                        return numA - numB;
                      })
                      .map((ele) => {
                        if (reviewProgress[stage].participantQuestions[ele].question === undefined) {
                          return (
                            <div className="question-ans">
                              <div className="question"></div>
                              <div className="ans">
                                <span className="turncate-text">{reviewProgress[stage].participantQuestions[ele]}</span>
                                <span
                                  className="expand"
                                  onClick={() =>
                                    this.setState({
                                      summaryText: reviewProgress[stage].participantQuestions[ele],
                                      openTextModal: true,
                                    })
                                  }
                                >
                                  Expand
                                </span>
                              </div>
                            </div>
                          );
                        } else if (reviewProgress[stage].participantQuestions[ele].question.includes("Other General discrepancies")) {
                          return (
                            <div className="question-ans">
                              <div className="question">{reviewProgress[stage].participantQuestions[ele].question}</div>
                              <div className="ans">
                                <span className="turncate-text">{reviewProgress[stage].participantQuestions[ele].value}</span>
                                <span
                                  className="expand"
                                  onClick={() =>
                                    this.setState({
                                      summaryText: reviewProgress[stage].participantQuestions[ele].value,
                                      openTextModal: true,
                                    })
                                  }
                                >
                                  Expand
                                </span>
                              </div>
                            </div>
                          );
                        } else if (reviewProgress[stage].participantQuestions[ele].question.includes("Upload")) {
                          return <div className="question-ans" style={{ display: "none" }}></div>;
                        } else if (reviewProgress[stage].participantQuestions[ele].label) {
                          return (
                            <div className="question-ans">
                              <div className="question">{reviewProgress[stage].participantQuestions[ele].question}</div>
                              <div className="ans">{reviewProgress[stage].participantQuestions[ele].value}</div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="question-ans">
                              <div className="question">{reviewProgress[stage].participantQuestions[ele].question}</div>
                              <div className="ans">{reviewProgress[stage].participantQuestions[ele].value}</div>
                            </div>
                          );
                        }
                      })}
                </div>
              )}
              {reviewProgress && reviewProgress[stage] && reviewProgress[stage].facilityQuestions && (
                <div className="facility-question-ans">
                  {reviewProgress[stage] &&
                    reviewProgress[stage].facilityQuestions &&
                    Object.keys(reviewProgress[stage].facilityQuestions).map((key) => {
                      return (
                        reviewProgress[stage].facilityQuestions &&
                        steps.map((keyEle) => (
                          <>
                            {keyEle === "Measures" || keyEle === "reviewState" ? (
                              ""
                            ) : (
                              <div className="q-header">
                                {`${keyEle}  - #`}
                                <span className="show-details" onClick={() => this.viewFacilityDetails(key)}>{`${key}`}</span>
                              </div>
                            )}
                            {reviewProgress[stage].facilityQuestions && reviewProgress[stage].facilityQuestions[key][keyEle]
                              ? keyEle === "Measures"
                                ? Object.keys(reviewProgress[stage].facilityQuestions[key][keyEle])
                                    .filter((fEle) => {
                                      if (fEle !== "reviewState" && fEle !== "secondaryReviewState" && !this.getDeletedStatus(allDeletedMeasureIds, fEle, key)) {
                                        return fEle;
                                      }
                                    })
                                    .map((mId) => {
                                      return <>
                                      <div className="q-header">
                                        {`Measure - #`}
                                        <span className="show-details" onClick={() => this.setSelectedMeasure(key, mId)}>{`${mId}`}</span>
                                        {`- #${key}`}
                                      </div>
                                      {Object.keys(reviewProgress[stage].facilityQuestions[key][keyEle][mId])
                                        .filter((fEle) => {
                                          if (applicationObj.isPrimaryReviewer) {
                                            if (utils.isNullOrEmpty(SelectedFilters)) {
                                              if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                                                return fEle;
                                              }
                                            } else if (
                                              SelectedFilters.includes(
                                                reviewProgress[stage].facilityQuestions[key][keyEle][mId][fEle].value
                                              )
                                            ) {
                                              return fEle;
                                            }
                                          } else {
                                            if (utils.isNullOrEmpty(SelectedFilters)) {
                                              if (
                                                fEle !== "reviewState" &&
                                                fEle !== "secondaryReviewState"
                                              ) {
                                                return fEle;
                                              }
                                            } else if (
                                              SelectedFilters.includes(
                                                reviewProgress[stage].facilityQuestions[key][keyEle][mId][fEle].value
                                              )
                                            ) {
                                              return fEle;
                                            }
                                          }
                                        })
                                        .sort((a, b) => {
                                          // let arrayA = a.split("").filter((val)=> val.match(/\d+/g));
                                          // let arrayB = b.split("").filter((val)=> val.match(/\d+/g));
                                          // let numA = Number(arrayA.join(""));
                                          // let numB = Number(arrayB.join(""));

                                          // return numA - numB;
                                          let questionInOrder = configData && configData.reviewQuestions ? configData.reviewQuestions["Facility"] : "";
                                          let eleA = questionInOrder.filter((element)=> element["fieldName"] === a);
                                          let eleB = questionInOrder.filter((element)=> element["fieldName"] === b);
                                          
                                          if ( eleA[0] && eleB[0]) {
                                            return eleA[0].order - eleB[0].order;
                                          }
                                        }).filter((validEle)=> !utils.isNullOrEmpty(reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][validEle]))
                                        .map((mField) => {
                                          if (reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].question === undefined) {
                                            return (
                                              <div className="question-ans">
                                                <div className="question"></div>
                                                <div className="ans">
                                                  <span className="turncate-text">
                                                    {reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField]}
                                                  </span>
                                                  <span
                                                    className="expand"
                                                    onClick={() =>
                                                      this.setState({
                                                        summaryText: reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField],
                                                        openTextModal: true,
                                                      })
                                                    }
                                                  >
                                                    Expand
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          } else if (
                                            reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].question.includes(
                                              "Other General discrepancies"
                                            )
                                          ) {
                                            return (
                                              <div className="question-ans">
                                                <div className="question">
                                                  {reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].question}
                                                </div>
                                                <div className="ans">
                                                  <span className="turncate-text">
                                                    {reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].value}
                                                  </span>
                                                  <span
                                                    className="expand"
                                                    onClick={() =>
                                                      this.setState({
                                                        summaryText:
                                                          reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].value,
                                                        openTextModal: true,
                                                      })
                                                    }
                                                  >
                                                    Expand
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          } else if (
                                            reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].question.includes("Upload ")
                                          ) {
                                            return (
                                              <div className="question-ans">
                                                <div className="question">
                                                  {
                                                    reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField]
                                                      .question
                                                  }
                                                </div>
                                                <div className="ans">
                                                  <span
                                                    className="view-file-btn"
                                                    onClick={() => this.openUploadedDocumentModal(key, "Measures", mId, mField)}
                                                  >
                                                    View Documents
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          } else if (reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].label) {
                                            return (
                                              <div className="question-ans">
                                                <div className="question">
                                                  {reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].question}
                                                </div>
                                                <div className="ans">
                                                  {reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].value}
                                                </div>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="question-ans">
                                                <div className="question">
                                                  {reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].question}
                                                </div>
                                                <div className="ans">
                                                  {reviewProgress[stage].facilityQuestions[key][keyEle][mId][mField].value}
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}
                                    </>
                                    })
                                : Object.keys(reviewProgress[stage].facilityQuestions[key][keyEle])
                                    .filter((fEle) => {
                                      if (applicationObj.isPrimaryReviewer) {
                                        if (utils.isNullOrEmpty(SelectedFilters)) {
                                          if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                                            return fEle;
                                          }
                                        } else if (
                                          SelectedFilters.includes(reviewProgress[stage].facilityQuestions[key][keyEle][fEle].value)
                                        ) {
                                          return fEle;
                                        }
                                      } else {
                                        if (utils.isNullOrEmpty(SelectedFilters)) {
                                          if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                                            return fEle;
                                          }
                                        } else if (
                                          SelectedFilters.includes(reviewProgress[stage].facilityQuestions[key][keyEle][fEle].value)
                                        ) {
                                          return fEle;
                                        }
                                      }
                                    })
                                    .sort((a, b) => {
                                      // let arrayA = a.split("").filter((val)=> val.match(/\d+/g));
                                      // let arrayB = b.split("").filter((val)=> val.match(/\d+/g));
                                      // let numA = Number(arrayA.join(""));
                                      // let numB = Number(arrayB.join(""));

                                      // return numA - numB;
                                      let questionInOrder = configData && configData.reviewQuestions ? configData.reviewQuestions["Facility"] : "";
                                      let eleA = questionInOrder.filter((element)=> element["fieldName"] === a);
                                      let eleB = questionInOrder.filter((element)=> element["fieldName"] === b);
                                      
                                      if ( eleA[0] && eleB[0]) {
                                        return eleA[0].order - eleB[0].order;
                                      }
                                    })
                                    .map((ele) => {
                                      if (reviewProgress[stage].facilityQuestions[key][keyEle][ele].question === undefined) {
                                        return (
                                          <div className="question-ans">
                                            <div className="question"></div>
                                            <div className="ans">
                                              <span className="turncate-text">
                                                {reviewProgress[stage].facilityQuestions[key][keyEle][ele]}
                                              </span>
                                              <span
                                                className="expand"
                                                onClick={() =>
                                                  this.setState({
                                                    summaryText: reviewProgress[stage].facilityQuestions[key][keyEle][ele],
                                                    openTextModal: true,
                                                  })
                                                }
                                              >
                                                Expand
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (
                                        reviewProgress[stage].facilityQuestions[key][keyEle][ele].question.includes(
                                          "Other General discrepancies"
                                        )
                                      ) {
                                        return (
                                          <div className="question-ans">
                                            <div className="question">
                                              {reviewProgress[stage].facilityQuestions[key][keyEle][ele].question}
                                            </div>
                                            <div className="ans">
                                              <span className="turncate-text">
                                                {reviewProgress[stage].facilityQuestions[key][keyEle][ele].value}
                                              </span>
                                              <span
                                                className="expand"
                                                onClick={() =>
                                                  this.setState({
                                                    summaryText: reviewProgress[stage].facilityQuestions[key][keyEle][ele].value,
                                                    openTextModal: true,
                                                  })
                                                }
                                              >
                                                Expand
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (reviewProgress[stage].facilityQuestions[key][keyEle][ele].question.includes("Upload ")) {
                                        return (
                                          <div className="question-ans">
                                            <div className="question">
                                              {reviewProgress[stage].facilityQuestions[key][keyEle][ele].question}
                                            </div>
                                            <div className="ans">
                                              <span
                                                className="view-file-btn"
                                                onClick={() => this.openUploadedDocumentModal(key, keyEle, "")}
                                              >
                                                View Documents
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (reviewProgress[stage].facilityQuestions[key][keyEle][ele].label) {
                                        return (
                                          <div className="question-ans">
                                            <div className="question">
                                              {reviewProgress[stage].facilityQuestions[key][keyEle][ele].question}
                                            </div>
                                            <div className="ans">{reviewProgress[stage].facilityQuestions[key][keyEle][ele].value}</div>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div className="question-ans">
                                            <div className="question">
                                              {reviewProgress[stage].facilityQuestions[key][keyEle][ele].question}
                                            </div>
                                            <div className="ans">{reviewProgress[stage].facilityQuestions[key][keyEle][ele].value}</div>
                                          </div>
                                        );
                                      }
                                    })
                              : ""}
                          </>
                        ))
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  downloadFile = (filePath) => {
    var link = document.createElement("a");
    link.target = "_blank";
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  };

  render() {
    const { dataLoaded, applicationObj, summaryText, openTextModal, viewUploadedFilesModal, currentQuestionFieldName, currentViewFileFacilityId, currentViewFileSection, currentViewFileMeasureId, } = this.state;
    const { stage } = this.props.match.params;
    const { reviewDocumentList, appReviewDocumentsLoading } = this.props;
    let uploadedDocuments = [];
    if (!utils.isNullOrEmpty(reviewDocumentList)) {
      uploadedDocuments = reviewDocumentList.filter((obj) => {
        if (currentViewFileSection === "Measures") {
          if (obj.measure_id === `${currentViewFileMeasureId}` && obj.fieldName === currentQuestionFieldName) {
            return obj;
          }
        } else if (currentViewFileSection === "QA/QC") {
          if (
            !obj.name.includes(`${currentViewFileFacilityId}`) &&
            obj.measure_id === "" &&
            obj.facility_id === `${currentViewFileFacilityId}` &&
            !obj.isFinalDocumentChecklist &&
            !obj.isDisposalDocument
          ) {
            return obj;
          }
        } else if (currentViewFileSection === "Final Document Checklist") {
          if (obj.isFinalDocumentChecklist) {
            return obj;
          }
        } else if (currentViewFileSection === "Disposal") {
          if (obj.isDisposalDocument) {
            return obj;
          }
        } else {
          return obj;
        }
      });
    }
    return (
      <ProgramLayout showFooter={false} showSideBarMenu={true} props={this.props}>
        {dataLoaded ? (
          <div className="app-review-responses">
            <Modal
              IsOpen={viewUploadedFilesModal}
              className={"esb-modal measure-details-modal"}
              handleCloseModal={() => this.setState({ viewUploadedFilesModal: false })}
              modalSize={"large"}
              loading={appReviewDocumentsLoading}
            >
              <div className="confirm-review-container">
                <div className="section-header">
                  <div className="section-top-details">{applicationObj.application_id}</div>
                  <div className="section-top-details name-section">{`${applicationObj.applicant_first_name} ${applicationObj.applicant_last_name}`}</div>
                  <div className="section-top-details">{applicationObj.applicant_account_id}</div>
                </div>
                <div className="section-below-header">
                  <h2 className="title" dangerouslySetInnerHTML={{ __html: "Uploaded Files" }}></h2>
                </div>
                {!utils.isNullOrEmpty(uploadedDocuments) ? <div className="uploaded-documents-table">
                  <ResponsiveTable
                    headings={[
                      { title: "S. No.", value: "SNO" },
                      { title: "Document Name", value: "name" },
                      { title: "Document Type", value: "extension" },
                      { title: "Uploaded Date", value: "createdAt" },
                      { title: "Uploaded By", value: "uploadedBy" },
                      { title: "", value: "moreActions" },
                    ]}
                    rows={uploadedDocuments.map((doc, index) => {
                      return {
                        SNO: index + 1,
                        name: (
                          <div className="doc-name">
                            <span className="turncate-string">
                              {doc.name}
                              <span className="tooltiptext">{doc.name}</span>
                            </span>
                          </div>
                        ),
                        extension: doc.extension,
                        createdAt: utils.formatDate(doc.createdAt),
                        uploadedBy: doc.actionTaker,
                        moreActions: (
                          <div className="more-actions">
                            <DownloadIcon
                              className="view-eye-icon"
                              width="30px"
                              height="20px"
                              onClick={() => this.downloadFile(doc.url)}
                            />
                          </div>
                        ),
                      };
                    })}
                  />
                </div> : <div className="no-records-found">No records.</div> }
              </div>
            </Modal>
            <Modal
              IsOpen={openTextModal}
              className={"esb-modal measure-details-modal"}
              handleCloseModal={() => this.setState({ openTextModal: false, summaryText: "" })}
              modalSize={"small"}
            >
              <div className="text-container">
                <p>{summaryText}</p>
              </div>
            </Modal>
            <div className="section-header-ret">
              <h2 className="section-title">{`Application ID: ${applicationObj.application_id}`}</h2>
              <div className="sub-heading">{`${stage} Review Responses`}</div>
            </div>
            <div className="summary-container-box">{this.applicationSummary()}</div>
            <div className={`responses`}>{this.reviewResponses()}</div>
          </div>
        ) : (
          <LoaderComponent />
        )}
      </ProgramLayout>
    );
  }
}
function mapState(state) {
  const { reviewProgress, reviewDocumentList, appReviewDocumentsLoading } = state.application;
  const { currentProgram, adminUserInfo } = state.authentication;
  const { configData } = state.settings;
  return {
    currentProgram,
    adminUserInfo,
    configData,
    reviewProgress,
    reviewDocumentList,
    appReviewDocumentsLoading
  };
}
const actionCreators = {
  setActiveProgram: userActions.setActiveProgram,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getRetrofitApplicationsReviewProgress: appActions.getRetrofitApplicationsReviewProgress,
  getRetrofitReviewProcessDocuments: appActions.getRetrofitReviewProcessDocuments,
  getRetrofitReviewProcessDocumentsLogs: appActions.getRetrofitReviewProcessDocumentsLogs,
};
export default connect(mapState, actionCreators)(ReviewResponses);
