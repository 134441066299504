import "./AdminLogin.css";
import React from "react";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import Modal from "../../Common/Modal/Modal";
import { utils } from "../../../helpers/utils";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../firebaseAuthContext";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { userService } from "../../../services/userService";
import AdminLayout from "../../Common/AdminLayout";

class AdminLogin extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      adminBlockInfoModal: false,
    };
  }

  goToDashboard = (path) => {
    this.props.history.push(path);
  };

  onChangeHandler = (name, value) => {
    this.props.setField(name, value);
  };

  verifyForm = () => {
    const { username, password } = this.props;
    let errors = {};
    let errorCount = 0;
    if (utils.isNullOrEmpty(username)) {
      errors.username = "Username is required.";
      errorCount++;
    }

    if (utils.isNullOrEmpty(password)) {
      errors.password = "Password is required.";
      errorCount++;
    }

    if (errorCount > 0) {
      this.props.setError(errors);
    }
    return errorCount === 0;
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { username, password } = this.props;
    if (this.verifyForm()) {
      // this.props.login(this.props.history, username, password);
      userService.getUserLoginActivities(username).then((activities) => {
        const { failCount, userExists } = activities;
        if (userExists === false) {
          let errors = {};
          errors.password = "You have entered an invalid username/password. Please try again.";
          this.props.setError(errors);
        }
        if (failCount === 5) {
          this.setState({
            adminBlockInfoModal: true,
          });
        } else {
          userService
            .login(username, password)
            .then(async (user) => {
              const result = await user.user.getIdTokenResult();
              userService.unblockUserAccount({ email: username, program: "" });
              if (result.claims.role) {
                this.props.history.push("/home");
              } else {
                await userService.logout();
              }
            })
            .catch((error) => {
              let errors = {};
              let { code, message } = error;
              if (code === "auth/user-not-found") {
                errors.password = "You have entered an invalid username/password. Please try again.";
                this.props.setError(errors);
              } else {
                if (failCount < 2 && code === "auth/wrong-password") {
                  userService.updateUserLoginActivities({ email: username, program: ""  }).then((data) => {
                    errors.password = "You have entered an invalid username/password. Please try again.";
                    this.props.setError(errors);
                  });
                } else if (failCount >= 2 && (code === "auth/wrong-password" || code === "auth/too-many-requests")) {
                  if(failCount === 4) {
                    this.setState({
                      userBlockInfoModal: true,
                    });
                  }
                  userService.updateUserLoginActivities({ email: username, program: ""  }).then((data) => {
                    errors.password = `You have entered an invalid username/password (${+(failCount)+1}/5). After 5 unsuccessful attempts, the account associated with the email id will be blocked`;
                    this.props.setError(errors);
                  });
                }
              }
            });
        }
      });
    }
  };

  closeAdminBlockInfoModal = () => {
    this.setState({
      adminBlockInfoModal: false,
    });
  };

  render() {
    const { username, password, errors } = this.props;
    const { adminBlockInfoModal } = this.state;
    if (this.context.user) {
      return <Redirect to="/home" />;
    }
    return (
      <AdminLayout showHeader={false} showSideBarMenu={false}>
        <Modal
          className="admin-blocked-modal"
          HeaderText={"ACCOUNT BLOCKED"}
          IsOpen={adminBlockInfoModal}
          handleCloseModal={this.closeAdminBlockInfoModal}
          modalSize={"small"}
        >
          <React.Fragment>
            <p>You have entered the incorrect password too many times. Your Account has been Blocked for the security purposes.</p>
            <p>Please reach out to your IT Admin to reset your password.</p>
            <Button title={"Go Back"} onClick={this.closeAdminBlockInfoModal} uppercase />
          </React.Fragment>
        </Modal>
        <div className="admin-login-container">
          <div className="logo"></div>
          <div className="section-header">
            <h2 className="section-title">Program Management Dashboard</h2>
          </div>
          <div className="container-box">
            <div className="form-section">
              <Input
                label={"Username"}
                name="username"
                value={username}
                onChange={this.onChangeHandler}
                error={errors ? errors["username"] : null}
                placeholder={"Enter Username"}
              />
              <Input
                label={"Password"}
                name="password"
                value={password}
                onChange={this.onChangeHandler}
                placeholder={"Enter Password"}
                onKeyDown={this._handleKeyDown}
                error={errors ? errors["password"] : null}
                type={"password"}
              />
              <Button
                className="login-btn"
                title={"Log In"}
                large
                uppercase
                onClick={this.handleSubmit}
                // ref={(ref)=>this.recaptcha=ref}
              />
              {/* <div className="login-links">Forgot Password?</div> */}
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

function mapState(state) {
  const { loggingIn, loggedIn, errors, username, password, passwordResetting, passwordReset, passwordResetError } = state.authentication;
  return { loggingIn, loggedIn, errors, username, password, passwordResetting, passwordReset, passwordResetError };
}

const actionCreators = {
  login: userActions.login,
  setError: userActions.setLoginError,
  setField: userActions.setLoginField,
  sendPasswordReset: userActions.passwordReset,
  clearPasswordReset: userActions.clearPasswordReset,
};

export default connect(mapState, actionCreators)(AdminLogin);
