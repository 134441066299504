import React from 'react';
import './FilterDropdown.css';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import CheckBox from '../CheckBox/CheckBox';
import Button from '../Button/Button'

class FilterDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label || null
        }

    }

    onFilterChange = (SelectedFilters) => {
        console.log('Selected Filters =>', SelectedFilters);
        this.props.onChange(SelectedFilters)
    }


    render() {
        const { label } = this.state
        const { SelectedFilters,FilterOptions } = this.props;
        return <div className="filter-component">
            <Select
                className={`${this.props.iconClass ? `${this.props.iconClass} ` : ''}filter-container ${SelectedFilters.length ? 'filter-selected' : ''}`}
                placeholder={label ? label : 'Filter By Status'}
                multiple
                displayEmpty={true}
                value={SelectedFilters}
                title={label ? label : 'Filter By Status'}
                onChange={(e) => {
                    const { value } = e.target;
                    console.log('value =>', value);
                    this.onFilterChange(value);
                }}
                input={<Input />}
                renderValue={(selected) => <React.Fragment>
                    <div className='filter-label'><span>{label}</span>{selected.length > 0 ? <div className='filter-badge'>{selected.length}</div> : ''}</div>
                    </React.Fragment>}
            >
                {this.props.iconBasedOption ? 
                FilterOptions.map((option) => (
                    <MenuItem className={`filter-option ${this.props.className ? this.props.className : ''}`} key={option.value} value={option.value}>
                        <CheckBox checked={SelectedFilters.indexOf(option.value) > -1} />
                        {option.icon}
                        <ListItemText primary={option.value} />
                    </MenuItem>
                )) : this.props.objectBasedOption ? FilterOptions.map((option) => (
                    <MenuItem className={`filter-option ${this.props.className ? this.props.className : ''}`} key={option.value} value={option.id}>
                        <CheckBox checked={SelectedFilters.indexOf(option.id) > -1} />
                        <ListItemText primary={option.value} />
                    </MenuItem>
                )) : FilterOptions.map((option) => (
                    <MenuItem className={`filter-option ${this.props.className ? this.props.className : ''}`} key={option} value={option}>
                        <CheckBox checked={SelectedFilters.indexOf(option) > -1} />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </div>
    }

}

export default FilterDropdown;