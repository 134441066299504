import React from 'react';
import './AppMeasureLogs.css';
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import Table from '../../../Common/Table/Table';
import EmptyContainer from '../../../Common/EmptyContainer/EmptyContainer';
import { applicationService } from '../../../../services/applicationService';
import LoaderComponent from '../../../Common/Loader/Loader';
class ApplicationMeasureLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appMeasuresLoading : true,
        applicationObj : {}
    }
}
  async componentDidMount() {
    if(this.props.applicationId){
      applicationService.getApplication(this.props.applicationId).then((appObj)=>{
        this.setState({
            applicationObj: appObj,
            appMeasuresLoading : false
        })
    });
    } else {
      this.setState({
        appMeasuresLoading : false
      })
    }
}

  render() {
      const {MeasureLogs,skipTopBit } = this.props;
      const {applicationObj,appMeasuresLoading} = this.state;
      return (
        <div className="app-measure-logs-container">
     {skipTopBit ? '' : <React.Fragment>
           {appMeasuresLoading ? <LoaderComponent/> : 
                     <ModalAppDataComponent  applicationObj={applicationObj}></ModalAppDataComponent>
            }
                    <div className="section-below-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'Measure Logs' }}></h2>
                    </div></React.Fragment>}
          {MeasureLogs && MeasureLogs.length ?
              <Table rows={MeasureLogs} headings={[
                {title:'Stage', value:'stage'}, 
                {title:'Status', value:'status'}, 
                {title:'Changes', value:'changes', custom : "measureLogs"}, 
                {title :'Log Time', value : 'createdAt'},
                {title :'Updated By', value : 'updatedBy'}
                ]}/> 
          
          : <EmptyContainer>No Logs to Show</EmptyContainer>}
        </div>
      );
    }
}


export default ApplicationMeasureLogs;
