import React from "react";
import "./ModalAppData.css";
class ModalAppDataComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationObj: props.applicationObj || {},
      participantInfo: props.participantInfo || {},
      modalTitle: props.modalTitle || "",
    };
  }
  render() {
    const { applicationObj, modalTitle,participantInfo } = this.state;
    const { program } = applicationObj;
    return (
      <React.Fragment>
        {applicationObj.appNo ? (
          <React.Fragment>
            <div className="section-header">
              {applicationObj.changeReqNo ? (
                <div className="section-top-details">CR-{applicationObj.changeReqNo}</div>
              ) : (
                <div className="section-top-details">{program === 'speed' ? 'SPEED' : applicationObj.stream === "ces" ? "CES" : 'ESB'}-{applicationObj.appNo}</div>
              )}

              <div className="section-top-details name-section">{applicationObj.companyName}</div>
              {<div className="section-top-details">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>}
            </div>
          </React.Fragment>
        ) : (
          <div className="section-header">
            <div className="section-top-details">{participantInfo.accountCategory === "contractor"?'CON-':"CUS-" }{participantInfo.userNo}</div>
            {/* {participantInfo.companyDetails.name} */}
            <div className="section-top-details name-section"> {participantInfo.companyDetails? participantInfo.companyDetails.name:''}</div>
            <div className="section-top-details">{participantInfo.email}</div> 
          </div>
        )}

        {modalTitle !== "" ? (
          <div className="section-below-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: modalTitle }}></h2>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default ModalAppDataComponent;
