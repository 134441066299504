import React, { Component } from "react";
import { AuthContext } from "../../../firebaseAuthContext";
import { applicationService } from "../../../services/applicationService";
import { userActions } from "../../../redux/actions/userActions";
import { adminSettingsActions } from "../../../redux/actions/adminSettingsActions";
import { connect } from "react-redux";
import { utils } from "../../../helpers/utils";
import LoaderComponent from "../../Common/Loader/Loader";
import Table from "../../Common/Table/Table";
import "./NewWindowPopup.css";
import { userService } from "../../../services/userService";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";

export class NewWindowPopup extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      detailsToShow: {},
      detailsName: "",
      tableName: "",
      eppListTableRows: {},
      allMeasuresDetailsHeader: [],
      allMeasuresDetailsRow: [],
    };
  }

  componentDidMount = async () => {
    const { infotype, typeId, facilityId, appId } = this.props.match.params;
    if (infotype === "epplist") {
      this.setEPPList();
    } else {
      applicationService
        .getApplicationRetrofit(appId)
        .then(async (appData) => {
          let facilities = "";
          try {
            facilities = await applicationService.getFacilitiesByApplicationIdRetrofit(appId);
          } catch (err) {
            console.log("Facilities", err);
          }
          let activeFacilities = facilities && facilities.filter((f) => f.isActive === true);
          if (infotype === "facility") {
            this.setFacilityTable(appData, activeFacilities, typeId);
          } else if (infotype === "measures") {
            this.setMeasureTable(activeFacilities, typeId, facilityId);
          } else if (infotype === "custom-measures") {
            this.setCustomMeasureTable(activeFacilities, typeId, facilityId);
          }else if (infotype === "projectcosts" || infotype === "disposal") {
            this.setAllMeasureTable(appData, activeFacilities, facilityId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  setFacilityTable = (appData, facilities, facilityId) => {
    let selectedFacility = facilities.filter((obj) => `${obj.facility_id}` === facilityId);
    this.viewFacilityDetails(appData, selectedFacility[0].facilitiesDetails);
  };
  viewFacilityDetails = (appData, facilitiesDetails) => {
    let details = [
      { label: "Facility Name", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_name) || "N/A" },
      { label: "Facility ID", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_id) || "N/A" },
      { label: "Facility Ownership", value: (facilitiesDetails.facility && facilitiesDetails.facility.ownership_type) || "N/A" },
      { label: "Facility Type", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_type_name) || "N/A" },
      {
        label: "Facility Address",
        value:
          (facilitiesDetails.facility &&
            `${facilitiesDetails.facility.address1},${
              facilitiesDetails.facility.address2 ? " " + facilitiesDetails.facility.address2 + "," : ""
            }${facilitiesDetails.facility.address3 ? " " + facilitiesDetails.facility.address3 + "," : ""} ${
              facilitiesDetails.facility.city
            }, ${facilitiesDetails.facility.province}, ${facilitiesDetails.facility.postal_code}`) ||
          "N/A",
      },
      {
        label: "Estimate Project Start Date",
        value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.estimated_project_start_date) || "N/A",
      },
      {
        label: "Estimated Facility Incentive",
        value: utils.formatAsCAD(facilitiesDetails.facility && facilitiesDetails.facility.tot_est_facility_incentive) || "N/A",
      },
      {
        label: "Other Financial Incentives",
        value: (facilitiesDetails.facility && facilitiesDetails.facility.other_financial_incentives) || "N/A",
      },
      {
        label: "Pre-Project Estimated Energy Savings",
        value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_estimated_kwh_saving}kwh` || "N/A",
      },
      {
        label: "Pre-Project Estimated Demand Savings",
        value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_est_peak_demand_savings}kwh` || "N/A",
      },
    ];
    if (appData.stage === "Post-Project") {
      details = [
        { label: "Facility Name", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_name) || "N/A" },
        { label: "Facility ID", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_id) || "N/A" },
        { label: "Facility Ownership", value: (facilitiesDetails.facility && facilitiesDetails.facility.ownership_type) || "N/A" },
        { label: "Facility Type", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_type_name) || "N/A" },
        {
          label: "Facility Address",
          value:
            (facilitiesDetails.facility &&
              `${facilitiesDetails.facility.address1},${
                facilitiesDetails.facility.address2 ? " " + facilitiesDetails.facility.address2 + "," : ""
              }${facilitiesDetails.facility.address3 ? " " + facilitiesDetails.facility.address3 + "," : ""} ${
                facilitiesDetails.facility.city
              }, ${facilitiesDetails.facility.province}, ${facilitiesDetails.facility.postal_code}`) ||
            "N/A",
        },
        {
          label: "Estimate Project Start Date",
          value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.estimated_project_start_date) || "N/A",
        },
        {
          label: "Actual Project Start Date",
          value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.actual_project_start_date) || "N/A",
        },
        {
          label: "Actual Project Completion Date",
          value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.actual_project_completion_date) || "N/A",
        },
        {
          label: "Estimated Facility Incentive",
          value: utils.formatAsCAD(facilitiesDetails.facility && facilitiesDetails.facility.tot_est_facility_incentive) || "N/A",
        },
        {
          label: "Actual Facility Incentive",
          value: utils.formatAsCAD(facilitiesDetails.facility && facilitiesDetails.facility.tot_actual_facility_incentive) || "N/A",
        },
        {
          label: "Other Financial Incentives",
          value: (facilitiesDetails.facility && facilitiesDetails.facility.other_financial_incentives) || "N/A",
        },
        {
          label: "Pre-Project Estimated Energy Savings",
          value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_estimated_kwh_saving}kwh` || "N/A",
        },
        {
          label: "Actual Energy Savings",
          value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_actual_kwh_saving}kwh` || "N/A",
        },
        {
          label: "Pre-Project Estimated Demand Savings",
          value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_est_peak_demand_savings}kwh` || "N/A",
        },
        {
          label: "Actual Energy Savings",
          value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_act_peak_demand_savings}kwh` || "N/A",
        },
      ];
    }
    this.setState({
      dataLoaded: true,
      detailsToShow: details,
      detailsName: "Facility Details",
    });
  };
  setAllMeasureTable = (appData, activeFacilities, facilityId) => {
    let selectedFacility = activeFacilities.filter((obj) => `${obj.facility_id}` === facilityId);
    let allMeasures = selectedFacility[0].facilitiesDetails ? [...selectedFacility[0].facilitiesDetails.prescriptiveMeasures, ...selectedFacility[0].facilitiesDetails.customMeasures] : [];
    this.viewAllMeasures(appData, allMeasures);
  };
  viewAllMeasures = (appData, allMeasures) => {
    let tableHeader =
      appData.stage === "Pre-Project"
        ? [
            { title: "Measure ID", value: "measureId" },
            { title: "Estimated Qty", value: "estimatedQty" },
            { title: "Model number", value: "modelNumber" },
          ]
        : [
            { title: "Measure ID", value: "measureId" },
            { title: "Estimated Qty", value: "estimatedQty" },
            { title: "Actual Qty", value: "actualQty" },
            { title: "Model number", value: "modelNumber" },
          ];
    let rows =
      appData.stage === "Pre-Project"
        ? allMeasures.map((measure) => {
            return {
              measureId: measure.prescriptive_measure_id || measure.custom_measure_id,
              estimatedQty: measure.estimated_quantity || "1",
              modelNumber: measure.model_number,
            };
          })
        : allMeasures.map((measure) => {
            return {
              measureId: measure.prescriptive_measure_id || measure.custom_measure_id,
              estimatedQty: measure.estimated_quantity || "1",
              actualQty: measure.actual_quantity || "1",
              modelNumber: measure.model_number,
            };
          });

    this.setState({
      dataLoaded: true,
      allMeasuresDetailsHeader: tableHeader,
      allMeasuresDetailsRow: rows,
      detailsName: "Measure Specific Fields",
      tableName: "All-Measures",
    });
  };
  setCustomMeasureTable = (facilities, measureId, facilityId) => {
    let selectedFacility = facilities.filter((obj) => `${obj.facility_id}` === facilityId);
    let selectedMeasure = selectedFacility[0].facilitiesDetails
      ? [...selectedFacility[0].facilitiesDetails.prescriptiveMeasures, ...selectedFacility[0].facilitiesDetails.customMeasures].filter((obj) => `${obj.prescriptive_measure_id || obj.custom_measure_id}` === measureId)
      : [];
    this.viewCustomMeasureDetails(selectedMeasure[0]);
  };
  viewCustomMeasureDetails = (measure) => {
    let details = [
      { label: "Custom Measure ID", value: measure.custom_measure_id || "N/A" },
      { label: "Measure Name", value: measure.custom_measure_name || "N/A" },
      { label: "Category", value: measure.custom_measure_category || "N/A" },
      { label: "End Use", value: measure.end_use || "N/A" },
      { label: "Sub-Category", value: measure.sub_category || "N/A" },
      { label: "Other Sub-Category", value: measure.sub_category_other || "N/A" },
      { label: "Base Case Description", value: measure.base_case_description || "N/A" },
      { label: "Energy Efficient Case Description", value: measure.energy_efficient_case_desc || "N/A" },
      { label: "Manufacturer", value: measure.manufacturer || "N/A" },
      { label: "Model Number", value: measure.model_number || "N/A" },
      { label: "Estimated Base Case Consumption", value: measure.est_base_case_consumption || "N/A" },
      { label: "Estimated Energy Efficient Case Consumed", value: measure.est_energy_eff_case_consume || "N/A" },
      { label: "Estimated Base Case Demand", value: measure.est_base_case_demand || "N/A" },
      { label: "Estimated Energy Efficient Case Demand", value: measure.est_energy_eff_case_demand || "N/A" },
      { label: "Actual Base Case Consumption", value: measure.actual_base_case_consumption || "N/A" },
      { label: "Actual Energy Efficient Case Consumed", value: measure.actual_nrg_eff_case_consume || "N/A" },
      { label: "Actual Base Case Demand", value: measure.actual_base_case_demand || "N/A" },
      { label: "Actual Energy Efficient Case Demand", value: measure.actual_nrg_eff_case_demand || "N/A" },
      { label: "Estimated kWh Savings", value: measure.estimated_kwh_savings || "N/A" },
      { label: "Actual kWh Savings", value: measure.actual_kwh_savings || "N/A" },
      { label: "Estimated kW Reduction", value: measure.estimated_kw_reduction || "N/A" },
      { label: "Actual kW Reduction", value: measure.actual_kw_reduction || "N/A" },
      { label: "Incentive Metric Pre-Project", value: measure.incentive_metric_pre_project || "N/A" },
      { label: "Calculated Incentive Pre-Project", value: measure.calculated_incentive_pre_proj || "N/A" },
      { label: "Incentive Metric Post-Project", value: measure.incentive_metric_post_project || "N/A" },
      { label: "Calculated Incentive Post-Project", value: measure.calculated_incentive_post_proj || "N/A" },
      { label: "Estimated Equipment Cost", value: measure.estimated_equipment_cost || "N/A" },
      { label: "Actual Equipment Cost", value: measure.actual_equipment_cost || "N/A" },
      { label: "Estimated Cost Summary", value: measure.estimated_cost_summary || "N/A" },
      { label: "Actual Cost Summary", value: measure.actual_cost_summary || "N/A" },
      { label: "One Month Meter Load", value: measure.one_month_meter_load || "N/A" },
      { label: "M&T Advance Incentive Amount", value: measure.mt_advance_incentive || "N/A" },
      { label: "TSM Advance Incentive", value: measure.tsm_advance_incentive || "N/A" },
      { label: "Estimated Installation Cost", value: measure.estimated_installation_cost || "N/A" },
      { label: "Actual Installation Cost", value: measure.actual_installation_cost || "N/A" },
      { label: "Is Complete?", value: measure.is_complete || "N/A" },
      { label: "Engineered", value: measure.engineered || "N/A" },
      { label: "Creation Date", value: measure.creation_dt || "N/A" },
    ];
    this.setState({
      dataLoaded: true,
      detailsToShow: details,
      detailsName: "Measure Specific Fields",
    });
  };
  setMeasureTable = (facilities, measureId, facilityId) => {
    let selectedFacility = facilities.filter((obj) => `${obj.facility_id}` === facilityId);
    let selectedMeasure = selectedFacility[0].facilitiesDetails
      ? [...selectedFacility[0].facilitiesDetails.prescriptiveMeasures, ...selectedFacility[0].facilitiesDetails.customMeasures].filter((obj) => `${obj.prescriptive_measure_id || obj.custom_measure_id}` === measureId)
      : [];
    this.viewMeasureDetails(selectedMeasure[0]);
  };
  viewMeasureDetails = (measure) => {
    let details = [
      { label: "Prescriptive Measure ID", value: measure.prescriptive_measure_id || measure.custom_measure_id || "N/A" },
      { label: "Conservation Measure Name", value: measure.conservation_measure_name || measure.custom_measure_name || "N/A" },
      { label: "Measure Category", value: measure.measure_category || measure.custom_measure_category || "N/A" },
      { label: "Measure Description", value: measure.measure_description || "N/A" },
      { label: "End-use", value: measure.end_use || "N/A" },
      { label: "Eligibility criteria", value: measure.eligibility_criteria || "N/A" },
      { label: "Manufacturer", value: measure.manufacturer || "N/A" },
      {
        label: "Model Number",
        value: measure.model_number || "N/A",
      },
      { label: "ES/DLC Code", value: measure.es_dlc_code || "N/A" },
      { label: "Assumed Base Case", value: measure.assumed_base_case || "N/A" },
      { label: "Estimated Quantity", value: measure.estimated_quantity || "N/A" },
      { label: "Actual Quantity", value: measure.actual_quantity || "N/A" },
      { label: "Measure Unit", value: measure.measure_unit || "N/A" },
      { label: "Incentive per Unit", value: measure.incentive_per_unit || "N/A" },
      { label: "Estimated Calculated Incentive", value: utils.formatAsCAD(measure.estimated_calculated_incentive) },
      { label: "Actual Calculated Incentive", value: utils.formatAsCAD(measure.actual_calculated_incentive) },
      { label: "Estimated kWh Savings", value: measure.estimated_kwh_saving || measure.estimated_kwh_savings || "N/A" },
      { label: "Actual kWh Savings", value: measure.actual_kwh_saving || measure.actual_kwh_savings || "N/A" },
      { label: "Estimated Peak Demand Savings", value: measure.est_peak_demand_savings || "N/A" },
      { label: "Actual Peak Demand Savings", value: measure.act_peak_demand_savings || "N/A" },
      { label: "Energy Saving per Unit", value: measure.energy_saving_per_unit || "N/A" },
      { label: "Demand Saving per Unit", value: measure.demand_saving_per_unit || "N/A" },
      { label: "Peak Demand Savings", value: measure.peak_demand_savings || "N/A" },
      { label: "Estimated kW Reduction", value: measure.estimated_kw_reduction || "N/A" },
      { label: "Actual kW Reduction", value: measure.acutal_kw_reduction || "N/A" },
      { label: "Measure Creation Date", value: measure.creation_dt || "N/A" },
      { label: "Is complete?", value: measure.is_complete || "N/A" },
    ];
    this.setState({
      dataLoaded: true,
      detailsToShow: details,
      detailsName: "Measure Specific Fields",
    });
  };

  setEPPList = async () => {
    await userService.getAdminConfigRetrofit().then((configData) => {
      if (configData.eppList && configData.eppList.epp.length) {
        this.setState({
          dataLoaded: true,
          tableName: "EPP",
          eppListTableRows: configData.eppList.epp,
          detailsName: "EPP List",
        });
      }
    });
    // const { currentProgram, adminUserInfo, configData} = this.props;
  };
  render() {
    const { detailsName, dataLoaded, detailsToShow, tableName, eppListTableRows, allMeasuresDetailsHeader, allMeasuresDetailsRow } =
      this.state;
    return (
      <div className="new-window">
        {dataLoaded ? (
          tableName === "EPP" ? (
            <>
              <h3>{detailsName}</h3>
              <ResponsiveTable
                rows={eppListTableRows}
                headings={[
                  { title: "Identifier", value: "identifier" },
                  { title: "Participant", value: "participant" },
                  { title: "Sector", value: "sector" },
                  { title: "Facility", value: "facility" },
                  { title: "Facility Address", value: "facilityAddress" },
                  { title: "Framework", value: "framework" },
                ]}
              />
            </>
          ) : tableName === "All-Measures" ? (
            <>
              <h3>{detailsName}</h3>
              <ResponsiveTable rows={allMeasuresDetailsRow.map((row) => row)} headings={allMeasuresDetailsHeader} />
            </>
          ) : (
            <>
              <h3>{detailsName}</h3>
              <div className="new-window-container">
                {detailsToShow ? (
                  <Table
                    rows={detailsToShow}
                    headings={[
                      { title: "Field Name", value: "label" },
                      { title: "Field Value", value: "value" },
                    ]}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          )
        ) : (
          <LoaderComponent />
        )}
      </div>
    );
  }
}
function mapState(state) {
  const { currentProgram, adminUserInfo } = state.authentication;
  const { configData } = state.settings;
  return {
    currentProgram,
    adminUserInfo,
    configData,
  };
}
const actionCreators = {
  setActiveProgram: userActions.setActiveProgram,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
};
export default connect(mapState, actionCreators)(NewWindowPopup);
