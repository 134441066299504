import React from "react";
import {
    Router,
    Switch,
    Route
} from "react-router-dom";
import { history } from './helpers/history'
import ParticipantBoard from './components/Pages/ParticipantBoard/ParticipantBoard';
import ChangeRequestBoard from './components/Pages/ChangeRequestBoard/ChangeRequestBoard';
import ReviewBoard from './components/Pages/ReviewBoard/ReviewBoard';
import ApplicationsBoard from './components/Pages/ApplicationsBoard/ApplicationsBoard';
import AdminHome from './components/Pages/AdminDashboard/Home/Home';
import AdminSettings from './components/Pages/AdminDashboard/Settings/Settings';
import AdminReports from './components/Pages/AdminDashboard/Reports/Reports';
import AdminLogin from './components/Pages/AdminLogin/AdminLogin';
import AppSummary from './components/Pages/AppSummary/AppSummary';
import IPNSummary from './components/Pages/IPNSummary/IPNSummary';
import AdminAccountVerification from "./components/Pages/AdminAccountVerification/AdminAccountVerification";
import ProtectedRoute from './components/Common/ProtectedRoute';
import IncentiveReviewBoard from './components/Pages/IncentiveReviewBoard/IncentiveReviewBoard';
import PayoutBoard from './components/Pages/PayoutBoard/PayoutBoard'; 
import AppSummaryRetrofit from "./components/Pages/AppSummaryRetrofit/AppSummaryRetrofit";
import AppReviewRetrofit from "./components/Pages/AppReviewRetrofit/AppReviewRetrofit";
import NewWindowPopup from "./components/Pages/AppReviewRetrofit/NewWindowPopup";
import ReviewResponses from "./components/Pages/AppSummaryRetrofit/ReviewResponses/ReviewResponses";
export default function Main() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={AdminLogin} />
                <ProtectedRoute path="/home" redirectTo="/" component={AdminHome} />
                <ProtectedRoute path="/verification" redirectTo="/" component={AdminAccountVerification} />
                <ProtectedRoute path="/admin" redirectTo="/" component={AdminHome}/>
                <ProtectedRoute path="/reports" redirectTo="/" component={AdminReports}/>
                <ProtectedRoute path="/settings" redirectTo="/" component={AdminSettings}/>
                <ProtectedRoute path="/esb/participant-board" redirectTo="/" component={ParticipantBoard}/>
                <ProtectedRoute path="/esb/review-board" redirectTo="/" component={ReviewBoard}/>
                <ProtectedRoute path="/esb/applications-board" redirectTo="/" component={ApplicationsBoard}/>
                <ProtectedRoute path="/esb/change-request-board" redirectTo="/" component={ChangeRequestBoard}/>
                <ProtectedRoute path="/esb/viewapplication/:id" redirectTo="/" component={AppSummary}/>
                <ProtectedRoute path="/esb/viewipn/:id" redirectTo="/" component={IPNSummary}/>
                <ProtectedRoute path="/esb/incentive-review-board" redirectTo="/" component={IncentiveReviewBoard}/>
                <ProtectedRoute path="/esb/payout-board" redirectTo="/" component={PayoutBoard}/>
                <ProtectedRoute path="/esb" redirectTo="/" component={ParticipantBoard}/>
                <ProtectedRoute path="/speed/participant-board" redirectTo="/" component={ParticipantBoard}/>
                <ProtectedRoute path="/speed/applications-board" redirectTo="/" component={ApplicationsBoard}/>
                <ProtectedRoute path="/speed/review-board" redirectTo="/" component={ReviewBoard}/>
                <ProtectedRoute path="/speed/change-request-board" redirectTo="/" component={ChangeRequestBoard}/>
                <ProtectedRoute path="/speed/viewapplication/:id" redirectTo="/" component={AppSummary}/>
                <ProtectedRoute path="/speed/viewipn/:id" redirectTo="/" component={IPNSummary}/>
                <ProtectedRoute path="/speed/incentive-review-board" redirectTo="/" component={IncentiveReviewBoard}/>
                <ProtectedRoute path="/speed/payout-board" redirectTo="/" component={PayoutBoard}/>
                <ProtectedRoute path="/speed" redirectTo="/" component={ParticipantBoard}/>
                <ProtectedRoute path="/retrofit/viewapplication/reviewresponses/:stage/:id" redirectTo="/" component={ReviewResponses}/>
                <ProtectedRoute path="/retrofit/viewapplication/:id" redirectTo="/" component={AppSummaryRetrofit}/>
                <ProtectedRoute path="/retrofit/primaryreview/inforeview/:infotype/:typeId/:facilityId/:appId" redirectTo="/" component={NewWindowPopup}/>
                <ProtectedRoute path="/retrofit/secondaryreview/inforeview/:infotype/:typeId/:facilityId/:appId" redirectTo="/" component={NewWindowPopup}/>
                <ProtectedRoute path="/retrofit/primaryreview/inforeview/:infotype" redirectTo="/" component={NewWindowPopup}/>
                <ProtectedRoute path="/retrofit/secondaryreview/inforeview/:infotype" redirectTo="/" component={NewWindowPopup}/>
                <ProtectedRoute path="/retrofit/primaryreview/:id" redirectTo="/" component={AppReviewRetrofit}/>
                <ProtectedRoute path="/retrofit/secondaryreview/:id" redirectTo="/" component={AppReviewRetrofit}/>
                <ProtectedRoute path="/retrofit/participant-board" redirectTo="/" component={ParticipantBoard}/>
                <ProtectedRoute path="/retrofit/applications-board" redirectTo="/" component={ApplicationsBoard}/>
                <ProtectedRoute path="/retrofit/review-board" redirectTo="/" component={ReviewBoard}/>
                <ProtectedRoute path="/retrofit" redirectTo="/" component={ParticipantBoard}/>
            </Switch>
        </Router>
    );
}