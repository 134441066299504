import React from "react";
import { utils } from "../../../../helpers/utils";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import AdminActionButton from "../../../Common/AdminActionButton/AdminActionButton";

class ApplicationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  viewApplication = (id) => {
    window.open(`viewapplication/${id}`, "_blank");
  };

  render() {
    const { application, currentProgram } = this.props;
    let showReviewDocument = false;
    if (
      application.status === "Application Submitted" ||
      application.status === "QA/QC In Progress" ||
      application.status === "Application Under Review" ||
      application.status === "Application Information Requested" ||
      application.status === "Application Rejected" ||
      application.status === "Application Draft" ||
      currentProgram === "retrofit"
    ) {
      showReviewDocument = true;
    }
    const status = application.status ? application.status : "";
    // console.log("Existing Application Status",application);
    const statusConfig = {
      showIR: ["Application Under Review", "QA/QC In Progress"].includes(status),
      showApproveQAQC: ["QA/QC In Progress"].includes(status),
      showApproveApplication: ["Application Under Review"].includes(status),
      showRejectApplication: ["Application Information Requested", "Application Under Review", "QA/QC In Progress"].includes(status),
    };
    return (
      <div className="payment-details-row" id={this.props.id}>
        {currentProgram !== "retrofit" ? (
          <div className="payment-details">
            <div>
              <label>ID:</label>
              <span>
                {application.flag ? (
                  <React.Fragment>
                    <FlagIcon currentStatus={application.flag.status} className="flag-app-icon" color={application.flag.colorCode} />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {application.appNo}
              </span>
            </div>
            <div>
              <label>Payee:</label>
              <span>{application.payeeName}</span>
            </div>
            <div>
              <label>Contractor:</label>
              <span>{application.contractorCompanyName}</span>
            </div>
            <div>
              <label>Customer:</label>
              <span>{application.customerCompanyName}</span>
            </div>
            <div>
              <label>Submission Date:</label>
              <span>{application.submissionDate}</span>
            </div>
            <div>
              <label>Approved Incentive:</label>
              <span>{utils.formatAsCAD(application["PostProjectReviewerApprovedIncentive"])}</span>
            </div>
            <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
          </div>
        ) : (
          <div className="payment-details">
            <div>
              <label>ID:</label>
              <span>
                {application.flag ? (
                  <React.Fragment>
                    <FlagIcon currentStatus={application.flag.status} className="flag-app-icon" color={application.flag.colorCode} />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {application.appNo}
              </span>
            </div>
            <div>
              <label>Application Name:</label>
              <span>{application.applicationName}</span>
            </div>
            <div>
              <label>Application Project Type:</label>
              <span>{application.applicationProjectType}</span>
            </div>
            <div>
              <label>Email:</label>
              <span>{application.applicantEmail}</span>
            </div>
            <div>
              <label>Multi-Site:</label>
              <span>{application.multiSite}</span>
            </div>
            <div>
              <label>Status:</label>
              <span>{application.status}</span>
            </div>
            <div>
              <label>Post-Project Approved Date:</label>
              <span>{application.preProjectApprovalDate}</span>
            </div>
            <div>
              <label>Invoice Submitted Date:</label>
              <span>{application.invoiceSubmittedDate}</span>
            </div>
            <div>
              <label>Estimated Incentive:</label>
              <span>{utils.formatAsCAD(application.estimatedIncentive)}</span>
            </div>
            <div>
              <label>IR Response:</label>
              <span>{application.postProjectResubmission}</span>
            </div>
            <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
          </div>
        )}
        <div className="payment-more-details">
          <div>
            <AdminActionButton
              iconClass={`view-icon`}
              actionName={`Review Board-View Application`}
              viewApplication={this.viewApplication}
              application={application}
              title={"View Application"}
            />
            <AdminActionButton
              iconClass={`view-icon`}
              actionName={`Review Board-View Customer Applications`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"view-customer-applications"}
              title={"View Customer Applications"}
            />
            <AdminActionButton
              iconClass={`bank-details-icon`}
              actionName={`Review Board-Review Banking Details`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"review-banking-details"}
              title={"Review Banking Details"}
            />
            <AdminActionButton
              iconClass={`approve-app-icon`}
              actionName={`Review Board-Complete Invoice Review`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"invoice-review"}
              title={"Complete Invoice Review"}
            />
            <AdminActionButton
              iconClass={`view-icon`}
              actionName={`Application Board-View Applicant Projects`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"view-customer-applications"}
              title={"View Applicant Projects"}
            />
            {/* <AdminActionButton 
                        iconClass={`status-log-icon`}
                        actionName={`Review Board-Flag Logs`}
                        showActionModal={this.props.showActionModal}
                        application={application}
                        appModalType={'flag-logs-application'}
                        title={'Flag Logs'}
                    /> */}
            <AdminActionButton
              iconClass={`flag-icon`}
              actionName={`Review Board-Application Flags`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"flag-logs-application"}
              title={"Application Flag"}
            />
            {statusConfig.showIR ? (
              <AdminActionButton
                iconClass={`req-info-icon`}
                actionName={`Review Board-Request Information`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"information-requested"}
                title={"Request Information"}
              />
            ) : (
              ""
            )}
            {statusConfig.showRejectApplication ? (
              <AdminActionButton
                iconClass={`reject-app-icon`}
                actionName={`Review Board-Reject Application`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"reject-app"}
                title={"Reject Application"}
              />
            ) : (
              ""
            )}
            {statusConfig.showApproveApplication ? (
              <AdminActionButton
                iconClass={`approve-app-icon`}
                actionName={`Review Board-Approve Application`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"review-application"}
                title={"Approve Application"}
              />
            ) : (
              ""
            )}
            {statusConfig.showApproveQAQC ? (
              <AdminActionButton
                iconClass={`approve-app-icon`}
                actionName={`Review Board-Approve QA/QC`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"approve-qaqc"}
                title={"Approve QA/QC"}
              />
            ) : (
              ""
            )}
            <AdminActionButton
              iconClass={`status-log-icon`}
              actionName={`Review Board-Status Logs`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"view-status-log"}
              title={"Status Logs"}
            />
            <AdminActionButton
              iconClass={`notes-icon`}
              actionName={`Review Board-Internal Notes`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"view-notes"}
              title={"Internal Notes"}
            />
            <AdminActionButton
              iconClass={`status-log-icon`}
              actionName={`Review Board-Internal Status Logs`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"internal-status-logs"}
              title={"Internal Status Logs"}
            />
            <AdminActionButton
              iconClass={`assign-icon`}
              actionName={`Review Board-Invoice Edits Completed`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"invoice-edit"}
              title={"Invoice Edits Completed"}
            />
            <AdminActionButton
              iconClass={`approve-app-icon`}
              actionName={`Review Board-Mark as 'Invoice Approved'`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"invoice-approve"}
              title={"Mark as 'Invoice Approved'"}
            />
            <AdminActionButton
              iconClass={`assign-icon`}
              actionName={`Review Board-Send Manually`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"send-cheque"}
              title={"Send Manually"}
            />

            {showReviewDocument && (
              <AdminActionButton
                iconClass={`upload-icon`}
                actionName={`Review Board-Review Documents`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"review-documents"}
                title={"Review Documents"}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationTab;
