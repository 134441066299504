import { appActionTypes } from "../actions/appActionTypes";

export function application(
  state = {
    measureDrawerConfig: {
      open: false,
      title: "ADD MEASURE",
      buttonTitle: "GET CALCULATED VALUES",
      loading: false,
      finishButtonTitle: "ADD MEASURE",
    },
    facilityList: [],
    portLogs: [],
    appStage: "",
    reviewAppStage: "",
    measureCategories: [],
    measureTypes: [],
    measures: [],
    measureConfig: [],
    measureList: [],
    documentList: [],
    appList: [],
    IPNList: [],
    changeReqList: [],
    changeReqListOffset: 0,
    appListOffset: 0,
    moreApps: true,
    moreChangeReqs: true,
    reviewAppList: [],
    reviewAppListOffset: 0,
    moreReviewApps: true,
    participantApplicationList: [],
    appStatusLogsLoading: true,
    appStateLogsLoading: true,
    activeStatusLogs: [],
    activeChangeReqLogs: [],
    activeChangeRequests: [],
    appChangeReqLogsLoading: true,
    notes: [],
    appFlagLogs: [],
    measureLogs: [],
    measureDetailLogs: [],
    loadingApplications: true,
    labels: [],
    reviewDocumentList: [],
    applicationReviewDocumentsLoading: true,
    reviewDocumentsLogs: [],
    appReviewDocumentsLoading: true,
    reviewDocumentDeleting: false,
    reviewProgressLoading: false,
    reviewProgress: [],
  },
  action
) {
  switch (action.type) {
    case appActionTypes.RELOAD_APPLICATION_MEASURE_REQUEST:
      return {
        ...state,
        reloadingMeasureData: true,
        measureDrawerConfig: {
          open: true,
          title: "EDIT MEASURE",
          buttonTitle: "GET CALCULATED VALUES",
          loading: true,
          finishButtonTitle: "UPDATE MEASURE",
        },
      };
    case appActionTypes.CLEAR_MEASURE_DRAWER:
      return {
        ...state,
        measureDrawerConfig: {
          open: action.drawerState,
          title: "ADD MEASURE",
          buttonTitle: "GET CALCULATED VALUES",
          loading: false,
          finishButtonTitle: "ADD MEASURE",
        },
        measureCategory: null,
        measureType: null,
        measureCode: null,
        editingMeasureId: null,
        measureConfig: [],
      };
    case appActionTypes.RELOAD_APPLICATION_MEASURE_SUCCESS:
      return {
        ...state,
        measureDrawerConfig: {
          open: true,
          title: "EDIT MEASURE",
          buttonTitle: "SAVE",
          loading: false,
        },
        reloadingMeasureData: false,
        measureCategory: action.measureDoc.measureCategory,
        measureType: action.measureDoc.measureType,
        measureCode: action.measureDoc.measure,
        applicationMeasureObj: action.measureDoc,
        measureCategories: action.measureCategories,
        measureTypes: action.measureTypes,
        measures: action.measures,
        measureConfig: action.measureConfig.fields,
        editingMeasureId: action.measureDoc.id,
      };
    case appActionTypes.RELOAD_APPLICATION_MEASURE_FAILURE:
      return { ...state, reloadingMeasureDataError: action.error, reloadingMeasureData: false };
    case appActionTypes.CREATE_APPLICATION_REQUEST:
      return { ...state, applicationProcessing: true };
    case appActionTypes.CREATE_APPLICATION_SUCCESS:
      return { ...state, applicationProcessing: false, applicationCreated: true, applicationId: action.applicationId };
    case appActionTypes.CREATE_APPLICATION_FAILURE:
      return { ...state, applicationCreationError: action.error };
    case appActionTypes.CREATE_APPLICATION_MEASURE_REQUEST:
      return { ...state, measureCreating: true };
    case appActionTypes.CREATE_APPLICATION_MEASURE_SUCCESS:
      return { ...state, measureCreating: false, measureCreated: true, measureList: action.data };
    case appActionTypes.CREATE_APPLICATION_MEASURE_FAILURE:
      return { ...state, measureCreationError: action.error, measureCreating: false };
    case appActionTypes.DELETE_APPLICATION_MEASURE_REQUEST:
      return { ...state, measureDeleting: true };
    case appActionTypes.DELETE_APPLICATION_MEASURE_SUCCESS:
      return { ...state, measureDeleting: false, measureDeleted: true, measureList: action.data };
    case appActionTypes.DELETE_APPLICATION_MEASURE_FAILURE:
      return { ...state, measureCreationError: action.error, measureDeleting: false };
    case appActionTypes.UPDATE_APPLICATION_MEASURE_REQUEST:
      return { ...state, measureUpdating: true };
    case appActionTypes.UPDATE_APPLICATION_MEASURE_SUCCESS:
      return { ...state, measureUpdating: false, measureUpdated: true, measureList: action.data };
    case appActionTypes.UPDATE_APPLICATION_MEASURE_FAILURE:
      return { ...state, measureUpdatingError: action.error, measureUpdating: false };
    case appActionTypes.GET_APPLICATION_MEASURES_REQUEST:
      return { ...state, applicationMeasuresLoading: true };
    case appActionTypes.GET_APPLICATION_MEASURES_SUCCESS:
      return { ...state, applicationMeasuresLoading: false, measureList: action.data };
    case appActionTypes.GET_APPLICATION_MEASURES_FAILURE:
      return { ...state, getApplicationMeasuresError: action.error, applicationMeasuresLoading: false };
    case appActionTypes.GET_APPLICATIONS_BY_STAGE_REQUEST:
      return { ...state, applicationsByStageLoading: true };
    case appActionTypes.GET_APPLICATIONS_BY_STAGE_SUCCESS:
      return {
        ...state,
        applicationsByStageLoading: false,
        appStage: action.stage,
        appList: action.offset > 0 ? state.appList.concat(action.apps) : action.apps,
        appListOffset: action.offset + action.apps.length,
        moreApps: action.apps.length > 0,
      };
    case appActionTypes.GET_APPLICATIONS_BY_STAGE_FAILURE:
      return { ...state, getApplicationMeasuresError: action.error, applicationsByStageLoading: false };
    case appActionTypes.GET_APPLICATIONS_BY_STATUS_REQUEST:
      return { ...state, applicationsByStatusLoading: true };
    case appActionTypes.GET_APPLICATIONS_BY_STATUS_SUCCESS:
      return {
        ...state,
        applicationsByStatusLoading: false,
        appList: action.offset > 0 ? state.appList.concat(action.apps) : action.apps,
        appListOffset: action.offset + action.apps.length,
        moreApps: action.apps.length > 0,
      };
    case appActionTypes.GET_APPLICATIONS_BY_STATUS_FAILURE:
      return { ...state, getApplicationsByStatusError: action.error, applicationsByStatusLoading: false };

    case appActionTypes.GET_IPNS_BY_STATUS_REQUEST:
      return { ...state, IPNSByStatusLoading: true };
    case appActionTypes.GET_IPNS_BY_STATUS_SUCCESS:
      return {
        ...state,
        IPNSByStatusLoading: false,
        IPNList: action.offset > 0 ? state.IPNList.concat(action.IPNS) : action.IPNS,
        IPNListOffset: action.offset + action.IPNS.length,
        moreIPNS: action.IPNS.length > 0,
      };
    case appActionTypes.GET_IPNS_BY_STATUS_FAILURE:
      return { ...state, getIPNSByStatusError: action.error, IPNSByStatusLoading: false };
    case appActionTypes.SEARCH_CHANGE_REQS_REQUEST:
      return { ...state, changeReqsLoading: true };
    case appActionTypes.SEARCH_CHANGE_REQS_SUCCESS:
      return {
        ...state,
        changeReqsLoading: false,
        changeReqList: action.offset > 0 ? state.changeReqList.concat(action.changeReqs) : action.changeReqs,
        changeReqListOffset: action.offset + action.changeReqs.length,
        moreChangeReqs: action.changeReqs.length > 0,
      };
    case appActionTypes.SEARCH_CHANGE_REQS_FAILURE:
      return { ...state, getApplicationMeasuresError: action.error, applicationsByStageLoading: false };
    case appActionTypes.GET_APPLICATIONS_TO_REVIEW_REQUEST:
      return { ...state, applicationsToReviewLoading: true };
    case appActionTypes.GET_APPLICATIONS_TO_REVIEW_SUCCESS:
      return {
        ...state,
        applicationsToReviewLoading: false,
        reviewAppStage: action.stage,
        reviewAppList: action.offset > 0 ? state.reviewAppList.concat(action.apps) : action.apps,
        reviewAppListOffset: action.offset + action.apps.length,
        moreReviewApps: action.apps.length > 0,
      };
    case appActionTypes.GET_APPLICATIONS_TO_REVIEW_FAILURE:
      return { ...state, getApplicationMeasuresError: action.error, applicationsToReviewLoading: false };
    case appActionTypes.CREATE_APPLICATION_DOCUMENT_REQUEST:
      return { ...state, documentUploading: true };
    case appActionTypes.CREATE_APPLICATION_DOCUMENT_SUCCESS:
      return { ...state, documentUploading: false, documentUploaded: true, documentList: action.documentList };
    case appActionTypes.CREATE_APPLICATION_DOCUMENT_FAILURE:
      return { ...state, documentUploadError: action.error, documentUploading: false };
    case appActionTypes.DELETE_APPLICATION_DOCUMENT_REQUEST:
      return { ...state, documentDeleting: true };
    case appActionTypes.DELETE_APPLICATION_DOCUMENT_SUCCESS:
      return { ...state, documentDeleting: false, documentDeleted: true, documentList: action.documentList };
    case appActionTypes.DELETE_APPLICATION_DOCUMENT_FAILURE:
      return { ...state, documentDeletionError: action.error, documentDeleting: false };
    case appActionTypes.UPDATE_APPLICATION_DOCUMENT_REQUEST:
      return { ...state, documentUpdating: true };
    case appActionTypes.UPDATE_APPLICATION_DOCUMENT_SUCCESS:
      return { ...state, documentUpdating: false, documentUpdated: true, documentList: action.documentList };
    case appActionTypes.UPDATE_APPLICATION_DOCUMENT_FAILURE:
      return { ...state, documentUpdatingError: action.error, documentUpdating: false };
    case appActionTypes.GET_APPLICATION_DOCUMENTS_REQUEST:
      return { ...state, applicationDocumentsLoading: true };
    case appActionTypes.GET_APPLICATION_DOCUMENTS_SUCCESS:
      return { ...state, applicationDocumentsLoading: false, documentList: action.data };
    case appActionTypes.GET_APPLICATION_DOCUMENTS_FAILURE:
      return { ...state, getApplicationDocumentsError: action.error, applicationDocumentsLoading: false };
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_REQUEST:
      return { ...state, loadingApplications: true, participantApplicationList: [] };
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_SUCCESS:
      return { ...state, loadingApplications: false, participantApplicationList: action.apps };
    case appActionTypes.GET_APPLICATIONS_FOR_PARTICIPANT_FAILURE:
      return { ...state, loadingApplicationsError: action.error, loadingApplications: false, participantApplicationList: [] };
    case appActionTypes.CREATE_FACILITY_REQUEST:
      return { ...state, facilityBeingCreated: true };
    case appActionTypes.CREATE_FACILITY_SUCCESS:
      return { ...state, facilityBeingCreated: false, facilityList: action.facilities };
    case appActionTypes.CREATE_FACILITY_FAILURE:
      return { ...state, facilityCreationError: action.error };
    case appActionTypes.UPDATE_FACILITY_REQUEST:
      return { ...state, applicationProcessing: true };
    case appActionTypes.UPDATE_APPLICATION_SUCCESS:
      return { ...state, applicationProcessing: false, applicationUpdated: true };
    case appActionTypes.UPDATE_APPLICATION_FAILURE:
      return { ...state, applicationCreationError: action.error };
    case appActionTypes.GET_ACTIVE_CONTRACTORS_REQUEST:
      return { ...state, loadingActiveContractors: true };
    case appActionTypes.GET_ACTIVE_CONTRACTORS_SUCCESS:
      return { ...state, loadingActiveContractors: false, activeContractors: action.data };
    case appActionTypes.GET_ACTIVE_CONTRACTORS_FAILURE:
      return { ...state, loadingActiveContractorsError: action.error, loadingActiveContractors: false };
    case appActionTypes.GET_FACILITIES_FOR_CUSTOMER_REQUEST:
      return { ...state, loadingFacilities: true };
    case appActionTypes.GET_FACILITIES_FOR_CUSTOMER_SUCCESS:
      return { ...state, loadingFacilities: false, facilityList: action.facilities };
    case appActionTypes.GET_FACILITIES_FOR_CUSTOMER_FAILURE:
      return { ...state, getFacilitiesError: action.error, loadingFacilities: false };
    case appActionTypes.GET_FACILITY_CONFIG_REQUEST:
      return { ...state, loadingFacilityConfig: true };
    case appActionTypes.GET_FACILITY_CONFIG_SUCCESS:
      return { ...state, loadingFacilityConfig: false, facilityConfig: action.data };
    case appActionTypes.GET_FACILITY_CONFIG_FAILURE:
      return { ...state, loadingFacilityConfig: false, facilityConfigError: action.error };
    case appActionTypes.GET_FLAG_CONFIG_REQUEST:
      return { ...state, loadingFlagConfig: true };
    case appActionTypes.GET_FLAG_CONFIG_SUCCESS:
      return { ...state, loadingFlagConfig: false, flagConfig: action.data };
    case appActionTypes.GET_FLAG_CONFIG_FAILURE:
      return { ...state, loadingFlagConfig: false, flagConfigError: action.error };
    case appActionTypes.GET_MEASURE_CATEGORIES_REQUEST:
      return { ...state, measureCategoriesLoading: true };
    case appActionTypes.GET_MEASURE_CATEGORIES_SUCCESS:
      return {
        ...state,
        measureCategoriesLoading: false,
        measureCategories: action.data,
        measureTypes: [],
        measures: [],
        measureConfig: [],
      };
    case appActionTypes.GET_MEASURE_CATEGORIES_FAILURE:
      return { ...state, measureCategoriesError: action.error, measureCategoriesLoading: false };
    case appActionTypes.GET_LABELS_REQUEST:
      return { ...state, labelsLoading: true };
    case appActionTypes.GET_LABELS_SUCCESS:
      return { ...state, labelsLoading: false, labels: action.data };
    case appActionTypes.GET_LABELS_FAILURE:
      return { ...state, labelsError: action.error, labelsLoading: false };
    case appActionTypes.GET_MEASURE_TYPES_REQUEST:
      return { ...state, measureTypesLoading: true };
    case appActionTypes.GET_MEASURE_TYPES_SUCCESS:
      return { ...state, measureTypesLoading: false, measureTypes: action.data, measureConfig: [] };
    case appActionTypes.GET_MEASURE_TYPES_FAILURE:
      return { ...state, measureTypesError: action.error, measureTypesLoading: false };
    case appActionTypes.GET_MEASURES_REQUEST:
      return { ...state, measuresLoading: true };
    case appActionTypes.GET_MEASURES_SUCCESS:
      return { ...state, measuresLoading: false, measures: action.data, measureConfig: [] };
    case appActionTypes.GET_MEASURES_FAILURE:
      return { ...state, measuresError: action.error, measuresLoading: false };
    case appActionTypes.GET_MEASURE_CONFIG_REQUEST:
      return { ...state, measureConfigLoading: true };
    case appActionTypes.GET_MEASURE_CONFIG_SUCCESS:
      return { ...state, measureConfigLoading: false, measureConfig: action.data.fields };
    case appActionTypes.SET_MEASURE_CONFIG:
      return { ...state, measureConfig: action.config };
    case appActionTypes.GET_MEASURE_CONFIG_FAILURE:
      return { ...state, measureConfigError: action.error, measureConfigLoading: false };
    case appActionTypes.SET_MEASURE_CATEGORY:
      return { ...state, measureCategory: action.measureCategory };
    case appActionTypes.SET_MEASURE_TYPE:
      return { ...state, measureType: action.measureType };
    case appActionTypes.SET_MEASURE:
      return { ...state, measureCode: action.measureCode };
    case appActionTypes.GET_APPLICATION_NOTES_REQUEST:
      return { ...state, appNotesLoading: true };
    case appActionTypes.GET_APPLICATION_STATUS_LOGS_REQUEST:
      return { ...state, appStatusLogsLoading: true };
    // REVIEW DOCUMENTS REQUESTS--->
    case appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_REQUEST:
      return { ...state, reviewDocumentUploading: true, applicationReviewDocumentsLoading: true };
    case appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        reviewDocumentUploading: false,
        reviewDocumentUploaded: true,
        applicationReviewDocumentsLoading: false,
        reviewDocumentList: action.reviewDocumentList,
      };
    case appActionTypes.CREATE_APPLICATION_REVIEW_DOCUMENT_FAILURE:
      return { ...state, reviewDocumentUploadError: action.error, reviewDocumentUploading: false };
    case appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_REQUEST:
      return { ...state, reviewDocumentUpdating: true };
    case appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS:
      return { ...state, reviewDocumentUpdating: false, reviewDocumentUpdated: true, reviewDocumentList: action.reviewDocumentList };
    case appActionTypes.UPDATE_APPLICATION_REVIEW_DOCUMENT_FAILURE:
      return { ...state, reviewDocumentUpdatingError: action.error, reviewDocumentUpdating: false };
    case appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_REQUEST:
      return { ...state, reviewDocumentDeleting: true };
    case appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_SUCCESS:
      return { ...state, reviewDocumentDeleting: false, reviewDocumentDeleted: true, reviewDocumentList: action.reviewDocumentList };
    case appActionTypes.DELETE_APPLICATION_REVIEW_DOCUMENT_FAILURE:
      return { ...state, reviewDocumentDeletionError: action.error, reviewDocumentDeleting: false };
    case appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_REQUEST:
      return { ...state, applicationReviewDocumentsLoading: true };
    case appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_SUCCESS:
      return { ...state, applicationReviewDocumentsLoading: false, reviewDocumentList: action.data };
    case appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_FAILURE:
      return { ...state, getApplicationReviewDocumentsError: action.error, applicationReviewDocumentsLoading: false };

    case appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_REQUEST:
      return { ...state, appReviewDocumentsLoading: true };
    case appActionTypes.GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_SUCCESS:
      return { ...state, appReviewDocumentsLoading: false, reviewDocumentsLogs: action.logs };
    case appActionTypes.GET_APPLICATION_STATUS_LOGS_SUCCESS:
      return { ...state, appStatusLogsLoading: false, activeStatusLogs: action.logs };
    case appActionTypes.GET_APPLICATION_PORT_LOGS_SUCCESS:
      return { ...state, portLogs: action.logs };
    case appActionTypes.GET_APPLICATION_STATE_LOGS_REQUEST:
      return { ...state, appStateLogsLoading: true };
    case appActionTypes.GET_APPLICATION_STATE_LOGS_SUCCESS:
      return { ...state, appStateLogsLoading: false, activeStateLogs: action.logs };
    case appActionTypes.GET_APPLICATION_FLAG_LOGS_REQUEST:
      return { ...state, appFlagsLoading: true };
    case appActionTypes.GET_APPLICATION_FLAG_LOGS_SUCCESS:
      return { ...state, appFlagsLoading: false, flagLogs: action.logs };
    case appActionTypes.GET_APPLICATION_MEASURE_LOGS_REQUEST:
      return { ...state, appMeasureLogsLoading: true };
    case appActionTypes.GET_APPLICATION_MEASURE_LOGS_SUCCESS:
      return { ...state, appMeasureLogsLoading: false, measureLogs: action.logs };
    case appActionTypes.GET_APPLICATION_MEASURE_DETAIL_LOGS_REQUEST:
      return { ...state, appMeasureDetailLogsLoading: true };
    case appActionTypes.GET_APPLICATION_MEASURE_DETAIL_LOGS_SUCCESS:
      return { ...state, appMeasureDetailLogsLoading: false, measureDetailLogs: action.logs };
    case appActionTypes.GET_APPLICATION_CHANGE_REQS_REQUEST:
      return { ...state, appChangeReqsLoading: true };
    case appActionTypes.GET_APPLICATION_CHANGE_REQS_SUCCESS:
      return { ...state, appChangeReqsLoading: false, activeChangeRequests: action.changeReqs };
    case appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_REQUEST:
      return { ...state, appChangeReqLogsLoading: true };
    case appActionTypes.GET_APPLICATION_CHANGE_REQ_LOGS_SUCCESS:
      return { ...state, appChangeReqLogsLoading: false, activeChangeReqLogs: action.logs };
    case appActionTypes.GET_APPLICATION_NOTES_SUCCESS:
      return { ...state, appNotesLoading: false, Notes: action.Notes };
    case appActionTypes.GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_SUCCESS:
      return { ...state, applicationsTotalIncentive: action.applicationsTotalIncentive };
    // FOR RETROFIT REVIEW PROGRESS
    case appActionTypes.GET_APPLICATIONS_REVIEW_PROGRESS_REQUEST:
      return { ...state,  reviewProgressLoading: true };
    case appActionTypes.GET_APPLICATIONS_REVIEW_PROGRESS_SUCCESS:
      return { ...state, reviewProgressLoading: false, reviewProgress: action.data };
    case appActionTypes.GET_APPLICATIONS_REVIEW_PROGRESS_FAILURE:
      return { ...state, reviewProgressLoadingError: action.error };
    default:
      return state;
  }
}
