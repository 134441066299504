import React from 'react';
import AdminActionButton from '../../../Common/AdminActionButton/AdminActionButton';

class CustomersTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { Customer } = this.props;
        const status = Customer.Status.toLowerCase();
        const statusConfig = {
            showFlagForQA : ['pending approval','details submission pending','participating','active'].includes(status),
            showSuspend : ['pending approval','participating','active','inactive'].includes(status),
            showReinstate : ['flagged for qa/qc', 'suspended'].includes(status),
            showActive : ['inactive'].includes(status),
            showInactive : ['email verification pending', 'details submission pending', 'pending approval', 'participating' , 'flagged for qa/qc','active'].includes(status),
            showViewApplications : ['participating','flagged for qa/qc', 'suspended','active','inactive'].includes(status),
            showDocs: false,
            // showDocs: ['pending approval','participating','flagged for qa/qc', 'suspended'].includes(status)
        }

        return (
            <div className={`customer-details-row ${Customer.isBlocked ? "blocked" : ''}`} id={this.props.id}>
                <div className="customer-details">
                    <div><label>ID:</label><span>{Customer.CustomerID}</span></div>
                    <div><label>Name:</label><span>{Customer.Name}</span></div>
                    <div><label>Status:</label><span>{Customer.Status}</span></div>
                    <div><label>Created Date:</label><span>{Customer.CreatedDate}</span></div>
                    <div><label>Email ID:</label><span>{Customer.EmailID}</span></div>
                    <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
                </div>
                    <div className="customer-more-details">
                        <div>
                            <AdminActionButton 
                            iconClass={`edit-icon`}
                            actionName={`Participant Board-View/Edit Customer Details`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'edit'}
                            title={'View/Edit Customer Details'}
                        />
                            {Customer.Status.toLowerCase() === 'email verification pending' ? 
                            <AdminActionButton 
                            iconClass={`mail-icon`}
                            actionName={`Participant Board-Resend Verification Email`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'send-mail'}
                            title={'Resend Verification Email'}
                        />
                            : ''}
                            {statusConfig.showViewApplications ? 
                            <AdminActionButton 
                            iconClass={`view-icon`}
                            actionName={`Participant Board-View Applications`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'show-submitted-apps'}
                            title={'View Applications'}
                        />
                            : ''}
                            {statusConfig.showDocs ? 
                            <AdminActionButton 
                            iconClass={`doc-icon`}
                            actionName={`Participant Board-View Uploaded Documents`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'view-docs'}
                            title={'View Uploaded Documents'}
                        />
                            : ''}
                            {statusConfig.showFlagForQA ? 
                            <AdminActionButton 
                            iconClass={`flag-icon`}
                            actionName={`Participant Board-Flag Customer for QC`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'flag-qa'}
                            title={'Flag Customer for QC'}
                        />
                            : ''}
                            {statusConfig.showSuspend ? 
                            <AdminActionButton 
                            iconClass={`suspend-icon`}
                            actionName={`Participant Board-Suspend Customer`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'suspend'}
                            title={'Suspend Customer'}
                        />
                            : ''}
                            {statusConfig.showReinstate ? 
                            <AdminActionButton 
                            iconClass={`reinstate-icon`}
                            actionName={`Participant Board-Reinstate Customer`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'reinstate'}
                            title={'Reinstate Customer'}
                        />
                            : ''}
                            {statusConfig.showActive ? 
                            <AdminActionButton 
                            iconClass={`flag-icon`}
                            actionName={`Participant Board-Mark as Active`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'active'}
                            title={'Mark as Active'}
                        />
                            : ''}
                            {statusConfig.showInactive ? 
                            <AdminActionButton 
                            iconClass={`flag-icon`}
                            actionName={`Participant Board-Mark as Inactive`}
                            showActionModal={this.props.showActionModal}
                            application={Customer}
                            appModalType={'inactive'}
                            title={'Mark as Inactive'}
                        />
                            : ''}
                        </div>
                    </div>
            </div>
        );
    }
}

export default CustomersTab;
