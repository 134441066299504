import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { ReactComponent as HamBurger } from "../../../images/HamBurger.svg";
import { ReactComponent as ParticipantBoard } from "../../../images/ParticipantBoard.svg";
import { ReactComponent as LeftArrow } from "../../../images/leftArrow.svg";
import { ReactComponent as Review } from "../../../images/Review.svg";
import { ReactComponent as ApplicationBoard } from "../../../images/ApplicationBoard.svg";
import { ReactComponent as ChangeRequestMenuIcon } from "../../../images/ChangeRequestMenuIcon.svg";
import { ReactComponent as PayoutBoard } from "../../../images/PayoutBoard.svg";
import "./ProgramSideBarMenu.css";

class AdminSideBarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCollapse: false,
      activeMenuItem: null,
    };
  }

  componentDidMount() {
    this.defaultActiveMenuItem();
  }

  setMenuCollapse = async () => {
    await this.props.setSideBarMenuCollapse(!this.props.menuCollapse);
  };

  setActiveMenuItem = (activeMenuItem, secondParam) => {
    if (window.innerWidth < 1025 && secondParam === "Onclick") {
      this.props.setSideBarMenuCollapse(!this.props.menuCollapse);
    }
    this.setState({
      activeMenuItem,
    });
  };

  defaultActiveMenuItem = () => {
    let Path = window.location && window.location.pathname;
    let viewAppSummary = Path.includes("viewapplication");
    if (viewAppSummary) {
      Path = "/viewapplication";
    }
    if (Path) {
      switch (Path) {
        case "/esb/participant-board":
          this.setActiveMenuItem("ParticipantBoard");
          break;
        case "/home":
          this.setActiveMenuItem("ParticipantBoard");
          break;
        case "/esb/applications-board":
          this.setActiveMenuItem("ApplicationsBoard");
          break;
        case "/esb/incentive-review-board":
          this.setActiveMenuItem("IncentiveReviewBoard");
          break;
        case "/esb/review-board":
          this.setActiveMenuItem("ReviewBoard");
          break;
        case "/esb/change-request-board":
          this.setActiveMenuItem("ChangeRequestBoard");
          break;
        case "/esb/payout-board":
          this.setActiveMenuItem("PayoutBoard");
          break;
        case "/speed/participant-board":
          this.setActiveMenuItem("ParticipantBoard");
          break;
        case "/speed/applications-board":
          this.setActiveMenuItem("ApplicationsBoard");
          break;
        case "/speed/incentive-review-board":
          this.setActiveMenuItem("IncentiveReviewBoard");
          break;
        case "/speed/review-board":
          this.setActiveMenuItem("ReviewBoard");
          break;
        case "/speed/change-request-board":
          this.setActiveMenuItem("ChangeRequestBoard");
          break;
        case "/speed/payout-board":
          this.setActiveMenuItem("PayoutBoard");
          break;
        case "/retrofit":
          this.setActiveMenuItem("ParticipantBoard");
          break;
        case "/retrofit/participant-board":
          this.setActiveMenuItem("ParticipantBoard");
          break;
        case "/retrofit/applications-board":
          this.setActiveMenuItem("ApplicationsBoard");
          break;
        case "/retrofit/review-board":
          this.setActiveMenuItem("ReviewBoard");
          break;
        case "/viewapplication":
          this.setActiveMenuItem("");
          break;
        default:
          this.setActiveMenuItem("ParticipantBoard");
          break;
      }
    }
  };

  render() {
    const { activeMenuItem } = this.state;
    const { menuCollapse, adminUserInfo, currentProgram } = this.props;
    let boards = {};
    let routePath = currentProgram;
    if (adminUserInfo && adminUserInfo.permissions) {
      boards = adminUserInfo.permissions[currentProgram].boards;
    }

    return (
      <div className={`program-sidebar-menu ${menuCollapse ? "program-sidebar-menu-close" : ""}`}>
        <nav>
          <div
            className={`program-burger-menu ${menuCollapse ? "program-burger-menu-collapsed" : ""}`}
            onClick={() => this.setMenuCollapse(!menuCollapse)}
          >
            <HamBurger width="42px" height="26px" />
          </div>
          <div className={`program-menu ${menuCollapse ? "program-menu-collapsed" : ""}`}>
            <div className="program-top-menu">
              {adminUserInfo && boards["Participant Board"] ? <div className={`program-menu-items ${activeMenuItem === "ParticipantBoard" ? "active-menu" : ""}`}>
                <Link to={`/${routePath}/participant-board`} onClick={() => this.setActiveMenuItem("ParticipantBoard", "Onclick")}>
                  <ParticipantBoard width="18px" height="18px" />
                  <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Participant Board</span>
                </Link>
              </div> : null}
              {adminUserInfo && boards["Application Board"] ? (
                <div className={`program-menu-items ${activeMenuItem === "ApplicationsBoard" ? "active-menu" : ""}`}>
                  <Link to={`/${routePath}/applications-board`} onClick={() => this.setActiveMenuItem("ApplicationsBoard", "Onclick")}>
                    <ApplicationBoard width="18px" height="18px" />
                    <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Applications Board</span>
                  </Link>
                </div>
              ) : (
                ""
              )}
              {adminUserInfo && boards["Review Board"] ? (
                <div className={`program-menu-items ${activeMenuItem === "ReviewBoard" ? "active-menu" : ""}`}>
                  <Link
                    to={`/${routePath}/review-board`}
                    onClick={() => this.setActiveMenuItem("ReviewBoard", "Onclick")}
                  >
                    <Review width="18px" height="18px" />
                    <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Review Board</span>
                  </Link>
                </div>
              ) : (
                ""
              )}
              {adminUserInfo && boards["Incentive Review Board"] ? (
                <div className={`program-menu-items ${activeMenuItem === "IncentiveReviewBoard" ? "active-menu" : ""}`}>
                  <Link
                    to={currentProgram === "esb" ? "/esb/incentive-review-board" : "/speed/incentive-review-board"}
                    onClick={() => this.setActiveMenuItem("IncentiveReviewBoard", "Onclick")}
                  >
                    <ApplicationBoard width="18px" height="18px" />
                    <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Incentive Review Board</span>
                  </Link>
                </div>
              ) : (
                ""
              )}
              {adminUserInfo && boards["Change Request Board"] ? (
                <div className={`program-menu-items ${activeMenuItem === "ChangeRequestBoard" ? "active-menu" : ""}`}>
                  <Link
                    to={currentProgram === "esb" ? "/esb/change-request-board" : "/speed/change-request-board"}
                    onClick={() => this.setActiveMenuItem("ChangeRequestBoard", "Onclick")}
                  >
                    <ChangeRequestMenuIcon width="18px" height="18px" />
                    <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Change Request Board</span>
                  </Link>
                </div>
              ) : (
                ""
              )}
              {adminUserInfo && boards["Payout Board"] ? (
                <div className={`program-menu-items ${activeMenuItem === "PayoutBoard" ? "active-menu" : ""}`}>
                  <Link
                    to={currentProgram === "esb" ? "/esb/payout-board" : "/speed/payout-board"}
                    onClick={() => this.setActiveMenuItem("PayoutBoard", "Onclick")}
                  >
                    <PayoutBoard width="18px" height="18px" />
                    <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Payout Board</span>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="program-bottom-menu" onClick={() => this.setMenuCollapse(!menuCollapse)}>
              <span className={`bottom-arrow ${menuCollapse ? "menu-items-collapsed" : ""}`}>
                {menuCollapse ? (
                  <LeftArrow className="right-arrow" />
                ) : (
                  <span>
                    <LeftArrow /> Collapse
                  </span>
                )}
              </span>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

function mapState(state) {
  const {
    adminUserInfo,
    currentProgram,
    logoutConfirmationModalShown,
    passwordChanging,
    passwordChanged,
    changePasswordError,
    menuCollapse,
  } = state.authentication;
  return {
    adminUserInfo,
    currentProgram,
    logoutConfirmationModalShown,
    passwordChanging,
    passwordChanged,
    changePasswordError,
    menuCollapse,
  };
}

const actionCreators = {
  getUserDetails: userActions.getUserDetails,
  logout: userActions.logout,
  showLogoutModal: userActions.showLogoutConfirmationModal,
  changePassword: userActions.changePassword,
  clearChangePassword: userActions.clearChangePassword,
  setSideBarMenuCollapse: userActions.setSideBarMenuCollapse,
};

export default connect(mapState, actionCreators)(AdminSideBarMenu);
