import React from 'react';
import Button from '../../../Common/Button/Button';
import Input from '../../../Common/Input/Input';
import Table from '../../../Common/Table/Table';
import './EditParticipant.css';
import { connect } from 'react-redux'
import { userActions } from '../../../../redux/actions/userActions';
import { userService } from '../../../../services/userService';
import { utils } from '../../../../helpers/utils';
import { debounce } from "debounce";
import Loader from '../../../Common/Loader/Loader';
import { participantActions } from '../../../../redux/actions/participantActions';
// import { adminSettingsActions } from '../../../../redux/actions/adminSettingsActions'
class EditParticipant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid:null,
      userDataLoading:true,
      cancelModalOpen: false,
      passwordValid: false,
      confirmAccountDetails: true,
      accountCategory : "contractor",
      createAccountModalOpen:false,
      dataLoaded:false,
      formSteps:[],
      user:{
        firstName : '',
        lastName : '',
        phoneNum : '',
        isIndigenousOwned: '',
        isOwnedBySomeoneFromURG: '',
        workNum : '',
        workPhoneExtension : '',
        email : '',
        accountCategory : '',
        companyName : '',
        companyWebsite: '',
        locationInfo : null,
        address2 : '',
        naicsCode : '',
        roleInCompany:'',
        supplyChainRole:'',
        technologyFocusForCompany:'',
        marketsServedByCompany:'',
        regionsOfOperation:'',
        licenseReqs: {
            meetInsuranceRequirements : false,
            meetWCBRequirements : false,
            registeredForGST : false,
            codeOfConductLicensesAndCertifications : false,
            havePEngOnStaff : false,
            haveCEMOnStaff : false,
            memberOfSESA : false,
            memberOfCanREA : false
        }
      },
      errors : {}
  }
    this.checkEmail = debounce(this.checkEmailInSystem, 300);
  }

  componentDidMount(){
    const { uid, activeParticipant } = this.props;
    // this.props.getParticipants('contractor');
    // this.props.getLogs(this.props.selectedCustomer.uid);
    // this.props.getParticipant(this.props.selectedCustomer.uid);
    // this.props.getParticipant(uid);
    if(activeParticipant){
    const mainThis = this;
    if(activeParticipant.licenseReqs){
    } else {
      activeParticipant.licenseReqs = {};
    }
    let userObj = {
      uid: activeParticipant.uid,
      firstName : activeParticipant.firstName || '',
      lastName : activeParticipant.lastName || '',
      // isIndigenousOwned: activeParticipant.isIndigenousOwned?activeParticipant.isIndigenousOwned: 'N/A',
      isOwnedBySomeoneFromURG: activeParticipant.isOwnedBySomeoneFromURG?activeParticipant.isOwnedBySomeoneFromURG: 'N/A',
      phoneNum : activeParticipant.phoneNum || '',
      workNum : activeParticipant.workNum || '',
      workPhoneExtension : activeParticipant.workPhoneExtension || '',
      email : activeParticipant.email,
      accountCategory : activeParticipant.accountCategory,
      companyName : activeParticipant.companyDetails.name || '',
      companyWebsite : activeParticipant.companyDetails.website || '',
      address2 : activeParticipant.companyDetails.address2 || '',
      location : activeParticipant.companyDetails.location,
      roleInCompany:activeParticipant.companyDetails.roleInCompany || '',
      supplyChainRole:activeParticipant.companyDetails.supplyChainRole || '',
      technologyFocusForCompany:activeParticipant.companyDetails.technologyFocusForCompany || '',
      marketsServedByCompany:activeParticipant.companyDetails.marketsServedByCompany || '',
      regionsOfOperation:activeParticipant.companyDetails.regionsOfOperation || '',
      naicsCode : activeParticipant.companyDetails.naicsCode || '',
      companyIndustry : activeParticipant.companyDetails.companyIndustry || '',
      locationInfo : {
        location_data : activeParticipant.companyDetails.location || null,
        address1 : activeParticipant.companyDetails.address1 || '',
        postalCode : activeParticipant.companyDetails.postalCode || '',
        city : activeParticipant.companyDetails.city || '',
        province : activeParticipant.companyDetails.province || ''
      },
      licenseReqs: {
        meetInsuranceRequirements : activeParticipant.licenseReqs.meetInsuranceRequirements || false,
        meetWCBRequirements : activeParticipant.licenseReqs.meetWCBRequirements || false,
        registeredForGST : activeParticipant.licenseReqs.registeredForGST || false,
        codeOfConductLicensesAndCertifications : activeParticipant.licenseReqs.codeOfConductLicensesAndCertifications || false,
        havePEngOnStaff : activeParticipant.licenseReqs.havePEngOnStaff || false,
        haveCEMOnStaff : activeParticipant.licenseReqs.haveCEMOnStaff || false,
        memberOfSESA : activeParticipant.licenseReqs.memberOfSESA || false,
        memberOfCanREA : activeParticipant.licenseReqs.memberOfCanREA || false
    }
    }
    mainThis.setState({uid, user : userObj, userDataLoading : false });
  }
 }

 componentDidUpdate(prevProps) {
  if (prevProps.activeParticipant !== this.props.activeParticipant) {
    const { uid, activeParticipant } = this.props;
    if(activeParticipant){
    const mainThis = this;
  
    if(activeParticipant.licenseReqs){
    } else {
      activeParticipant.licenseReqs = {};
    }
    let userObj = {
      uid: activeParticipant.uid,
      firstName : activeParticipant.firstName || '',
      lastName : activeParticipant.lastName || '',
      // isIndigenousOwned: activeParticipant.isIndigenousOwned?activeParticipant.isIndigenousOwned: 'N/A',
      isOwnedBySomeoneFromURG: activeParticipant.isOwnedBySomeoneFromURG?activeParticipant.isOwnedBySomeoneFromURG: 'N/A',
      phoneNum : activeParticipant.phoneNum || '',
      workNum : activeParticipant.workNum || '',
      workPhoneExtension : activeParticipant.workPhoneExtension || '',
      email : activeParticipant.email,
      accountCategory : activeParticipant.accountCategory,
      companyName : activeParticipant.companyDetails.name || '',
      companyWebsite : activeParticipant.companyDetails.website || '',
      address2 : activeParticipant.companyDetails.address2 || '',
      location : activeParticipant.companyDetails.location,
      roleInCompany:activeParticipant.companyDetails.roleInCompany || '',
      supplyChainRole:activeParticipant.companyDetails.supplyChainRole || '',
      technologyFocusForCompany:activeParticipant.companyDetails.technologyFocusForCompany || '',
      marketsServedByCompany:activeParticipant.companyDetails.marketsServedByCompany || '',
      regionsOfOperation:activeParticipant.companyDetails.regionsOfOperation || '',
      naicsCode : activeParticipant.companyDetails.naicsCode || '',
      companyIndustry : activeParticipant.companyDetails.companyIndustry || '',
      locationInfo : {
        location_data : activeParticipant.companyDetails.location || null,
        address1 : activeParticipant.companyDetails.address1 || '',
        postalCode : activeParticipant.companyDetails.postalCode || '',
        city : activeParticipant.companyDetails.city || '',
        province : activeParticipant.companyDetails.province || ''
      },
      licenseReqs: {
        meetInsuranceRequirements : activeParticipant.licenseReqs.meetInsuranceRequirements || false,
        meetWCBRequirements : activeParticipant.licenseReqs.meetWCBRequirements || false,
        registeredForGST : activeParticipant.licenseReqs.registeredForGST || false,
        codeOfConductLicensesAndCertifications : activeParticipant.licenseReqs.codeOfConductLicensesAndCertifications || false,
        havePEngOnStaff : activeParticipant.licenseReqs.havePEngOnStaff || false,
        haveCEMOnStaff : activeParticipant.licenseReqs.haveCEMOnStaff || false,
        memberOfSESA : activeParticipant.licenseReqs.memberOfSESA || false,
        memberOfCanREA : activeParticipant.licenseReqs.memberOfCanREA || false
    }
    }
    mainThis.setState({uid, user : userObj, userDataLoading : false });
  }
  }
}

  onChangeHandler = (name,value) => {
    const { user, errors } = this.state;
    delete errors[name];
    this.setState({
      user: {
        ...user,
        [name]: value
      },
      errors
    });
  }

  checkEmailInSystem = () => {
    const { errors, user } = this.state;
    userService.checkIfEmailExists(user.email, (userExists) => {
      if (userExists) {
        errors.email = 'Email already exists in our system.';
      }
      this.setState({
        errors
      });
    });
  }

  onEmailChange = (e) => {
    const { name, value } = e.target;
    const { user, errors } = this.state;
    delete errors[name];

    if (!utils.isNullOrEmpty(value)) {
      if (name === 'email') {
        if (value === user.confirmEmail) {
          delete errors.confirmEmail;
        } else {
          errors.confirmEmail = 'The email ids do not match';
        }
        if (utils.validateEmail(value)) {
          this.checkEmail();
        } else {
          errors.email = 'The email id is invalid';
        }
      } else {
        if (value === user.email) {
          delete errors.confirmEmail;
        } else {
          errors.confirmEmail = 'The email ids do not match';
        }
      }
    }

    this.setState({
      user: {
        ...user,
        [name]: value
      },
      errors
    });
  }

  onCatChange = (cat) => {
    const { user, errors } = this.state;
    delete errors['accountCategory'];
    this.setState({
      user: {
        ...user,
        accountCategory: cat.value
      },
      errors
    });
  }

  // onindeginiousChange = (ind) => {
  //   const { user,errors } = this.state;
  //   let isIndigenousOwned = ind.value;
  //   delete errors['isIndigenousOwned'];
  //   this.setState({
  //       user: {
  //           ...user,
  //           isIndigenousOwned,
  //       },
  //       errors
  //   });
  // }
  onBusinessOwnerChange = (value) => {
    const { user,errors } = this.state;
    delete errors['isOwnedBySomeoneFromURG'];
    this.setState({
        user: {
            ...user,
            isOwnedBySomeoneFromURG: value,
        },
        errors
    });
  }
  onLocationChange = (locationInfo) => {
    const { user, errors } = this.state;
    delete errors['companyAddress'];
    this.setState({
      user: {
        ...user,
        locationInfo
      },
      errors
    });
  }

  verifyForm = (user) => {
    let errors = {};
    let errorCount = 0;
    if (utils.isNullOrEmpty(user.firstName)) {
      errors.firstName = 'First name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.lastName)) {
      errors.lastName = 'Last name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.companyName)) {
      errors.companyName = 'Company name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.locationInfo)) {
      errors.companyAddress = 'Company address is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.accountCategory)) {
      errors.accountCategory = 'Please select an account category.'
      errorCount++;
    }
    // if(utils.isNullOrEmpty(user.isIndigenousOwned)){
    //   errors.isIndigenousOwned = 'Please select an answer.'
    //   errorCount++;
    // }
    if(utils.isNullOrEmpty(user.isOwnedBySomeoneFromURG)){
      errors.isIndigenousOwned = 'Please select an answer.'
      errorCount++;
    }
    if (!utils.isNullOrEmpty(user.phoneNum) && (user.phoneNum.length > 1 && user.phoneNum.length < 11)) {
      errors.phoneNum = 'Phone number is invalid.'
      errorCount++;
    } else if (utils.isNullOrEmpty(user.phoneNum) || (user.phoneNum.length <= 1)) {
      errors.phoneNum = 'Phone number is required.'
      errorCount++;
    }

    if (!utils.isNullOrEmpty(user.email)) {
      if (!utils.validateEmail(user.email)) {
        errors.email = 'Email id is invalid.'
        errorCount++;
      }
    } else {
      errors.email = 'Email id is required.'
      errorCount++;
    }
    if (errorCount > 0) {
      this.setState({ errors });
    }
    return errorCount === 0;
  }

  nextStep = () => {
    this.setState({ confirmAccountDetails: !this.state.confirmAccountDetails })
  }

  componentWillUnmount(){
    this.setState({userDataLoading:true});
  }

  handleEditParticipant = async ()=>{
    const { user, uid } = this.state;
    const mainThis = this;
    if(this.verifyForm(user)){

      const userInfo = {
        firstName : user.firstName,
        lastName : user.lastName,
        // isIndigenousOwned:user.isIndigenousOwned,
        isOwnedBySomeoneFromURG:user.isOwnedBySomeoneFromURG,
        "companyDetails.name" : user.companyName,
        "companyDetails.website" : user.companyWebsite,
        "companyDetails.address1" : user.locationInfo.address1,
        "companyDetails.address2" : user.address2,
        "companyDetails.city" : user.locationInfo.city,
        "companyDetails.province" : user.locationInfo.province,
        "companyDetails.postalCode" : user.locationInfo.postalCode,
        "companyDetails.location" : user.locationInfo.location_data,
        phoneNum : user.phoneNum,
        workNum : user.workNum,
        workPhoneExtension : user.workPhoneExtension
     }

    userService.updateUserProfile(mainThis.props.currentProgram, uid, userInfo).then(()=>{
      mainThis.setState({confirmAccountDetails : true});
      // mainThis.props.getParticipants('contractor');
      // mainThis.props.getParticipants('customer');
      mainThis.props.getParticipant(mainThis.props.currentProgram, uid);
    })
    .catch(function(e) {
      mainThis.setState({confirmAccountDetails : true});
      // mainThis.props.getParticipants('contractor');
      // mainThis.props.getParticipants('customer');
      mainThis.props.getParticipant(mainThis.props.currentProgram, uid);
    })
    
  }
  }
  
  render() {

      const { user, errors, userDataLoading, confirmAccountDetails } = this.state;
      const isIndigenousOwnedOptions =  [
        "Yes","No","Prefer not to Answer"
      ];
      let customerData = [
        { label : 'First Name', value : user.firstName},
        { label : 'Last Name', value : user.lastName},
        { label : 'Mobile Number', value : user.phoneNum},
        { label : 'Work Number', value : user.workNum},
        { label : 'Work Extension', value : user.workPhoneExtension},
        { label : 'Email', value : user.email},
        { label : 'Account Category', value : user.accountCategory === 'contractor' ? 'Contractor' : 'Customer'},
        { label : 'Company Name', value : user.companyName},
        { label : 'Is your business owned by someone from an under-represented group?', value : user.isOwnedBySomeoneFromURG.value},
        { label : 'Company Website', value : user.companyWebsite},
        { label : 'Company Address', value : user.locationInfo ? `${user.locationInfo.address1}, ${user.locationInfo.address2 ? ', '+ user.locationInfo.address2 : ''} ${user.locationInfo.city}, ${user.locationInfo.province}, ${user.locationInfo.postalCode}` : ''}
      ];
      let {canEdit, adminUserInfo} = this.props;
      canEdit = !utils.isNullOrEmpty(canEdit) ? canEdit : true;
      const thereAreErrors = !utils.isNullOrEmpty(errors);

      let actions = {};
      if(adminUserInfo && adminUserInfo.permissions){
        actions = adminUserInfo.permissions[this.props.currentProgram].actions;
        if(user.accountCategory === 'contractor' && utils.isNullOrEmpty(actions['Participant Board-Edit Contractor Details'])){
          canEdit = false;
        }
        else if(user.accountCategory === 'customer' && utils.isNullOrEmpty(actions['Participant Board-Edit Customer Details'])){
          canEdit = false;
        }
      }


      return (
        <div className="edit-participant-container">
          <div className="container-box">
          { userDataLoading ? <Loader/> : <React.Fragment>
            <div className="form-sections">
              { confirmAccountDetails ? <Table rows={customerData} headings={[
            {title:'Field Name', value:'label'}, 
            {title : 'Field Value', value : 'value'}
        ]}/> : <React.Fragment>
              <div className="form-section">
                <Input
                  label={'First Name'}
                  name='firstName'
                  value={user.firstName}
                  error={errors['firstName']}
                  onChange={this.onChangeHandler}
                  placeholder={'Type your first name'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Last Name'}
                  name='lastName'
                  value={user.lastName}
                  error={errors['lastName']}
                  onChange={this.onChangeHandler}
                  placeholder={'Type your last name'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  type='phone'
                  label={'Mobile Number'}
                  name='phoneNum'
                  value={user.phoneNum}
                  error={errors['phoneNum']}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled={confirmAccountDetails}
                />
                <Input
                type='phone'
                label={'Work Number'}
                name='workNum'
                value={user.workNum}
                error={errors['workNum']}
                onChange={this.onChangeHandler}
                placeholder={''}
                disabled={confirmAccountDetails}
                />   
                <Input
                type='number'
                label={'Extension'}
                name='workPhoneExtension'
                value={user.workPhoneExtension}
                error={errors['workPhoneExtension']}
                className={'shortField'}
                onChange={this.onChangeHandler}
                placeholder={'X X - X X X'}
                disabled={confirmAccountDetails}
                />         
                <Input
                  label={'Email'}
                  type='email'
                  name='email'
                  value={user.email}
                  onChange={this.onEmailChange}
                  error={errors['email']}
                  placeholder={'Type your email id'}
                  disabled={true}
                />
                <Input
                  label={'Account Category'}
                  type='dropdown'
                  name='accountCategory'
                  error={errors['accountCategory']}
                  onChange={this.onCatChange}
                  value={user.accountCategory === 'contractor' ? {value:'contractor', label :'Contractor'} : {value:'customer', label : 'Customer'}}
                  options={[{ value: 'contractor', label: 'Contractor' }, { value: 'customer', label: 'Customer' }]}
                  disabled={true}
                  isSearchable={false}
                />
              </div>
              <div className='vertical-separator-box'>
                <span className='separator'></span>
              </div>
              <div className="form-section">
                <Input
                  label={'Company Name'}
                  name='companyName'
                  value={user.companyName}
                  error={errors['companyName']}
                  onChange={this.onChangeHandler}
                  placeholder={"Type your company's name"}
                  disabled={confirmAccountDetails}
                />
           
                {/* <Input
                  label={'Is the company indigenous owned?'}
                  type='dropdown'
                  name='isIndigenousOwned'
                  error={errors['isIndigenousOwned']}
                  onChange={this.onindeginiousChange}
                  value={user.isIndigenousOwned ? { value: user.isIndigenousOwned, label: user.isIndigenousOwned } : 'N/A'}
                  options={isIndigenousOwnedOptions.map((item) => {
                    return { label: item, value: item };
                  })}
                  // isSearchable={false}
                /> */}
                <Input
                  label={'Is your business owned by someone from an under-represented group?'}
                  type='dropdown'
                  name='isOwnedBySomeoneFromURG'
                  error={errors['isOwnedBySomeoneFromURG']}
                  onChange={this.onBusinessOwnerChange}
                  value={user.isOwnedBySomeoneFromURG ? {value:user.isOwnedBySomeoneFromURG.value, label :user.isOwnedBySomeoneFromURG.label} : null}
                  options={isIndigenousOwnedOptions.map((item) => {
                    return { label: item, value: item };
                  })}
                />
                <Input
                  label={'Company Website'}
                  name='companyWebsite'
                  value={user.companyWebsite}
                  error={errors['companyWebsite']}
                  onChange={this.onChangeHandler}
                  placeholder={"Type your company's website"}
                  disabled={confirmAccountDetails}
                />
                <Input
                  type='location'
                  name='companyAddress'
                  label={'Company Address'}
                  error={errors['companyAddress']} 
                  value={user.location}
                  onChange={this.onLocationChange}
                  placeholder={'Company HQ Address Line 1'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Address Line 2'}
                  name='address2'
                  value={user.address2}
                  onChange={this.onChangeHandler}
                  placeholder={'Company HQ Address Line 2'}
                  disabled={confirmAccountDetails}
                />
                <Input
                  label={'Postal Code'}
                  value={user.locationInfo ? user.locationInfo.postalCode : ''}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled
                />
                <Input
                  label={'City'}
                  value={user.locationInfo ? user.locationInfo.city : ''}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled
                />
                <Input
                  label={'Province'}
                  value={user.locationInfo ? user.locationInfo.province : ''}
                  onChange={this.onChangeHandler}
                  placeholder={''}
                  disabled
                />
              </div>
              </React.Fragment>}
            </div>
            </React.Fragment>}
          </div>
          { canEdit ? <React.Fragment>
          {confirmAccountDetails ?
            <div className="confirm-btn-container">
              <Button
                title={'Edit Details'}
                onClick={this.nextStep}
                uppercase
                disabled={thereAreErrors}
              />
            </div>
            :
            <Button
              title={'Update Details'}
              onClick={this.handleEditParticipant}
              uppercase
              disabled={thereAreErrors}
            />
          }
          </React.Fragment> : ''}
        </div>
      );
    }
}


function mapState(state) {
  const { activeParticipant } = state.participant;
  const { adminUserInfo, currentProgram } = state.authentication;
  return { currentProgram, adminUserInfo, activeParticipant };
}

const actionCreators = {
  register: userActions.register,
  getParticipants:participantActions.getParticipants,
  getParticipant : participantActions.getParticipantDetails
}

export default connect(mapState, actionCreators)(EditParticipant);