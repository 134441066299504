import React from 'react';
import { utils } from '../../../../helpers/utils';
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import AdminActionButton from '../../../Common/AdminActionButton/AdminActionButton';

class ApplicationTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    viewApplication = (id) => {
        window.open(`viewapplication/${id}`, '_blank');
    }

    render() {
        const { application } = this.props;
        let showReviewDocument = false;
        if(application.status === 'Application Draft Approved' || application.status === 'Application Submitted'){
            showReviewDocument = true;
        }
        return (
            <div className='preapproval-details-row' id={this.props.id}>
                <div className={`preapproval-details ${application.isLinkedToCES ? "linked-app" : ""} ${application.hasOwnProperty("isActive") && !application.isActive ? "inactive" : ""}`}>
                <div><label>ID:</label><span>{application.flag ? (
            <React.Fragment><FlagIcon currentStatus={application.flag.status} className="flag-app-icon" color={application.flag.colorCode} /></React.Fragment>) :''}{application.appNo}</span></div>
                    <div><label>Customer:</label><span>{application.customerCompanyName}</span></div>
                    <div><label>Contractor:</label><span>{application.contractorCompanyName}</span></div>
                    <div><label>Status:</label><span>{application.status}</span></div>
                    <div><label>Draft Approved Date:</label><span>{application.applicationDraftApprovedDate}</span></div>
                    <div><label>Submission Date:</label><span>{application.applicationSubmittedDate}</span></div>
                    <div><label>Reviewer:</label><span>{application.reviewerName}</span></div>
                    <div><label>Estimated Incentive:</label><span>{utils.formatAsCAD(application.estimatedIncentive)}</span></div>
                    <div><label>Pre-Approved Incentive:</label><span>{utils.formatAsCAD(application.reviewerEstimatedIncentive)}</span></div>
                    <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
                </div>
                <div className="preapproval-more-details">
                    <div>

                       <AdminActionButton 
                            iconClass={`view-icon`}
                            actionName={`Application Board-View Application`}
                            viewApplication={this.viewApplication}
                            application={application}
                            title={'View Application'}
                        />
                        <AdminActionButton 
                            iconClass={`notes-icon`}
                            actionName={`Application Board-Internal Notes`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'view-notes'}
                            title={'Internal Notes'}
                        />
                        {showReviewDocument && 
                        <AdminActionButton 
                                iconClass={`upload-icon`}
                                actionName={`Application Board-Review Documents`}
                                showActionModal={this.props.showActionModal}
                                application={application}
                                appModalType={'review-documents'}
                                title={'Review Documents'}
                            />
                        }
                        {application.isLinkedToCES ? "" : application.hasOwnProperty("isActive") && !application.isActive ? "" : 
                        <React.Fragment>
                        <AdminActionButton 
                            iconClass={`status-log-icon`}
                            actionName={`Application Board-Status Logs`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'view-status-log'}
                            title={'Status Logs'}
                        />
                        {/* <AdminActionButton 
                            iconClass={`status-log-icon`}
                            actionName={`Application Board-Flag Logs`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'flag-logs-application'}
                            title={'Flag Logs'}
                        /> */}
                        <AdminActionButton 
                            iconClass={`flag-icon`}
                            actionName={`Application Board-Application Flags`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'flag-logs-application'}
                            title={'Application Flag'}
                        />
                        <AdminActionButton 
                            iconClass={`change-request-icon`}
                            actionName={`Application Board-View Change Requests`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'view-change-requests'}
                            title={'View Change Requests'}
                        />
                        {/* <AdminActionButton 
                            iconClass={`status-log-icon`}
                            actionName={`Application Board-Change Request Logs`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'view-change-request-logs'}
                            title={'Change Request Logs'}
                        /> */}
                     <AdminActionButton
                            iconClass={'port-customer-icon'}
                            actionName={'Application Board-Port Customer'}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={"port-customer"}
                            title={"Port Customer"}
                        />
                     </React.Fragment>}
                     {application.isLinkedToCES ? "" : <AdminActionButton 
                        iconClass={`state-icon`}
                        actionName={`Application Board-Application State`}
                        showActionModal={this.props.showActionModal}
                        application={application}
                        appModalType={'application-state'}
                        title={'Application State'}
                    />}
                    </div>
                </div>
            </div>
        );
    }
}

export default ApplicationTab;
