import React from "react";
import PaymentRow from "./PaymentRow";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import { utils } from "../../../../helpers/utils";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import "./Payment.css";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";
import Button from "../../../Common/Button/Button";
import Drawer from "../../../Common/Drawer/Drawer";
import FilterVector from "../../../../images/FilterVector.svg";

class PaymentTab extends React.Component {
  constructor(props) {
    super(props);
    this.searchDebouncedData = debounce(this.searchData, 300);
    this.state = {
      openDrawer: false,
      selectedFlagFilters: [],
      programStream: [],
    };
  }

  componentDidMount() {
    if (this.props.currentProgram === "retrofit") {
      this.props.loadAdminConfigRetrofit();
    } else {
      this.props.loadAdminConfig();
    }
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".payment-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onFlagFilterChange = (SelectedFlagFilters) => {
    this.props.setFilters("Payment", {
      SelectedFlagFilters,
    });
  };

  onSearch = (searchInput) => {
    this.props.setFilters("Payment", {
      searchInput,
    });
  };

  searchData = (refresh = false) => {
    const { sortConfig, SelectedStatusFilters, SelectedFlagFilters, currentProgram, StageFilters, stream } = this.props;
    let config = {
      statuses: SelectedStatusFilters,
      flags: SelectedFlagFilters,
      sort: sortConfig,
      stage: StageFilters,
      reviewerId: this.props.reviewerId,
      program: currentProgram,
      stream: this.state.programStream,
    };
    if (currentProgram === "retrofit") {
      this.props.getApplicationsToReviewRetrofit("Payment", this.props.searchInput, config, refresh ? 0 : this.props.reviewAppListOffset);
    } else {
      this.props.getApplicationsToReview("Payment", this.props.searchInput, config, refresh ? 0 : this.props.reviewAppListOffset);
    }
  };

  handleListScroll = () => {
    if (this.props.moreReviewApps) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    this.props.setFilters("Payment", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };

  toggleDrawer = (status) => {
    let updateObj = {
      openDrawer: status,
    };
    this.setState(updateObj);
  };

  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let checkedStatus = this.state[name];
    if (checked) {
      // push selected value in list
      checkedStatus = [...checkedStatus, value];
    } else {
      // remove unchecked value from the list
      checkedStatus = checkedStatus.filter((x) => x !== value);
      // setLang(prev => prev.filter(x => x !== value));
    }
    this.setState({
      [name]: checkedStatus,
    });
  };

  applyFiters = () => {
    const { state, selectedFlagFilters, selectedStatusFilters, programStream } = this.state;
    this.props.setFilters("Payment", {
      SelectedFlagFilters: selectedFlagFilters,
      SelectedStatusFilters: selectedStatusFilters,
      stream: programStream,
      onlyActiveApps: state === "Hide Inactive Applications" ? [true] : state === "Hide Active Applications" ? [false] : [true, false],
    });
    this.toggleDrawer(false);
  };

  clearFilters = () => {
    this.props.setFilters("Payment", {
      SelectedFlagFilters: [],
      SelectedStatusFilters: [],
      stream: [],
      onlyActiveApps: [true, false],
    });
    this.setState({
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      state: "Show All Applications",
      programStream: [],
    });
  };

  render() {
    const { reviewAppList, SelectedFlagFilters, configData, applicationsToReviewLoading, reviewAppStage, currentProgram, reviewerId } =
      this.props;
    const { openDrawer, programStream, selectedFlagFilters } = this.state;
    let flagsList = [];
    let reviewList = reviewAppList;
    if (configData) {
      if (currentProgram === "retrofit") {
        let paymentFlags = configData.appFlags.flags.filter((item) => item.stage === "Payment");
        flagsList = paymentFlags.map((item) => {
          return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
        });
        reviewList =
          reviewAppList &&
          reviewAppList.filter((app) => {
            if (app.finalInvoiceReviewer && app.finalInvoiceReviewer.value === reviewerId) {
              return app;
            }
          });
      } else {
        flagsList = configData.appFlags.flags.map((item) => {
          return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
        });
      }
    }
    let diffPrograms = [
      { label: "Energy Savings For Business", value: "esb" },
      { label: "Comprehensive Energy Savings", value: "ces" },
    ];
    return (
      <div className="reviews-tab">
        <Drawer toggleDrawer={this.toggleDrawer} openDrawer={openDrawer} className={"program-panel-drawer"}>
          <div className="filter-drawer">
            <h3>FILTER BY</h3>
            {configData ? (
              <div className="all-filters">
                <div className="filter">
                  <label className="filter-label">Flag</label>
                  <div className="filter-options">
                    {flagsList.map((flagItem, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="selectedFlagFilters"
                            checked={selectedFlagFilters.includes(flagItem.value)}
                            value={flagItem.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />{" "}
                          <span>{flagItem.icon}</span>
                          {flagItem.value}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">Program</label>
                  <div className="filter-options">
                    {diffPrograms.map((program, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="programStream"
                            checked={programStream.includes(program.value)}
                            value={program.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />
                          {program.label}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Button className="inversed-btn drawer-btn first-btn" title="Clear All Filters" onClick={this.clearFilters} />
          <Button className="drawer-btn" title="Apply Filters" onClick={this.applyFiters} />
        </Drawer>
        <div className="reviews-top-bar">
          <div className="reviews-top-bar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for Applications" onClick={this.onSearch} />
          </div>
          {currentProgram === "esb" ? (
            <div className="reviews-top-bar-filter-btn">
              <Button
                className="filter-btn"
                uppercase
                title={"Filters"}
                svg={FilterVector}
                onClick={() => this.setState({ openDrawer: true })}
              />
            </div>
          ) : null}
          {currentProgram === "esb" ? null : (
            <div className="reviews-top-bar-filter">
              {configData ? (
                <FilterDropdown
                  label={"Filter by Flag"}
                  iconBasedOption={true}
                  iconClass="flag-filter"
                  FilterOptions={flagsList}
                  SelectedFilters={SelectedFlagFilters}
                  onChange={this.onFlagFilterChange}
                  className={"program-filter"}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="payment-details-header payment-details-row">
          {currentProgram !== "retrofit" ? (
            <div className="payment-details">
              <div onClick={() => this.sortData("appNo", "string")}>ID</div>
              <div onClick={() => this.sortData("payeeInfo.payeeName", "string")}>Payee</div>
              <div onClick={() => this.sortData("contractor.label", "string")}>Contractor</div>
              <div onClick={() => this.sortData("companyName", "string")}>Customer</div>
              <div onClick={() => this.sortData("Payment - Banking Details In Review", "date")}>Submission Date</div>
              <div onClick={() => this.sortData("PostProjectReviewerApprovedIncentive", "number")}>Approved Incentive</div>
              <div></div>
            </div>
          ) : (
            <div className="payment-details">
              <div onClick={() => this.sortData("application_id", "string")}>ID</div>
              <div onClick={() => this.sortData("application_name", "string")}>Application Name</div>
              <div onClick={() => this.sortData("application_project_type", "string")}>Application Project Type</div>
              <div onClick={() => this.sortData("applicant_account_id", "string")}>Email</div>
              <div onClick={() => this.sortData("multi_site", "string")}>Multi-Site</div>
              <div onClick={() => this.sortData("application_status_name", "string")}>Status</div>
              <div onClick={() => this.sortData("pre_project_approval_date", "date")}>Post-Project Approved Date</div>
              <div onClick={() => this.sortData("submission_dt", "string")}>Invoice Submitted Date</div>
              <div onClick={() => this.sortData("total_est_app_incentive", "number")}>Estimated Incentive</div>
              <div onClick={() => this.sortData("post_project_resubmission", "string")}>IR Response</div>
              <div></div>
            </div>
          )}
        </div>
        <div className="payment-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {applicationsToReviewLoading && reviewAppStage !== "Payment" ? (
            <SkeletonLoading />
          ) : reviewList && !utils.isNullOrEmpty(reviewList) ? (
            reviewList.map((application, i) => (
              <PaymentRow
                application={application}
                currentProgram={currentProgram}
                key={`application-${i}`}
                id={`application-${i}`}
                showActionModal={this.props.showActionModal}
                ShowMoreDetails={this.ShowMoreDetails}
              />
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { currentProgram } = state.authentication;
  const {
    reviewAppList,
    reviewAppListOffset,
    applicationsToReviewLoading,
    reviewAppStage,
    appStatusLogsLoading,
    activeStatusLogs,
    moreReviewApps,
  } = state.application;
  return {
    currentProgram,
    reviewAppList,
    reviewAppListOffset,
    applicationsToReviewLoading,
    reviewAppStage,
    appStatusLogsLoading,
    activeStatusLogs,
    moreReviewApps,
    configData,
  };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getApplicationsToReview: appActions.getApplicationsToReview,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getApplicationsToReviewRetrofit: appActions.getApplicationsToReviewRetrofit,
};

export default connect(mapState, actionCreators)(PaymentTab);
