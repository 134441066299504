import React from "react";
import "./ReviewDocuments.css";
import ResponsiveTable from "../../../Common/ResponsiveTable/ResponsiveTable";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import LoaderComponent from "../../../Common/Loader/Loader";
import Button from "../../../Common/Button/Button";
import Input from "../../../Common/Input/Input";
import { connect } from "react-redux";
import { appActions } from "../../../../redux/actions/appActions";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import { ReactComponent as DownloadIcon } from "../../../../images/DownloadGrey.svg";
import { ReactComponent as TrashIcon } from "../../../../images/TrashGrey.svg";
import { ReactComponent as EditIcon } from "../../../../images/EditDocIcon.svg";
import SuccessIcon from "../../../../images/VerifiedTick.svg";
import Table from "../../../Common/Table/Table";
import Modal from "../../../Common/Modal/Modal";
import { utils } from "../../../../helpers/utils";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";

class ReviewDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationObj: {},
      showFileUpload: false,
      reviewDocumentsLoading: true,
      documentAddedSuccessfully: false,
      confirmingDocumentAddition: false,
      deleteDocumentModalOpen: false,
      folderOpen: false,
      changeButtonStatus: false,
      name: "",
      editingDocId: "",
      editedDocument: {},
      documentIdToDelete: "",
      reviewDocUpdate: true,
      reviewDocUpload: false,
      logsSearchInput: "",
      listSearchInput: "",
      searchReviewDocumentsList: [],
      searchReviewDocumentsLogs: [],
      infectedFile: false,
    };
  }

  getReviewDocumentsCred = () => {
    const { currentProgram, applicationId, uploadDocumentFor, currentFacilityId } = this.props;
    if (currentProgram === "retrofit") {
      if (
        uploadDocumentFor === "Measures" ||
        uploadDocumentFor === "QA/QC" ||
        uploadDocumentFor === "Final Document Checklist" ||
        uploadDocumentFor === "Disposal"
      ) {
        this.props.getRetrofitReviewProcessDocuments(currentFacilityId);
        this.props.getRetrofitReviewProcessDocumentsLogs(currentFacilityId);
      } else {
        this.props.getRetrofitReviewDocuments(applicationId);
        this.props.getRetrofitAppReviewDocumentsLogs(applicationId);
      }
    } else {
      this.props.getReviewDocuments(applicationId);
      this.props.getAppReviewDocumentsLogs(applicationId);
    }
  };
  componentWillMount() {
    this.getReviewDocumentsCred();
    if (this.props.uploadDocumentFor) {
      this.props.loadAdminConfigRetrofit();
    }
    this.setState({
      applicationObj: this.props.application,
      reviewDocumentsLoading: false,
      infectedFile: false,
      searchReviewDocumentsList: this.props.reviewDocumentList,
      searchReviewDocumentsLogs: this.props.reviewDocumentsLogs,
    });
  }
  componentDidMount() {
    this.getReviewDocumentsCred();
    this.setState({
      applicationObj: this.props.application,
      reviewDocumentsLoading: false,
      searchReviewDocumentsList: this.props.reviewDocumentList,
      searchReviewDocumentsLogs: this.props.reviewDocumentsLogs,
    });
  }
  onSearchList = (listSearchInput) => {
    const { reviewDocumentList, uploadDocumentFor, currentMeasureId } = this.props;
    let FilteredList = [];
    let filteredList = [];
    if (listSearchInput) {
      let SearchPattern = new RegExp("(\\w*" + listSearchInput + "\\w*)", "gi");
      FilteredList = reviewDocumentList.filter((doc) => {
        return doc.name.match(SearchPattern) || doc.extension.match(SearchPattern) || doc.actionTaker.match(SearchPattern);
      });
    } else {
      FilteredList = reviewDocumentList;
    }
    this.setState({
      searchReviewDocumentsList: filteredList,
      listSearchInput,
    });
  };
  onSearchLogs = (logsSearchInput) => {
    const { reviewDocumentsLogs } = this.props;
    let FilteredList = [];
    if (logsSearchInput) {
      let SearchPattern = new RegExp("(\\w*" + logsSearchInput + "\\w*)", "gi");
      FilteredList = reviewDocumentsLogs.filter((doc) => {
        return doc.documentName.match(SearchPattern) || doc.action.match(SearchPattern) || doc.updatedBy.match(SearchPattern);
      });
    } else {
      FilteredList = reviewDocumentsLogs;
    }
    this.setState({
      searchReviewDocumentsLogs: FilteredList,
      logsSearchInput,
    });
  };
  buttonStatus = () => {
    this.setState({
      changeButtonStatus: true,
    });
  };
  showFileUploader = () => {
    this.setState({
      showFileUpload: true,
      changeButtonStatus: true,
    });
  };
  closeFileUploader = () => {
    this.setState({
      showFileUpload: false,
      changeButtonStatus: false,
    });
  };
  handleReviewDocumentUpload = async (name, fileObj) => {
    const { currentProgram, application, currentMeasureId, currentFacilityId, uploadDocumentFor, uploadFilesFieldName } = this.props;
    if (currentProgram === "retrofit") {
      fileObj.application_id = `${application.id}`;
      fileObj.appStage = application.stage;
      fileObj.actionTaker = `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`;
      let newObj = { ...fileObj };
      if (uploadDocumentFor === "Measures") {
        newObj.application_id = `${application.application_id}`;
        newObj.measure_id = `${currentMeasureId}`;
        newObj.facility_id = `${currentFacilityId}`;
        newObj.isFinalDocumentChecklist = false;
        newObj.isDisposalDocument = false;
        newObj.fieldName = uploadFilesFieldName;
        await this.props.createRetrofitReviewProcessDocument(newObj);
      } else if (uploadDocumentFor === "QA/QC") {
        newObj.application_id = `${application.application_id}`;
        newObj.measure_id = "";
        newObj.facility_id = `${currentFacilityId}`;
        newObj.isFinalDocumentChecklist = false;
        newObj.isDisposalDocument = false;
        this.props.createRetrofitReviewProcessDocument(newObj);
      } else if (uploadDocumentFor === "Final Document Checklist") {
        newObj.application_id = `${application.application_id}`;
        newObj.measure_id = "";
        newObj.facility_id = `${currentFacilityId}`;
        newObj.isFinalDocumentChecklist = true;
        newObj.isDisposalDocument = false;
        this.props.createRetrofitReviewProcessDocument(newObj);
      } else if (uploadDocumentFor === "Disposal") {
        newObj.application_id = `${application.application_id}`;
        newObj.measure_id = "";
        newObj.facility_id = `${currentFacilityId}`;
        newObj.isFinalDocumentChecklist = false;
        newObj.isDisposalDocument = true;
        this.props.createRetrofitReviewProcessDocument(newObj);
      } else {
        this.props.createRetrofitReviewDocument(newObj);
      }
    } else {
      fileObj.applicationId = this.props.applicationId;
      fileObj.appStage = this.state.applicationObj.stage;
      fileObj.actionTaker = `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`;
      let newObj = { ...fileObj };
      this.props.createReviewDocument(newObj);
    }
    if (fileObj.isInfected) {
      this.setState({ infectedFile: true });
    }
  };
  handleLogsFolder = (val) => {
    const { currentProgram, currentFacilityId, uploadDocumentFor } = this.props;
    if (val) {
      if (currentProgram === "retrofit") {
        if (
          uploadDocumentFor === "Measures" ||
          uploadDocumentFor === "QA/QC" ||
          uploadDocumentFor === "Final Document Checklist" ||
          uploadDocumentFor === "Disposal"
        ) {
          this.props.getRetrofitReviewProcessDocumentsLogs(currentFacilityId);
        } else {
          this.props.getRetrofitAppReviewDocumentsLogs(this.props.applicationId);
        }
      } else {
        this.props.getAppReviewDocumentsLogs(this.props.applicationId);
      }
    }
    this.setState({
      folderOpen: val,
    });
  };

  // viewDocument = (document) => {
  //   window.open(document.url);
  // };
  downloadFile = (filePath) => {
    var link = document.createElement("a");
    link.target = "_blank";
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  };
  editDocument = (docId) => {
    this.setState({
      editingDocId: docId,
    });
  };
  onDocumentNameChange = (id, newName) => {
    this.setState({
      editedDocument: {
        id,
        name: newName,
      },
    });
  };

  saveDocument = () => {
    const { currentProgram, uploadDocumentFor, currentFacilityId } = this.props;
    let { editedDocument } = this.state;
    if (currentProgram === "retrofit") {
      if (
        uploadDocumentFor === "Measures" ||
        uploadDocumentFor === "QA/QC" ||
        uploadDocumentFor === "Final Document Checklist" ||
        uploadDocumentFor === "Disposal"
      ) {
        this.props.updateRetrofitReviewProcessDocument(editedDocument.id, {
          name: editedDocument.name,
          facility_id: `${currentFacilityId}`,
        });
      } else {
        this.props.updateRetrofitReviewDocument(editedDocument.id, { name: editedDocument.name, applicationId: this.props.applicationId });
      }
    } else {
      this.props.updateReviewDocument(editedDocument.id, { name: editedDocument.name, applicationId: this.props.applicationId });
    }
    this.setState({ editedDocument: {}, editingDocId: null });
  };
  deleteDocument = (documentId) => {
    this.setState({
      deleteDocumentModalOpen: true,
      documentIdToDelete: documentId,
    });
  };
  toggleDeleteDocumentModal = (modalOpen) => {
    this.setState({ deleteDocumentModalOpen: modalOpen });
  };
  deleteDocumentFinally = () => {
    const { currentProgram, uploadDocumentFor, currentFacilityId } = this.props;
    this.setState({
      deleteDocumentModalOpen: false,
      documentIdToDelete: "",
    });
    if (currentProgram === "retrofit") {
      if (
        uploadDocumentFor === "Measures" ||
        uploadDocumentFor === "QA/QC" ||
        uploadDocumentFor === "Final Document Checklist" ||
        uploadDocumentFor === "Disposal"
      ) {
        this.props.deleteRetrofitReviewProcessDocument(this.state.documentIdToDelete, currentFacilityId);
      } else {
        this.props.deleteRetrofitReviewDocument(this.state.documentIdToDelete, this.props.applicationId);
      }
    } else {
      this.props.deleteReviewDocument(this.state.documentIdToDelete, this.props.applicationId);
    }
  };
  render() {
    const {
      currentProgram,
      reviewDocumentDeleting,
      reviewDocumentsLogs,
      reviewDocumentList,
      applicationReviewDocumentsLoading,
      appReviewDocumentsLoading,
      application,
      uploadDocumentFor,
      currentMeasureId,
      currentFacilityId,
      uploadFilesFieldName,
    } = this.props;
    const {
      showFileUpload,
      deleteDocumentModalOpen,
      folderOpen,
      changeButtonStatus,
      reviewDocumentsLoading,
      editingDocId,
      editedDocument,
      logsSearchInput,
      listSearchInput,
      searchReviewDocumentsList,
      searchReviewDocumentsLogs,
      infectedFile,
    } = this.state;
    const IESO_RETROFIT_CLOUD_STORAGE_APPLICATION_PATH = "ieso/retrofit/applications_document/";
    let documentList = listSearchInput ? searchReviewDocumentsList : reviewDocumentList;
    let documentLogs = logsSearchInput ? searchReviewDocumentsLogs : reviewDocumentsLogs;
    documentList = documentList.filter((doc) => !doc.isInfected);
    let filteredList = [];
    let filteredLogs = [];
    if (uploadDocumentFor === "Measures") {
      filteredList = documentList.filter((obj) => obj.measure_id === `${currentMeasureId}` && obj.fieldName === uploadFilesFieldName);
      filteredLogs = documentLogs.filter((obj) => obj.measure_id === `${currentMeasureId}` && obj.fieldName === uploadFilesFieldName);
    } else if (uploadDocumentFor === "QA/QC") {
      filteredList = documentList.filter(
        (obj) => !obj.name.includes(`${currentFacilityId}`) && obj.measure_id === "" && obj.facility_id === `${currentFacilityId}` && obj.isDisposalDocument === false && obj.isFinalDocumentChecklist === false
      );
      filteredLogs = documentLogs.filter(
        (obj) => !obj.documentName.includes(currentFacilityId) && !obj.isFinalDocumentChecklist && !obj.isDisposalDocument
      );
    } else if (uploadDocumentFor === "Final Document Checklist") {
      filteredList = documentList.filter((obj) => obj.isFinalDocumentChecklist === true);
      filteredLogs = documentLogs.filter((obj) => obj.isFinalDocumentChecklist === true);
    } else if (uploadDocumentFor === "Disposal") {
      filteredList = documentList.filter((obj) => obj.isDisposalDocument === true);
      filteredLogs = documentLogs.filter((obj) => obj.isDisposalDocument === true);
    } else {
      filteredList = documentList;
      filteredLogs = documentLogs;
    }
    return (
      <div className="review-list-container">
        {reviewDocumentsLoading ? (
          <LoaderComponent />
        ) : (
          <div className="section-header">
            <div className="section-top-details">{application.appNo || application.application_id || application.id}</div>
            <div className="section-top-details name-section">
              {uploadDocumentFor
                ? `${application.applicant_first_name} ${application.applicant_last_name}`
                : currentProgram === "retrofit"
                ? application.applicantName
                : application.customerCompanyName}
            </div>
            <div className="section-top-details">
              {uploadDocumentFor
                ? application.applicant_account_id
                : currentProgram === "retrofit"
                ? application.applicantEmail
                : application.contractorCompanyName}
            </div>
          </div>
        )}
        <div className="section-below-header">
          <div className="button-set">
            <div className="button-options">
              <h2 className="section-title">
                {!folderOpen && ReviewDocuments.length
                  ? uploadDocumentFor
                    ? uploadFilesFieldName === "facilityMeasuresPrimary32"
                      ? "Supporting Documents"
                      : uploadFilesFieldName === "facilityMeasuresPrimary33"
                      ? "Custom Worksheet"
                      : uploadFilesFieldName === "facilityMeasuresPrimary11"
                      ? "DLC/ES Screenshots"
                      : "Upload File"
                    : "Review Documents"
                  : uploadDocumentFor
                  ? "Uploaded Files"
                  : "Document Logs"}
              </h2>{" "}
              {(reviewDocumentList && reviewDocumentList.length) || (reviewDocumentsLogs && reviewDocumentsLogs.length) ? (
                <div className={folderOpen ? "logs-folder-btn" : "logs-btn"} onClick={() => this.handleLogsFolder(!folderOpen)} />
              ) : (
                ""
              )}
            </div>
            <div className="button-options">
              {folderOpen ? (
                <SearchInput value={logsSearchInput} placeholder="Search for Logs" onClick={this.onSearchLogs} />
              ) : (
                <SearchInput value={listSearchInput} placeholder="Search for Documents" onClick={this.onSearchList} />
              )}
            </div>
            <div className="button-options">
              {folderOpen ? (
                ""
              ) : !changeButtonStatus ? (
                <Button className="upload-btn review-upload-icon" title={"Upload"} uppercase onClick={this.showFileUploader} />
              ) : (
                <Button className="close-btn review-close-icon" title={"close"} uppercase onClick={this.closeFileUploader} />
              )}
            </div>
          </div>
        </div>
        {folderOpen ? (
          <div className="container-box">
            {!appReviewDocumentsLoading ? (
              searchReviewDocumentsLogs ? (
                filteredLogs.length ? (
                  <React.Fragment>
                    <Table
                      rows={filteredLogs}
                      headings={[
                        { title: "Document Name", value: "documentName" },
                        { title: "Document Description", value: "action" },
                        { title: "Date", value: "createdAt" },
                        { title: "Updated By", value: "updatedBy" },
                      ]}
                    />
                  </React.Fragment>
                ) : (
                  <div className="no-records-found">No records.</div>
                )
              ) : (
                <EmptyContainer>There are currently no logs to show.</EmptyContainer>
              )
            ) : (
              <LoaderComponent />
            )}
          </div>
        ) : (
          <div className="container-box1">
            {showFileUpload ? (
              <div className="reviewdocument-file-upload-container">
                <Input
                  type={"file"}
                  name={"documents"}
                  onChange={this.handleReviewDocumentUpload}
                  maxFiles={5}
                  // customFileName={customFileName}
                  // docSerialNo={docSerialNo+1}
                  folderPath={
                    uploadDocumentFor
                      ? uploadDocumentFor === "QA/QC"
                        ? `${IESO_RETROFIT_CLOUD_STORAGE_APPLICATION_PATH}${application.application_id}/${currentFacilityId}/qaqc`
                        : uploadDocumentFor === "Measures"
                        ? `${IESO_RETROFIT_CLOUD_STORAGE_APPLICATION_PATH}${application.application_id}/${currentFacilityId}/${currentMeasureId}`
                        : uploadDocumentFor === "Final Document Checklist"
                        ? `${IESO_RETROFIT_CLOUD_STORAGE_APPLICATION_PATH}${application.application_id}/${currentFacilityId}/finaldocumentchecklist`
                        : `${IESO_RETROFIT_CLOUD_STORAGE_APPLICATION_PATH}${application.application_id}/${currentFacilityId}/disposal`
                      : ""
                  }
                  placeholder={
                    !infectedFile ? (
                      <p className="file-upload-prompt">
                        Drag & Drop a file or Select from computer to attach review documents to this application. All file types are
                        accepted.
                      </p>
                    ) : (
                      <p className="infected-file">
                        A file you are trying to upload seems to be infected. Please try again with a different file. If you feel the file
                        is clean, reach out to us on support@esbprogram.ca
                      </p>
                    )
                  }
                />
              </div>
            ) : (
              ""
            )}

            {!applicationReviewDocumentsLoading ? (
              reviewDocumentDeleting ? (
                <LoaderComponent />
              ) : searchReviewDocumentsList && documentList.length && filteredList.length ? (
                documentList.length ? (
                  <React.Fragment>
                    <ResponsiveTable
                      headings={[
                        { title: "S. No.", value: "SNO" },
                        { title: "Document Name", value: "name" },
                        { title: "Document Type", value: "extension" },
                        { title: "Uploaded Date", value: "createdAt" },
                        { title: "Uploaded By", value: "uploadedBy" },
                        { title: "", value: "moreActions" },
                      ]}
                      rows={documentList
                        .filter((obj) => {
                          if (uploadDocumentFor === "Measures") {
                            if (obj.measure_id === `${currentMeasureId}` && obj.fieldName === uploadFilesFieldName) {
                              return obj;
                            }
                          } else if (uploadDocumentFor === "QA/QC") {
                            if (
                              !obj.name.includes(`${currentFacilityId}`) &&
                              obj.measure_id === "" &&
                              obj.facility_id === `${currentFacilityId}` &&
                              !obj.isFinalDocumentChecklist &&
                              !obj.isDisposalDocument
                            ) {
                              return obj;
                            }
                          } else if (uploadDocumentFor === "Final Document Checklist") {
                            if (obj.isFinalDocumentChecklist) {
                              return obj;
                            }
                          } else if (uploadDocumentFor === "Disposal") {
                            console.log("Disposal---------->", obj);
                            if (obj.isDisposalDocument) {
                              return obj;
                            }
                          } else {
                            return obj;
                          }
                        })
                        .map((doc, index) => {
                          return {
                            SNO: index + 1,
                            name:
                              doc.id === editingDocId ? (
                                <React.Fragment>
                                  <input
                                    name={editingDocId}
                                    value={
                                      editedDocument && editedDocument.id ? editedDocument.name : doc.name ? doc.name.split(".")[0] : null
                                    }
                                    className={`document-name-input`}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      this.onDocumentNameChange(name, value);
                                    }}
                                  />
                                  <img alt="success icon" src={SuccessIcon} className="save-icon" onClick={this.saveDocument} />
                                </React.Fragment>
                              ) : (
                                <div className="doc-name">
                                  <span className="turncate-string">
                                    {doc.name}
                                    <span className="tooltiptext">{doc.name}</span>
                                  </span>
                                  {uploadDocumentFor === "Measures" ? (
                                    ""
                                  ) : (
                                    <span className="turncate-icon">
                                      <EditIcon
                                        className="view-eye-icon"
                                        width="30px"
                                        height="20px"
                                        onClick={() => this.editDocument(doc.id)}
                                      />
                                    </span>
                                  )}
                                </div>
                              ),
                            extension: doc.extension,
                            createdAt: utils.formatDate(doc.createdAt),
                            uploadedBy: doc.actionTaker,
                            moreActions: (
                              <div className="more-actions">
                                <DownloadIcon
                                  className="view-eye-icon"
                                  width="30px"
                                  height="20px"
                                  onClick={() => this.downloadFile(doc.url)}
                                />
                                <TrashIcon
                                  className="view-eye-icon"
                                  width="30px"
                                  height="20px"
                                  onClick={() => this.deleteDocument(doc.id)}
                                />
                              </div>
                            ),
                          };
                        })}
                    />
                  </React.Fragment>
                ) : (
                  <div className="no-records-found">No records.</div>
                )
              ) : (
                <EmptyContainer>There are currently no uploaded documents against this application.</EmptyContainer>
              )
            ) : (
              <LoaderComponent />
            )}
          </div>
        )}
        <Modal
          HeaderText={"Are you sure?"}
          className="review-delete"
          IsOpen={deleteDocumentModalOpen}
          handleCloseModal={() => this.toggleDeleteDocumentModal(false)}
        >
          <p>Are you sure you want to delete this document?</p>
          <div className="button-set">
            <Button className="inversed-btn" title={"No"} uppercase onClick={() => this.toggleDeleteDocumentModal(false)} />
            <Button title={"Yes"} onClick={this.deleteDocumentFinally} uppercase />
          </div>
        </Modal>
      </div>
    );
  }
}

function mapState(state) {
  const { currentProgram, adminUserInfo } = state.authentication;
  const {
    reviewDocumentList,
    reviewDocumentsLogs,
    reviewDocumentDeleting,
    applicationReviewDocumentsLoading,
    appReviewDocumentsLoading,
    reviewDocumentUpdated,
  } = state.application;
  return {
    currentProgram,
    adminUserInfo,
    reviewDocumentList,
    reviewDocumentsLogs,
    applicationReviewDocumentsLoading,
    appReviewDocumentsLoading,
    reviewDocumentUpdated,
    reviewDocumentDeleting,
  };
}
const actionCreators = {
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getReviewDocuments: appActions.getAppReviewDocuments,
  createReviewDocument: appActions.createAppReviewDocument,
  updateReviewDocument: appActions.updateAppReviewDocument,
  deleteReviewDocument: appActions.deleteAppReviewDocument,
  getAppReviewDocumentsLogs: appActions.getAppReviewDocumentsLogs,
  getRetrofitReviewDocuments: appActions.getRetrofitAppReviewDocuments,
  createRetrofitReviewDocument: appActions.createRetrofitAppReviewDocument,
  updateRetrofitReviewDocument: appActions.updateRetrofitAppReviewDocument,
  deleteRetrofitReviewDocument: appActions.deleteRetrofitAppReviewDocument,
  getRetrofitAppReviewDocumentsLogs: appActions.getRetrofitAppReviewDocumentsLogs,
  createRetrofitReviewProcessDocument: appActions.createRetrofitReviewProcessDocument,
  updateRetrofitReviewProcessDocument: appActions.updateRetrofitReviewProcessDocument,
  deleteRetrofitReviewProcessDocument: appActions.deleteRetrofitReviewProcessDocument,
  getRetrofitReviewProcessDocuments: appActions.getRetrofitReviewProcessDocuments,
  getRetrofitReviewProcessDocumentsLogs: appActions.getRetrofitReviewProcessDocumentsLogs,
};
export default connect(mapState, actionCreators)(ReviewDocuments);
