import React from 'react';
import './Settings.css';
import AdminLayout from '../../../Common/AdminLayout';
import { AuthContext } from '../../../../firebaseAuthContext';
import Tabs from '../../../Common/Tabs/Tabs'
import Users from './Users/Users'
import Clients from './Clients/Clients'
import Programs from './Programs/Programs'
import { connect } from 'react-redux';
import { adminSettingsActions } from '../../../../redux/actions/adminSettingsActions';

class Settings extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.props.loadUsers();
    this.props.loadClients();
    this.props.loadPrograms();
    this.props.loadAdminConfig();
  }

  render() {
    return (
      <AdminLayout showFooter={false} props={this.props}>
        <Tabs>
          <div label="Users">
            <Users />
          </div>
          <div label="Client Mngt.">
            <Clients />
          </div>
          <div label="Program Mngt.">
            <Programs />
          </div>
          {/* <div label="Role Mngt.">
            Role Mngt
          </div> */}
        </Tabs>
      </AdminLayout>
    );
  }
}

function mapState(state) {
  const { activeAdminUser, userList, usersLoading } = state.settings;
  return { activeAdminUser, userList, usersLoading };
}

const actionCreators = {
  loadUsers: adminSettingsActions.getAdminUsers,
  loadClients: adminSettingsActions.getClients,
  loadPrograms: adminSettingsActions.getPrograms,
  loadAdminConfig : adminSettingsActions.getAdminConfig
}


export default connect(mapState, actionCreators)(Settings);