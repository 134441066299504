import React, { Component } from "react";
import { applicationService } from "../../../../services/applicationService";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import LoaderComponent from "../../../Common/Loader/Loader";
import Input from "../../../Common/Input/Input";
import "./ReviewBankingDetails.css";
import ImageZoom from "../ImageZoom/ImageZoom";
import Button from "../../../Common/Button/Button";
import { utils } from "../../../../helpers/utils";

export default class ReviewBankingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      reviewLoading: true,
      confirmation: "",
      followedUpViaPhoneCall: false,
      followedUpViaEmail: false,
      applicationObj: {},
      bankDetails: {},
      decision: {},
      reason: {},
      rejectedBankDetailsNote: "",
      decisions: [
        { label: "Approved", value: true },
        { label: "Rejected", value: false },
      ],
      reasons: [
        { label: "Name on bank account (Payee Name) does not match verification document", value: "PayeeNameMismatch" },
        { label: "Institution number does not match bank name on verification document", value: "InstitutionNumberMismatch" },
        { label: "Bank account number does not match verification document", value: "AccountNumberMismatch" },
        { label: "Payee name is spelled incorrectly compared to verification document", value: "IncorrectPayeeName" },
        { label: "Transit number does not match verification document", value: "TransitNumberMismatch" },
        { label: "Verification document is not legible", value: "InvalidCheque" },
        { label: "Others", value: "Others" },
      ],
    };
  }
  async componentDidMount() {
    let updateObj = {};
    applicationService
      .getApplication(this.props.applicationId)
      .then((appObj) => {
        updateObj.applicationObj = appObj;
        updateObj.reviewLoading = false;
      })
      .then((res) => {
        applicationService.getAppBankDetails(this.props.applicationId).then((bankDetails) => {
          updateObj.bankDetails = bankDetails;
          this.setState(updateObj);
        });
      });
  }
  verifyDecision = () => {
    const { decision, reason, errors } = this.state;
    let errorCount = 0;
    if (utils.isNullOrEmpty(decision)) {
      errors.decision = "Decision is required.";
      errorCount++;
    }
    if (decision.label === "Rejected") {
      if (utils.isNullOrEmpty(reason)) {
        errors.reason = "Reason is required.";
        errorCount++;
      }
    }
    if (errorCount > 0) {
      this.setState({ errors });
    }
    return errorCount <= 0;
  };
  handleDecision = (val) => {
    const { errors } = this.state;
    delete errors.decision;
    this.setState({ decision: val, reason: {}, errors });
  };
  handleReason = (val) => {
    const { errors } = this.state;
    delete errors.reason;
    this.setState({ reason: val, errors });
  };
  handleConfirmReview = (conf) => {
    const { decision, reason, rejectedBankDetailsNote, followedUpViaEmail, followedUpViaPhoneCall } = this.state;
    const { firstName, lastName } = this.props.adminUserInfo;
    if (this.verifyDecision()) {
      if (conf === "finalConfirm") {
        let updateObj = {};
        updateObj.reviewer = `${firstName} ${lastName}`;
        updateObj.decision = decision;
        updateObj.reviewStage = "Payment";
        updateObj.reviewStatus = "Incentive Recommendation Approval Pending";
        if (decision && !decision.value) {
          updateObj.reason = reason;
          updateObj.stage = reason.value === "Others" || reason.value === "incorrectPayeeName" ? "Post-Project" : "Payment";
          updateObj.reviewStatus =
            reason.value === "Others" || reason.value === "incorrectPayeeName"
              ? "Application Information Requested"
              : "Revise Banking Details";
          updateObj.informationRequest = rejectedBankDetailsNote;
          applicationService.createAppBankDetailsReview(this.props.applicationId, updateObj).then((res) => {
            this.setState({ confirmation: conf }, () => {
              setTimeout(() => {
                this.props.closeActionModal(true);
              }, 2000);
            });
          });
        } else {
          updateObj.followedUpViaEmail = followedUpViaEmail;
          updateObj.followedUpViaPhoneCall = followedUpViaPhoneCall;
          updateObj.approvedBankDetailsNote = rejectedBankDetailsNote;
          updateObj.paymentMode = "EFT";
          applicationService.createAppBankDetailsReview(this.props.applicationId, updateObj).then((res) => {
            this.setState({ confirmation: conf }, () => {
              setTimeout(() => {
                this.props.closeActionModal(true);
              }, 2000);
            });
          });
        }
      } else this.setState({ confirmation: conf });
    }
  };
  handleGoBack = () => {
    this.setState({ confirmation: "" });
  };
  handleNotesChange = (name, value) => {
    const { errors } = this.state;
    delete errors.rejectedBankDetailsNote;
    this.setState({
      rejectedBankDetailsNote: value,
    });
  };

  handleSwitchValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      rejectedBankDetailsNote,
      reviewLoading,
      applicationObj,
      bankDetails,
      decisions,
      decision,
      errors,
      reason,
      reasons,
      confirmation,
      followedUpViaPhoneCall,
      followedUpViaEmail,
    } = this.state;
    console.log("bankDetails", bankDetails);
    return (
      <div className="banking-details-container">
        {reviewLoading && !bankDetails.length ? (
          <LoaderComponent />
        ) : (
          <React.Fragment>
            <ModalAppDataComponent applicationObj={applicationObj} modalTitle={"Review Banking Details"}></ModalAppDataComponent>
            <div className="container-box">
              <div className="bank-details">
                <div className="details">
                  <div>
                    <label>Name on Bank Account:</label>
                    <span>{bankDetails.nameOnBankAccount}</span>
                  </div>
                  <div>
                    <label>Bank Account Number:</label>
                    <span>{bankDetails.bankAccountNumber}</span>
                  </div>
                  <div>
                    <label>Transit Code:</label>
                    <span>{bankDetails.branchInfo ? bankDetails.branchInfo.transitCode : ""}</span>
                  </div>
                  <div className="inst-code">
                    <label>Institution Code:</label>
                    <span>{bankDetails.bankInstitutionCode.label}</span>
                  </div>
                </div>
                <div className="vertical-separator">
                  <span className="separator"></span>
                </div>
                {!confirmation ? (
                  <div
                    className="cheque-container"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <div
                      className="cheque"
                      onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <ImageZoom url={bankDetails.bankVoidCheque ? bankDetails.bankVoidCheque.url : ""} />
                    </div>
                  </div>
                ) : (
                  <div className="confirm-message">
                    {!(confirmation === "finalConfirm") ? (
                      !(reason && reason.label) ? (
                        <React.Fragment>
                          <p>You are about to approve the submitted banking details.</p>
                          <p>Please make sure that the reviewed details are accurate before clicking 'Confirm Review'.</p>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <p>You are about to reject the submitted banking details and request changes for this application.</p>
                          <p>
                            Please make sure the note you are sharing is detailed and thorough for the payee to make necessary changes
                            before clicking 'Confirm Review'.
                          </p>
                        </React.Fragment>
                      )
                    ) : (
                      <React.Fragment>
                        <p>
                          Your review of the submitted banking details is complete and the status of the application has been changed
                          successfully.
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
              <div className="horizontal-bar"></div>
              <div className="review-actions">
                <div className="form-inner-elements">
                  <div className="form-element">
                    <Input
                      label={"Decision:"}
                      disabled={confirmation}
                      placeholder="Select from List"
                      type="dropdown"
                      name="decision"
                      error={errors["decision"]}
                      onChange={this.handleDecision}
                      value={utils.isNullOrEmpty(decision) ? undefined : decision}
                      options={decisions.map((decision) => {
                        return { label: decision.label, value: decision.value };
                      })}
                      isSearchable={false}
                    />
                  </div>
                  {decision.label === "Rejected" ? (
                    <div className="form-element-two">
                      <Input
                        label={"Reason:"}
                        disabled={confirmation}
                        placeholder={"Select Reason"}
                        type="dropdown"
                        name="reason"
                        error={errors["reason"]}
                        onChange={this.handleReason}
                        value={utils.isNullOrEmpty(reason) ? undefined : reason}
                        options={reasons.map((reason) => {
                          return {
                            label: reason.label,
                            value: reason.value,
                          };
                        })}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {decision.label === "Approved" ? (
                  <div className="approved-decision-section">
                    <div className="horizontal-bar"></div>
                    <div className="approved-decision-values">
                      <Input
                        label="Followed up via Phone Call"
                        type="switch"
                        name="followedUpViaPhoneCall"
                        checked={followedUpViaPhoneCall}
                        onChange={this.handleSwitchValue}
                      />
                      <div className="vertical-separator">
                        <span className="separator"></span>
                      </div>
                      <Input
                        label="Followed up via Email"
                        type="switch"
                        name="followedUpViaEmail"
                        checked={followedUpViaEmail}
                        onChange={this.handleSwitchValue}
                      />
                    </div>
                    <div className="horizontal-bar"></div>
                  </div>
                ) : null}
                {decision.label === "Rejected" ? <div className="form-inner-section">
                  <div className="note-label">Note:</div>
                  <div className="form-element full">
                    <Input
                      type="richtext"
                      disabled={confirmation}
                      value={rejectedBankDetailsNote}
                      onChange={this.handleNotesChange}
                      name="notes"
                    />
                  </div>
                </div> : null}
                {decision.label === "Approved" ? <div className="form-inner-section">
                  <div className="note-label">Note:</div>
                  <div className="form-element full">
                    <Input
                      type="textarea"
                      disabled={confirmation}
                      value={rejectedBankDetailsNote}
                      onChange={this.handleNotesChange}
                      name="notes"
                    />
                  </div>
                </div> : null}
              </div>
            </div>
            <div className="action-buttons">
              {!(confirmation === "finalConfirm") ? (
                <div className="button-set">
                  {confirmation ? (
                    <div className="goback">
                      <Button className="inversed-btn" title={"Go Back"} onClick={this.handleGoBack} uppercase />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="confirm-btn">
                    <Button
                      title={"Confirm Review"}
                      disabled={ decision.label === "Rejected" ? utils.isNullOrEmpty(utils.cleanHTMLText(rejectedBankDetailsNote)) : false}
                      uppercase
                      onClick={() => this.handleConfirmReview(confirmation ? "finalConfirm" : "initialConfirm")}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
