import React from "react";
import "./flagApplication.css";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import { applicationService } from "../../../../services/applicationService";
import { connect } from "react-redux";
import LoaderComponent from "../../../Common/Loader/Loader";
import { utils } from "../../../../helpers/utils";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import LeftArrow from '../../../../images/leftArrow.svg';
class FlagApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flagAddedSuccessfully: false,
      confirmingFlagAddition: false,
      appFlagNotes: "",
      activeAppId: "",
      appFlagLoading: true,
      applicationObj: {},
    };
  }
  async componentDidMount() {
    this.props.loadAdminConfig();
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
        appFlagLoading: false,
      });
    });
  }
  handleappFlagNotesChange = (name, value) => {
    this.setState({
      appFlagNotes: value,
    });
  };
  handleappFlagTypeChange = (value) => {
    console.log(value);
    this.setState({
      appFlagType: value,
    });
  };
  confirmingFlagAddition = () => {
    this.setState({
      confirmingFlagAddition: !this.state.confirmingFlagAddition,
    });
  };
  addFlag = () => {
    let { appFlagNotes, appFlagType } = this.state;
    let { adminUserInfo } = this.props;
    if (appFlagNotes && appFlagType) {
      applicationService
        .flagApplication({
          actionTaker: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
          actionNote: appFlagNotes,
          flag: { definition: `${appFlagType.definition}`, status: appFlagType.value, colorCode: appFlagType.colorCode },
          applicationId: this.props.applicationId,
        })
        .then((res) => {
          this.props.getFlagSetStatus();
          this.setState(
            {
              flagAddedSuccessfully: true,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    appFlagNotes: "",
                    appFlagType: undefined,
                    flagAddedSuccessfully: false,
                  },
                  () => {
                    this.props.closeActionModal(true);
                    this.props.reload();
                  }
                );
              }, 1600);
            }
          );
        });
    }
  };
  render() {
    const { configData, closeFlagApplication } = this.props;
    const { appFlagLoading } = this.state;
    let flagsList = [];
    if (configData) {
      flagsList = configData.appFlags.flags.filter((item) => item.trigger !== "Automated");
    }
    const { applicationObj, flagAddedSuccessfully, confirmingFlagAddition, appFlagNotes, appFlagType } = this.state;
    return (
      <React.Fragment>
        <div className={`flag-apps-container ${flagAddedSuccessfully ? "flag-set" : ""}`}>
          {appFlagLoading ? (
            <LoaderComponent />
          ) : (
            <React.Fragment>
              {!flagAddedSuccessfully ? <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent> : ""}
            </React.Fragment>
          )}
          <button onClick={closeFlagApplication} className="back-button">
            <img src={LeftArrow} alt="arrow" />
            Back
          </button>
          <div className="section-below-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "FLAG THIS APPLICATION" }}></h2>
          </div>
          <div className="content-container">
            <div className="container-box">
              <div className="new-form-sections">
                <div className="form-inner-section">
                  <div className="form-label">Select an Internal Status for the application</div>
                  <br />
                  <div className="form-element">
                    <Input
                      placeholder={"Please select a flag"}
                      className={"flag-dropdown"}
                      type="dropdown"
                      name="appflagtype"
                      disabled={confirmingFlagAddition}
                      onChange={this.handleappFlagTypeChange}
                      value={appFlagType}
                      iconEnabled={true}
                      options={flagsList.map((flag) => {
                        return {
                          label: `${flag.status}`,
                          value: flag.status,
                          definition: flag.definition,
                          colorCode: flag.colorCode,
                          icon: <FlagIcon color={flag.colorCode} />,
                        };
                      })}
                    />
                  </div>
                  {confirmingFlagAddition ? (
                    <React.Fragment>
                      <div className="form-label info-text">
                        {flagAddedSuccessfully
                          ? `A flag has been successfully added to the application`
                          : `You are about to flag the application. Please verify the status and the notes and confirm their accuracy`}
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-inner-section">
                  <div className="form-label bold full">Notes:</div>
                </div>
                <div className="form-inner-section">
                  <div className="form-element full">
                    <Input
                      type="richtext"
                      disabled={confirmingFlagAddition}
                      value={appFlagNotes}
                      onChange={this.handleappFlagNotesChange}
                      name="note"
                    />
                  </div>
                </div>
              </div>
            </div>
            {flagAddedSuccessfully ? (
              ""
            ) : (
              <div className="button-block">
                <div className="button-set">
                  {confirmingFlagAddition ? (
                    <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmingFlagAddition} />
                  ) : (
                    <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.props.closeActionModal()} />
                  )}
                  {confirmingFlagAddition ? (
                    <Button className="signup-btn" title={"Add A Flag"} uppercase onClick={this.addFlag} />
                  ) : (
                    <Button
                      className="signup-btn"
                      title={"Confirm Flag Addition"}
                      uppercase
                      onClick={this.confirmingFlagAddition}
                      disabled={utils.isNullOrEmpty(appFlagType) || utils.isNullOrEmpty(utils.cleanHTMLText(appFlagNotes))}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapState(state) {
  const { adminUserInfo } = state.authentication;
  const { configData } = state.settings;
  return {
    adminUserInfo,
    configData,
  };
}
const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
};
export default connect(mapState, actionCreators)(FlagApplication);
