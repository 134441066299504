import React from "react";
import PostProjectRow from "./PostProjectRow";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import "./PostProject.css";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import Input from "../../../Common/Input/Input";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";
import Button from "../../../Common/Button/Button";
import Drawer from "../../../Common/Drawer/Drawer";
import FilterVector from "../../../../images/FilterVector.svg";
import firebase from "../../../../helpers/Firebase";

class PostProjectTab extends React.Component {
  constructor(props) {
    super(props);
    this.searchDebouncedData = debounce(this.searchData, 300);
    this.state = {
      openDrawer: false,
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      state: "Show All Applications",
      programStream: [],
      // showRetrofitAssignBtn: true,
      retrofitAppsDisableStartReview: []
    };
    this.db = firebase.db;
    this.collectionName = 'ieso';
    this.collectionDoc = 'retrofit';
    this.subCollectionName = 'reviewerState';
    this.docRef = null;
  }

  componentDidMount() {
    if (this.props.currentProgram === "retrofit") {
      this.props.loadAdminConfigRetrofit();
      this.docRef = this.db.collection(this.collectionName)
        .doc(this.collectionDoc)
        .collection(this.subCollectionName);

      this.docRef.onSnapshot(snapshot => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'modified') {
            let documentData = change.doc.data();
            const reviewrLastUpdateTime = typeof documentData.updatedAt === 'object' ? documentData.updatedAt.toDate() : new Date(documentData.updatedAt);
            const timeDifferenceInSeconds = Math.abs(reviewrLastUpdateTime - new Date()) / 1000;
            const retrofitAppsDisableStartReviewArr = this.state.retrofitAppsDisableStartReview.length > 0 ? this.state.retrofitAppsDisableStartReview.filter(F => {
              return F.application_id && F.application_id === documentData.currentReviewApplicationId &&
                F.reviewerState === 'In-Progress'
            }) : [];
            if (timeDifferenceInSeconds > 4 && documentData.reviewerState === "In-Progress") {
              documentData.reviewerState = 'Idle';
            }
            if (!retrofitAppsDisableStartReviewArr.length > 0) {
              console.log('data modified', documentData.currentReviewApplicationId, documentData.reviewerState)
              this.setState({
                retrofitAppsDisableStartReview: [...this.state.retrofitAppsDisableStartReview, { application_id: documentData.currentReviewApplicationId, reviewerState: documentData.reviewerState, updatedAt: documentData.updatedAt }]
              })
            } else {
              if (this.state.retrofitAppsDisableStartReview.length > 0 && documentData.reviewerState === 'Idle') {
                this.setState({
                  retrofitAppsDisableStartReview: this.state.retrofitAppsDisableStartReview.filter(F => F.application_id !== documentData.currentReviewApplicationId)
                })
              }
            }
          }
        });
      })
    } else {
      this.props.loadAdminConfig();
    }
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".preproject-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters("Post-Project", {
      SelectedStatusFilters,
    });
  };

  onFlagFilterChange = (SelectedFlagFilters) => {
    this.props.setFilters("Post-Project", {
      SelectedFlagFilters,
    });
  };

  onSearch = (searchInput) => {
    this.props.setFilters("Post-Project", {
      searchInput,
    });
  };

  searchData = (refresh = false) => {
    const { sortConfig, SelectedStatusFilters, SelectedFlagFilters, onlyActiveApps, currentProgram, StageFilters } = this.props;
    let config = {
      statuses: SelectedStatusFilters,
      stage: StageFilters,
      flags: SelectedFlagFilters,
      sort: sortConfig,
      activeApps: onlyActiveApps,
      program: currentProgram,
      stream: this.state.programStream,
    };
    if (this.props.currentProgram !== "retrofit") {
      this.props.getApplicationsByStage("Post-Project", this.props.searchInput, config, refresh ? 0 : this.props.appListOffset);
    } else {
      this.props.getApplicationsByStageRetrofit("Post-Project", this.props.searchInput, config, refresh ? 0 : this.props.appListOffset);
    }
  };

  handleListScroll = () => {
    if (this.props.moreApps) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    this.props.setFilters("Post-Project", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };

  onStateChange = (name, val) => {
    this.props.setFilters("Post-Project", {
      onlyActiveApps: !val ? [true] : [true, false],
    });
  };

  toggleDrawer = (status) => {
    let updateObj = {
      openDrawer: status,
    };
    this.setState(updateObj);
  };

  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let checkedStatus = this.state[name];
    if (checked) {
      // push selected value in list
      checkedStatus = [...checkedStatus, value];
    } else {
      // remove unchecked value from the list
      checkedStatus = checkedStatus.filter((x) => x !== value);
      // setLang(prev => prev.filter(x => x !== value));
    }
    this.setState({
      [name]: checkedStatus,
    });
  };
  onChangeRadio = (e) => {
    const { name, value } = e.target;
    if (value === "Show All Applications") {
      this.setState({ [name]: "Show All Applications" });
    } else if (value === "Hide Inactive Applications") {
      this.setState({ [name]: "Hide Inactive Applications" });
    } else {
      this.setState({ [name]: value });
    }
  };

  applyFiters = () => {
    const { state, selectedFlagFilters, selectedStatusFilters, programStream } = this.state;
    this.props.setFilters("Post-Project", {
      SelectedFlagFilters: selectedFlagFilters,
      SelectedStatusFilters: selectedStatusFilters,
      stream: programStream,
      onlyActiveApps: state === "Hide Inactive Applications" ? [true] : state === "Hide Active Applications" ? [false] : [true, false],
    });
    this.toggleDrawer(false);
  };

  clearFilters = () => {
    this.props.setFilters("Post-Project", {
      SelectedFlagFilters: [],
      SelectedStatusFilters: [],
      stream: [],
      onlyActiveApps: [true, false],
    });
    this.setState({
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      state: "Show All Applications",
      programStream: [],
    });
  };

  render() {
    const {
      appStage,
      applicationsByStageLoading,
      SelectedStatusFilters,
      SelectedFlagFilters,
      appList,
      configData,
      onlyActiveApps,
      currentProgram,
    } = this.props;
    // const FilterOptions = [
    //   "Application Under Review",
    //   "Application Draft",
    //   "Application Information Requested",
    //   "Application Submitted",
    //   "Application Rejected",
    // ];
    const { openDrawer, state, programStream, selectedFlagFilters, selectedStatusFilters } = this.state;
    let flagsList = [];
    let FilterOptions = [];
    if (configData) {
      if (currentProgram === "retrofit") {
        FilterOptions = configData.appStatuses["Post-Project"].map((item) => item.applicationStatusName);
        let postProjectFlags = configData.appFlags.flags.filter((item) => item.stage === "Post-Project");
        flagsList = postProjectFlags.map((item) => {
          return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
        });
      } else {
        flagsList = configData.appFlags
          ? configData.appFlags.flags.map((item) => {
            return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
          })
          : "";
        FilterOptions = configData.appStatuses["Post-Project"].map((item) => item.status);
      }
    }
    let diffPrograms = [
      { label: "Energy Savings For Business", value: "esb" },
      { label: "Comprehensive Energy Savings", value: "ces" },
    ];
    return (
      <div className="preproject-tab">
        <Drawer toggleDrawer={this.toggleDrawer} openDrawer={openDrawer} className={"program-panel-drawer"}>
          <div className="filter-drawer">
            <h3>FILTER BY</h3>
            {configData ? (
              <div className="all-filters">
                <div className="filter">
                  <label className="filter-label">Status</label>
                  <div className="filter-options">
                    {FilterOptions.map((status, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="selectedStatusFilters"
                            checked={selectedStatusFilters.includes(status)}
                            value={status}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />{" "}
                          {status}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">Flag</label>
                  <div className="filter-options">
                    {flagsList.map((flagItem, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="selectedFlagFilters"
                            checked={selectedFlagFilters.includes(flagItem.value)}
                            value={flagItem.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />{" "}
                          <span>{flagItem.icon}</span>
                          {flagItem.value}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">State</label>
                  <div className="filter-options">
                    {
                      <div onChange={(e) => this.onChangeRadio(e)}>
                        <label>
                          <input type="radio" checked={state === "Show All Applications"} value="Show All Applications" name="state" /> Show
                          All Applications
                        </label>
                        <label>
                          <input
                            type="radio"
                            checked={state === "Hide Inactive Applications"}
                            value="Hide Inactive Applications"
                            name="state"
                          />{" "}
                          Hide Inactive Applications
                        </label>
                        <label>
                          <input
                            type="radio"
                            checked={state === "Hide Active Applications"}
                            value="Hide Active Applications"
                            name="state"
                          />{" "}
                          Hide Active Applications
                        </label>
                      </div>
                    }
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">Program</label>
                  <div className="filter-options">
                    {diffPrograms.map((program, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="programStream"
                            checked={programStream.includes(program.value)}
                            value={program.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />
                          {program.label}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Button className="inversed-btn drawer-btn first-btn" title="CLEAR ALL FILTERS" onClick={this.clearFilters} />
          <Button className="drawer-btn" title="APPLY FILTERS" onClick={this.applyFiters} />
        </Drawer>
        <div className="applications-top-bar">
          <div className="applications-top-bar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for Applications" onClick={this.onSearch} />
          </div>
          {currentProgram === "esb" ? (
            <div className="applications-top-bar-filter-btn">
              <Button
                className="filter-btn"
                uppercase
                title={"Filters"}
                svg={FilterVector}
                onClick={() => this.setState({ openDrawer: true })}
              />
            </div>
          ) : null}
          {currentProgram === "esb" ? null : (
            <>
              <div className="applications-top-bar-filter">
                {configData ? (
                  <FilterDropdown
                    label={"Filter by Status"}
                    FilterOptions={FilterOptions}
                    SelectedFilters={SelectedStatusFilters}
                    onChange={this.onStatusFilterChange}
                    className={"program-filter"}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="applications-top-bar-filter">
                {configData ? (
                  <FilterDropdown
                    label={"Filter by Flag"}
                    iconBasedOption={true}
                    iconClass="flag-filter"
                    FilterOptions={flagsList}
                    SelectedFilters={SelectedFlagFilters}
                    onChange={this.onFlagFilterChange}
                    className={"program-filter"}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="appstate-details">
                {configData && currentProgram !== "retrofit" ? (
                  <React.Fragment>
                    <Input type="switch" name="appState" checked={onlyActiveApps.includes(false)} onChange={this.onStateChange} />
                    <div className="appstate-text">
                      {onlyActiveApps.includes(false) ? "Show All Applications" : "Hide Inactive Applications"}
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
        <div className="preproject-details-header preproject-details-row">
          <div className="preproject-details">
            {currentProgram !== "retrofit" ? (
              <React.Fragment>
                <div onClick={() => this.sortData("appNo", "string")}>ID</div>
                <div onClick={() => this.sortData("companyName", "string")}>Customer</div>
                <div onClick={() => this.sortData("contractor.label", "string")}>Contractor</div>
                <div onClick={() => this.sortData("status", "string")}>Status</div>
                <div onClick={() => this.sortData("Post-Project - Application Submitted Time", "date")}>Submitted Date</div>
                <div onClick={() => this.sortData("Reviewer Estimated Incentive", "number")}>Pre-Approved Incentive</div>
                <div onClick={() => this.sortData("Actual Project Cost", "string")}>Project Cost</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div onClick={() => this.sortData("application_id", "string")}>ID</div>
                <div onClick={() => this.sortData("application_name", "string")}>Application Name</div>
                <div onClick={() => this.sortData("application_project_type", "string")}>Application Project Type</div>
                <div onClick={() => this.sortData("applicant_account_id", "string")}>Email</div>
                <div onClick={() => this.sortData("multi_site", "string")}>Multi-Site</div>
                <div onClick={() => this.sortData("application_status_name", "string")}>Status</div>
                <div onClick={() => this.sortData("pre_project_approval_date", "date")}>Pre-Project Approved Date</div>
                <div onClick={() => this.sortData("post_project_submission_date", "date")}>Post Project Submission Date</div>
                <div onClick={() => this.sortData("total_actual_app_incentive", "number")}>Actual Incentive</div>
                <div onClick={() => this.sortData("previousFlagStatus", "string")}>Pre-Project QA</div>
                <div></div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="preproject-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {applicationsByStageLoading && appStage !== "Post-Project" ? (
            <SkeletonLoading />
          ) : appList && appList.length ? (
            appList.map((application, i) => (
              <PostProjectRow
                currentProgram={currentProgram}
                application={application}
                key={`application-${i}`}
                id={`application-${i}`}
                retrofitAppsDisableStartReview={this.props.currentProgram === 'retrofit' ? this.state.retrofitAppsDisableStartReview : {}}
                showActionModal={this.props.showActionModal}
                ShowMoreDetails={this.ShowMoreDetails}
              />
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { currentProgram } = state.authentication;
  const { appList, appListOffset, appStage, applicationsByStageLoading, appStatusLogsLoading, activeStatusLogs, moreApps } =
    state.application;
  return {
    currentProgram,
    appStage,
    appList,
    appListOffset,
    applicationsByStageLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    moreApps,
    configData,
  };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getApplicationsByStage: appActions.getApplicationsByStage,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getApplicationsByStageRetrofit: appActions.getApplicationsByStageRetrofit,
};

export default connect(mapState, actionCreators)(PostProjectTab);
