import React, { useCallback } from "react";
import "./FileUpload.css";
import { ReactComponent as FileUploadVector } from "../../../images/FileUploadVector.svg";
import { utils } from "../../../helpers/utils";
import Loader from "../../../images/Loader.svg";
import { useDropzone } from "react-dropzone";
import { applicationService } from "../../../services/applicationService";

const FileUpload = (props) => {
  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections.length === 0) {
      acceptedFiles.length &&
        acceptedFiles.map(async (fileObj) => {
          setUploadState(true);
          const file = fileObj;
          if (!file) {
            return;
          }
          try {
            console.log(props.folderPath)
            const scannedFile = await applicationService.virusScanForFileUploads(file, props.folderPath);
            const fileObj = {
              name: scannedFile[0].originalname.split(".")[0],
              extension: "." + scannedFile[0].originalname.split(".")[scannedFile[0].originalname.split(".").length - 1],
              url: scannedFile[0].url,
              isInfected: scannedFile[0].isInfected,
            };
            props.onChange(props.name, fileObj);
            setFileScanResult(scannedFile[0].isInfected);
            setUploadState(false);
          } catch (e) {
            console.log("File Upload", e);
            setUploadState(false);
          }
        });
    }
  }, []);
  const [uploadState, setUploadState] = React.useState(false);
  const [isInfected, setFileScanResult] = React.useState(undefined);
  const maxSize = 52428800;
  const maxFiles = props.maxFiles;
  const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, maxSize, maxFiles, disabled: uploadState });
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  const fileUploadErrorCode = fileRejections.length > 0 && fileRejections[0].errors ? fileRejections[0].errors[0].code : "";
  return (
    <div className={`file-upload-container${uploadState ? " disabled-file-upload-container" : ""}`}>
      <div {...getRootProps({ className: "file-dropzone" })}>
        <input {...getInputProps()} />
        {uploadState ? (
          <div className="file-upload-loader">
            <img src={Loader} alt="loader.." />
            <p>Scanning for virus...</p>
          </div>
        ) : (
          <FileUploadVector width="62" height="68" />
        )}
        {isInfected ? (
          <React.Fragment>
            <span className="error-message">
              A file you are trying to upload seems to be infected. Please try again with a different file. If you feel the file is clean,
              reach out to us on support@esbprogram.ca
            </span>
          </React.Fragment>
        ) : fileUploadErrorCode ? (
          <React.Fragment>
            <span className="error-message">
              {fileUploadErrorCode === "file-too-large"
                ? "Maximum allowed file size is 50 MB"
                : fileUploadErrorCode === "too-many-files"
                ? `You are trying to uplaod more than the maximum number(${props.maxFiles}) allowed files.`
                : ""}
            </span>
          </React.Fragment>
        ) : uploadState ? (
          ""
        ) : (
          props.placeholder || (
            <p>
              Select from computer (or) Drag and Drop <span>documents</span> to upload. Acceptable file types: .JPEG, .PNG, .PDF, .Docx
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default FileUpload;
