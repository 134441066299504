import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../../../../redux/actions/userActions";
import { participantActions } from "../../../../../redux/actions/participantActions";
import { appActions } from "../../../../../redux/actions/appActions";
import { utils } from "../../../../../helpers/utils";
import ResponsiveTable from "../../../../Common/ResponsiveTable/ResponsiveTable";
import ViewChangeRequests from "../../../ApplicationsBoard/ViewChangeRequests/ViewChangeRequests";
import "./ParticipantApplications.css";
import LoaderComponent from "../../../../Common/Loader/Loader";
import EmptyContainer from "../../../../Common/EmptyContainer/EmptyContainer";
import FilterDropdown from "../../../../Common/FilterDropdown/FilterDropdown";

class ParticipantApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      showChangeRequest: false,
      SelectedFilters: [],
    };
  }

  componentDidMount() {
    this.props.getParticipant(this.props.currentProgram, this.props.selectedParticipant.uid);
    this.props.getApps(this.props.selectedParticipant.userInfo.accountCategory, this.props.selectedParticipant.uid);
  }

  openChangeReq = (appId) => {
    this.setState(
      {
        showChangeRequest: true,
        selectedAppId: appId,
      },
      () => {
        this.props.getChangeRequests(appId);
      }
    );
  };

  closeChangeReq = () => {
    this.setState({
      showChangeRequest: false,
      selectedAppId: "",
    });
  };
  onFilterChange = (SelectedFilters) => {
    console.log("Stream ---------->", SelectedFilters);
    
    this.setState({
      SelectedFilters: SelectedFilters
    })
  };

  render() {
    const accCat = this.props.selectedParticipant.userInfo.accountCategory;
    const { selectedAppId, showChangeRequest, applicationsList, SelectedFilters } = this.state;
    let { activeParticipant, participantApplicationList, canEdit, activeChangeRequests, loadingApplications, currentProgram } = this.props;
    canEdit = !utils.isNullOrEmpty(canEdit) ? canEdit : true;
    let filteredList = participantApplicationList;
    if (!utils.isNullOrEmpty(SelectedFilters)){
      let stream = SelectedFilters[0];
      if (SelectedFilters.length === 1) {
        filteredList = participantApplicationList.filter((ele)=> ele.stream === stream.toLowerCase())
      } else {
        filteredList = participantApplicationList; 
      }
    }
    if (showChangeRequest) {
      return (
        <div className="view-requests-inside-apps-container">
          <ViewChangeRequests
            closeChangeReq={this.closeChangeReq}
            applicationId={selectedAppId}
            activeChangeRequests={activeChangeRequests}
          />
        </div>
      );
    }
    return (
      <div>
        {loadingApplications ? (
          <LoaderComponent />
        ) : (
          <div className="submitted-apps-container">
            {activeParticipant ? (
              <div className="section-header">
                <div className="section-top-details">
                  {accCat === "contractor" ? "CON" : "CUS"}-{activeParticipant.userNo}
                </div>
                <div className="section-top-details name-section">{activeParticipant.companyDetails.name}</div>
                <div className="section-top-details">{activeParticipant.email}</div>
              </div>
            ) : (
              ""
            )}
            <div className="section-header">
              <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Applications" }}></h2>
              {currentProgram === "esb" ? <div className="applications-stream-filter">
                <FilterDropdown
                  label={"Filter by Program"}
                  FilterOptions={["ESB", "CES"]}
                  SelectedFilters={SelectedFilters}
                  onChange={this.onFilterChange}
                  className={"program-filter"}
                />
              </div> : null}
            </div>
            {activeParticipant && filteredList.length ? (
              <div className="container-box">
                <ResponsiveTable
                  rows={filteredList.map((item) => {
                    return {
                      actionURL: (
                        <a rel="noreferrer" target="_blank" href={`/${currentProgram}/viewapplication/${item.id}`}>
                          View
                        </a>
                      ),
                      changeReq: (
                        <span className="link-button" onClick={() => this.openChangeReq(item.id)}>
                          View
                        </span>
                      ),
                      appId: `${currentProgram === "esb" ? item.stream === "ces" ? "CES" : "ESB" : "SPEED"}-${item.appNo}`,
                      participantName: accCat === "contractor" ? item.companyName : item.contractor ? item.contractor.label : "",
                      createdDate: utils.formatDate(item.createdAt),
                      estimatedIncentive: utils.formatAsCAD(item["Estimated Incentive"]),
                      ...item,
                    };
                  })}
                  headings={[
                    { title: "Application #ID", value: "appId" },
                    { title: accCat === "contractor" ? "Customer" : "Assigned Contractor", value: "participantName" },
                    { title: "Stage", value: "stage" },
                    { title: "Status", value: "status" },
                    { title: "Created Date", value: "createdDate" },
                    { title: "Estimated Incentive", value: "estimatedIncentive" },
                    { title: "Details", value: "actionURL" },
                    { title: "Change Requests", value: "changeReq" },
                  ]}
                />
              </div>
            ) : (
              <EmptyContainer>No Applications to Show</EmptyContainer>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { activeParticipant, activeStatusLogs } = state.participant;
  const { currentProgram } = state.authentication;
  const { participantApplicationList, activeChangeRequests, loadingApplications } = state.application;
  return { currentProgram, activeChangeRequests, activeParticipant, loadingApplications, activeStatusLogs, participantApplicationList };
}

const actionCreators = {
  showModal: userActions.showEditParticipantModal,
  getParticipant: participantActions.getParticipantDetails,
  getLogs: participantActions.getParticipantStatusLogs,
  getChangeRequests: appActions.getApplicationChangeRequests,
  getApps: appActions.getApplicationsForParticipant,
};

export default connect(mapState, actionCreators)(ParticipantApplications);
