import React from "react";
import "./MoveToWaitlisted.css";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import { applicationService } from "../../../../services/applicationService";
import { connect } from "react-redux";
import LoaderComponent from "../../../Common/Loader/Loader";
import { utils } from "../../../../helpers/utils";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
class RejectRecommendation extends React.Component {
  constructor(props) {
    super(props);
    const { adminUserInfo } = this.props;
    this.state = {
      rejectRecommendation: false,
      rejectedRecommendationSuccessfully: false,
      confirmingRejectRecommendation: false,
      activeAppId: "",
      rejectRecommendationLoading: true,
      applicationObj: {},
      statuses:[{ value: 'Application Under Review', label: 'Application Under Review' }, { value: 'QA/QC In Progress', label: 'QA/QC In Progress' }],
      waitlistDetails: {
        status: null,
        actionNote: "",
        actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
      },
    };
  }
  async componentDidMount() {
    this.props.loadAdminConfig();
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
        rejectRecommendationLoading: false,
      });
    });
  }
  onStatusChange = async  (value) => {
    const {waitlistDetails}= this.state;
    waitlistDetails['status']=value;
    this.setState({
      waitlistDetails:waitlistDetails,
    });
  
  };

  handleNotesChange = async (name, value) => {
    const {waitlistDetails}= this.state;
    waitlistDetails['actionNote']=value
    this.setState({
      waitlistDetails:waitlistDetails,
    });
  };
  confirmingRejectAction = () => {
    this.setState({
      confirmingRejectRecommendation: !this.state.confirmingRejectRecommendation,
    });
  };
  rejectRecommendationforApp = () => {
    let { waitlistDetails } = this.state;
    applicationService.updateApplication(this.props.applicationId, { status:"Application Waitlisted", actionNote:waitlistDetails['actionNote'],actionTaker:waitlistDetails['actionTaker']}).then((res) => {
      this.setState(
        {
          rejectedRecommendationSuccessfully: true,
        },
        () => {
          setTimeout(() => {
            this.props.closeActionModal(true);
          }, 5000);
        }
      );
    });
  };
  render() {
    const { configData } = this.props;
    const { statuses,rejectRecommendationLoading, approvalDetails, paymentApprovalParameters, rejectedRecommendationSuccessfully, waitlistDetails} = this.state;
    // let flagsList = [];
    // if (configData) {
    //   flagsList = configData.appFlags.flags.filter((item)=>item.trigger !== 'Automated');
    // }
    const { applicationObj, rejectRecommendation, confirmingRejectRecommendation } = this.state;
    return (
      <div className="assign-payment-forapproval-container">
        <React.Fragment>
          {rejectRecommendationLoading ? (
            <LoaderComponent />
          ) : (
            <React.Fragment>
              {!rejectRecommendation ? (
                <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
              ) : (
                // <div className="section-header">
                //   <div className="section-top-details">ESB-{applicationObj.appNo}</div>
                //   <div className="section-top-details name-section">{applicationObj.companyName}</div>
                //   {<div className="section-top-details">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>}
                // </div>
                ""
              )}
            </React.Fragment>
          )}
          <div className="section-below-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Move to Waitlist" }}></h2>
          </div>
          <div className="container-box">
            <div className="new-form-sections">
              <div className="form-inner-section">
                
                {confirmingRejectRecommendation ? (
                  <React.Fragment>
                    <div className="form-label">
                      {rejectedRecommendationSuccessfully
                        ? `This application has been moved to waitlist.`
                        : `You are about to move this application to waitlist. Please verify the notes and confirm their accuracy.`}
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="form-label">Are you sure you want to move this application to waitlist?</div>
                )}
              </div>
              <div className="form-inner-section">
                <div className="form-label bold full">Note:</div>
              </div>
              <div className="form-inner-section">
                <div className="form-element full">
                  <Input
                    type="richtext"
                    disabled={confirmingRejectRecommendation}
                    value={waitlistDetails.actionNote}
                    onChange={this.handleNotesChange}
                    name="note"
                  />
                </div>
              </div>
            </div>
          </div>
          {rejectedRecommendationSuccessfully ? (
            ""
          ) : (
            <div className="button-block">
              <div className="button-set">
                {confirmingRejectRecommendation ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmingRejectAction} /> 
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.props.closeActionModal()} />
                )}
                {confirmingRejectRecommendation ? (
                  <Button className="signup-btn" title={"Confirm"} uppercase onClick={this.rejectRecommendationforApp} />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"Move To Waitlist"}
                    uppercase
                    onClick={this.confirmingRejectAction}
                    disabled={utils.isNullOrEmpty(utils.cleanHTMLText(waitlistDetails.actionNote))}
                  />
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}
function mapState(state) {
  const { adminUserInfo } = state.authentication;
  const { configData } = state.settings;
  return {
    adminUserInfo,
    configData,
  };
}
const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
};
export default connect(mapState, actionCreators)(RejectRecommendation);
