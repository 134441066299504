import React from 'react';
import './MultiSelectWithCheckBox.css';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import CheckBox from '../CheckBox/CheckBox';
import Button from '../Button/Button'

const MultiSelectWithCheckBox = (props) => {
    return <div className="multi-select-checkbox-component">
        <div className="multi-select-checkbox-container">
            {props.value ? 
                <Button
                    title={props.placeholder ? props.placeholder : 'Select from the list'}
                    className='multi-select-checkbox-btn'
                    uppercase
                /> : null
            }
            <Select
                className={`multi-select-checkbox ${(Object.keys(props.value).length > 0) ? 'multi-select-checkbox-selected' : ''}`}
                multiple
                value={props.value}
                title={props.value.name}
                onChange={(e) => {
                    const { value } = e.target;
                    props.onChange(props.name, value);
                }}
                input={<Input />}
                renderValue={(selected) => selected.map((item)=>item.name).join(', ')}
            >   
                {props.options.length > 0 ? props.options.map((option) => (
                    <MenuItem className="multi-select-checkbox-option" key={`multi-${option.value}`} value={option}>
                        <CheckBox checked={props.value[option.value] ? true : false} />
                        <ListItemText primary={option.name} />
                    </MenuItem>
                )):null}
            </Select>
        </div>
    </div>
}


export default MultiSelectWithCheckBox;